import React, { useState } from "react";
import styled from "styled-components";
import { LoginForm } from "./loginForm";
// import logo from "../../assets/logo.svg";
import logo from "../../assets/LivoLogo white.png";
import homeTherapy from "../../assets/Therapy logo.png";

const BoxContainer = styled.div`
  width: 350px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  border-radius: 19px;
  background-color: #fff;
  box-shadow: 0 0 2px rgba(15, 15, 15, 0.28) !important;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 4rem;
`;

const TopContainer = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 1.8em;
  padding-bottom: 5em;
`;

const BackDrop = styled.div`
  width: 160%;
  height: 550px;
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 50%;
  transform: rotate(60deg);
  top: -290px;
  left: -70px;
  background: #0c344e;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
`;

const HeaderText = styled.h2`
  font-size: 30px;
  font-weight: 600;
  line-height: 1.24;
  color: #fff;
  z-index: 10;
  margin: 0;
  text-align: center;
`;

const SmallText = styled.h5`
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  z-index: 10;
  margin: 0;
  margin-top: 7px;
`;

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1.8em;
`;

const backdropVariants = {
  expanded: {
    width: "233%",
    height: "1050px",
    borderRadius: "20%",
    transform: "rotate(60deg)",
  },
  collapsed: {
    width: "160%",
    height: "550px",
    borderRadius: "50%",
    transform: "rotate(60deg)",
  },
};

export function AccountBox(props) {
  const [active, setActive] = useState("signin");

  return (
    <div style={{ background: "#FFE3D2", width: "100vw" }}>
      <BoxContainer>
        <TopContainer>
          <BackDrop initial={false} variants={backdropVariants} />
          {active === "signin" && (
            <HeaderContainer>
              <HeaderText
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                  // marginTop: "40px",
                }}
              >
                <img
                  // className=" text-center"
                  src={homeTherapy}
                  alt="logo"
                  style={{ width: "150px", height: "150px" }}
                />
                <HeaderText>Welcome</HeaderText>
                <SmallText>Please sign-in to continue!</SmallText>
              </HeaderText>
            </HeaderContainer>
          )}
        </TopContainer>
        <InnerContainer>{active === "signin" && <LoginForm />}</InnerContainer>
      </BoxContainer>
    </div>
  );
}
