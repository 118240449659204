import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  MenuItem,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import InputText from "../components/InputText";
import Textfiled from "../components/Textfiled";
import { buildingContext } from "../login/AuthProvider";
import { deleteAssetSlice, editAssetSlice } from "../Redux/AssetSlice";
import { getflatDetail } from "../services/allCommunityApi";
import { getAllBuilding } from "../services/allPropertiesAPI";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import BuildingDropDown from "../components/BuildingDropDown";

const EditAsset = ({ setHandleEditMode, assetData }) => {
  const [buildingDataList, setBuildingDataList] = useState([]);
  const [units, setUnits] = useState([]);
  const [addNewCategory, setAddNewCategory] = useState(false);
  const [handleToggle, setHandleToggle] = useState(assetData.isForFlat);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedBuilding } = React.useContext(buildingContext);

  const ASSET_CONDITIONS = ["Working", "Maintenance", "Decomissioned"];
  const ASSET_CATEGORY = [
    "Cutlery/Kitchen Items",
    "Fixtures",
    "Furnitures",
    "Machinery",
  ];

  const editAssetSchema = yup.object().shape({
    name: yup.string().trim().required("This field  is required"),
    buildingId: yup.string().required("This field  is required"),
    category: yup.string().trim().required("This field  is required"),
    condition: yup.string().required("This field  is required"),
    ...(handleToggle && {
      flatId: yup.string().required("This field  is required"),
    }),
    ...(!handleToggle && {
      floor: yup.string().trim().required("This field  is required"),
    }),
    ...(!handleToggle && {
      location: yup.string().trim().required("This field  is required"),
    }),
  });
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: assetData?.id,
      name: assetData?.name,
      buildingId: assetData?.isForFlat
        ? assetData?.flat?.buildingId
        : assetData?.buildingId,
      category: assetData?.category,
      condition: assetData?.condition,
      flatId: assetData?.flat?.id,
      floor: assetData?.floor,
      location: assetData?.location,
      brand: assetData?.brand,
      model: assetData?.model,
      isBuilding: assetData?.isForFlat,
      description: assetData?.description,
    },
    mode: "onChange",
    resolver: yupResolver(editAssetSchema),
  });
  const [isBuilding, buildingId] = watch(["isBuilding", "buildingId"]);
  useEffect(() => {
    setHandleToggle(isBuilding);
  }, [isBuilding]);

  const fetchAllBuildingDataList = async () => {
    let buildingFromStorage = sessionStorage.getItem("building");
    if (buildingFromStorage?.length > 0) {
      const resp = await getAllBuilding();
      let specificBuilding = resp?.data?.data?.rows?.filter(
        (building) => building.id === buildingFromStorage
      );
      setBuildingDataList(specificBuilding);
    } else {
      const resp = await getAllBuilding();
      setBuildingDataList(resp.data.data.rows);
    }
  };

  const FetchUnitData = async (id) => {
    const resp = await getflatDetail(id);
    setUnits(resp.data?.data?.rows);
  };

  useEffect(() => {
    if (selectedBuilding || buildingId) {
      FetchUnitData(selectedBuilding ? selectedBuilding : buildingId);
    }
  }, [buildingId, selectedBuilding]);

  useEffect(() => {
    fetchAllBuildingDataList();
  }, []);

  const { isSuccess } = useSelector((state) => state.AssetData);

  const onSubmit = (data) => {
    const assetId = data.id;
    delete data.id;
    if (data?.isBuilding === true) {
      delete data.floor;
      delete data.location;
      delete data.buildingId;
    } else {
      delete data?.flatId;
    }
    dispatch(editAssetSlice({ data, assetId }));
    {
      isSuccess && setHandleEditMode(false);
    }
  };
  const handleDeleteAsset = () => {
    const assetId = assetData.id;
    dispatch(deleteAssetSlice({ assetId, navigate }));
  };
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <h4>Edit Asset Details</h4>
        <Box>
          <Button
            style={{ textDecoration: "underLine" }}
            onClick={() => {
              setHandleEditMode(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            style={{ textDecoration: "underLine" }}
            onClick={handleSubmit(onSubmit)}
          >
            Save Changes
          </Button>
          <Button onClick={() => handleDeleteAsset()}>
            <DeleteIcon />
          </Button>
        </Box>
      </Box>
      <Box sx={{ marginTop: "50px", display: "flex" }}>
        <Typography>Asset for Building</Typography>
        <Switch
          size="small"
          name="isBuilding"
          {...register("isBuilding")}
          defaultChecked={assetData?.isForFlat}
          inputProps={{
            "aria-label": "controlled",
          }}
        />
        <Typography>Asset for Flat</Typography>
      </Box>
      <Box marginTop="30px">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box>
            <BuildingDropDown
              displayedOptions={buildingDataList}
              name="buildingId"
              register={register}
              label="Select Building*"
              width="250px"
              innerWidth="250px"
              buildingId={buildingId}
            />
            <Typography
              sx={{
                fontStyle: "italic",
                fontSize: "13px",
                color: "red",
              }}
            >
              {errors?.buildingId?.message && "Building field is required"}
            </Typography>
          </Box>

          <Box sx={{ marginTop: "-13px" }}>
            <Textfiled
              label={"Name*"}
              type="text"
              width="300px"
              name="name"
              maxLength="25"
              setValue={setValue}
              register={register}
            />
            <Typography
              sx={{
                fontStyle: "italic",
                fontSize: "13px",
                color: "red",
              }}
            >
              {errors?.name?.message && "Name is a required field"}
            </Typography>
          </Box>
        </Box>
        {isBuilding && (
          <Box>
            <Box marginTop="30px">
              <TextField
                select
                label="Flat Name*"
                inputProps={{ maxLength: 100 }}
                multiline
                maxRows={4}
                maxLength={10}
                defaultValue={assetData?.flat?.id}
                {...register("flatId")}
                name="flatId"
                variant="standard"
                style={{
                  fontFamily: "Barlow",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  width: "100%",
                  lineHeight: "19px",
                }}
              >
                {units?.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name_en}
                    </MenuItem>
                  );
                })}
              </TextField>
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "red",
                }}
              >
                {errors?.flatId?.message && "Flat is a required field"}
              </Typography>
            </Box>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "40px",
          }}
        >
          <Box>
            <TextField
              select
              label="Condition"
              inputProps={{ maxLength: 100 }}
              multiline
              maxRows={4}
              maxLength={10}
              {...register("condition")}
              name="condition"
              variant="standard"
              defaultValue={assetData?.condition}
              style={{
                fontFamily: "Barlow",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "16px",
                width: "300px",
                lineHeight: "19px",
              }}
            >
              {ASSET_CONDITIONS?.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </TextField>
            <Typography
              sx={{
                fontStyle: "italic",
                fontSize: "13px",
                color: "red",
              }}
            >
              {errors?.condition?.message}
            </Typography>
          </Box>
          <Box>
            {addNewCategory ? (
              <>
                <Box marginTop="-12px">
                  <Textfiled
                    label="Category*"
                    type="text"
                    width="300px"
                    name="category"
                    defaultValue={assetData?.category}
                    maxLength="25"
                    register={register}
                    autofocus={true}
                  />
                </Box>
              </>
            ) : (
              <>
                <TextField
                  select
                  label="Category*"
                  inputProps={{ maxLength: 100 }}
                  multiline
                  maxRows={4}
                  maxLength={10}
                  defaultValue={assetData?.category}
                  name="category"
                  {...register("category")}
                  variant="standard"
                  style={{
                    fontFamily: "Barlow",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    width: "300px",
                    lineHeight: "19px",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setAddNewCategory(true);
                    }}
                  >
                    + Add New
                  </MenuItem>
                  {ASSET_CATEGORY?.map((option) => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </TextField>
                <Typography
                  sx={{
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "red",
                  }}
                >
                  {errors?.category?.message && "Category is a required field"}
                </Typography>
              </>
            )}
          </Box>
        </Box>
        {!isBuilding && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "40px",
              }}
            >
              <Box>
                <InputText
                  label="Floor*"
                  name="floor"
                  register={register}
                  width="300px"
                />
                <Typography
                  sx={{
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "red",
                  }}
                >
                  {errors?.floor?.message && "Floor is a required field"}
                </Typography>
              </Box>
              <Box marginTop="-13px">
                <Textfiled
                  label={"Location*"}
                  type="text"
                  width="300px"
                  name="location"
                  maxLength="25"
                  register={register}
                />
                <Typography
                  sx={{
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "red",
                  }}
                >
                  {errors?.location?.message && "Location is a required field"}
                </Typography>
              </Box>
            </Box>
          </>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "40px",
          }}
        >
          <Box>
            <Textfiled
              label={"Brand"}
              type="text"
              width="300px"
              name="brand"
              maxLength="25"
              register={register}
            />
          </Box>
          <Box>
            <Textfiled
              label={"Model"}
              type="text"
              width="300px"
              name="model"
              maxLength="25"
              register={register}
            />
          </Box>
        </Box>
        <Box width="100%">
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextareaAutosize
                {...field}
                aria-label="minimum height"
                minRows={2}
                maxLength={300}
                placeholder="Description"
                style={{
                  width: "100%",
                  minHeight: "50px",
                  marginTop: "20px",
                  paddingLeft: "10px",
                  borderRadius: "10px",
                }}
              />
            )}
          />
        </Box>
        <Box>
          {/* <button
            type="submit"
            id="savebtn"
            className="Button"
            variant="contained"
            color="success"
            style={{
              border: "1px solid #0C344E",
              color: "#0C344E",
              background: "#0c344e",
              width: "187px",
              height: "48px",
              fontFamily: "Barlow",
              fontSize: "16px",
              marginTop: "20px",
              borderRadius: "10px",
              textTransform: "capitalize",
              color: "white",
            }}
            onClick={handleSubmit(onSubmit)}
          >
            Add Asset
          </button> */}
        </Box>
      </Box>
    </>
  );
};

export default EditAsset;
