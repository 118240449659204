import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import OpenDoc from "../PropertyName/OpenDoc";
import { fetchAllAssetName } from "../Redux/AssetSlice";
import {
  CategoryDetailSlice,
  addPreventativeSlice,
} from "../Redux/PreventativeSlice";
import pdfPhoto from "../StaffDetail/assets/pdfPhoto.png";
import BuildingDropDown from "../components/BuildingDropDown";
import DatePicker from "../components/DatePicker";
import Loader from "../components/Loader";
import Textfiled from "../components/Textfiled";
import { buildingContext } from "../login/AuthProvider";
import { getflatDetail } from "../services/allCommunityApi";
import { getAllBuilding, uploadFile } from "../services/allPropertiesAPI";
import "./preventative.css";

const AddPreventative = () => {
  const dispatch = useDispatch();
  const { allAssetName, isLoading: assetLoading } = useSelector(
    (state) => state.AssetData
  );
  const { getCategoriesData, isLoading } = useSelector(
    (state) => state.PreventativeData
  );
  const [searchText, setSearchText] = useState("");
  const [searchAssetText, setSearchAssetText] = useState("");
  const [documentUrl, setDocumentUrl] = useState([]);
  const [getLengthDoc, setGetLengthDoc] = useState();
  const [getImageDataDoc, setGetImageDataDoc] = useState([]);
  const [handleLoader, setHandleLoader] = useState(false);
  const [buildingDataList, setBuildingDataList] = useState([]);
  const [units, setUnits] = useState([]);
  const { selectedBuilding } = React.useContext(buildingContext);
  const [addNewCategory, setAddNewCategory] = useState(false);
  const [cronExpression, setCronExpression] = useState({
    daily: ["0", "0", "0", "?", "*", "*", "*"],
  });
  const [payload, setPayload] = useState([]);
  const navigate = useNavigate();
  const [chip, setChip] = useState({
    Sun: false,
    Mon: false,
    Tue: false,
    Wed: false,
    Thru: false,
    Fri: false,
    Sat: false,
  });
  const dateList = [
    { name: "1st Day", value: "1" },
    { name: "2nd Day", value: "2" },
    { name: "3rd Day", value: "3" },
    { name: "4th Day", value: "4" },
    { name: "5th Day", value: "5" },
    { name: "6th Day", value: "6" },
    { name: "7th Day", value: "7" },
    { name: "8th Day", value: "8" },
    { name: "9th Day", value: "9" },
    { name: "10th Day", value: "10" },
    { name: "11th Day", value: "11" },
    { name: "12th Day", value: "12" },
    { name: "13th Day", value: "13" },
    { name: "14th Day", value: "14" },
    { name: "15th Day", value: "15" },
    { name: "16th Day", value: "16" },
    { name: "17th Day", value: "17" },
    { name: "18th Day", value: "18" },
    { name: "19th Day", value: "19" },
    { name: "20th Day", value: "20" },
    { name: "21th Day", value: "21" },
    { name: "22th Day", value: "22" },
    { name: "23th Day", value: "23" },
    { name: "24th Day", value: "24" },
    { name: "25th Day", value: "25" },
    { name: "26th Day", value: "26" },
    { name: "27th Day", value: "27" },
    { name: "28th Day", value: "28" },
  ];
  const MonthsList = ["1", "2", "3", "4", "5", "6"];
  const MonthName = [
    { name: "January", value: "1" },
    { name: "February", value: "2" },
    { name: "March", value: "3" },
    { name: "April", value: "4" },
    { name: "May", value: "5" },
    { name: "June", value: "6" },
    { name: "July", value: "7" },
    { name: "August", value: "8" },
    { name: "September", value: "9" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];

  const handleDaysChange = (key, index) => {
    setChip((prevState) => {
      return {
        ...prevState,
        [`${key}`]: !chip[key],
      };
    });

    if (payload.includes(index)) {
      const filteredArr = payload.filter((item) => item !== index);
      setPayload(filteredArr);
    } else {
      setPayload([...payload, index]);
    }
  };
  const handlePattern = (pattern) => {
    if (pattern === "daily") {
      setCronExpression({
        daily: ["0", "0", "0", "?", "*", "*", "*"],
      });
    } else if (pattern === "weekly") {
      setCronExpression({
        weekly: ["0", "0", "0", "?", "*", "d", "*"],
      });
    } else if (pattern === "monthly") {
      setCronExpression({
        monthly: ["0", "0", "0", "d", "d", "?", "*"],
      });
    } else {
      setCronExpression({
        yearly: ["0", "0", "0", "d", "d", "?", "*"],
      });
    }
  };

  const [getFrequencyType, setGetFrequencyType] = useState("");
  const ppmSchema = yup.object().shape({
    name: yup.string().trim().required("This field  is required"),
    frequencyType: yup.string().trim().required("This field  is required"),
    buildingId: yup.string().required("This field  is required"),
    assetId: yup.string().required("This field  is required"),
    category: yup.string().trim().required("This field  is required"),
    ...(getFrequencyType === "Pattern" && {
      validFrom: yup.string().required("This field  is required"),
    }),
    ...(getFrequencyType === "Pattern" && {
      validTill: yup.string().required("This field  is required"),
    }),
    ...(getFrequencyType === "Custom" && {
      runDate: yup.string().required("This field  is required"),
    }),
    endTime: yup.string().required("This field  is required"),
    startTime: yup.string().required("This field  is required"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "Building",
      priority: "Normal",
      name: "",
      buildingId: "",
      assetId: "",
      flatId: "",
      typeId: "",
      validTill: "",
      validFrom: "",
      daysCountForMonths: "1st Day",
      monthCountForMonths: "1",
      dayCountForYear: "1st Day",
      monthsNameForYear: "January",
      endTime: "",
      startTime: "",
      frequencyType: "Pattern",
      cron: [],
      runDate: "",
      category: "",
    },
    mode: "onChange",
    resolver: yupResolver(ppmSchema),
  });
  const [frequencyType, startTime, endTime, buildingId, flatId, type] = watch([
    "frequencyType",
    "startTime",
    "endTime",
    "buildingId",
    "flatId",
    "type",
  ]);
  useEffect(() => {
    setGetFrequencyType(frequencyType);
  }, [frequencyType]);

  const handleChange = (e, newValue) => {
    let date = moment(new Date()).format("YYYY-MM-DD");
    let todayDateTime = moment(
      `${date} ${e.target.value}`,
      "YYYY-MM-DD HH:mm:ss"
    ).format();
    setValue(`${newValue}`, todayDateTime);
  };

  const fetchAllBuildingDataList = async () => {
    let buildingFromStorage = sessionStorage.getItem("building");
    if (buildingFromStorage?.length > 0) {
      const resp = await getAllBuilding();
      let specificBuilding = resp?.data?.data?.rows?.filter(
        (building) => building.id === buildingFromStorage
      );

      setBuildingDataList(specificBuilding);
    } else {
      const resp = await getAllBuilding();

      setBuildingDataList(resp.data.data.rows);
    }
  };

  const FetchUnitData = async (id) => {
    const resp = await getflatDetail(id);
    setUnits(resp.data?.data?.rows);
  };
  useEffect(() => {
    dispatch(CategoryDetailSlice());
  }, []);

  useEffect(() => {
    if (flatId && type === "Flat") {
      dispatch(fetchAllAssetName({ flat: flatId }));
    } else if (buildingId && type === "Building") {
      dispatch(fetchAllAssetName({ building: buildingId }));
    }
  }, [buildingId, flatId]);

  useEffect(() => {
    if (buildingId) FetchUnitData(buildingId);
  }, [buildingId]);

  useEffect(() => {
    fetchAllBuildingDataList();
  }, []);

  const onSubmit = (data) => {
    data["type"] = "Asset";
    data["typeId"] = data.assetId;
    data["validTill"] = moment(data.validTill).format("YYYY/MM/DD");
    data["validFrom"] = moment(data.validFrom).format("YYYY/MM/DD");
    data["documents"] = documentUrl;
    data["pay"] = payload?.forEach((element, index) => {
      payload[index] = element + 1;
    });
    data["pay"] = payload.toString();
    if (data.frequencyType === "Custom") {
      data["schedules"] = [
        {
          startTime: data.startTime,
          endTime: data.endTime,
          runDate: data.runDate,
        },
      ];
      const {
        buildingId,
        assetId,
        dayCountForYear,
        daysCountForMonths,
        flatId,
        monthCountForMonths,
        monthsNameForYear,
        cron,
        pay,
        validFrom,
        validTill,
        ...dataRest
      } = data;
      dispatch(addPreventativeSlice({ dataRest, navigate }));
    } else {
      if (cronExpression?.daily) {
        data["cron"] = cronExpression.daily;
        data["frequency"] = "Daily";
      } else if (cronExpression?.weekly) {
        cronExpression.weekly[5] = data.pay;
        data["cron"] = cronExpression.weekly;
        data["frequency"] = "Weekly";
      } else if (cronExpression?.monthly) {
        cronExpression.monthly[3] = data.daysCountForMonths;
        cronExpression.monthly[4] = `1/${data.monthCountForMonths}`;
        data["cron"] = cronExpression.monthly;
        data["frequency"] = "Monthly";
      } else if (cronExpression?.yearly) {
        cronExpression.yearly[4] = data.monthsNameForYear;
        cronExpression.yearly[3] = data.dayCountForYear;
        data["cron"] = cronExpression.yearly;
        data["frequency"] = "Yearly";
      }
      const {
        buildingId,
        assetId,
        dayCountForYear,
        daysCountForMonths,
        flatId,
        monthCountForMonths,
        monthsNameForYear,
        pay,
        runDate,
        ...dataRest
      } = data;
      dispatch(addPreventativeSlice({ dataRest, navigate }));
    }
  };

  // document upload functionality
  const handleRemoveDoc = (att) => {
    const filtered = documentUrl.filter((item) => item !== att);
    setDocumentUrl(filtered);
  };
  const getDocUrl = async () => {
    setHandleLoader(true);
    try {
      const res = await uploadFile(getImageDataDoc);
      if (res.data.status === "success") {
        const getUrl = res.data.data.map((current) => current.location);
        setDocumentUrl([...documentUrl, ...getUrl]);
      } else {
        toast("Document is not uploaded. Please check your connection");
      }
    } catch {}
    setHandleLoader(false);
  };
  useEffect(() => {
    if (getLengthDoc) {
      getDocUrl();
    }
  }, [getLengthDoc]);
  return (
    <div className="top-level-container">
      {(isLoading || assetLoading || handleLoader) && <Loader />}
      <div className="home">
        <h4>Add PPM</h4>
        <Grid container spacing={2}>
          <Grid item xs={8} my={2}>
            <Box display="flex" justifyContent="space-between">
              <Box marginTop="-12px">
                <Textfiled
                  label={"Title*"}
                  type="text"
                  width="300px"
                  name="name"
                  maxLength="50"
                  register={register}
                />
                <Typography
                  sx={{
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "red",
                  }}
                >
                  {errors?.name?.message && "This field is required"}
                </Typography>
              </Box>
              <Box>
                {addNewCategory ? (
                  <>
                    <Box marginTop="-12px">
                      <Textfiled
                        label="Category"
                        type="text"
                        width="300px"
                        name="category"
                        maxLength="25"
                        register={register}
                        autofocus={true}
                      />
                      <Typography
                        sx={{
                          fontStyle: "italic",
                          fontSize: "13px",
                          color: "red",
                        }}
                      >
                        {/* {errors?.category?.message && "This field is required"} */}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <TextField
                      select
                      label="Category*"
                      inputProps={{ maxLength: 50 }}
                      multiline
                      maxRows={4}
                      maxLength={10}
                      name="category"
                      {...register("category")}
                      variant="standard"
                      style={{
                        fontFamily: "Barlow",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        width: "300px",
                        lineHeight: "19px",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          setAddNewCategory(true);
                        }}
                      >
                        + Add New
                      </MenuItem>
                      {getCategoriesData?.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <Typography
                      sx={{
                        fontStyle: "italic",
                        fontSize: "13px",
                        color: "red",
                      }}
                    >
                      {errors?.category?.message && "This field is required"}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
            <Box display="display" justifyContent="space-between" mt={2}>
              <FormLabel id="demo-radio-buttons-group-label">
                Selected Asset From
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="type"
                defaultValue="Building"
                onChange={(event) => setValue("type", event.target.value)}
                row
              >
                <FormControlLabel
                  value="Building"
                  control={<Radio />}
                  label="Building"
                />
                <FormControlLabel
                  value="Flat"
                  control={<Radio />}
                  label="Unit"
                />
              </RadioGroup>
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                my={2}
              >
                <Box>
                  <BuildingDropDown
                    displayedOptions={buildingDataList}
                    name="buildingId"
                    register={register}
                    label="Select Property Name"
                    width="300px"
                    innerWidth="300px"
                    buildingId={buildingId}
                    selectedBuilding={selectedBuilding}
                  />

                  <Typography
                    sx={{
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "red",
                    }}
                  >
                    {errors?.buildingId?.message && "This field is required"}
                  </Typography>
                </Box>
                {type === "Flat" && (
                  <Box>
                    <FormControl>
                      <InputLabel
                        id="search-select-label"
                        sx={{ marginLeft: "-14px" }}
                      >
                        Select Unit*
                      </InputLabel>
                      <Select
                        MenuProps={{ autoFocus: false }}
                        sx={{ width: "300px" }}
                        labelId="search-select-label"
                        id="search-select"
                        disabled={!buildingId && "true"}
                        variant="standard"
                        {...register("flatId")}
                        name="flatId"
                        label={"Select Unit"}
                        onClose={() => setSearchText("")}
                      >
                        <ListSubheader>
                          <TextField
                            size="small"
                            variant="standard"
                            placeholder="Type to search..."
                            sx={{
                              width: "300px",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        {units
                          ?.filter((option) =>
                            option?.name_en
                              ?.toLowerCase()
                              .includes(searchText.toLowerCase())
                          )
                          .map((option, i) => (
                            <MenuItem key={option?.id} value={option?.id}>
                              {option?.name_en}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {/* <Typography
                      sx={{
                        fontStyle: "italic",
                        fontSize: "13px",
                        color: "red",
                      }}
                    >
                      {errors?.flatId?.message && "This field is required"}
                    </Typography> */}
                  </Box>
                )}
              </Box>

              <Box mb={4}>
                <FormControl>
                  <InputLabel
                    id="search-select-label"
                    sx={{ marginLeft: "-14px" }}
                  >
                    Asset Name*
                  </InputLabel>
                  <Select
                    MenuProps={{ autoFocus: false }}
                    sx={{ width: "300px" }}
                    labelId="search-select-label"
                    id="search-select"
                    disabled={!buildingId && "true"}
                    variant="standard"
                    {...register("assetId")}
                    name="assetId"
                    label={"Asset Name*"}
                    onClose={() => setSearchAssetText("")}
                  >
                    <ListSubheader>
                      <TextField
                        size="small"
                        variant="standard"
                        placeholder="Type to search..."
                        sx={{
                          width: "300px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => setSearchAssetText(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                    </ListSubheader>
                    {allAssetName?.rows
                      ?.filter((option) =>
                        option?.name
                          ?.toLowerCase()
                          .includes(searchAssetText.toLowerCase())
                      )
                      .map((option, i) => (
                        <MenuItem key={option?.id} value={option?.id}>
                          {option?.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <Typography
                  sx={{
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "red",
                  }}
                >
                  {errors?.assetId?.message && "This field is required"}
                </Typography>
              </Box>
              <FormLabel id="demo-radio-buttons-group-label">
                Priority*
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Normal"
                name="priority"
                onChange={(event) => setValue("priority", event.target.value)}
                row
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                />
                <FormControlLabel
                  value="High"
                  control={<Radio />}
                  label="High"
                />
                <FormControlLabel value="Low" control={<Radio />} label="Low" />
              </RadioGroup>
            </Box>
            <Box my={2}>
              <FormLabel id="demo-radio-buttons-group-label">
                Checklist*
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="frequencyType"
                defaultValue="Pattern"
                onChange={(event) =>
                  setValue("frequencyType", event.target.value)
                }
                row
              >
                <FormControlLabel
                  value="Pattern"
                  control={<Radio />}
                  label="Pattern"
                />
                <FormControlLabel
                  value="Custom"
                  control={<Radio />}
                  label="Custom"
                />
              </RadioGroup>
              {frequencyType === "Pattern" && (
                <Box width="100%" display="flex" justifyContent="space-between">
                  <Box>
                    <DatePicker
                      label="Valid From*"
                      Controller={Controller}
                      control={control}
                      name="validFrom"
                      register={register}
                      width={"300px"}
                      sx={{ marginRight: "1rem" }}
                    />
                    <Typography
                      sx={{
                        fontStyle: "italic",
                        fontSize: "13px",
                        color: "red",
                      }}
                    >
                      {errors?.validFrom?.message && "This field is required"}
                    </Typography>
                  </Box>
                  <Box>
                    <DatePicker
                      label="Valid Till*"
                      Controller={Controller}
                      control={control}
                      name="validTill"
                      register={register}
                      width={"300px"}
                    />
                    <Typography
                      sx={{
                        fontStyle: "italic",
                        fontSize: "13px",
                        color: "red",
                      }}
                    >
                      {errors?.validTill?.message && "This field is required"}
                    </Typography>
                  </Box>
                </Box>
              )}
              <Box my={2}>
                {frequencyType === "Pattern" && (
                  <Box>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Frequency
                    </FormLabel>
                    <br />
                    <Box my={4}>
                      <Box>
                        <Button
                          value="cronExpression.daily"
                          name="daily"
                          onClick={(e) => handlePattern("daily")}
                          className={
                            cronExpression.daily
                              ? "frequencyBtnActive"
                              : "frequencyBtn"
                          }
                        >
                          Daily
                        </Button>
                        <Button
                          value="cronExpression.weekly"
                          name="weekly"
                          onClick={(e) => handlePattern("weekly")}
                          className={
                            cronExpression.weekly
                              ? "frequencyBtnActive"
                              : "frequencyBtn"
                          }
                        >
                          Weekly
                        </Button>
                        <Button
                          value="cronExpression.monthly"
                          name="monthly"
                          onClick={(e) => handlePattern("monthly")}
                          className={
                            cronExpression.monthly
                              ? "frequencyBtnActive"
                              : "frequencyBtn"
                          }
                        >
                          Monthly
                        </Button>
                        <Button
                          value="cronExpression.yearly"
                          name="Yearly"
                          onClick={(e) => handlePattern("yearly")}
                          className={
                            cronExpression.yearly
                              ? "frequencyBtnActive"
                              : "frequencyBtn"
                          }
                        >
                          Yearly
                        </Button>
                      </Box>
                      {cronExpression?.weekly && (
                        <Box display="flex" my={2} mx={1}>
                          {Object.entries(chip).map(([key, data], index) => {
                            return (
                              <Box key={data.index} sx={{ padding: "4px" }}>
                                <div
                                  onClick={(e) => handleDaysChange(key, index)}
                                  style={{
                                    backgroundColor: data
                                      ? "#0C344E"
                                      : "#EFF0F6",
                                    color: data ? "#fff" : "#0C344E",
                                    borderRadius: "1.5rem",
                                    padding: "8px",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    cursor: "pointer",
                                    width: "5rem",
                                    textAlign: "center",
                                  }}
                                >
                                  {key}
                                </div>
                              </Box>
                            );
                          })}
                        </Box>
                      )}
                      {cronExpression?.monthly && (
                        <Box display="flex" my={4} mx={1}>
                          <Typography mr={2}>On the</Typography>
                          <TextField
                            select
                            inputProps={{ maxLength: 100 }}
                            multiline
                            maxRows={4}
                            maxLength={10}
                            defaultValue="1"
                            {...register("daysCountForMonths")}
                            name="daysCountForMonths"
                            variant="standard"
                            style={{
                              fontFamily: "Barlow",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "16px",
                              width: "150px",
                              lineHeight: "19px",
                            }}
                          >
                            {dateList?.map((option) => {
                              return (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.name}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                          <Typography mx={2}>of every</Typography>
                          <TextField
                            select
                            inputProps={{ maxLength: 100 }}
                            multiline
                            maxRows={4}
                            maxLength={10}
                            defaultValue="1"
                            {...register("monthCountForMonths")}
                            name="monthCountForMonths"
                            variant="standard"
                            style={{
                              fontFamily: "Barlow",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "16px",
                              width: "50px",
                              lineHeight: "19px",
                            }}
                          >
                            {MonthsList?.map((option) => {
                              return (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                          <Typography>month (s)</Typography>
                        </Box>
                      )}
                      {cronExpression.yearly && (
                        <Box display="flex" my={4} mx={1}>
                          <Typography mr={2}>Every</Typography>
                          <TextField
                            select
                            inputProps={{ maxLength: 100 }}
                            multiline
                            maxRows={4}
                            maxLength={10}
                            defaultValue="1"
                            {...register("monthsNameForYear")}
                            name="monthsNameForYear"
                            variant="standard"
                            style={{
                              fontFamily: "Barlow",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "16px",
                              width: "100px",
                              lineHeight: "19px",
                            }}
                          >
                            {MonthName?.map((option) => {
                              return (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.name}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                          <Typography mx={2}>on the</Typography>
                          <TextField
                            select
                            inputProps={{ maxLength: 100 }}
                            multiline
                            maxRows={4}
                            maxLength={10}
                            defaultValue="1"
                            {...register("dayCountForYear")}
                            name="dayCountForYear"
                            variant="standard"
                            style={{
                              fontFamily: "Barlow",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "16px",
                              width: "100px",
                              lineHeight: "19px",
                            }}
                          >
                            {dateList?.map((option) => {
                              return (
                                <MenuItem key={option} value={option.value}>
                                  {option.name}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Box>
                    <label>Start Time</label>
                    <br />
                    <input
                      type="time"
                      id="startTime"
                      name="startTime"
                      placeholder="Start Time"
                      style={{
                        width: "300px",
                        height: "3rem",
                        paddingLeft: "1rem",
                      }}
                      onChange={(e) => handleChange(e, "startTime")}
                    />
                    <Typography
                      sx={{
                        fontStyle: "italic",
                        fontSize: "13px",
                        color: "red",
                      }}
                    >
                      {errors?.startTime?.message && "This field is required"}
                    </Typography>
                  </Box>
                  <Box>
                    <label>End Time</label>
                    <br />
                    <input
                      type="time"
                      id="endTime"
                      name="endTime"
                      placeholder="End Time"
                      style={{
                        width: "300px",
                        height: "3rem",
                        paddingLeft: "1rem",
                      }}
                      onChange={(e) => handleChange(e, "endTime")}
                    />
                    <Typography
                      sx={{
                        fontStyle: "italic",
                        fontSize: "13px",
                        color: "red",
                      }}
                    >
                      {errors?.endTime?.message && "This field is required"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {frequencyType !== "Pattern" && (
                <Box width="100%">
                  <DatePicker
                    label="Run Date"
                    Controller={Controller}
                    control={control}
                    name="runDate"
                    register={register}
                    width={"300px"}
                    sx={{ marginRight: "1rem" }}
                  />
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "red",
                    }}
                  >
                    {errors?.runDate?.message && "This field is required"}
                  </Typography>
                </Box>
              )}
            </Box>

            <Box>
              <button
                type="submit"
                id="savebtn"
                className="Button"
                variant="contained"
                color="success"
                style={{
                  border: "1px solid #0C344E",
                  background: "#0c344e",
                  width: "187px",
                  height: "48px",
                  fontFamily: "Barlow",
                  fontSize: "16px",
                  marginTop: "20px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                  color: "white",
                }}
                onClick={handleSubmit(onSubmit)}
              >
                Add Preventative
              </button>
            </Box>
          </Grid>
          <Grid item xs={4}>
            {/* document upload  */}
            <Box sx={{ marginTop: "30px" }}>
              <Typography textAlign="center" fontSize="20px">
                Attach Document
              </Typography>
              <OpenDoc
                setGetImageDataDoc={setGetImageDataDoc}
                setGetLengthDoc={setGetLengthDoc}
              />
              {documentUrl.length > 0 &&
                documentUrl?.map((curElem, id) => (
                  <Box
                    className="card"
                    display="flex"
                    justifyContent="space-between"
                    p={2}
                    m={2}
                    style={{
                      boxShadow: " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                      borderStyle: "none",
                    }}
                    width="18rem"
                  >
                    <Box display="flex" justifyContent="space-between">
                      <Typography>
                        <img
                          src={pdfPhoto}
                          style={{ marginRight: "10px" }}
                          alt=""
                        />
                        {curElem?.split("/")[3]?.split(".")[0]?.slice(0, 10)}
                      </Typography>
                      <Typography>
                        <a
                          download
                          href={curElem}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <RemoveRedEyeIcon />
                        </a>
                        <CloseIcon onClick={() => handleRemoveDoc(curElem)} />
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AddPreventative;
