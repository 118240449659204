import {
  Avatar,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./index.css";

// import Pdf from "../../../components/";
import { useParams } from "react-router-dom";
import PropertyTitle from "./PropertyTitle";

import { uploadFile } from "../../../services/allPropertiesAPI";
import {
  addImageApi,
  changeStaffStatus,
  deleteStaffDeatail,
  getAllRequestProperty,
  GetStaffAvailabilty,
  UpdateStaffTime,
} from "../../../services/allServisesApi";
import { formatAMPM } from "../../../util";

import { TiDelete } from "react-icons/ti";
// import staffimage from "../../../assets/staffimage.png";
import csvimage from "../../../assets/csvimage.png";
import DocImage from "../../../assets/DocImage.png";
import pdf from "../../../assets/pdf.png";

import { Box } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment-timezone";
import { MdDownloadForOffline } from "react-icons/md";
import { TiDeleteOutline } from "react-icons/ti";
import { toast } from "react-toastify";
import "../../../components/AllRequestProperty.css";
import Loader from "../../../components/Loader";
import Pdf from "../../../components/Pdf";
import ServiseRequestImageUi from "../../../components/ServiseRequestImageUI";
import {
  getAssignStaffdetail,
  getMentinanceStatus,
  postStaffDeatail,
} from "../../../services/allServisesApi";
import StatuslogViewDetailDailoc from "./StatusViewDetailDailoc";
import Timeline from "./Timeline";

const AllRequestProperty = () => {
  const urlParams = useParams();
  const [AllRequestProperty, setAllRequestProperty] = useState({});
  const [staffassign, setStaffAssign] = useState([]);
  const [staffDisabled, setStaffDisabled] = useState(false);
  const [editStaffTimeSlot, setEditStaffTimeSlot] = useState(false);
  const [hideButton, setHideButton] = useState(true);
  const [deleteStaff, setDeleteStaff] = useState(true);
  const [maintenanceStatus, setMantinanceStatus] = useState([{}]);
  const [handleTimeSlotDate, sethandleTimeSlotDate] = useState(dayjs(""));
  const [changeStatus, setchangeStatus] = useState("");
  const [description, setDescription] = useState("");
  const [loader, setLoader] = useState(false);
  const [staffData, setStaffData] = useState({
    name: "",
    img: "",
    id: "",
  });
  const [showSlot, setShowSlot] = useState(false);
  const [showFeilds, setShowFeilds] = useState(false);
  const [handleVisitButton, setHandleVisitButton] = useState(false);
  const [slotTime, setSlotTime] = useState([]);
  const [showAndHide, setShowAndHide] = useState(true);

  let handleDate = handleTimeSlotDate && handleTimeSlotDate[`$d`];
  let formatStartDate = moment(handleDate).format("DD-MM-YYYY");

  const fetchStaffAvailabiliy = async () => {
    const resp = await GetStaffAvailabilty({ staffData, formatStartDate });
    let now = moment();
    const filterData = resp.data?.data?.filter(
      (items) => !now.isSameOrAfter(items?.startTime)
    );

    setSlotTime(filterData);
  };
  // const hanldejuned = useMemo(() =>fetchStaffAvailabiliy() , [handleTimeSlotDate])

  useEffect(() => {
    if (handleTimeSlotDate) {
      fetchStaffAvailabiliy();
    }
  }, [handleTimeSlotDate]);

  const [selectedTimeSlotForPaylaod, setSelectedTimeSlotForPaylaod] =
    useState("");
  const selectTimeForSlot = (time) => {
    setSelectedTimeSlotForPaylaod(time);
  };

  const [viewDetailOpen, setViewDetailOpen] = useState(false);

  const fetchMantinaceStatus = async () => {
    let maintenanceId = urlParams.id;
    const resp = await getMentinanceStatus(maintenanceId);

    setMantinanceStatus(resp.data.data);
  };

  useEffect(() => {
    fetchMantinaceStatus();
  }, [AllRequestProperty?.statusDetails?.[0]?.status]);

  const fetchAllRequestProperty = async () => {
    const response = await getAllRequestProperty(urlParams?.id);
    setAllRequestProperty(response.data.data);
  };

  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    fetchAllRequestProperty();
  }, []);
  // image upload

  const [requestImage, setRequestImage] = useState([]);

  const handleImage = async (e) => {
    const fileListArray = e.target.files;
    const formData = new FormData();

    for (let i = 0; i < fileListArray.length; i++) {
      formData.append("file", fileListArray[i]);
    }
    try {
      const response = await uploadFile(formData);

      setRequestImage((prevState) => {
        const copy = [...prevState];
        const newarr = response?.data?.data?.map((ele) => {
          return {
            contentType: ele.contentType,
            location: ele.location,
            originalName: ele.originalName,
          };
        });
        return [...copy, ...newarr];
      });
    } catch (error) {}
  };

  const [uplaodDomDocument, setUplaodDomDocument] = useState([]);

  let requestId = urlParams.id;

  // const [uplaodDomDocument, setUplaodDomDocument] = useState([])
  const hanldeDomUplaod = async (e) => {
    const fileListArray = e.target.files;
    const formData = new FormData();

    for (let i = 0; i < fileListArray.length; i++) {
      formData.append("file", fileListArray[i]);
    }

    try {
      setLoader(true);

      const response = await addImageApi(requestId, formData);

      if (response.data.status == "success") {
        fetchAllRequestProperty();
      }
      setLoader(false);
    } catch (error) {}
  };

  const handleChangeStaffStatus = async () => {
    let data = {
      requestId: urlParams.id,
      status: changeStatus,
      comment: description,
      files: requestImage,
    };
    try {
      const repStatus = await changeStaffStatus(data);

      if (repStatus.status == 200) {
        toast(repStatus.data.data);
        setRefresh(!refresh);
        setchangeStatus("");
        setDescription("");
        setRequestImage("");

        handleClose();

        window.scrollTo(0, 0);
        fetchAllRequestProperty();
      } else if (repStatus.statusText == "Precondition Failed") {
        toast(repStatus.data.msg);
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const removeImage = (i) => {
    const remove = requestImage.filter((ele, index) => index !== i);
    setRequestImage(remove);
  };

  const [optionSelect, setOptionSelect] = useState();
  const StaffAssign = async () => {
    const response2 = await getAssignStaffdetail();
    setStaffAssign(response2.data.data);
    if (response2) {
      fetchAllRequestProperty();
    }
  };
  useEffect(() => {
    let statusw;
    if (AllRequestProperty.statusDetails?.[0]?.status == "In-Process") {
      statusw = true;
    }
    if (AllRequestProperty.statusDetails?.[0]?.status == "Completed") {
      statusw = true;
      setHideButton(false);
    }
    StaffAssign();
    setOptionSelect(statusw);
  }, []);

  useEffect(() => {
    let statusw;
    if (AllRequestProperty?.statusDetails?.[0]?.status == "In-Process") {
      statusw = true;
    }
    if (AllRequestProperty?.statusDetails?.[0]?.status == "Completed") {
      statusw = true;
      setHideButton(false);
    }
    if (AllRequestProperty.staff === null) {
      setOptionSelect(false);
    } else {
      setOptionSelect(statusw);
      setStaffData({
        name: AllRequestProperty.staff?.name || "",
        img:
          AllRequestProperty.staff && AllRequestProperty.staff.profilePicture,
        id: AllRequestProperty.staff && AllRequestProperty.staff.id,
      });
      setStaffDisabled(false);
      setDeleteStaff(statusw);
    }
  }, [AllRequestProperty]);

  const AssignStaffDetail = async () => {
    let data = {
      requestId: urlParams.id,
      staffId: staffData.id,
      staffTimeSlots: [selectedTimeSlotForPaylaod.staffTimeSlotId],
    };
    const response3 = await postStaffDeatail(data);

    if (response3.data.status === "success") {
      toast.success("staff assign successfully");
      setStaffData({ ...staffData, name: "" });
      setShowAndHide(false);
      sethandleTimeSlotDate("");
      fetchAllRequestProperty();
      setSlotTime([]);
      setHideButton(false);
      setShowFeilds(false);
    } else {
      toast.error(response3.data.msg);
    }
  };

  const deleteRequset = async () => {
    let data = {
      requestId: urlParams.id,
    };
    let response = await deleteStaffDeatail(data);

    if (response.data.status === "success") {
      setShowAndHide(true);
      fetchAllRequestProperty();
      setStaffDisabled(false);
      setShowFeilds(true);
      setDeleteStaff(true);
      setOptionSelect(false);
    } else {
      toast.error(response.data.msg);
    }
  };

  const selectItem = (name, g, id) => {
    setStaffDisabled(true);
    setOptionSelect(true);

    const value = {
      name,
      img: g,
      id: id,
    };
    setStaffData(value);
  };

  const [selectedCataegory, setSelectedCategory] = useState("");

  const handleCategoryClick = (category) => {
    category === selectedCataegory
      ? setSelectedCategory("")
      : setSelectedCategory(category);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff",
    borderRadius: "5px",
    p: 2,
    boxShadow: "0px -2px 20px rgb(0 0 0 / 23%)",
    borderRadius: "16px",
  };
  const ViewhandleClose = () => {
    setViewDetailOpen(false);
  };

  const date = moment(AllRequestProperty?.staffTimeSlot?.start).format(
    "YYYY/MM/DD"
  );

  const showLastTime = `${date}, ${formatAMPM(
    AllRequestProperty?.staffTimeSlot?.start
  )} - ${formatAMPM(AllRequestProperty?.staffTimeSlot?.end)}`;

  const handleStaffEdit = async () => {
    if (!handleTimeSlotDate || !selectedTimeSlotForPaylaod) {
      // toast("Enter the Data");
    } else {
      let requestId = urlParams.id;
      let timeSlotId = {
        staffTimeSlots: [selectedTimeSlotForPaylaod.staffTimeSlotId],
      };

      try {
        const response = await UpdateStaffTime(requestId, timeSlotId);

        if (response.data.status) {
          toast(response.data.data);
          fetchAllRequestProperty();
        } else {
          toast(response.data.msg);
        }
      } catch {}
      setEditStaffTimeSlot(false);
      sethandleTimeSlotDate("");
      setSelectedTimeSlotForPaylaod("");
    }
  };
  console.log(requestImage, "");
  return (
    <div className="container bg-white" style={{ borderTopLeftRadius: "50px" }}>
      {loader && <Loader />}
      <div className="p-4">
        <PropertyTitle
          requestId={AllRequestProperty?.requestId}
          id={urlParams.id}
          generatedBy={AllRequestProperty?.generatedBy}
          IsBuilding={AllRequestProperty?.isBuilding}
          isUrgent={AllRequestProperty?.isUrgent}
          type={AllRequestProperty?.category?.name_en}
          unitNo={AllRequestProperty?.flat?.name_en}
          ResidentName={AllRequestProperty?.user?.name}
          date={AllRequestProperty?.createdAt}
          status={AllRequestProperty?.statusDetails?.[0]?.status}
          time={formatAMPM(AllRequestProperty?.createdAt)}
          property={AllRequestProperty?.flat?.building?.name_en}
          description={AllRequestProperty.description}
          images={AllRequestProperty?.files}
          preferredTime={AllRequestProperty.preferredTime}
          user={AllRequestProperty.user}
          fetchAllRequestProperty={fetchAllRequestProperty}
          AllRequestProperty={AllRequestProperty}
          setAllRequestProperty={setAllRequestProperty}
          createdAt={AllRequestProperty.createdAt}
          subCategory={AllRequestProperty?.subCategory?.name_en}
        />
        <div className="d-flex  mt-3">
          <div style={{ width: "750px" }}>
            <p className="assist">Assign Staff</p>
          </div>
        </div>
        <div className=" justify-content-between align-items-start assign-staff">
          <div className="staffpart">
            <div className="d-flex justify-content-between">
              <div>
                {AllRequestProperty.staff !== null && !editStaffTimeSlot && (
                  <>
                    <div className="selectedStaff">
                      <div
                        className="d-flex justify-content-between align-items-center mt-2"
                        style={{ width: "520px" }}
                      >
                        <div
                          style={{
                            width: "275px",
                            display: "flex",
                            alignItems: "center",
                            paddingBottom: "10px",
                          }}
                        >
                          <div>
                            <Avatar
                              // src={staffimage}
                              src={AllRequestProperty?.staff?.profilePicture}
                              alt="pdfimage"
                            />
                          </div>
                          <div>
                            <span
                              style={{
                                fontSize: "18px",
                                paddingLeft: "10px",
                              }}
                            >
                              {AllRequestProperty?.staff?.name}
                            </span>
                            <br />
                            <span
                              style={{
                                fontSize: "14px",
                                paddingLeft: "10px",
                              }}
                            >
                              Phone no:
                              {AllRequestProperty?.staff?.mobileNumber}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div>
                            <h6
                              style={{
                                fontSize: "14px",
                                marginBottom: "0px",
                              }}
                            >
                              {formatAMPM(
                                AllRequestProperty?.staffTimeSlot?.start
                              )}
                              -
                              {formatAMPM(
                                AllRequestProperty?.staffTimeSlot?.end
                              )}
                              <h6 style={{ fontSize: "12px" }}>{date}</h6>
                            </h6>
                          </div>
                          {AllRequestProperty.statusDetails?.[0]?.status ==
                            "Assigned" && (
                            <div style={{ marginLeft: "40px" }}>
                              <TiDeleteOutline
                                size={20}
                                onClick={() => {
                                  deleteRequset();
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className=" d-flex gap-2 editAddstaffAssign justify-content-center">
                      {(AllRequestProperty?.statusDetails?.[0]?.status ===
                        "In-Process" ||
                        AllRequestProperty?.statusDetails?.[0]?.status ===
                          "Re-open") &&
                        !handleVisitButton && (
                          <button
                            onClick={() => {
                              setShowFeilds(true);
                              setHandleVisitButton(true);
                              setOptionSelect(false);
                            }}
                            className="addNewStaff"
                          >
                            Add New Staff
                          </button>
                        )}
                      {(AllRequestProperty?.statusDetails?.[0]?.status ===
                        "Assigned" ||
                        AllRequestProperty?.statusDetails?.[0]?.status ===
                          "Re-Assigned") &&
                        !editStaffTimeSlot && (
                          <button
                            onClick={() => {
                              setEditStaffTimeSlot(true);
                            }}
                            className="addNewStaff"
                          >
                            Edit Staff
                          </button>
                        )}
                    </div>
                  </>
                )}

                {editStaffTimeSlot && (
                  <>
                    <div>
                      <TextField
                        id="standard-basic"
                        label="Selected Staff`s Name"
                        variant="standard"
                        value={AllRequestProperty?.staff?.name}
                        style={{ width: "450px", marginBottom: "1rem" }}
                      />
                      <TextField
                        id="standard-basic"
                        label="Previously Selected Slot"
                        variant="standard"
                        value={showLastTime}
                        style={{ width: "450px" }}
                      />
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        className="dateFeild"
                      >
                        <DesktopDatePicker
                          disablePast
                          variant="standard"
                          className="changecolor"
                          inputFormat="YYYY/MM/DD"
                          label="Select Preference Date"
                          formatDate={(date) =>
                            moment(date).format("YYYY-MM-DD")
                          }
                          value={handleTimeSlotDate}
                          onChange={(e) => {
                            sethandleTimeSlotDate(e);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                width: "450px !important",
                                marginTop: "12px",
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>

                      <div className="slotshowonselect mb-4">
                        {slotTime &&
                          slotTime?.map((time) => (
                            <>
                              <span
                                className={`timeproper ${
                                  selectedTimeSlotForPaylaod == time &&
                                  "addBackOnselect"
                                }`}
                                onClick={() => {
                                  selectTimeForSlot(time);
                                }}
                              >
                                {formatAMPM(time.startTime)}-
                                {formatAMPM(time.endTime)}
                              </span>
                            </>
                          ))}
                      </div>
                      <Button
                        className="Button"
                        variant="contained"
                        color="success"
                        onClick={handleStaffEdit}
                        style={{
                          border: "1px solid #0C344E",
                          color: "#0C344E",
                          background: "white",
                          width: "187px",
                          height: "48px",
                          fontSize: "16px",
                          textTransform: "capitalize",
                        }}
                      >
                        Change Time Slot
                      </Button>
                    </div>
                  </>
                )}

                {(AllRequestProperty.staff === null || showFeilds) && (
                  <>
                    <div className="mb-3">
                      {optionSelect ? (
                        <div
                          style={{
                            width: "100% !important",
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-center paros">
                            <div className="d-flex justify-content-between align-items-center">
                              <Avatar src={staffData?.img} alt="pdfimage" />
                              <p
                                style={{
                                  padding: "12px 0px 0px 10px",
                                }}
                              >
                                {staffData?.name}
                              </p>
                            </div>

                            {hideButton && (
                              <div className="float-right">
                                {staffDisabled ? (
                                  <TiDelete
                                    onClick={() => {
                                      setOptionSelect(false);
                                      sethandleTimeSlotDate(null);
                                      setStaffDisabled(false);
                                    }}
                                  />
                                ) : (
                                  <TiDelete
                                    onClick={() => {
                                      deleteRequset();
                                      handleChangeStaffStatus();
                                    }}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : AllRequestProperty?.statusDetails &&
                        AllRequestProperty?.statusDetails?.[0]?.status ===
                          "Completed" ? (
                        <>
                          <p>No staffs has been assinged for this request.</p>
                        </>
                      ) : (
                        <div className="d-flex justify-content-between">
                          <div>
                            <FormControl variant="standard" fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Select Staff
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={""}
                                label="Select Property"
                                sx={{ width: "450px" }}
                              >
                                {Object.keys(staffassign).map((option) => (
                                  <MenuItem
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      textAlign: "left",
                                    }}
                                  >
                                    <Typography
                                      className="f-bold"
                                      onClick={() =>
                                        handleCategoryClick(option)
                                      }
                                      sx={{
                                        textAlign: "left",
                                        width: "100%",
                                      }}
                                    >
                                      {option}
                                    </Typography>
                                    <FormGroup sx={{ width: "100%" }}>
                                      {selectedCataegory === option &&
                                        staffassign[selectedCataegory]?.map(
                                          (staff) => (
                                            <FormControlLabel
                                              className="handledrop"
                                              control={
                                                <Checkbox
                                                  name={staff.name}
                                                  checked={
                                                    staffData.name ===
                                                      staff.name &&
                                                    !staffData.name
                                                  }
                                                  onClick={(e) =>
                                                    selectItem(
                                                      e.target.name,
                                                      staff.profilePicture,
                                                      staff.id
                                                    )
                                                  }
                                                />
                                              }
                                              label={staff.name}
                                              F
                                            />
                                          )
                                        )}
                                    </FormGroup>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      )}
                    </div>
                    {AllRequestProperty?.statusDetails &&
                    AllRequestProperty?.statusDetails?.[0]?.status ===
                      "Completed" ? (
                      ""
                    ) : (
                      <div>
                        {(showAndHide || showFeilds) && (
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            className="dateFeild"
                          >
                            <DesktopDatePicker
                              disablePast
                              variant="standard"
                              className="changecolor"
                              inputFormat="YYYY/MM/DD"
                              label="Select Preference Date"
                              formatDate={(date) =>
                                moment(date).format("YYYY-MM-DD")
                              }
                              value={handleTimeSlotDate}
                              onChange={(e) => {
                                sethandleTimeSlotDate(e);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  sx={{
                                    width: "450px !important",

                                    marginTop: "12px",
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      </div>
                    )}
                    <div className="slotshowonselect mb-4">
                      {slotTime &&
                        slotTime?.map((time) => (
                          <>
                            <span
                              className={`timeproper ${
                                selectedTimeSlotForPaylaod == time &&
                                "addBackOnselect"
                              }`}
                              onClick={() => {
                                selectTimeForSlot(time);
                              }}
                            >
                              {formatAMPM(time.startTime)}-
                              {formatAMPM(time.endTime)}
                            </span>
                          </>
                        ))}
                    </div>
                    <div>
                      {/* {(hideButton || showFeilds) && ( */}
                      {AllRequestProperty?.statusDetails &&
                      AllRequestProperty?.statusDetails?.[0]?.status ===
                        "Completed" ? (
                        ""
                      ) : (
                        <button
                          className={staffDisabled ? "assign" : "disassign"}
                          onClick={AssignStaffDetail}
                          disabled={staffDisabled ? false : true}
                        >
                          Assign
                        </button>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className="timelineScroll"
            style={{
              maxHeight:
                AllRequestProperty.statusDetails?.[0]?.status !== "Pending"
                  ? "260px"
                  : "100%",
            }}
          >
            <div className="statustitle">
              <div>
                <h5>Status Log</h5>
              </div>
              <div>
                <p
                  onClick={() => {
                    setViewDetailOpen(true);
                  }}
                  style={{
                    borderBottom: "1px solid  #0C344E",
                    cursor: "pointer",
                  }}
                >
                  View Details
                </p>
              </div>
            </div>
            <div>
              <StatuslogViewDetailDailoc
                {...{
                  viewDetailOpen,
                  ViewhandleClose,
                  AllRequestProperty,
                }}
              />
            </div>
            <div>
              <Timeline {...{ AllRequestProperty }} />
            </div>
          </div>
        </div>
        <div className="statusflow mt-3">
          <div style={{ width: "50%", height: "350px" }}>
            <div
              className="mb-4"
              style={{ paddingLeft: "30px", paddingTop: "48px" }}
            >
              <div>
                <h5>Change Request Status</h5>
              </div>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={changeStatus}
                  name="Select Status"
                  onChange={(e) => {
                    setchangeStatus(e.target.value);
                  }}
                  label="Select Property"
                  sx={{ width: "100%" }}
                >
                  {maintenanceStatus &&
                    maintenanceStatus?.map((option, i) => (
                      <MenuItem key={option.key} value={option.key}>
                        {option.status}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <div>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={4}
                  placeholder="Add Comments"
                  value={description}
                  style={{
                    width: "100%",
                    padding: "5px",
                    backgroundColor: "#FCFCFC",
                    borderRadius: " 1rem",
                    padding: "10px",
                    borderStyle: "none",
                    marginTop: "10px",
                  }}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
                <Button
                  variant="outlined"
                  className="changeStatus"
                  disabled={changeStatus.length == 0}
                  onClick={handleOpen}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </div>

          <div style={{ width: "476px", paddingTop: "48px", overflow: "auto" }}>
            <h2>Add attchment</h2>
            <div>
              <input
                type="file"
                multiple
                accept=".doc,.docx,.csv,.png,.jpeg,jpg,application/pdf,.svg"
                id="uploadUserProfile"
                style={{ display: "none" }}
                onChange={handleImage}
              />
              <label htmlFor="uploadUserProfile">
                <ServiseRequestImageUi />
              </label>
            </div>
            <div className="visiblerequestimg">
              {requestImage &&
                requestImage.map((requestUrl, i) => {
                  if (
                    (requestUrl.contentType || requestUrl.type) ==
                    "application/pdf"
                  ) {
                    return (
                      <a
                        href={requestUrl.location}
                        download
                        target="_blank"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <div className="d-flex  align-items-center justify-content-between alternativeUpdoad">
                          <div className="d-flex justify-content-center align-items-start gap-1">
                            <img
                              src={pdf}
                              alt="pdficons"
                              style={{
                                marginTop: "5px",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                            <p>
                              {requestUrl.originalName.slice(0, 10) + "..."}
                            </p>
                          </div>
                          <p>
                            <MdDownloadForOffline />
                          </p>
                        </div>
                      </a>
                    );
                  } else if (
                    (requestUrl.contentType || requestUrl.type) ==
                    "application/x-msi"
                  ) {
                    return (
                      <a
                        href={requestUrl.location}
                        download
                        target="_blank"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <div className="d-flex  align-items-center justify-content-between alternativeUpdoad">
                          <div className="d-flex justify-content-center align-items-start gap-1">
                            <img
                              src={DocImage}
                              alt="pdficons"
                              style={{
                                marginTop: "5px",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                            <p>
                              {requestUrl.originalName.slice(0, 10) + "..."}
                            </p>
                          </div>
                          <p>
                            <MdDownloadForOffline />
                          </p>
                        </div>
                      </a>
                    );
                  } else if (
                    (requestUrl.contentType || requestUrl.type) == "application/octet-stream"
                  ) {
                    return (
                      <a
                        href={requestUrl.location}
                        download
                        target="_blank"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <div className="d-flex  align-items-center justify-content-between alternativeUpdoad">
                          <div className="d-flex justify-content-center align-items-start gap-1">
                            <img
                              src={csvimage}
                              alt="pdficons"
                              style={{
                                marginTop: "5px",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                            <p>
                              {requestUrl.originalName.slice(0, 10) + "..."}
                            </p>
                          </div>
                          <p>
                            <MdDownloadForOffline />
                          </p>
                        </div>
                      </a>
                    );
                  } else {
                    return (
                      <a
                        href={requestUrl.location}
                        download
                        target="_blank"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <div className="d-flex  align-items-center justify-content-between alternativeUpdoad">
                          <div className="d-flex justify-content-center align-items-start gap-1">
                            <img
                              src={requestUrl.location}
                              alt="pdficons"
                              style={{
                                marginTop: "5px",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                            <p>
                              {requestUrl.originalName.slice(0, 10) + "..."}
                            </p>
                          </div>
                          <p>
                            <MdDownloadForOffline />
                          </p>
                        </div>
                      </a>
                    );
                  }
                })}
            </div>
          </div>
        </div>

        {/* Need to be change ui  */}

        {AllRequestProperty?.files && (
          <>
            <div>
              <p className="assist">Attached Documents</p>
              <div>
                <div className="documentpdf">
                  <div className="mb-2">
                    <input
                      type="file"
                      multiple
                      accept=".doc,.docx,.csv,.png,.jpeg,jpg,application/pdf,.svg"
                      id="DomuploadUser"
                      style={{ display: "none" }}
                      onChange={hanldeDomUplaod}
                    />
                    <label htmlFor="DomuploadUser">
                      <ServiseRequestImageUi />
                    </label>
                  </div>
                  <div>
                    <Pdf
                      images={
                        AllRequestProperty?.files
                          ? AllRequestProperty.files
                          : []
                      }
                      fetchAllRequestProperty
                    />
                  </div>

                  {AllRequestProperty.statusDetails.map(
                    ({ files }) =>
                      Array.isArray(files) &&
                      files.map((requestUrl) => {
                        if (requestUrl.contentType == "application/pdf") {
                          return (
                            <a
                              href={requestUrl.location}
                              download
                              target="_blank"
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <div className="d-flex  align-items-center justify-content-between alternativeUpdoad">
                                <div className="d-flex justify-content-center align-items-start gap-1">
                                  <img
                                    src={pdf}
                                    alt="pdficons"
                                    style={{
                                      marginTop: "5px",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />

                                  <p>
                                    {requestUrl?.originalName?.slice(0, 10) +
                                      "..."}
                                  </p>
                                </div>
                                <p>
                                  <MdDownloadForOffline />
                                </p>
                              </div>
                            </a>
                          );
                        } else if (
                          requestUrl?.contentType == "application/x-msi"
                        ) {
                          return (
                            <a
                              href={requestUrl?.location}
                              download
                              target="_blank"
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <div className="d-flex  align-items-center justify-content-between alternativeUpdoad">
                                <div className="d-flex justify-content-center align-items-start gap-1">
                                  <img
                                    src={DocImage}
                                    alt="pdficons"
                                    style={{
                                      marginTop: "5px",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />
                                  <p>
                                    {requestUrl?.originalName?.slice(0, 10) +
                                      "..."}
                                  </p>
                                </div>
                                <p>
                                  <MdDownloadForOffline />
                                </p>
                              </div>
                            </a>
                          );
                        } else if (
                          requestUrl.contentType == "application/octet-stream"
                        ) {
                          return (
                            <a
                              href={requestUrl?.location}
                              download
                              target="_blank"
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <div className="d-flex  align-items-center justify-content-between alternativeUpdoad">
                                <div className="d-flex justify-content-center align-items-start gap-1">
                                  <img
                                    src={csvimage}
                                    alt="pdficons"
                                    style={{
                                      marginTop: "5px",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />
                                  <p>
                                    {requestUrl?.originalName?.slice(0, 10) +
                                      "..."}
                                  </p>
                                </div>
                                <p>
                                  <MdDownloadForOffline />
                                </p>
                              </div>
                            </a>
                          );
                        } else {
                          return (
                            <a
                              href={requestUrl?.location}
                              download
                              target="_blank"
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <div className="d-flex  align-items-center justify-content-between alternativeUpdoad">
                                <div className="d-flex justify-content-center align-items-start gap-1">
                                  <img
                                    src={requestUrl?.location}
                                    alt="pdficons"
                                    style={{
                                      marginTop: "5px",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />
                                  <p>
                                    {requestUrl?.originalName?.slice(0, 10) +
                                      "..."}
                                  </p>
                                </div>
                                <p>
                                  <MdDownloadForOffline />
                                </p>
                              </div>
                            </a>
                          );
                        }
                      })
                  )}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </>
        )}

        {/* modal --------------------- */}
        <Grid>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} textAlign="center">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Save Request?
              </Typography>

              <Typography textAlign="center" mt={2}>
                Are you sure, you want to Save these Changes?
              </Typography>
              <Box m={2}>
                <Button
                  variant="outlined"
                  style={{
                    width: "28%",
                    color: "#C24C4C",
                    border: "1px solid #C24C4C",
                    borderRadius: "10px",
                    height: "2rem",
                    background: "rgb(194, 76, 76)",
                    color: "white",
                    textTransform: "capitalize",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  style={{
                    marginLeft: "1rem",
                    width: "28%",
                    borderRadius: "10px",
                    height: "2rem",
                    background: "#0C344E",
                    color: "white",
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    handleChangeStaffStatus();
                    fetchMantinaceStatus();
                  }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Modal>
        </Grid>
      </div>
    </div>
  );
};

export default AllRequestProperty;
