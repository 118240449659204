import React from "react";

import EditUserDetailPage from "./EditUserDetail";

export default function EditUserDetail() {
  return (
    <>
    
      <div className="top-level-container">
     
        <EditUserDetailPage />
      </div>
    </>
  );
}
