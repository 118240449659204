import { useState, useEffect, useCallback } from "react";
import InputField from "../InputField";
import Form from "../Form";
import { useNavigate } from "react-router-dom";
import { axiosClient } from "../../../services/axiosClient";
import { AiOutlinePlus } from "react-icons/ai";
import { toast } from "react-toastify";
import CatalogForm2 from "./CatalogForm2";
import NewCharges from "./NewCharges";
const CatalogForm = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    chargeName: "",
    serviceCost: "",
  });
  const { chargeName, serviceCost } = data;
  const handleChangeInput = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  //Adding Add New Charge Type Component
  const [newChargeList, setNewChargeList] = useState([]);
  const [newChargeListShow, setNewChargeListShow] = useState(false);
  const handleAddCharge = () => {
    setNewChargeListShow(true);
    setNewChargeList([...newChargeList, new Date().getTime()]);
  };
  //Removing Charge List
  const removeClickHandler = (itemId) => {
    setNewChargeList(newChargeList.filter((id) => id !== itemId));
  };
  const [chargesType, setChargesType] = useState([]);
  const getChargesTypes = async () => {
    try {
      const resp = await axiosClient.get(
        `maintenance-catalogues/charges/types`
      );
      const result = await resp.data.data;
      setChargesType(result);
    } catch (error) {}
  };
  useEffect(() => {
    getChargesTypes();
  }, []);
  const [value, setValue] = useState("");
  //Create Charges POST DATA
  const createCharges = () => {
    if (data && value) {
      axiosClient
        .post(`maintenance-catalogues/charges`, {
          ...data,
          category: value,
        })
        .then((result) => {
          toast.success("Charges Catalogue Created");
          setData({
            chargeName: "",
            serviceCost: "",
          });
          navigate("/chargescatalouge");
        })
        .catch((err) => {
          toast.error(err.msg);
        });
    } else {
      toast.error("Please Fill the Data");
    }
  };
  //!ADD NEW CATEGORY
  const [items, setItems] = useState([]);
  const addDiv = useCallback(() => {
    setItems([...items, new Date().getTime()]);
  }, [items]);
  const removeDiv = useCallback(
    (itemId) => {
      setItems(items.filter((id) => id !== itemId));
    },
    [items]
  );
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="width:400px">
          <h5 className="mb-0">Create Charges Catalogue</h5>
        </div>
        <div className="d-flex align-items-center gap-3">
          <button className="Abtn" onClick={addDiv}>
            <AiOutlinePlus />
            &nbsp; Add New Category
          </button>
          <button className="Sbtn" onClick={createCharges}>
            Save
          </button>
        </div>
      </div>
      <Form>
        <InputField
          placeholder="Category Name"
          value={chargeName}
          onChange={handleChangeInput}
          name="chargeName"
        />
        <div className="d-flex  align-items-end">
          <button className="addCharge" onClick={handleAddCharge}>
            Add New Charge Type
          </button>
        </div>
        <div>
          <select
            style={{
              width: "280px",
              height: "50px",
              marginTop: "41px",
              border: ".5px solid #DFE0E0",
            }}
            onChange={(e) => setValue(e.target.value)}
          >
            <option>Charge Type</option>
            {chargesType?.map((item) => (
              <option key={item?.value} value={item?.key}>
                {item?.value}
              </option>
            ))}
          </select>
        </div>
        <InputField
          placeholder="Service Cost (AED)"
          name="serviceCost"
          value={serviceCost}
          onChange={handleChangeInput}
        />
        {newChargeListShow &&
          newChargeList.map((id) => (
            <NewCharges
              key={id}
              id={id}
              removeClickHandler={removeClickHandler}
            />
          ))}
      </Form>
      {items.map((id) => (
        <CatalogForm2 key={id} id={id} removeDiv={removeDiv} />
      ))}
    </>
  );
};
export default CatalogForm;
