import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Switch,
  TextareaAutosize,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddImageUi from "../../components/AddImageUi";
import ErrorBox from "../../components/ErrorBox";
import { getflatDetail } from "../../services/allCommunityApi";
import {
  addMultipleRequest,
  getMantinanceType,
} from "../../services/allServisesApi";

import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useForm } from "react-hook-form";
import { AiFillDelete } from "react-icons/ai";
import * as yup from "yup";
import csvimage from "../../assets/csvimage.png";
import DocImage from "../../assets/DocImage.png";
import { useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import { getAllBuilding, uploadFile } from "../../services/allPropertiesAPI";
import SlotSelect from "./SlotSelect";
import { buildingContext } from "../../login/AuthProvider";
import BuildingDropDown from "../../components/BuildingDropDown";
import SearchIcon from "@mui/icons-material/Search";

const RenderImages = ({ watch, index }) => {
  const watchFiles = watch(`Requests[${index}].files`, []);
  const imageStyle = {
    width: "150px",
    height: "140px",
    borderRadius: "10px",
    marginTop: "10px",
    marginRight: "20px",
  };

  return (
    <div>
      {Array.isArray(watchFiles) &&
        watchFiles?.map((docData) => {
          if (docData.contentType == "application/pdf") {
            return (
              <>
                <a href={docData.url} target="_blank">
                  <img src={DocImage} style={imageStyle} />
                </a>
              </>
            );
          } else if (docData.contentType == "application/x-msi") {
            return (
              <>
                <a href={docData.url} target="_blank">
                  <img src={DocImage} style={imageStyle} />
                </a>
              </>
            );
          } else if (docData.contentType == "application/octet-stream") {
            return (
              <>
                <a href={docData.url} target="_blank">
                  <img src={csvimage} style={imageStyle} />
                </a>
              </>
            );
          } else {
            return (
              <>
                <a href={docData.url} target="_blank">
                  <img src={docData.url} style={imageStyle} />
                </a>
              </>
            );
          }
        })}
    </div>
  );
};

const AddRequest = () => {
  const [fieldError, setFieldError] = useState({});
  const [buildingDataList, setbuildingDataList] = useState([]);
  const [flatDetail, setflatDetail] = useState([]);
  const [maintenanceType, setMaintenanceType] = useState([]);
  const [maintenanceSubType, setMaintenanceSubType] = useState([]);
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();
  const [error, setError] = useState({});

  const SignupSchema = yup.object().shape({
    building: yup.string().required("This field is required"),
  });

  const { selectedBuilding } = React.useContext(buildingContext);

  // const s3images = getValues()?.Requests.files  || []

  let buildingFromStorage = sessionStorage.getItem("building");

  const fetchAllBuildingDataList = async () => {
    if (buildingFromStorage?.length > 0) {
      const resp = await getAllBuilding();
      let specificBuilding = resp?.data?.data?.rows?.filter(
        (building) => building.id === buildingFromStorage
      );

      setbuildingDataList(specificBuilding);
    } else {
      const resp = await getAllBuilding();

      setbuildingDataList(resp.data.data.rows);
    }
  };

  const fetchMaintenanceType = async () => {
    const resp = await getMantinanceType();
    setMaintenanceType(resp?.data?.data?.rows);
    const categories = resp?.data?.data?.rows.filter(
      (UserCat) => UserCat.isVisible
    );
    setMaintenanceSubType(categories);
  };

  useEffect(() => {
    fetchAllBuildingDataList();
    fetchMaintenanceType();
  }, []);

  const fetchFlatDetail = async (id) => {
    try {
      const resp = await getflatDetail(id);
      setflatDetail(resp.data.data.rows);
    } catch (err) {}
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState,
    getValues,
    watch,
    errors,
  } = useForm({
    resolver: yupResolver(SignupSchema),
    mode: "onChange",
    defaultValue: {
      building: buildingDataList,
    },
  });
  const [building, unitNo] = watch(["building", "unitNo"]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "Requests",
  });

  useEffect(() => {
    if (building || selectedBuilding) {
      fetchFlatDetail(selectedBuilding ? selectedBuilding : building);
    }
  }, [selectedBuilding, building]);

  const onSubmit = async (data) => {
    let payload = {};
    let requests = [];

    payload.flatId = data.unitNo;
    data?.Requests.forEach((item) => {
      const data = {
        ...item,
        time: moment(`${item?.date} ${item?.time}`).format(),
      };

      delete data["date"];
      delete data["building"];
      delete data["unitNo"];
      requests.push(data);
    });
    payload.requests = requests;

    try {
      const res = await addMultipleRequest(payload);

      if (res.data.status == "success") {
        toast.success(`Requests created successfully`);
        navigate("/allrequest");
      } else {
        res.data.data.map((response, index) => {
          if (response.success) {
            toast.success(`Request created with id ${response.requestId}`);
            remove(index);
          } else {
            toast.error(response.message);
          }
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.msg);
    }
  };

  if (fields.length < 1) {
    append({
      unitNo: "",
      categoryId: "",
      subCategoryId: "",
      description: "",
      isBuilding: false,
      isUrgent: false,
      files: [],
      time: "",
    });
  }
  const files = [];
  const handleFormAddition = () => {
    append({
      categoryId: "",
      subCategoryId: "",
      description: "",
      isBuilding: false,
      isUrgent: false,
      files: [],
      time: "",
    });
  };

  const hanldeUplaodImage = async (e, index) => {
    try {
      const fileListArray = e.target.files;

      const formData = new FormData();

      for (let i = 0; i < fileListArray.length; i++) {
        formData.append("file", fileListArray[i]);
      }
      const response = await uploadFile(formData);
      let nameAndLocaiton = response?.data?.data?.map((ele) => {
        return {
          url: ele?.location,
          name: ele?.originalName,
          contentType: ele?.contentType,
        };
      });

      const files = getValues().Requests[index].files;
      setValue(`Requests[${index}].files`, [...files, ...nameAndLocaiton]);
    } catch (error) {}
  };

  const onError = (errors) => {
    setError(errors);
  };

  let min = new Date().toISOString().split("T")[0];
  return (
    <div className="home">
      <div className="d-flex justify-content-between">
        <div>
          <h5>Add Request</h5>
        </div>
      </div>
      <div>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          {fields.map((item, index) => {
            return (
              <>
                <div className="d-flex justify-content-between pr-5">
                  <div>
                    <div className="d-flex gap-1 align-items-center">
                      <div className="mt-3">
                        <input
                          type="file"
                          id={item.id}
                          // name={item}
                          name="files"
                          multiple
                          accept=".doc,.docx,.csv,.png,.jpeg,jpg,application/pdf"
                          style={{ display: "none" }}
                          {...register(`Requests[${index}].files`)}
                          onChange={(e) => hanldeUplaodImage(e, index)}
                        />
                        <div style={{ display: "flex", columnGap: "20px" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <label htmlFor={item.id}>
                              <AddImageUi />
                            </label>
                          </div>
                        </div>
                      </div>

                      <RenderImages watch={watch} index={index} />
                    </div>
                    <div>
                      <div>
                        <div>
                          <div className="mt-3">
                            {index == 0 && (
                              <div className="d-flex justify-content-between">
                                <div>
                                  <BuildingDropDown
                                    displayedOptions={buildingDataList}
                                    name="building"
                                    register={register}
                                    label="Select Property Name"
                                    width="280px"
                                    innerWidth="300px"
                                    buildingId={building}
                                    selectedBuilding={selectedBuilding}
                                  />
                                </div>
                                <div style={{ marginRight: "24px" }}>
                                  <FormControl fullWidth>
                                    <InputLabel
                                      id="search-select-label"
                                      sx={{ marginLeft: "-14px" }}
                                    >
                                      Select Unit*
                                    </InputLabel>
                                    <Select
                                      MenuProps={{ autoFocus: false }}
                                      sx={{
                                        width: "280px",
                                      }}
                                      labelId="search-select-label"
                                      id="search-select"
                                      variant="standard"
                                      // {...register("unitNo")}
                                      onChange={(e) => {
                                        setValue("unitNo", e.target.value);
                                      }}
                                      name="unitNo"
                                      label={"Select Unit"}
                                      onClose={() => setSearchText("")}
                                    >
                                      <ListSubheader>
                                        <TextField
                                          size="small"
                                          variant="standard"
                                          placeholder="Type to search..."
                                          sx={{
                                            width: "280px",
                                          }}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <SearchIcon />
                                              </InputAdornment>
                                            ),
                                          }}
                                          onChange={(e) =>
                                            setSearchText(e.target.value)
                                          }
                                          onKeyDown={(e) => {
                                            if (e.key !== "Escape") {
                                              e.stopPropagation();
                                            }
                                          }}
                                        />
                                      </ListSubheader>
                                      {flatDetail
                                        ?.filter((option) =>
                                          option?.name_en
                                            ?.toLowerCase()
                                            .includes(searchText.toLowerCase())
                                        )
                                        .map((option, i) => (
                                          <MenuItem
                                            key={option?.id}
                                            value={option?.id}
                                          >
                                            {option?.name_en}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                            )}
                          </div>
                          {
                            <span
                              style={{
                                color: "red",
                                fontWeight: "400 !important",
                                fontStyle: "italic",
                                fontSize: "14px",
                              }}
                            >
                              {error && error?.building?.message}
                            </span>
                          }
                        </div>
                      </div>

                      <div className="d-flex justify-content-between flex-column">
                        <div className="mt-3 mb-1 d-flex gap-5">
                          <div>
                            <FormControl fullWidth>
                              <InputLabel
                                id="search-select-label"
                                sx={{ marginLeft: "-14px" }}
                              >
                                User category*
                              </InputLabel>
                              <Select
                                MenuProps={{ autoFocus: false }}
                                sx={{
                                  width: "280px",
                                }}
                                labelId="search-select-label"
                                id="search-select"
                                variant="standard"
                                name="subCategoryId"
                                {...register(`Requests[${index}].categoryId`)}
                                label={"Select Unit"}
                                onClose={() => setSearchText("")}
                              >
                                <ListSubheader>
                                  <TextField
                                    size="small"
                                    variant="standard"
                                    placeholder="Type to search..."
                                    sx={{
                                      width: "280px",
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <SearchIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                    onChange={(e) =>
                                      setSearchText(e.target.value)
                                    }
                                    onKeyDown={(e) => {
                                      if (e.key !== "Escape") {
                                        e.stopPropagation();
                                      }
                                    }}
                                  />
                                </ListSubheader>
                                {maintenanceType
                                  ?.filter((option) =>
                                    option?.name_en
                                      ?.toLowerCase()
                                      .includes(searchText.toLowerCase())
                                  )
                                  .map((option, i) => (
                                    <MenuItem
                                      key={option?.id}
                                      value={option?.id}
                                    >
                                      {option?.name_en}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>

                            <div>
                              {fieldError.isUrgent && (
                                <ErrorBox mes={fieldError.categoryId} />
                              )}
                            </div>
                          </div>
                          <div>
                            <FormControl fullWidth>
                              <InputLabel
                                id="search-select-label"
                                sx={{ marginLeft: "-14px" }}
                              >
                                Admin category*
                              </InputLabel>
                              <Select
                                MenuProps={{ autoFocus: false }}
                                sx={{
                                  width: "280px",
                                }}
                                labelId="search-select-label"
                                id="search-select"
                                variant="standard"
                                name="subCategoryId"
                                {...register(
                                  `Requests[${index}].subCategoryId`
                                )}
                                label={"Select Unit"}
                                onClose={() => setSearchText("")}
                              >
                                <ListSubheader>
                                  <TextField
                                    size="small"
                                    variant="standard"
                                    placeholder="Type to search..."
                                    sx={{
                                      width: "280px",
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <SearchIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                    onChange={(e) =>
                                      setSearchText(e.target.value)
                                    }
                                    onKeyDown={(e) => {
                                      if (e.key !== "Escape") {
                                        e.stopPropagation();
                                      }
                                    }}
                                  />
                                </ListSubheader>
                                {maintenanceSubType
                                  ?.filter((option) =>
                                    option?.name_en
                                      ?.toLowerCase()
                                      .includes(searchText.toLowerCase())
                                  )
                                  .map((option, i) => (
                                    <MenuItem
                                      key={option?.id}
                                      value={option?.id}
                                    >
                                      {option?.name_en}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>

                            <div>
                              {fieldError.isUrgent && (
                                <ErrorBox mes={fieldError.categoryId} />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="mt-3 d-flex gap-5">
                          <Grid
                            display="flex"
                            mt={1}
                            mb={1}
                            alignItems="center"
                          >
                            <Box color="#7a6666">
                              Is the request for the public area?
                            </Box>
                            <Switch
                              size="small"
                              name="isBuilding"
                              {...register(`Requests[${index}].isBuilding`)}
                              inputProps={{
                                "aria-label": "controlled",
                              }}
                            />
                          </Grid>
                          <Grid
                            mb={1}
                            mt={1}
                            display="flex"
                            alignItems="center"
                          >
                            <Box color="#7a6666">Is the Request Urgent ?</Box>
                            <Box ml={6}>
                              <Switch
                                size="small"
                                name="isUrgent"
                                {...register(`Requests[${index}].isUrgent`)}
                                inputProps={{
                                  "aria-label": "controlled",
                                }}
                              />
                            </Box>
                          </Grid>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={1}
                        id="staydisabled"
                        name="description"
                        maxLength={300}
                        maxRows={5}
                        {...register(`Requests[${index}].description`)}
                        placeholder="Explain your service request"
                        style={{
                          width: "98%",
                          padding: "5px",
                          borderRadius: "5px",
                          borderWidth: "0 0 1px 0",
                        }}
                      />
                      {fieldError.description && (
                        <ErrorBox mes={fieldError.description} />
                      )}
                    </div>
                    <div className="mt-4" style={{ width: "630px" }}>
                      <label>Slot Preference date</label>
                      <TextField
                        type="date"
                        name="previousDisabled"
                        className="datepicker"
                        id="staydisabled"
                        {...register(`Requests[${index}].date`)}
                        variant="standard"
                        sx={{ width: "610px" }}
                        // defaultValue=""
                        disableHighlightToday
                        required
                        inputProps={{
                          min: min,
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>

                    <SlotSelect
                      register={register}
                      index={index}
                      setValue={setValue}
                    />
                  </div>
                  <div>
                    {fields.length !== 1 && (
                      <div style={{ marginRight: "30px" }}>
                        <AiFillDelete size={30} onClick={() => remove(index)} />
                      </div>
                    )}
                  </div>
                </div>
                {index !== 0 && <hr />}
              </>
            );
          })}

          <div className="d-flex gap-2">
            <div>
              <Button
                type="submit"
                id="savebtn"
                className="Button"
                variant="contained"
                color="success"
                style={{
                  border: "1px solid #0C344E",
                  color: "#0C344E",
                  background: "#0c344e",
                  width: "187px",
                  height: "48px",
                  fontFamily: "Barlow",
                  fontSize: "16px",

                  marginTop: "20px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                  color: "white",
                }}
              >
                Save
              </Button>
            </div>
            <div>
              <Button
                type="submit"
                className="Button"
                variant="contained"
                color="success"
                onClick={handleFormAddition}
                style={{
                  border: "1px solid #0C344E",
                  color: "#0C344E",
                  background: "#0c344e",
                  color: "white",
                  width: "230px",
                  height: "48px",
                  fontSize: "16px",
                  marginLeft: "5px",
                  marginTop: "20px",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                }}
              >
                Add Another Request
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddRequest;
