import axios from "axios";
import { axiosClient } from "./axiosClient";

export const addLease = async (data) => {
  const resp = axiosClient.post(`/flat-contracts`, data);
  return resp;
};
export const TenentDropDown = async () => {
  const resp = await axiosClient.get(`/master-users/potential-residents`);
  return resp;
};

export const OwnerDataDropDown = async (flatId) => {
  const resp = await axiosClient.get(`/flats/admin/owner/${flatId}`);
  return resp;
};

export const LeaseAllData = async (paylaod) => {
  const resp = await axiosClient.get(
    `/flat-contracts?page=${paylaod.page}&limit=${paylaod.postPerpage}&search=${paylaod.searchText}&flatUsage=${paylaod.selectedPurpose}&startDate=${paylaod.formatStartDate}&endDate=${paylaod.formatEndDate}&flatId=${paylaod.selectedUnit}&status=${paylaod?.selectedStatus}&buildingId=${paylaod.buildingId}`
  );
  return resp;
};

export const getFlatDataOnlyVacant = async (buildingId) => {
  const resp = await axiosClient.get(`/floors/flats/vacant/${buildingId}`);
  return resp;
};

export const getLeaseDetailSevises = async (id) => {
  const resp = await axiosClient.get(`/flat-contracts/${id}`);
  return resp;
};

export const avoidLeaseService = async ({ data, id }) => {
  const resp = await axiosClient.patch(`/flat-contracts/void/${id}`, data);
  return resp;
};
export const updateOwner = async (payload) => {
  const resp = await axiosClient.patch(`/flats/owner`, payload);
  return resp;
};
export const updateUserValue = async (data) => {
  const resp = await axiosClient.patch(`/master-users`, data);
  return resp;
};

export const addUserForLease = async (payload) => {
  const resp = await axiosClient.post(`/master-users`, payload);
  return resp;
};
