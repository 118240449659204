import React from "react";

import LeftNav from "../components/leftNav";
import TopNav from "../components/topNav";
import ResetPassword from "./ResetPassword";

export default function MyProfile() {
  return (
    <section style={{ overflow: "hidden", height: "100vh" }}>
      {/* <TopNav /> */}
      <div
        style={{
          display: "flex",
          background: "#FFE3D2",
          height: "99.9%",
        }}
      >
        {/* <LeftNav /> */}

        <ResetPassword />
      </div>
    </section>
  );
}
