import React, { useState, useEffect } from "react";
import "./index.css";
import {
  changeGenarateStatus,
  getCatalougeType,
} from "../../../services/allServisesApi";
import { getCatalougeTypyByCategory } from "../../../services/allServisesApi";
import { axiosClient } from "../../../services/axiosClient";
import { toast } from "react-toastify";

const DataManulist = ({ requestID, open, setOpen, urlParams }) => {
  const [categories, setCategories] = useState({
    data: [],
    currentlySelectedCategory: undefined,
    currentlySelectedCategoryData: [],
    billTotal: 0,
  });
  ///
  const fetchCatalougeCategory = async () => {
    const response = await getCatalougeType();
    setCategories({ ...categories, data: response.data.data });
  };
  useEffect(() => {
    fetchCatalougeCategory();
  }, []);

  const fetchCatalougeTypyByCategory = async () => {
    const response2 = await getCatalougeTypyByCategory(
      categories.currentlySelectedCategory
    );
    let updatedCategories = response2.data.data?.map((response) => {
      return {
        ...response,
        isChecked: false,
      };
    });
    setCategories({
      ...categories,
      currentlySelectedCategoryData: updatedCategories,
    });
  };
  useEffect(() => {
    if (categories.currentlySelectedCategory !== undefined) {
      fetchCatalougeTypyByCategory();
    }
  }, [categories.currentlySelectedCategory]);

  const toggleChecked = (index) => {
    let tempArr = [...categories.currentlySelectedCategoryData];
    tempArr[index].isChecked = !tempArr[index].isChecked;

    let updatedAmount = tempArr.reduce((accumulator, currentValue) => {
      if (currentValue.isChecked) {
        return accumulator + currentValue.serviceCost;
      } else {
        return accumulator;
      }
    }, 0);

    setCategories({
      ...categories,
      currentlySelectedCategoryData: tempArr,
      billTotal: updatedAmount,
    });
    // getInvoiceAmount();
  };

  const charges =
    categories.currentlySelectedCategoryData &&
    categories.currentlySelectedCategoryData
      .filter((item) => item.isChecked === true)
      .map((item) => item.id);

  const handleSubmitData = () => {
    axiosClient
      .post(`maintenance-catalogues/request-payment`, {
        requestId: requestID,
        charges: charges,
      })
      .then((result) => {
        setOpen(true);
        toast.success(result.data.msg);
      })
      .catch((err) => {});
  };
  const handleChangeGenarateStatus = () => {
    let data = {
      requestId: urlParams,
      status: "COMPLETED",
    };
    const repStatus = changeGenarateStatus(data);
  };

  return (
    <div className="datalist">
      <div className="p-3">
        <div>
          <select
            className="selectinput"
            onChange={(e) => {
              setCategories({
                ...categories,
                currentlySelectedCategory: e.target.value,
                billTotal: 0,
              });
            }}
          >
            <option value="">Select Category</option>
            {categories.data.map((item, index) => (
              <option key={index} value={item.key}>
                {item.value}
              </option>
            ))}
          </select>
        </div>
        <div className="scrollbar">
          {categories.currentlySelectedCategoryData?.map((item, index) => (
            <div
              key={item.id}
              className="d-flex justify-content-between align-items-center pt-3"
            >
              <div className="d-flex justify-content-center gap ">
                <div className="d-flex justify-content-center align-items-center ">
                  <input
                    type={"checkbox"}
                    className="input"
                    checked={item.isChecked}
                    onChange={() => {
                      toggleChecked(index);
                    }}
                    name="onecheck"
                  />
                </div>

                <p className="m-0">{item.chargeName}</p>
              </div>
              <div>{item.serviceCost}</div>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-between mt-3">
          <div>
            <h6 className="amount">Invoice Amount</h6>
            <p>Service Tax</p>
            <p>Other Charges</p>
            <h6 className="amount">Total Amount</h6>
          </div>
          <div>
            <h6 className="amount">{categories.billTotal + " AED"}</h6>
            <p>1000 AED</p>

            <h6 className="amount">
              {categories.billTotal > 0 ? categories.billTotal + 1000 : 0} AED
            </h6>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end mt-3">
        <button
          disabled={
            categories.currentlySelectedCategory === "" ||
            categories.currentlySelectedCategory === undefined
              ? true
              : false
          }
          className="Rpayment"
          onClick={() => {
            handleSubmitData();
            handleChangeGenarateStatus();
          }}
        >
          Request Payment
        </button>
      </div>
    </div>
  );
};

export default DataManulist;
