import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getUnit } from "../services/allPropertiesAPI";
import "../UserDetail/UserDetail.css";
// import StaffDetailUi from "../StaffDetail/StaffDetailUi";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { removeCountryCode } from "../util";
import ContractDetails from "./ContractDetails";
import UnitDetailUi from "./UnitDetailUi";

const UnitDetailPage = () => {
  const [handleShift, setHandleShift] = useState(false);
  const [handleLoader, setHandleLoader] = useState(false);
  const [handleEditMode, setHandleEditMode] = useState(false);
  const [propertyfields, setPropertyfields] = useState({
    Image: [],
    name: "",
    floor: "",
    size: "",
    bedroom: "",
    bathroom: "",
    document: [],
    contractDetails: [],
    residentName: "",
    residentImage: "",
    Id: "",
    showForm: false,
    buildingName: "",
    flatType: "",
    owner: {},
    tenant: {},
    flatId: "",
    contactName: "",
    contactEmail: "",
    contactMobileNumber: "",
    unitId: "",
    isExpired: null,
  });

  const location = useLocation();
  const userId = location.pathname.split("/")[2];
  let buildingId = location?.state?.buildingId;
  const fetchUser = async () => {
    setHandleLoader(true);
    let params = {
      buildingId,
      userId,
    };
    const res = await getUnit(params);
    if (res.data.status === "success") {
      setPropertyfields({
        Image: res?.data?.data?.images ? res.data.data.images : [],
        name: res?.data?.data?.name_en,
        floor: res?.data?.data?.floor,
        size: res?.data?.data?.size,
        bedroom: res?.data?.data?.flatInfo?.bedroom,
        bathroom: res?.data?.data?.flatInfo?.bathroom,
        document: res?.data?.data?.documents ? res.data?.data?.documents : [],
        contractDetails: res?.data?.data?.contractDetails,
        Id: res.data?.data?.flatContract?.id,
        showForm: res.data.data.flatContract === null ? false : true,
        buildingName: res.data.data?.building?.name_en,
        buildingId: res.data.data?.building?.id,
        flatType: res.data.data?.flatType,
        flatId: res.data.data?.id,
        owner: res.data.data.owner,
        tenant: res.data.data.contractDetails[0]?.resident,
        contactName: res?.data?.data?.flatInfo?.primaryContact?.name,
        contactEmail: res?.data?.data?.flatInfo?.primaryContact?.email,
        contactCountryCode: `+${res?.data?.data?.flatInfo?.primaryContact?.countryCode}`,
        contactMobileNumber: removeCountryCode(
          res?.data?.data?.flatInfo?.primaryContact?.countryCode,
          res?.data?.data?.flatInfo?.primaryContact?.mobileNumber
        ),
        unitId: res?.data?.data?.unitId,
        isExpired: res?.data?.data?.contractDetails[0]?.isExpired,
      });
      setHandleLoader(false);
    } else {
      toast("fail");
    }
  };

  useEffect(() => {
    fetchUser();
  }, [buildingId, !handleEditMode]);

  return (
    <>
      <Grid className="home">
        <Button
          variant={handleShift === false ? "contained" : "outlined"}
          sx={{
            marginRight: "1rem",
            borderRadius: "1rem",
            backgroundColor: handleShift === false ? "#0C344E" : "",
          }}
          onClick={() => setHandleShift(false)}
        >
          Unit Details
        </Button>
        <Button
          variant={handleShift === true ? "contained" : "outlined"}
          sx={{
            borderRadius: "1rem",
            backgroundColor: handleShift === true ? "#0C344E" : "",
          }}
          onClick={() => setHandleShift(true)}
        >
          Contract Details
        </Button>
        {handleLoader && <Loader />}
        {!handleShift && (
          <UnitDetailUi
            propertyFields={propertyfields}
            setPropertyFields={setPropertyfields}
            setHandleLoader={setHandleLoader}
            handleEditMode={handleEditMode}
            setHandleEditMode={setHandleEditMode}
          />
        )}
        {handleShift && <ContractDetails propertyFields={propertyfields} />}
      </Grid>
    </>
  );
};

export default UnitDetailPage;
