import InputField from "../InputField";
import { TiDeleteOutline } from "react-icons/ti";
const NewCharges = ({ removeClickHandler, id }) => {
    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "96%",
                }}
            >
                <select
                    style={{
                        width: "280px",
                        height: "50px",
                        marginTop: "41px",
                        border: ".5px solid #DFE0E0",
                    }}
                    //   onChange={(e) => setValue(e.target.value)}
                >
                    <option>Charge Type</option>
                    {/* {chargesType?.map((item) => (
            <option key={item?.value} value={item?.key}>
              {item?.value}
            </option>
          ))} */}
                </select>
                <InputField
                    placeholder="Service Cost (AED)"
                    name="serviceCost"
                    // value={serviceCost}
                    // onChange={handleChangeInput}
                />
                <span
                    onClick={() => removeClickHandler(id)}
                    style={{
                        marginRight: "-2.4rem",
                        marginTop: "1rem",
                    }}
                >
                    <TiDeleteOutline
                        style={{
                            fontSize: "28px",
                            marginTop: "10px",
                            marginLeft: "-34px",
                        }}
                    />
                </span>
            </div>
        </>
    );
};
export default NewCharges;
