import axios from "axios";
import { MdDownloadForOffline } from "react-icons/md";
import { Link } from "react-router-dom";
import pdf from "../assets/pdf.png";
import "../ServisesRequest/AllRequest/AllRequestProperty/index.css";
import DocImage from "../assets/DocImage.png";
import csvimage from "../assets/csvimage.png";

const Pdf = ({ images }) => {
  const download = (url) => {
    axios({
      url: "https://livodev.s3.us-east-2.amazonaws.com/rn_image_picker_lib_temp_61c43d88-c486-4543-be83-8b0117cde4b0-1656942411122.jpg",
      method: "GET",
      responseType: "blob", // important
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.jpg");
      document.body.appendChild(link);
      link.click();
    });
  };
  return (
    <div className="d-flex flex-wrap gap-2">
      {images.map((docData) => {
        if (docData.contentType == "application/pdf") {
          return (
            <>
              <div className="attchmentcard">
                <div className="attchmenttile">
                  <img src={pdf} style={{ width: "20px", height: "20px" }} />
                  <span>
                    {docData.name && docData.name.slice(0, 10) + "..."}
                  </span>{" "}
                </div>
                <div>
                  <a href={docData.url} download target="_blank">
                    <MdDownloadForOffline />
                  </a>{" "}
                </div>
              </div>
            </>
          );
        } else if (docData.contentType == "application/x-msi") {
          return (
            <>
              <div className="attchmentcard">
                <div className="attchmenttile">
                  <img
                    src={DocImage}
                    style={{ width: "20px", height: "20px" }}
                  />
                  <span>
                    {docData.name && docData.name.slice(0, 10) + "..."}
                  </span>{" "}
                </div>
                <div>
                  <a href={docData.url} download target="_blank">
                    <MdDownloadForOffline />
                  </a>{" "}
                </div>
              </div>
            </>
          );
        } else if (docData.contentType == "application/octet-stream") {
          return (
            <>
              <div className="attchmentcard">
                <div className="attchmenttile">
                  <img
                    src={csvimage}
                    style={{ width: "20px", height: "20px" }}
                  />
                  <span>
                    {docData.name && docData.name.slice(0, 10) + "..."}
                  </span>{" "}
                </div>
                <div>
                  <a href={docData.url} download target="_blank">
                    <MdDownloadForOffline />
                  </a>{" "}
                </div>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className="attchmentcard">
                <div className="attchmenttile">
                  <img
                    src={docData.url}
                    style={{ width: "20px", height: "20px" }}
                  />
                  <span>
                    {docData.name && docData.name.slice(0, 10) + "..."}
                  </span>{" "}
                </div>
                <div>
                  <a href={docData.url} download target="_blank">
                    <MdDownloadForOffline />
                  </a>{" "}
                </div>
              </div>
            </>
          );
        }
      })}
    </div>
  );
};

export default Pdf;
