import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { noticeSchema } from "../../components/AuthSchema";
import BuildingMultiSelectDropdown from "../../components/BuildingMultiSelectDropdown";
import DatePicker from "../../components/DatePicker";
import TextEditor from "../../components/TextEditor";
import Textfiled from "../../components/Textfiled";
import { buildingContext } from "../../login/AuthProvider";
import OpenDoc from "../../PropertyName/OpenDoc";
import { createNotices } from "../../Redux/NoticeSlice";
import { getAllBuilding, uploadFile } from "../../services/allPropertiesAPI";
import pdfPhoto from "../../StaffDetail/assets/pdfPhoto.png";

const CreateNotices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedBuilding } = React.useContext(buildingContext);
  const [getImageDataDoc, setGetImageDataDoc] = useState([]);
  const [documentUrl, setDocumentUrl] = useState([]);
  const [getLengthDoc, setGetLengthDoc] = useState();
  const [buildingData, setBuildingData] = useState([]);
  const [description, setDescription] = useState("");
  const NOTICE_CATEGORIES = ["Event", "Announcement"];
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      category: "",
      validFrom: "",
      validTill: "",
      actionRequired: false,
      actionDeadline: "",
      targetUser: [],
      buildings: [],
      documents: [],
    },
    mode: "onChange",
    resolver: yupResolver(noticeSchema),
  });

  let buildingFromStorage = sessionStorage.getItem("building");

  const fetchBuildingData = async () => {
    if (buildingFromStorage?.length > 0) {
      const resp = await getAllBuilding();
      let specificBuilding = resp?.data?.data?.rows?.filter(
        (building) => building.id === buildingFromStorage
      );

      setBuildingData(specificBuilding);
    } else {
      const resp = await getAllBuilding();

      setBuildingData(resp.data.data.rows);
    }
  };

  useEffect(() => {
    fetchBuildingData();
  }, []);

  const [actionRequired, targetUser, buildings] = watch([
    "actionRequired",
    "targetUser",
    "buildings",
  ]);

  const buildingId = selectedBuilding;
  const onSubmit = (data) => {
    const payload = {
      title: data.title,
      description: description,
      category: data.category,
      validFrom: data.validFrom
        ? moment(data.validFrom).format("DD-MM-YYYY")
        : null,
      validTill: data.validTill
        ? moment(data.validTill).format("DD-MM-YYYY")
        : null,
      actionRequired: data.actionRequired,
      actionDeadline: data.actionDeadline
        ? moment(data.validTill).format("DD-MM-YYYY")
        : null,
      targetUser: data.targetUser,
      buildings: data.buildings.map((building) => building.id),
      documents: [...documentUrl],
    };

    dispatch(createNotices({ payload, navigate, buildingId }));
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setValue("targetUser", [...targetUser, value]);
  };

  const handleInputChangeBuilding = (event) => {
    const value = event.target.value;
    let elementExists = false;
    for (let i = 0; i < buildings.length; i++) {
      if (
        buildings[i].id === value.id ||
        buildings[i].buildingId === value.id
      ) {
        elementExists = true;
        break;
      }
    }
    if (!elementExists) {
      setValue("buildings", [...buildings, value]);
    }
  };

  const getDocUrl = async () => {
    // setHandleLoader(true);
    try {
      const res = await uploadFile(getImageDataDoc);
      if (res.data.status === "success") {
        const getUrl = res.data.data.map((current) => current.location);
        setDocumentUrl([...documentUrl, ...getUrl]);
      } else {
        toast("Document is not uploaded. Please check your connection");
      }
    } catch {}
    // setHandleLoader(false);
  };

  const handleRemoveDoc = (att) => {
    const filtered = documentUrl.filter((item) => item !== att);
    setDocumentUrl(filtered);
  };

  useEffect(() => {
    if (getLengthDoc) {
      getDocUrl();
    }
  }, [getLengthDoc]);

  const handleDelete = (CheckedEle) => {
    const result = buildings?.filter((curElem) => {
      return curElem?.id !== CheckedEle;
    });
    setValue("buildings", result);
  };
  return (
    <div className="top-level-container">
      <div className="home">
        <h4>Add Notice</h4>
        <Box className="d-flex">
          <Box width="75%" marginTop="50px">
            <Box sx={{ display: "flex", columnGap: "30px", width: "100%" }}>
              <Box>
                <Textfiled
                  label={"Title*"}
                  type="text"
                  width="300px"
                  name="title"
                  maxLength="25"
                  setValue={setValue}
                  register={register}
                />
                <Typography
                  sx={{
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "red",
                  }}
                >
                  {errors?.title?.message}
                </Typography>
              </Box>
              <Box>
                <TextField
                  select
                  label="Category*"
                  inputProps={{ maxLength: 100 }}
                  multiline
                  maxRows={4}
                  maxLength={10}
                  {...register("category")}
                  name="category"
                  variant="standard"
                  style={{
                    width: "300px",
                    marginTop: "13px",
                  }}
                >
                  {NOTICE_CATEGORIES?.map((option) => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </TextField>
                <Typography
                  sx={{
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "red",
                  }}
                >
                  {errors?.category?.message}
                </Typography>
              </Box>
            </Box>
            {/* <Box width="85%">
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextareaAutosize
                    {...field}
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="Description"
                    style={{
                      width: "100%",
                      minHeight: "50px",
                      marginTop: "20px",
                      paddingLeft: "10px",
                      borderRadius: "10px",
                    }}
                  />
                )}
              />
            </Box> */}
            <Box sx={{ width: "650px", marginTop: "40px" }}>
              <TextEditor
                setDescription={setDescription}
                description={description}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                columnGap: "30px",
                width: "100%",
                marginTop: "60px",
              }}
            >
              <Box>
                <DatePicker
                  label="Valid From"
                  Controller={Controller}
                  control={control}
                  name="validFrom"
                  register={register}
                  width={"300px"}
                />
                <Typography
                  sx={{
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "red",
                  }}
                >
                  {errors?.validFrom?.message && "This field is required"}
                </Typography>
              </Box>
              <Box>
                <DatePicker
                  label="Valid Till"
                  Controller={Controller}
                  control={control}
                  name="validTill"
                  register={register}
                  width={"300px"}
                />
                <Typography
                  sx={{
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "red",
                  }}
                >
                  {errors?.validTill?.message && "This field is required"}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ marginTop: "20px" }}>
              <FormGroup name="targetUser" onChange={handleInputChange}>
                <Box sx={{ display: "flex" }}>
                  {" "}
                  <FormControlLabel
                    value="Owner"
                    control={
                      <Checkbox checked={targetUser.includes("Owner")} />
                    }
                    label="Owner"
                  />
                  <FormControlLabel
                    value="Resident"
                    control={
                      <Checkbox checked={targetUser.includes("Resident")} />
                    }
                    label="Resident"
                  />
                </Box>
              </FormGroup>
            </Box>
            <Box mt={2}>
              <BuildingMultiSelectDropdown
                label="Select Building*"
                name="buildings"
                setValue={setValue}
                width="650px"
                innerWidth="650px"
                buildings={buildings}
              />
            </Box>

            <Stack direction="row" spacing={2} marginTop="10px">
              {buildings.map((ele) => (
                <Chip
                  sx={{
                    width: "150px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  label={ele?.name_en}
                  variant="outlined"
                  onDelete={() => {
                    handleDelete(ele?.id);
                  }}
                />
              ))}
            </Stack>

            <Box>
              <button
                type="submit"
                id="savebtn"
                className="Button"
                variant="contained"
                color="success"
                style={{
                  border: "1px solid #0C344E",
                  color: "#0C344E",
                  background: "#0c344e",
                  width: "187px",
                  height: "48px",
                  fontFamily: "Barlow",
                  fontSize: "16px",

                  marginTop: "20px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                  color: "white",
                }}
                onClick={handleSubmit(onSubmit)}
              >
                Add Notice
              </button>
            </Box>
          </Box>

          <Box>
            <Typography textAlign="center" fontSize="20px">
              Attach Document
            </Typography>
            <OpenDoc
              setGetImageDataDoc={setGetImageDataDoc}
              setGetLengthDoc={setGetLengthDoc}
            />
            {documentUrl.length > 0 &&
              documentUrl?.map((curElem, id) => (
                <Box
                  className="card"
                  display="flex"
                  justifyContent="space-between"
                  p={2}
                  m={2}
                  style={{
                    boxShadow: " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                    borderStyle: "none",
                  }}
                  width="18rem"
                >
                  <Box display="flex" justifyContent="space-between">
                    <Typography>
                      <img
                        src={pdfPhoto}
                        style={{ marginRight: "10px" }}
                        alt=""
                      />
                      {curElem?.split("/")[3]?.split(".")[0]?.slice(0, 10)}
                    </Typography>
                    <Typography>
                      <a
                        download
                        href={curElem}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <RemoveRedEyeIcon />
                      </a>
                      <CloseIcon onClick={() => handleRemoveDoc(curElem)} />
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default CreateNotices;
