import { Box, Card, Typography } from "@mui/material";
import { useRef } from "react";
import image from "../StaffDetail/assets/gallery.png";

const OpenDoc = ({ setGetImageDataDoc, setGetLengthDoc }) => {
  const ref = useRef();
  const handleImageData = async (event) => {
    const fileListArray = event.target.files;
    const formData = new FormData();

    for (let i = 0; i < fileListArray.length; i++) {
      formData.append("file", fileListArray[i]);
    }
    setGetImageDataDoc(formData);
    setGetLengthDoc(formData.getAll("file"));
    event.target.value = null;
  };
  return (
    <Box my={2} style={{ position: "relative" }}>
      <input
        type="file"
        ref={ref}
        style={{ display: "none" }}
        multiple
        onChange={(event) => handleImageData(event)}
      />
      <Typography
        onClick={() => ref.current.click()}
        style={{ cursor: "pointer", textAlign: "center" }}
        className="card"
        mx={4}
        p={1}
        width="15rem"
      >
        Add Document
      </Typography>
    </Box>
  );
};

export default OpenDoc;
