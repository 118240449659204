import React from "react";

import LeftNav from "../components/leftNav";
import TopNav from "../components/topNav";
import Staff from "./Staffs";

export default function PropertyName() {
  return (
    <>
      <div className="top-level-container">
        <Staff />
      </div>
    </>
  );
}
