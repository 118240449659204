import { ArrowBack, ArrowForward } from "@mui/icons-material";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Carousol = ({ children }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    // centerMode: true,
    // slidesToScroll: 2,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return <Slider {...settings}>{children}</Slider>;
};

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    onClick !== null && (
      <button {...props}>
        {
          <ArrowForward
            sx={{
              color: "#fff !important",
              bgcolor: "#000 !important",
              fontSize: 16,
              fontWeight: 700,
              borderRadius: "50%",
            }}
          />
        }
      </button>
    )
  );
}
function SamplePrevArrow(props) {
  const { onClick, currentSlide } = props;

  return currentSlide !== 0 && onClick !== null ? (
    <button {...props}>
      {
        <ArrowBack
          sx={{
            color: "#fff !important",
            bgcolor: "#000 !important",
            fontSize: 16,
            fontWeight: 700,
            borderRadius: "50%",
          }}
        />
      }
    </button>
  ) : null;
}

export default Carousol;
