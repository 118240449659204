import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { AllStaffsAPI, getSoftDeleteStaff } from "../services/allPropertiesAPI";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "./Loader";
import { buildingContext } from "../login/AuthProvider";
import { showDash } from "../util";
import NoDataFound from "./NoDataFound";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import ConfirmBox from "./ConfirmBox";
import { toast } from "react-toastify";

export default function StaffTable({
  searchText,
  postPerpage,
  currentPage,
  setcurrentPage,
  totalCount,
  settotalCount,
  staff,
  setStaff,
}) {
  const { selectedBuilding } = React.useContext(buildingContext);
  const [showtotalData, setshowtotalData] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [staffId, setStaffId] = useState("");
  const navigate = useNavigate();
  let buildingId = selectedBuilding;
  const header = [
    "Name",
    "Email ID",
    "Country-Code",
    "Mobile Number",
    "Department",
    "Designation",
    "Appointment",
    "Nationality",
    "Action",
  ];
  const StaffData = async () => {
    setLoader(true);
    const resp = await AllStaffsAPI(
      currentPage,
      postPerpage,
      searchText,
      buildingId
    );
    let totalData = resp?.data?.data?.count;
    setshowtotalData(totalData);

    let pagesCount = Math.ceil(totalData / postPerpage);
    settotalCount(pagesCount);
    setStaff(resp.data.data.rows);
    setLoader(false);
  };

  useEffect(() => {
    StaffData();
  }, [currentPage, searchText]);

  const [loader, setLoader] = useState(false);
  const [handleNoData, setHandleNoData] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setHandleNoData(true);
    }, 1000);
  }, []);

  const handleNavigate = (id) => {
    navigate(`/staffs/${id}`);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleCloseConfirmBox = () => {
    setOpenConfirm(false);
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };
  const deleteStaff = async () => {
    const resp = await getSoftDeleteStaff(staffId);
    if (resp.data.status == "fail") {
      toast.error(resp.data.msg);
    } else {
      toast.success("Staff delete successfully");
      StaffData();
    }
  };

  return (
    <>
      {loader && <Loader />}
      {staff.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table sx={{}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {header.map((h) => (
                    <TableCell
                      align="center"
                      className="fw-bold"
                      style={{
                        backgroundColor: "#FFF7F3",
                      }}
                    >
                      {h}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {staff.map((row) => (
                  <TableRow
                    className="rowTable"
                    key={row.id}
                    onClick={() => {
                      setStaffId(row.id);
                    }}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell align="center" padding="15px">
                      <Link to={`/staffs/${row.id}`} className="unitname">
                        {row.name}
                      </Link>
                    </TableCell>
                    <TableCell align="center" padding="15px">
                      {showDash(row.email)}
                    </TableCell>
                    <TableCell align="center" padding="15px">
                      +{row.countryCode}
                    </TableCell>
                    <TableCell align="center" padding="15px">
                      {showDash(row.mobileNumber)}
                    </TableCell>
                    <TableCell align="center" padding="15px">
                      {showDash(row.department)}
                    </TableCell>
                    <TableCell align="center" padding="15px">
                      {showDash(row.designation)}
                    </TableCell>
                    <TableCell align="center" padding="15px">
                      {showDash(row.appointment)}
                    </TableCell>
                    <TableCell align="center" padding="15px">
                      {showDash(row.nationality)}
                    </TableCell>
                    <ConfirmBox {...{ openConfirm, handleCloseConfirmBox }}>
                      <Typography variant="h6">
                        Are you sure, You want to delete ?
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          columnGap: "20px",
                          margin: "30px 0px 0px 15px",
                        }}
                      >
                        <Button
                          sx={{
                            textTransform: "capitalize",
                            border: "1px solid #0c344e",
                            color: "#0c344e",
                          }}
                          onClick={handleCloseConfirmBox}
                        >
                          No
                        </Button>
                        <Button
                          onClick={(e) => {
                            deleteStaff();
                            setOpenConfirm(false);
                            handleClose();
                            e.stopPropagation();
                          }}
                          variant="standard"
                          sx={{
                            textTransform: "capitalize",
                            border: "1px solid #0c344e",
                            color: "white",
                            background: "#0c344e",
                            "&:hover": {
                              backgroundColor: "white",
                              color: "#0c344e",
                            },
                          }}
                        >
                          Yes
                        </Button>
                      </Box>
                    </ConfirmBox>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={(e) => {
                          handleNavigate(staffId);
                          e.stopPropagation();
                        }}
                      >
                        Update
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          handleOpenConfirm();
                          e.stopPropagation();
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                    <TableCell align="center">
                      <MoreVertIcon onClick={handleClick} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex justify-content-between height-200px mt-2">
            <div className="">
              <p>
                Showing {postPerpage * (currentPage - 1) + 1}
                &nbsp;-
                {currentPage * postPerpage > showtotalData
                  ? showtotalData
                  : currentPage * postPerpage}{" "}
                out of {showtotalData} entries
              </p>
            </div>
            <div className="">
              <Pagination
                count={totalCount}
                onChange={(event, value) => {
                  setcurrentPage(value);
                }}
                shape="rounded"
                style={{ margin: "10px", float: "right" }}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center mt-5">
          <p className="nofound">{handleNoData && <NoDataFound />}</p>
        </div>
      )}
    </>
  );
}
