import { Grid } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Image from "./assets/gallery.png";
import edit from "./assets/edit.png";
import pdfPhoto from "./assets/pdfPhoto.png";
import { useState } from "react";
import EditStaffDetail from "./EditStaffDetail";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import UploadDocUIStaff from "../components/UploadDocUIStaff";
import {
  getStaffdetail,
  updateStaff,
  uploadFile,
} from "../services/allPropertiesAPI";
import { useEffect } from "react";
import UserDocument from "../components/UserDocument";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import { buildingContext } from "../login/AuthProvider";
import Loader from "../components/Loader";

const StaffDetailUi = ({
  propertyFields,
  setPropertyFields,
  handleLoader,
  setHandleLoader,
}) => {
  const urlParams = useParams();
  const staffId = urlParams.id;
  const [handleEditMode, setHandleEditMode] = useState(false);
  const [handleEditDocMode, setHandleEditDocMode] = useState(false);
  const [openDocument, setOpenDocument] = useState(false);
  const [visaDocument, setVisaDocument] = useState(undefined);
  const [offerLetterDocument, setOfferLetterDocument] = useState(undefined);
  const [passportDocument, setPassportDocument] = useState(undefined);
  const [userDocument, setUserDocument] = useState([]);
  // const [handleLoader, setHandleLoader] = useState(false);
  const [docType, setDocType] = useState({
    staffId: "",
    visaDocument: "",
    passportDocument: "",
    offerLetterDocument: "",
  });

  const { selectedBuilding } = React.useContext(buildingContext);
  let buildingId = selectedBuilding;
  const location = useLocation();
  const userId = location.pathname.split("/")[2];

  const Document = [
    {
      value: "Passport",
      label: "Passport",
    },
    {
      value: "Visa Document",
      label: "Visa Document",
    },
    {
      value: "Offer Letter",
      label: "Offer Letter",
    },
  ];

  const handleEditButton = () => {
    setHandleEditMode(true);
  };
  const handleEditDocButton = () => {
    setHandleEditDocMode(true);
  };
  const handleRemoveDoc = (type) => {
    setDocType({
      ...docType,
      [type]: "",
    });
  };
  const getStaffDetails = async () => {
    setHandleLoader(true);
    try {
      const res = await getStaffdetail({ userId, buildingId });
      if (res.data.status === "success") {
        setDocType({
          staffId: res.data.data.id,
          visaDocument: res.data.data.visaDocument,
          passportDocument: res.data.data.passportDocument,
          offerLetterDocument: res.data.data.offerLetterDocument,
        });
      } else {
        toast("Not get the data");
      }
    } catch {
      alert("msg");
    }
    setHandleLoader(false);
  };
  useEffect(() => {
    getStaffDetails();
  }, []);
  const handleUpdateData = async () => {
    const request = {
      ...docType,
      offerLetterDocument: docType.offerLetterDocument,
      passportDocument: docType.passportDocument,
      visaDocument: docType.visaDocument,
    };
    setHandleLoader(true);
    try {
      const res = await updateStaff(request);
      toast(res?.data?.status);
      if (res?.data?.status === "success") {
        getStaffDetails();
        setHandleEditDocMode(false);
        // toast("Saved Successfully");
      } else {
        // toast(res?.data?.status);
      }
    } catch (err) {
      alert(err);
    }
    setHandleLoader(false);
  };

  const UploadDocument = async () => {
    // for visaDocument upload
    setHandleLoader(true);
    if (visaDocument) {
      let visaDocumentFormData = new FormData();
      visaDocumentFormData.append("file", visaDocument.document);
      try {
        let visaDocumentLink = await uploadFile(visaDocumentFormData);
        if (visaDocumentLink?.data?.status === "success") {
          setDocType({
            ...docType,
            visaDocument: visaDocumentLink.data.data[0].location,
          });
        } else {
          toast("Please Check Upload doc is not uploaded");
        }
      } catch {
        alert("err");
      }
    }
    // for passportDocument upload
    if (passportDocument) {
      let passportDocumentFormData = new FormData();
      passportDocumentFormData.append("file", passportDocument.document);
      let passportDocumentLink = await uploadFile(passportDocumentFormData);
      if (passportDocumentLink?.data?.status === "success") {
        setDocType({
          ...docType,
          passportDocument: passportDocumentLink.data.data[0].location,
        });
      } else {
        toast("Please Check Upload doc is not uploaded");
      }
    }
    // for passportDocument upload
    if (offerLetterDocument) {
      let offerLetterDocumentFormData = new FormData();
      offerLetterDocumentFormData.append("file", offerLetterDocument.document);
      let offerLetterDocumentLink = await uploadFile(
        offerLetterDocumentFormData
      );

      if (offerLetterDocumentLink?.data?.status === "success") {
        setDocType({
          ...docType,
          offerLetterDocument: offerLetterDocumentLink.data.data[0].location,
        });
      } else {
        toast("Please Check Upload doc is not uploaded");
      }
    }
    setHandleLoader(false);
  };
  useEffect(() => {
    UploadDocument();
  }, [visaDocument, passportDocument, offerLetterDocument]);

  const download = async (linkOfImage) => {
    setHandleLoader(true);
    const originalImage = linkOfImage;
    const image = await fetch(originalImage);
    const nameSplit = originalImage.split("/");
    const duplicateName = nameSplit.pop();
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = "" + duplicateName + "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setHandleLoader(false);
  };

  return (
    <>
      <Grid container mt={2} gap={2}>
        {handleLoader && <Loader />}
        <Grid xs={7}>
          <Box className="card" p={2}>
            {handleEditMode === true ? (
              <EditStaffDetail
                propertyFields={propertyFields}
                docType={docType}
                setPropertyFields={setPropertyFields}
                setHandleEditMode={setHandleEditMode}
                setHandleLoader={setHandleLoader}
              />
            ) : (
              <>
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex">
                    <img
                      src={
                        propertyFields?.profilePicture === ""
                          ? Image
                          : propertyFields?.profilePicture
                      }
                      alt=""
                      style={{ height: "8rem", width: "10rem" }}
                    />
                    <Box px={2}>
                      <Typography fontWeight="500">
                        {propertyFields.name}
                      </Typography>
                      <Typography
                        mt={1.5}
                        color="#636764"
                        fontSize="14px"
                        fontWeight="600"
                      >
                        Email
                      </Typography>
                      <Typography color="#111120" fontWeight="400">
                        {propertyFields.email}
                      </Typography>
                      <Typography color=" #727272" mt={1.5} fontWeight="600">
                        Phone Number
                      </Typography>
                      <Typography fontWeight="400">
                        {propertyFields.countryCode}-
                        {propertyFields.mobileNumber}
                      </Typography>
                    </Box>
                  </Box>
                  <Box onClick={handleEditButton}>
                    <img src={edit} />
                  </Box>
                </Box>
                <Box my={4}>
                  <Typography color="#111120" fontSize={20}>
                    Other Details
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    my={2}
                    px={1.5}
                  >
                    <Typography color="#111120" lineHeight={2.5}>
                      Name
                    </Typography>
                    <Typography>{propertyFields?.name}</Typography>
                  </Box>
                  {/* <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={1.5}
                  >
                    <Typography color="#111120">Last Name</Typography>
                    <Typography>
                      {propertyFields?.name?.split(" ").length > 1
                        ? propertyFields?.name?.split(" ").slice(-1)
                        : ""}
                    </Typography>
                  </Box> */}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    bgcolor="#FAFAFA"
                    alignItems="center"
                    my={2}
                    px={1.5}
                  >
                    <Typography color="#111120" lineHeight={2.5}>
                      Department
                    </Typography>
                    <Typography>{propertyFields.department}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={1.5}
                  >
                    <Typography color="#111120">Designation</Typography>
                    <Typography>{propertyFields.designation}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    bgcolor="#FAFAFA"
                    alignItems="center"
                    my={2}
                    px={1.5}
                  >
                    <Typography color="#111120" lineHeight={2.5}>
                      Appointment
                    </Typography>
                    <Typography>{propertyFields.appointment}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={1.5}
                  >
                    <Typography color="#111120">Nationality</Typography>
                    <Typography>{propertyFields.nationality}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    bgcolor="#FAFAFA"
                    alignItems="center"
                    my={2}
                    px={1.5}
                  >
                    <Typography color="#111120" lineHeight={2.5}>
                      Passport Number
                    </Typography>
                    <Typography>{propertyFields.passportNumber}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    my={2}
                    px={1.5}
                  >
                    <Typography color="#111120" lineHeight={2.5}>
                      National Id Number
                    </Typography>
                    <Typography>{propertyFields?.emiratesId}</Typography>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Grid>

        <Grid xs={4}>
          <Box className="card" height="100vh">
            {handleEditDocMode === true ? (
              <>
                <Box display="flex" justifyContent="space-between" m={2}>
                  <Typography color="#111120" fontSize={20} lineHeight={1.5}>
                    Edit Documents
                  </Typography>
                  <Box
                    color=" #0C344E"
                    fontSize={16}
                    sx={{ cursor: "pointer", textDecoration: "underLine" }}
                    onClick={handleUpdateData}
                  >
                    Save Changes
                  </Box>
                </Box>
                <Box
                  width="90%"
                  textAlign="center"
                  style={{
                    border: "1px dashed  #efefef",
                    borderRadius: "10px",
                  }}
                  m={2}
                  p={1.5}
                  fontSize={18}
                >
                  <input
                    multiple
                    id="uploadUserProfile"
                    style={{ display: "none" }}
                    onClick={() => {
                      setOpenDocument(!openDocument);
                    }}
                  />
                  <label
                    htmlFor="uploadUserProfile"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AddIcon />
                    Add Document
                  </label>
                </Box>
                {userDocument.map((doc) => (
                  <UserDocument
                    setUserDocument={setUserDocument}
                    userDocument={userDocument}
                    doc={doc}
                  />
                ))}

                {openDocument && (
                  <UploadDocUIStaff
                    openDocument={openDocument}
                    setOpenDocument={setOpenDocument}
                    setVisaDocument={setVisaDocument}
                    setPassportDocument={setPassportDocument}
                    setOfferLetterDocument={setOfferLetterDocument}
                  />
                )}
              </>
            ) : (
              <Box display="flex" justifyContent="space-between" m={2}>
                <Typography color="#111120" fontSize={20} lineHeight={1.5}>
                  Attached Documents
                </Typography>
                <Box onClick={handleEditDocButton}>
                  <img src={edit} alt="" />
                </Box>
              </Box>
            )}
            {!docType?.passportDocument &&
              !docType?.passportDocument &&
              !docType?.offerLetterDocument && (
                <Box textAlign="center" mt={2}>
                  No Document
                </Box>
              )}
            {docType?.passportDocument && (
              <Box m={2}>
                <Typography color="#727272" mb={1}>
                  Passport
                </Typography>
                <Box
                  className="card"
                  display="flex"
                  justifyContent="space-between"
                  p={2}
                  style={{
                    boxShadow: " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                    borderStyle: "none",
                  }}
                >
                  <Box display="flex" justifyContent="space-between">
                    <Typography>
                      <img
                        src={pdfPhoto}
                        style={{ marginRight: "10px" }}
                        alt=""
                      />
                      {docType?.passportDocument?.split("/")[3]?.split("-")[0]}
                    </Typography>
                    <Typography>
                      <a onClick={() => download(docType?.passportDocument)}>
                        <DownloadForOfflineIcon style={{ color: "#0d6efd" }} />
                      </a>
                      {handleEditDocMode && (
                        <CloseIcon
                          onClick={() => handleRemoveDoc("passportDocument")}
                        />
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
            {docType?.visaDocument && (
              <Box m={2}>
                <Typography color="#727272" mb={1}>
                  Visa
                </Typography>
                <Box
                  className="card"
                  display="flex"
                  justifyContent="space-between"
                  p={2}
                  style={{
                    boxShadow: " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                    borderStyle: "none",
                  }}
                >
                  <Box display="flex" justifyContent="space-between">
                    <Typography>
                      <img src={pdfPhoto} style={{ marginRight: "10px" }} />
                      {docType?.visaDocument?.split("/")[3]?.split("-")[0]}
                    </Typography>
                    <Typography>
                      <a onClick={() => download(docType?.visaDocument)}>
                        <DownloadForOfflineIcon style={{ color: "#0d6efd" }} />
                      </a>
                      {handleEditDocMode && (
                        <CloseIcon
                          onClick={() => handleRemoveDoc("visaDocument")}
                        />
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
            {docType?.offerLetterDocument && (
              <Box m={2}>
                <Typography color="#727272" mb={1}>
                  OfferLetter
                </Typography>
                <Box
                  className="card"
                  display="flex"
                  justifyContent="space-between"
                  p={2}
                  style={{
                    boxShadow: " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                    borderStyle: "none",
                  }}
                >
                  <Box display="flex" justifyContent="space-between">
                    <Typography>
                      <img
                        src={pdfPhoto}
                        style={{ marginRight: "10px" }}
                        alt=""
                      />
                      {
                        docType?.offerLetterDocument
                          ?.split("/")[3]
                          ?.split("-")[0]
                      }
                    </Typography>
                    <Typography>
                      <a onClick={() => download(docType?.visaDocument)}>
                        <DownloadForOfflineIcon style={{ color: "#0d6efd" }} />
                      </a>
                      {handleEditDocMode && (
                        <CloseIcon
                          onClick={() => handleRemoveDoc("offerLetterDocument")}
                        />
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default StaffDetailUi;
