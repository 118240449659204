import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import "./AllRequesttable.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { getHelplineData } from "../Redux/HelplineSlice";
import { showDash } from "../util";
import NoDataFound from "./NoDataFound";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ConfirmBox from "./ConfirmBox";
import { getSoftDeleteHelpline } from "../services/helplineServise";
import { toast } from "react-toastify";

export default function HelplineTable({
  placeholder,
  postPerPage,
  page,
  totalCount,
  setPage,
  showTotalData,
  setShowTotalData,
  searchText,
  selectedBuilding,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [helplineId, setHelplineId] = useState("");
  const [getDelete, setGetDelete] = useState(false);

  useEffect(() => {
    dispatch(
      getHelplineData({ page, postPerPage, searchText, selectedBuilding })
    );
  }, [page, selectedBuilding, searchText]);

  const { helplineData } = useSelector((state) => state.HelplineData);
  const handleNavigate = (id) => {
    navigate(`/helplinedetail/${id}`);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirmBox = () => {
    setOpenConfirm(false);
  };

  const deleteHelpline = async () => {
    const resp = await getSoftDeleteHelpline(helplineId);
    if (resp.data.status == "fail") {
      toast.error(resp.data.msg);
    } else {
      toast.success("Helpline number delete successfully");
      dispatch(
        getHelplineData({ page, postPerPage, searchText, selectedBuilding })
      );
    }
  };
  return (
    <>
      {helplineData?.rows?.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table sx={{}} aria-label="simple table">
              <TableHead className="thead">
                <TableRow>
                  <TableCell align="center" className="bold">
                    Name
                  </TableCell>
                  <TableCell align="center" className="bold">
                    Country Code
                  </TableCell>
                  <TableCell align="center" className="bold">
                    Contact Number
                  </TableCell>
                  <TableCell align="center" className="bold">
                    Toll Free
                  </TableCell>
                  <TableCell align="center" className="bold">
                    No. of Building
                  </TableCell>
                  <TableCell align="center" className="bold">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {helplineData?.rows?.map((row) => (
                  <TableRow
                    onClick={() => {
                      setHelplineId(row?.id);
                    }}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                      "&:nth-of-type(even)": {
                        backgroundColor: "#EFF0F6",
                      },
                    }}
                  >
                    <TableCell
                      align="center"
                      style={{
                        padding: "0px",
                        color: "#0d6efd",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleNavigate(row?.id);
                      }}
                    >
                      {row.name_en}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ padding: "0px" }}
                      onClick={() => {
                        handleNavigate(row?.id);
                      }}
                    >
                      {row?.countryCode
                        ? `+${showDash(row?.countryCode)}`
                        : "-"}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ padding: "0px" }}
                      onClick={() => {
                        handleNavigate(row?.id);
                      }}
                    >
                      {row.contactNumber}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ padding: "0px" }}
                      onClick={() => {
                        handleNavigate(row?.id);
                      }}
                    >
                      {row?.isTollFree ? "Yes" : "No"}
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{ padding: "0px" }}
                      onClick={() => {
                        handleNavigate(row?.id);
                      }}
                    >
                      <Button sx={{ m: 1, color: "#471f20" }}>
                        {row?.helplineBuildings?.length === 1
                          ? row?.helplineBuildings[0]?.building?.name_en
                          : `${
                              row?.helplineBuildings[0]?.building?.name_en
                            }${","} ${"+"}
                         ${row?.helplineBuildings?.length - 1} ${"more"}`}
                      </Button>
                    </TableCell>
                    <ConfirmBox {...{ openConfirm, handleCloseConfirmBox }}>
                      <Typography variant="h6">
                        Are you sure, You want to delete ?
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          columnGap: "20px",
                          margin: "30px 0px 0px 15px",
                        }}
                      >
                        <Button
                          sx={{
                            textTransform: "capitalize",
                            border: "1px solid #0c344e",
                            color: "#0c344e",
                          }}
                          onClick={handleCloseConfirmBox}
                        >
                          No
                        </Button>
                        <Button
                          onClick={(e) => {
                            deleteHelpline();
                            setOpenConfirm(false);
                            e.stopPropagation();
                          }}
                          variant="standard"
                          sx={{
                            textTransform: "capitalize",
                            border: "1px solid #0c344e",
                            color: "white",
                            background: "#0c344e",
                            "&:hover": {
                              backgroundColor: "white",
                              color: "#0c344e",
                            },
                          }}
                        >
                          Yes
                        </Button>
                      </Box>
                    </ConfirmBox>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={(e) => {
                          handleNavigate(helplineId);
                          e.stopPropagation();
                        }}
                      >
                        Update
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          handleClose();
                          handleOpenConfirm();
                          e.stopPropagation();
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                    <TableCell align="center" padding="7px">
                      <MoreVertIcon onClick={handleClick} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="space-between" marginTop="10px">
            <Box>
              <p>
                Showing {postPerPage * (page - 1) + 1}
                &nbsp;-
                {page * postPerPage > showTotalData
                  ? showTotalData
                  : page * postPerPage}{" "}
                out of {showTotalData} entries
              </p>
            </Box>
            <Box>
              <Pagination
                count={totalCount}
                onChange={(event, value) => {
                  setPage(value);
                }}
                shape="rounded"
              />
            </Box>
          </Box>
        </>
      ) : (
        <div className="nofound">
          <NoDataFound />
        </div>
      )}
    </>
  );
}
