import React from "react";
import AllRequest from "./AllRequest";

const ServisesRequestMain = () => {
  return (
    <>
      <div className="top-level-container">
        <AllRequest />
      </div>
    </>
  );
};

export default ServisesRequestMain;
