import { yupResolver } from "@hookform/resolvers/yup";
import SearchIcon from "@mui/icons-material/Search";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MdDownloadForOffline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import edit from "../../StaffDetail/assets/edit.png";
import DocImage from "../../assets/DocImage.png";
import BuildingDropDown from "../../components/BuildingDropDown";
import CurrencyList from "../../components/CurrencyList";
import Model from "../../components/Dailog";
import DatePicker from "../../components/DatePicker";
import InputText from "../../components/InputText";
import Loader from "../../components/Loader";
import { buildingContext } from "../../login/AuthProvider";
import {
  OwnerDataDropDown,
  TenentDropDown,
  getFlatDataOnlyVacant,
} from "../../services/LeaseApi";
import { getAllBuilding, uploadFile } from "../../services/allPropertiesAPI";
import "../Lease.css";
import EditOwner from "./EditOwner";
import { addlease } from "../../Redux/LeaseSlice";
import AddUnitInLease from "./AddUnitInLease";
import AddTenant from "./AddTenant";

const AddLease = () => {
  const [buildingDataList, setbuildingDataList] = useState([]);
  const [flatData, setFlatData] = useState([]);
  const [tenentData, setTenentData] = useState([]);
  const [ownerData, setOwnerData] = useState({});
  const [loaderUploadImage, setLoaderUploadImage] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [addOwnerCreation, setAddOwnerCreation] = useState({});
  const [masterUserIdDupicate, setMasterUserIdDupicate] = useState("");
  const [openModelForUnit, setopenModelForUnit] = useState(false);
  const [openModelForTenant, setOpenModelForTenant] = useState(false);
  const [addTenantCreation, setAddTenantCreation] = useState({});

  const DISCOUNT_APPLICABILITY = ["Deposit", "Rent", "Grace Period"];

  const { isLoading } = useSelector((state) => state.LeaseData);
  const { selectedBuilding } = React.useContext(buildingContext);

  const handleClose = () => {
    setOpenModel(false);
  };
  const handleOpen = () => {
    setOpenModel(true);
  };
  const handleCloseforUnit = () => {
    setopenModelForUnit(false);
    setOpenModelForTenant(false);
  };

  const handleOpenForUnit = (tenant) => {
    if (tenant) {
      setOpenModelForTenant(true);
    } else {
      setopenModelForUnit(true);
    }
  };

  const validationSchema = yup.object().shape({
    building: yup.string().required("This field is required"),
    ...(!masterUserIdDupicate?.name?.length > 0 &&
      !addTenantCreation?.name?.length > 0 && {
        masterUserId: yup.string().required("This filed is required"),
      }),
    flatId: yup.string().required("This filed is required"),
    activationFee: yup
      .number()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      )
      .nullable()
      .positive("Number must be positive"),
    discountAmount: yup
      .number()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      )
      .nullable()
      .positive("Number must be positive"),
    noticePeriod: yup.number().required("This filed is required"),
    flatUsage: yup.string().required("This field is required"),
    rentAmount: yup
      .number()
      .typeError("This field is required")
      .required("This field is required")
      .positive("Rent Amount must be postive"),
    paymentMode: yup.string().required("This field is required"),
    paymentFrequency: yup.string().required("This field is required"),
    contractStartDate: yup
      .date("Contract Start Date should be date")
      .required("This field is required"),
    contractEndDate: yup.date().required("This field is required"),
    securityDeposit: yup
      .number()
      .typeError("This field is required")
      .required("This field is required")
      .positive("Security deposit must be postive"),
  });

  const monthCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      discount: "percentage",
      masterUserId: {},
      discountAmount: "",
      Fixeddeposit: "",
      noticePeriod: "",
      rentAmount: "",
      flatId: "",
      building: selectedBuilding ? selectedBuilding : "",
      contractEndDate: "",
      securityDeposit: "",
      flatUsage: "",
      contractStartDate: "",
      firstpaymentdate: "",
      moveInDate: "",
      moveOutDate: "",
      owner: "No Owner Name",
      paymentFrequency: "",
      paymentMode: "",
      activationFee: "",
      contractImage: [],
      isDiscountRequired: false,
      applicableOn: "",
      currency: "AED",
      ownerEmail: "No Owner Email",
      ownerMobileNumber: "No Owner Mobile Number",
      tenantEmail: "",
      tenantMobileNumber: "",
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const [
    Fixeddeposit,
    discount,
    building,
    contractImage,
    flatId,
    moveInDate,
    contractStartDate,
    contractEndDate,
    isDiscountRequired,
    applicableOn,
    currency,
    discountAmount,
    securityDeposit,
    rentAmount,
    masterUserIdShowData,
    owner,
    ownerEmail,
    moveOutDate,
  ] = watch([
    "Fixeddeposit",
    "discount",
    "building",
    "contractImage",
    "flatId",
    "moveInDate",
    "contractStartDate",
    "contractEndDate",
    "isDiscountRequired",
    "applicableOn",
    "currency",
    "discountAmount",
    "securityDeposit",
    "rentAmount",
    "masterUserId",
    "owner",
    "ownerEmail",
    "moveOutDate",
  ]);
  useEffect(() => {
    setValue("moveOutDate", contractEndDate);
  }, [contractEndDate]);
  console.log(errors, "errors");

  useEffect(() => {
    setValue("contractEndDate", moment(contractStartDate).add(1, "year"));
  }, [contractStartDate]);

  const PAYMENT_FREQUENCIES = ["Monthly", "Quaterly", "Half Yearly", "Yearly"];
  const FLAT_USAGE = ["Commercial", "Residential", "Industrial"];
  const PAYMENT_MODES = ["Cheque", "Cash", "Online"];

  const fetchAllBuildingDataList = async () => {
    let buildingFromStorage = sessionStorage.getItem("building");
    if (buildingFromStorage?.length > 0) {
      const resp = await getAllBuilding();
      let specificBuilding = resp?.data?.data?.rows?.filter(
        (building) => building.id === buildingFromStorage
      );

      setbuildingDataList(specificBuilding);
    } else {
      const resp = await getAllBuilding();

      setbuildingDataList(resp.data.data.rows);
    }
  };

  const getTenentData = async () => {
    const resp = await TenentDropDown();

    setTenentData(resp.data.data.rows);
  };

  useEffect(() => {
    setValue("owner", ownerData?.name ? ownerData?.name : "No Owner");
    setValue("ownerEmail", `${ownerData?.email || "No Owner Email"}`);
    setValue(
      "ownerMobileNumber",
      ownerData?.mobileNumber
        ? `+${ownerData?.mobileNumber || "No Owner Mobile Number"}`
        : "No Owner Mobile Number"
    );
  }, [JSON.stringify(ownerData)]);

  useEffect(() => {
    setValue(
      "tenantMobileNumber",
      masterUserIdShowData?.mobileNumber
        ? `+${masterUserIdShowData?.mobileNumber || ""}`
        : ""
    );
    setValue("tenantEmail", `${masterUserIdShowData?.email || ""}`);
  }, [masterUserIdShowData]);

  const getOwnerData = async (flatId) => {
    try {
      const resp = await OwnerDataDropDown(flatId);
      setOwnerData(resp.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchAllBuildingDataList();
    getTenentData();
  }, [selectedBuilding]);
  // fetch flat data
  useEffect(() => {
    if (flatId) {
      getOwnerData(flatId);
    }
  }, [flatId]);

  const fetchFlatDetail = async (id) => {
    try {
      const resp = await getFlatDataOnlyVacant(id);
      setFlatData(resp.data.data);
    } catch (err) {}
  };

  useEffect(() => {
    if (building || selectedBuilding) {
      fetchFlatDetail(selectedBuilding ? selectedBuilding : building);
    }
  }, [building, selectedBuilding]);

  const UplaodContactDocs = useCallback(
    async (e) => {
      setLoaderUploadImage(true);
      const selectedFile = e.target.files;
      const formData = new FormData();
      for (let i = 0; i < selectedFile.length; i++) {
        formData.append("file", selectedFile[i]);
      }
      const resp = await uploadFile(formData);

      const newFiles = resp.data.data.map((file) => {
        return {
          name: file.originalName,
          url: file.location,
          contentType: file.contentType,
        };
      });
      const files = getValues().contractImage;
      setValue("contractImage", [...files, ...newFiles]);
      setLoaderUploadImage(false);
    },

    [contractImage]
  );

  const periodMonth = useMemo(() => {
    var startDate = moment(contractStartDate);
    var endDate = moment(contractEndDate);
    var months = endDate.diff(startDate, "months");
    return months;
  }, [contractEndDate]);

  const onSubmit = (data) => {
    const paylaod = {
      masterUserId: addTenantCreation.id
        ? addTenantCreation.id
        : data?.masterUserId?.id,
      flatId: data.flatId,
      flatUsage: data.flatUsage,
      securityDeposit: data.securityDeposit,
      noticePeriod: data.noticePeriod,
      activationFee: data.activationFee ? data.activationFee : null,
      paymentFrequency: data.paymentFrequency,
      paymentMode: data.paymentMode,
      contractStartDate: data.contractStartDate
        ? moment(data.contractStartDate).format("DD-MM-YYYY")
        : null,
      contractEndDate: data.contractEndDate
        ? moment(data.contractEndDate).format("DD-MM-YYYY")
        : null,
      moveInDate: data.moveInDate
        ? moment(data.moveInDate).format("DD-MM-YYYY")
        : null,
      moveOutDate: data.moveOutDate
        ? moment(data.moveOutDate).format("DD-MM-YYYY")
        : null,
      discountAmount:
        discount === "percentage"
          ? applicableOn === "Deposit"
            ? (securityDeposit * discountAmount) / 100
            : (rentAmount * discountAmount) / 100
          : data.discountAmount,
      ownerId: addOwnerCreation ? addOwnerCreation.id : null,
      rentAmount: data.rentAmount,
      contractImage: data.contractImage,
      applicableOn: data?.applicableOn,
      grace: data?.grace,
      currency: data?.currency,
      description: data.description,
      isDiscountRequired: new Boolean(data.isDiscountRequired),
    };

    dispatch(addlease({ paylaod, navigate }));
  };

  useEffect(() => {
    setValue("owner", addOwnerCreation?.name);
    setValue("ownerEmail", addOwnerCreation.email);
    setValue(
      "ownerMobileNumber",
      addOwnerCreation.mobileNumber ? `+${addOwnerCreation?.mobileNumber}` : ""
    );
  }, [JSON.stringify(addOwnerCreation)]);

  useEffect(() => {
    setValue("totalDiscount", "");
    setValue("discountAmount", "");
  }, [isDiscountRequired]);

  useEffect(() => {
    setValue("tenantName", addTenantCreation.name);
    setValue("tenantEmail", addTenantCreation.email);
    setValue(
      "tenantMobileNumber",
      addTenantCreation?.mobileNumber
        ? `+${addTenantCreation?.mobileNumber || "No Owner Mobile Number"}`
        : "No Owner Mobile Number"
    );
  }, [JSON.stringify(addTenantCreation)]);

  // addUserForLease()
  useEffect(() => {
    if (applicableOn === "Deposit") {
      let updatedDiscount = securityDeposit - discountAmount;
      setValue("totalDiscount", Math.ceil(updatedDiscount));
      if (discount === "percentage") {
        let finalPercentageAmount =
          securityDeposit - (securityDeposit * discountAmount) / 100;
        setValue("totalDiscount", Math.ceil(finalPercentageAmount));
      }
    } else {
      let updated = rentAmount - discountAmount;
      setValue("totalDiscount", Math.ceil(updated));
      if (discount === "percentage") {
        let finalPercentageAmount =
          rentAmount - (rentAmount * discountAmount) / 100;
        setValue("totalDiscount", Math.ceil(finalPercentageAmount));
      }
    }
  }, [discountAmount, applicableOn, securityDeposit, rentAmount, discount]);

  // useEffect(() => {
  //   addOwnerCreation["name"] = null;
  // }, [flatId]);

  console.log(errors, "isLoading");
  return (
    <div className="home">
      <h3>Add Lease</h3>
      {isLoading && <Loader />}
      <div className="d-flex justify-content-start">
        <div>
          <form>
            <div className="mt-5">
              <div
                className="d-flex justify-content-between mt-3"
                style={{ width: "50vw" }}
              >
                <div>
                  <BuildingDropDown
                    displayedOptions={buildingDataList}
                    name="building"
                    register={register}
                    label="Select Property Name*"
                    width="300px"
                    innerWidth="300px"
                    buildingId={building}
                    selectedBuilding={selectedBuilding}
                    building={building}
                  />

                  <span className="errorHanlde">
                    {errors?.building?.message}
                  </span>
                </div>
                <div>
                  <Model
                    openModel={openModelForUnit}
                    handleClose={handleCloseforUnit}
                  >
                    <AddUnitInLease
                      buildingId={building ? building : selectedBuilding}
                      handleClose={handleCloseforUnit}
                      fetchFlatDetail={fetchFlatDetail}
                    />
                  </Model>
                  <FormControl fullWidth>
                    <InputLabel
                      id="search-select-label"
                      sx={{ marginLeft: "-14px" }}
                    >
                      Select Unit*
                    </InputLabel>
                    <Select
                      MenuProps={{ autoFocus: false }}
                      sx={{ width: "300px" }}
                      labelId="search-select-label"
                      id="search-select"
                      variant="standard"
                      {...register("flatId")}
                      name="flatId"
                      label={"Select Unit"}
                      onClose={() => setSearchText("")}
                    >
                      <ListSubheader>
                        <TextField
                          size="small"
                          variant="standard"
                          placeholder="Type to search..."
                          sx={{
                            width: "260px",
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>
                      <MenuItem
                        onClick={() => {
                          handleOpenForUnit();
                        }}
                      >
                        + Add New Unit
                      </MenuItem>
                      {flatData
                        ?.filter((option) =>
                          option?.name
                            ?.toLowerCase()
                            .includes(searchText.toLowerCase())
                        )
                        .map((option, i) => (
                          <MenuItem key={option?.id} value={option?.id}>
                            {option?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <br />
                  <span className="errorHanlde">{errors?.flatId?.message}</span>
                </div>
              </div>
              <Model {...{ handleClose, openModel }}>
                <EditOwner
                  ownerData={
                    addOwnerCreation?.name ? addOwnerCreation : ownerData
                  }
                  setAddOwnerCreation={setAddOwnerCreation}
                  flatId={flatId}
                  handleClose={handleClose}
                  setOpenModel={setOpenModel}
                  getOwnerData={getOwnerData}
                />
              </Model>
              <div
                className="d-flex justify-content-between mt-3"
                style={{ width: "50vw" }}
              >
                <div>
                  <div className="d-flex">
                    <TextField
                      label="Owner"
                      inputProps={{ maxLength: 100 }}
                      multiline
                      maxRows={4}
                      maxLength={10}
                      className="labelHanlde"
                      disabled
                      {...register("owner")}
                      name="owner"
                      variant="standard"
                      style={{
                        fontFamily: "Barlow",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        width: "300px",
                        lineHeight: "19px",
                      }}
                    ></TextField>
                    <div>
                      <img
                        src={edit}
                        className="HandleEdit"
                        onClick={handleOpen}
                        alt="...."
                      />
                    </div>
                  </div>
                  <br />
                  <span className="errorHanlde">{errors?.owner?.message}</span>
                </div>
                <div>
                  <TextField
                    label="Owner Email"
                    inputProps={{ maxLength: 100 }}
                    multiline
                    className="labelHanlde"
                    disabled
                    maxRows={4}
                    maxLength={10}
                    {...register("ownerEmail")}
                    name="ownerEmail"
                    variant="standard"
                    style={{
                      fontFamily: "Barlow",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      width: "300px",
                      lineHeight: "19px",
                    }}
                  ></TextField>
                </div>
              </div>
              <div
                className="d-flex justify-content-between mt-3"
                style={{ width: "50vw" }}
              >
                <div>
                  <TextField
                    label="Owner Mobile Number"
                    shrink
                    inputProps={{ maxLength: 100 }}
                    multiline
                    maxRows={4}
                    maxLength={10}
                    className={"labelHanlde"}
                    disabled
                    {...register("ownerMobileNumber")}
                    name="ownerMobileNumber"
                    variant="standard"
                    style={{
                      fontFamily: "Barlow",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      width: "300px",
                      lineHeight: "19px",
                    }}
                  ></TextField>
                </div>
                <div sx={{ marginLeft: "100px" }}>
                  <Model
                    openModel={openModelForTenant}
                    handleClose={handleCloseforUnit}
                  >
                    <AddTenant
                      {...{ handleCloseforUnit, setAddTenantCreation }}
                    />
                  </Model>
                  {addTenantCreation.name ? (
                    <>
                      <TextField
                        label="Tenant Name"
                        inputProps={{ maxLength: 100 }}
                        multiline
                        maxRows={4}
                        maxLength={10}
                        className="HandleLabel"
                        {...register("tenantName")}
                        // disabled
                        name="tenantName"
                        value={masterUserIdShowData?.email}
                        InputLabelProps={{
                          shrink:
                            masterUserIdShowData?.name || addTenantCreation.name
                              ? true
                              : false,
                        }}
                        variant="standard"
                        style={{
                          fontFamily: "Barlow",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "16px",
                          width: "300px",
                          lineHeight: "19px",
                        }}
                      ></TextField>
                    </>
                  ) : (
                    <>
                      <FormControl fullWidth>
                        <InputLabel
                          id="search-select-label"
                          sx={{ marginLeft: "62px" }}
                        >
                          Tenant*
                        </InputLabel>
                        <Select
                          MenuProps={{ autoFocus: false }}
                          sx={{ width: "300px", marginLeft: "80px" }}
                          labelId="search-select-label"
                          id="search-select"
                          variant="standard"
                          name="masterUserId"
                          {...register("masterUserId")}
                          onChange={(e) => {
                            setValue("masterUserId", e.target.value);
                            setMasterUserIdDupicate(e.target.value);
                          }}
                          label="Tenant*"
                          onClose={() => setSearchText("")}
                        >
                          <ListSubheader>
                            <TextField
                              size="small"
                              variant="standard"
                              placeholder="Type to search..."
                              inputProps={{ maxLength: "30" }}
                              sx={{
                                width: "300px",
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => setSearchText(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key !== "Escape") {
                                  e.stopPropagation();
                                }
                              }}
                            />
                          </ListSubheader>
                          <MenuItem
                            onClick={() => {
                              handleOpenForUnit("tenant");
                            }}
                          >
                            + Add New Tenant
                          </MenuItem>
                          {tenentData
                            .filter(
                              (option) =>
                                option?.name
                                  ?.toLowerCase()
                                  .includes(searchText.toLowerCase()) ||
                                option?.email
                                  ?.toLowerCase()
                                  .includes(searchText.toLowerCase()) ||
                                option?.mobileNumber
                                  ?.toLowerCase()
                                  .includes(searchText.toLowerCase())
                            )
                            .map((option, i) => (
                              <MenuItem key={option?.id} value={option}>
                                <span>{option.name}</span>
                                <span
                                  style={{
                                    background: "#eff0f6",
                                    color: "#0c344e",
                                    padding: "0px 10px 0px 10px",
                                    borderRadius: "10px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {option.userType}
                                </span>
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <span
                        className="errorHanlde"
                        style={{
                          marginLeft: "80px",
                          display: masterUserIdDupicate.name
                            ? "none"
                            : "visible",
                        }}
                      >
                        {errors?.masterUserId?.message &&
                          "This field is a required "}
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div
                className="d-flex justify-content-between mt-3"
                style={{ width: "50vw" }}
              >
                <div>
                  <TextField
                    label="Tenant Mobile Number"
                    inputProps={{ maxLength: 100 }}
                    multiline
                    maxRows={4}
                    maxLength={10}
                    className="HandleLabel"
                    {...register("tenantMobileNumber")}
                    disabled
                    InputLabelProps={{
                      shrink:
                        masterUserIdShowData?.mobileNumber ||
                        addTenantCreation.email
                          ? true
                          : false,
                    }}
                    name="tenantMobileNumber"
                    variant="standard"
                    style={{
                      fontFamily: "Barlow",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      width: "300px",
                      lineHeight: "19px",
                    }}
                  ></TextField>
                </div>
                <div>
                  <TextField
                    label="Tenant Email"
                    inputProps={{ maxLength: 100 }}
                    multiline
                    maxRows={4}
                    maxLength={10}
                    className="HandleLabel"
                    {...register("tenantEmail")}
                    disabled
                    name="tenantEmail"
                    value={masterUserIdShowData?.email}
                    InputLabelProps={{
                      shrink:
                        masterUserIdShowData?.email || addTenantCreation.email
                          ? true
                          : false,
                    }}
                    variant="standard"
                    style={{
                      fontFamily: "Barlow",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      width: "300px",
                      lineHeight: "19px",
                    }}
                  ></TextField>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <div className="d-flex  flex-column">
                  <DatePicker
                    label="Contract Start Date*"
                    Controller={Controller}
                    control={control}
                    name="contractStartDate"
                    register={register}
                    width={"300px"}
                  />
                  {errors?.contractStartDate?.message && (
                    <span className="errorHanlde">
                      {errors?.contractStartDate?.message !==
                      "This filed is required"
                        ? "Contract Start Date must be a date"
                        : errors?.contractStartDate?.message}
                    </span>
                  )}
                </div>
                <div>
                  <div className="d-flex  flex-column">
                    <DatePicker
                      label="Contract End Date*"
                      Controller={Controller}
                      control={control}
                      name="contractEndDate"
                      register={register}
                      date={moment(getValues().contractStartDate).add(1, "Y")}
                      width={"300px"}
                    />
                    {errors?.contractEndDate?.message && (
                      <span className="errorHanlde">
                        {errors?.contractEndDate?.message !==
                        "This filed is required"
                          ? "Contract End Date must be a date"
                          : errors?.contractEndDate?.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="d-flex justify-content-between mt-3"
                style={{ width: "50vw" }}
              >
                <div>
                  <DatePicker
                    label="Move In Date"
                    Controller={Controller}
                    control={control}
                    name="moveInDate"
                    register={register}
                    width={"300px"}
                  />
                </div>
                <div>
                  <DatePicker
                    label="Move Out Date"
                    Controller={Controller}
                    control={control}
                    name="moveOutDate"
                    register={register}
                    width={"300px"}
                  />
                </div>
              </div>

              <div
                className="d-flex justify-content-between mt-3"
                style={{ width: "50vw" }}
              >
                <div>
                  <TextField
                    select
                    label="Notice Period (months)*"
                    inputProps={{ maxLength: 100 }}
                    multiline
                    maxRows={4}
                    maxLength={10}
                    {...register("noticePeriod")}
                    name="noticePeriod"
                    variant="standard"
                    style={{
                      fontFamily: "Barlow",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      width: "300px",
                      lineHeight: "19px",
                    }}
                  >
                    {monthCount?.map((curElem) => {
                      return (
                        <MenuItem key={curElem} value={curElem}>
                          {curElem}
                        </MenuItem>
                      );
                    })}
                  </TextField>

                  <br />
                  {errors?.noticePeriod?.message && (
                    <span className="errorHanlde">
                      Notice Period must be number
                    </span>
                  )}
                </div>
                <div>
                  <TextField
                    disabled
                    label="Contract Period(month)"
                    value={periodMonth ? periodMonth : 0}
                    sx={{ width: "300px" }}
                    variant="standard"
                  />
                </div>
              </div>
              <div style={{ marginTop: "20px" }}>
                <TextField
                  select
                  label="Purpose*"
                  inputProps={{ maxLength: 100 }}
                  multiline
                  maxRows={4}
                  maxLength={10}
                  {...register("flatUsage")}
                  name="flatUsage"
                  variant="standard"
                  style={{
                    fontFamily: "Barlow",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    width: "300px",
                    lineHeight: "19px",
                  }}
                >
                  {FLAT_USAGE?.map((option) => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </TextField>
                <br />
                <span className="errorHanlde">
                  {errors?.flatUsage?.message}
                </span>
              </div>
              <div className="mt-4">
                <h5>Tenancy Terms</h5>
                <Box sx={{ marginTop: "15px" }}>
                  <TextField
                    select
                    label="Select Your Currency"
                    inputProps={{ maxLength: 100 }}
                    multiline
                    maxRows={4}
                    maxLength={10}
                    defaultValue="AED"
                    {...register("currency")}
                    name="currency"
                    variant="standard"
                    style={{
                      fontFamily: "Barlow",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      width: "300px",
                      lineHeight: "19px",
                    }}
                  >
                    {CurrencyList?.map((cur, key) => {
                      return (
                        <MenuItem value={cur.currency_code}>
                          {cur.currency_code}
                        </MenuItem>
                      );
                    })}
                  </TextField>

                  {/* <FormControl fullWidth>
                    <InputLabel
                      id="search-select-label"
                      sx={{ marginLeft: "-14px" }}
                    >
                      Select Currency
                    </InputLabel>
                    <Select
                      MenuProps={{ autoFocus: false }}
                      sx={{ width: "300px" }}
                      labelId="search-select-label"
                      id="search-select"
                      variant="standard"
                      defaultValue="AED"
                      {...register("currency")}
                      name="currency"
                      label={"Select Unit"}
                      onClose={() => setSearchText("")}
                    >
                      <ListSubheader>
                        <TextField
                          size="small"
                          variant="standard"
                          placeholder="Search the country name"
                          sx={{
                            width: "260px",
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>
                      {CurrencyList?.filter((option) =>
                        option?.country
                          ?.toLowerCase()
                          .includes(searchText.toLowerCase())
                      ).map((option, i) => (
                        <MenuItem value={option?.currency_code}>
                          {option?.currency_code}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                </Box>
                <div
                  className="d-flex justify-content-between mt-3"
                  style={{ width: "50vw" }}
                >
                  <Box>
                    <Box display="flex">
                      <p
                        style={{
                          marginTop: "22px",
                          borderBottom: "1px solid #727272",
                          width: "30px",
                          marginRight: "20px",
                        }}
                      >
                        {currency}
                      </p>
                      <InputText
                        label="Payment Rent Amount*"
                        register={register}
                        name="rentAmount"
                        width="250px"
                      />
                      {/* <br /> */}
                    </Box>
                    {errors?.rentAmount?.message && (
                      <Box className="errorHanlde" mt="-0.5rem">
                        {errors?.rentAmount?.message}
                      </Box>
                    )}
                  </Box>

                  <div>
                    <TextField
                      select
                      label="Payment Mode*"
                      inputProps={{ maxLength: 100 }}
                      multiline
                      maxRows={4}
                      maxLength={10}
                      {...register("paymentMode")}
                      name="paymentMode"
                      variant="standard"
                      style={{
                        fontFamily: "Barlow",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        width: "300px",
                        lineHeight: "19px",
                      }}
                    >
                      {PAYMENT_MODES?.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <br />
                    <span className="errorHanlde">
                      {errors?.paymentMode?.message}
                    </span>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between mt-3"
                  style={{ width: "50vw" }}
                >
                  <div>
                    <TextField
                      select
                      label="Payment Frequency*"
                      inputProps={{ maxLength: 100 }}
                      multiline
                      maxRows={4}
                      maxLength={10}
                      {...register("paymentFrequency")}
                      name="paymentFrequency"
                      variant="standard"
                      style={{
                        fontFamily: "Barlow",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        width: "300px",
                        lineHeight: "19px",
                      }}
                    >
                      {PAYMENT_FREQUENCIES?.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <br />
                    <span className="errorHanlde">
                      {errors?.paymentFrequency?.message}
                    </span>
                  </div>
                  <Box>
                    <Box display="flex">
                      <p
                        style={{
                          marginTop: "22px",
                          borderBottom: "1px solid #727272",
                          width: "30px",
                          marginRight: "20px",
                        }}
                      >
                        {currency}
                      </p>
                      <InputText
                        label="Government Charges"
                        register={register}
                        name="activationFee"
                        width="250px"
                      />
                      {/* <br /> */}
                    </Box>
                    {errors?.activationFee?.message && (
                      <Box className="errorHanlde" mt="-0.5rem">
                        {errors?.activationFee?.message}
                      </Box>
                    )}
                  </Box>
                </div>
                <div style={{ width: "50vw" }}>
                  <Box display="flex" my={2}>
                    <p
                      style={{
                        marginTop: "22px",
                        borderBottom: "1px solid #727272",
                        width: "30px",
                        marginRight: "20px",
                      }}
                    >
                      {currency}
                    </p>
                    <InputText
                      label="Security Deposit Amount*"
                      name="securityDeposit"
                      register={register}
                      width="250px"
                    />
                  </Box>
                  <Box className="errorHanlde" mt="-0.5rem">
                    {errors?.securityDeposit?.message}
                  </Box>
                </div>
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <div>
                    <Controller
                      name="isDiscountRequired"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          {...register("isDiscountRequired")}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      )}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <h6>Discount(Optional)</h6>
                  </div>
                </div>
                {isDiscountRequired && (
                  <>
                    <div>
                      <TextField
                        select
                        label="Applicable On"
                        inputProps={{ maxLength: 100 }}
                        multiline
                        maxRows={4}
                        maxLength={10}
                        {...register("applicableOn")}
                        name="applicableOn"
                        variant="standard"
                        style={{
                          fontFamily: "Barlow",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "16px",
                          width: "300px",
                          lineHeight: "19px",
                        }}
                      >
                        {DISCOUNT_APPLICABILITY?.map((option) => {
                          return (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                      <div>
                        {applicableOn?.length > 0 && (
                          <div>
                            {applicableOn === "Grace Period" ? (
                              <div style={{ marginTop: "10px" }}>
                                <InputText
                                  label="Grace Period(month)"
                                  name="grace"
                                  register={register}
                                  width="300px"
                                />
                              </div>
                            ) : (
                              <>
                                <div className="mt-4">
                                  <div>
                                    <RadioGroup
                                      aria-labelledby="demo-radio-buttons-group-label"
                                      defaultValue="percentage"
                                      name="discount"
                                      onChange={(event) =>
                                        setValue("discount", event.target.value)
                                      }
                                      sx={{ display: "flex" }}
                                    >
                                      <Box display={"flex"}>
                                        <Box>
                                          <FormControlLabel
                                            value="percentage"
                                            control={<Radio />}
                                            label="Percentage"
                                          />
                                        </Box>
                                        <Box>
                                          <FormControlLabel
                                            value="Fixed"
                                            control={<Radio />}
                                            label="Fixed"
                                          />
                                        </Box>
                                      </Box>
                                    </RadioGroup>
                                  </div>
                                  <div>
                                    <div
                                      className="d-flex justify-content-between"
                                      style={{ width: "50vw" }}
                                    >
                                      <div>
                                        <div className="d-flex gap-2">
                                          <div className="d-flex gap-2">
                                            <p
                                              style={{
                                                marginTop: "22px",
                                                borderBottom:
                                                  "1px solid #727272",
                                              }}
                                            >
                                              {discount === "Fixed"
                                                ? currency
                                                : "%"}
                                            </p>
                                            <div>
                                              <InputText
                                                label={
                                                  discount === "Fixed"
                                                    ? "Amount"
                                                    : "Percentage"
                                                }
                                                name="discountAmount"
                                                register={register}
                                                width="300px"
                                              />
                                              <br />
                                              {errors?.discountAmount
                                                ?.message && (
                                                <Box className="errorHanlde">
                                                  {
                                                    errors?.discountAmount
                                                      ?.message
                                                  }
                                                </Box>
                                              )}
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              marginLeft: "50px",
                                              display: "flex",
                                            }}
                                          >
                                            <p
                                              style={{
                                                marginTop: "22px",
                                                borderBottom:
                                                  "1px solid #727272",
                                                width: "30px",
                                                marginRight: "20px",
                                              }}
                                            >
                                              {currency}
                                            </p>
                                            <InputText
                                              label={`Total ${applicableOn} after Discount`}
                                              name="totalDiscount"
                                              register={register}
                                              width="255px"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <Controller
                                      name="description"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <TextareaAutosize
                                            {...field}
                                            aria-label="minimum height"
                                            minRows={2}
                                            placeholder="Description"
                                            style={{
                                              width: "100%",
                                              minHeight: "50px",
                                              marginTop: "5px",
                                              paddingLeft: "10px",
                                              borderRadius: "10px",
                                            }}
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
        <div style={{ margin: "90px 0px 0px 80px" }}>
          <input
            type="file"
            multiple
            accept=".doc,.docx,.csv,.png,.jpeg,jpg,application/pdf"
            name="contractImage"
            {...register("contractImage")}
            id="leaseDoc"
            style={{ display: "none" }}
            onChange={(e) => UplaodContactDocs(e)}
          />
          <label htmlFor="leaseDoc">
            <div
              style={{
                padding: "13px 50px",
                borderRadius: "20px",
                border: "1px dotted black",
                background: "white",
              }}
            >
              Add Contract
            </div>
          </label>
          <div className="mt-2">
            {contractImage.map((fileInfo) => {
              if (fileInfo.contentType == "application/pdf") {
                return (
                  <div
                    className="d-flex justify-content-between align-items-center mb-2"
                    style={{
                      background: "white",
                      boxShadow: "0px 3px 9px rgba(112, 112, 112, 0.22)",
                      padding: "8px 20px",
                      borderRadius: "5px",
                    }}
                  >
                    <div>
                      <div>
                        <img
                          src={DocImage}
                          style={{ width: "20px", height: "20px" }}
                        />
                        <span style={{ marginLeft: "10px" }}>
                          {fileInfo.name.slice(0, 20)}
                        </span>
                      </div>
                    </div>
                    <div>
                      <MdDownloadForOffline />
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    className="d-flex justify-content-between align-items-center mb-2"
                    style={{
                      background: "white",
                      boxShadow: "0px 3px 9px rgba(112, 112, 112, 0.22)",
                      padding: "8px 20px",
                      borderRadius: "5px",
                    }}
                  >
                    <div>
                      <div>
                        <img
                          src={fileInfo.url}
                          style={{ width: "20px", height: "20px" }}
                        />
                        <span style={{ marginLeft: "10px" }}>
                          {fileInfo.name.slice(0, 10)}
                        </span>
                      </div>
                    </div>
                    <div>
                      <MdDownloadForOffline />
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
      <div>
        <button
          type="submit"
          id="savebtn"
          className="Button"
          variant="contained"
          color="success"
          style={{
            border: "1px solid #0C344E",
            color: "#0C344E",
            background: "#0c344e",
            width: "187px",
            height: "48px",
            fontFamily: "Barlow",
            fontSize: "16px",
            marginTop: "20px",
            borderRadius: "10px",
            textTransform: "capitalize",
            color: "white",
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Add Lease
        </button>
        <button
          onClick={() => navigate("/lease")}
          style={{
            border: "1px solid #0C344E",
            width: "140px",
            height: "48px",
            fontFamily: "Barlow",
            fontSize: "16px",
            marginTop: "20px",
            borderRadius: "10px",
            textTransform: "capitalize",
            color: "#0C344E",
            marginLeft: "20px",
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AddLease;
