import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { buildingContext } from "../login/AuthProvider";
import { axiosClient } from "../services/axiosClient";
import { formatAMPM } from "../util";
import "./AllRequesttable.css";
import { Close } from "@mui/icons-material";
import { Button, Chip, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { fetchBuildingName, fetchUnitName } from "../Redux/MultiUseSlice";
import { getFilterKey, getVisitorData } from "../services/visitorApi";
import ExportCsvFile from "./ExportCsvFile";
import Loader from "./Loader";
import NoDataFound from "./NoDataFound";

export default function UserTable({ id }) {
  const { buildingName, isLoading, flatName } = useSelector(
    (state) => state.MultiUseReducerData
  );

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [visitor, setVisitor] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [postPerPage, setPostPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [showTotalData, setShowTotalData] = useState("");
  const { selectedBuilding } = React.useContext(buildingContext);
  const [searchByUnit, setSearchByUnit] = useState("");
  const [visitorSearch, setVisitorSearch] = useState("");
  const [data, setData] = useState([]);
  const [handleDefault, setHandleDefault] = useState([]);
  const [csvFile, setCsvFile] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [handleChip, setHanldeChip] = useState(false);
  let buildingId = selectedBuilding;
  const [open, setOpen] = React.useState(false);
  const [buildingData, setBuildingData] = useState("");
  const [flatData, setFlatData] = useState("");
  const [handleFilterState, setHandleFilterState] = useState(null);
  const handleOpen = () => setOpen(true);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    setHandleFilterState(null);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff",
    borderRadius: "5px",
    p: 2,
    boxShadow: "0px -2px 20px rgb(0 0 0 / 23%)",
    borderRadius: "16px",
    maxHeight: "400px",
    overflow: "auto",
  };

  const arrFilter = ["Date", "Category", "Building", "Unit Name"];

  let formatStartDate = moment(startDate).format("DD/MM/YYYY");
  let formatEndDate = moment(endDate)
    .endOf("days")
    .format("DD/MM/YYYY hh:mm a");
  const getVisitorByTime = (pageValue) => {
    if (
      formatStartDate !== "Invalid date" &&
      formatEndDate !== "Invalid date"
    ) {
      getData(formatStartDate, formatEndDate, pageValue);
    } else {
      toast("Please the correct date");
    }
  };

  const getData = async (getStartDate, getEndDate, pageValue) => {
    const limit = 10;
    setLoader(true);
    try {
      const res = await getVisitorData(
        visitorSearch,
        buildingId,
        limit,
        currentPage,
        formatStartDate !== "Invalid date" ? formatStartDate : "",
        formatEndDate !== "Invalid date" ? formatEndDate : "",
        pageValue,
        searchByUnit,
        buildingData,
        flatData
      );
      setVisitor(res.data.data.rows);
      let pagesCount = Math.ceil(res.data.data.count / postPerPage);
      setTotalCount(pagesCount);
      setShowTotalData(res.data.data.count);
      setLoader(false);
    } catch {}
  };

  const DataFilter = async () => {
    try {
      const data = await getFilterKey();
      if (data?.data?.status === "success") {
        setData(data.data.data);
      }
    } catch {}
  };
  useEffect(() => {
    setCurrentPage(1);
    getData();
  }, [
    visitorSearch,
    endDate,
    searchByUnit,
    buildingId,
    handleChip,
    buildingData,
    flatData,
  ]);

  useEffect(() => {
    if (
      formatStartDate !== "Invalid date" &&
      formatEndDate !== "Invalid date"
    ) {
      getVisitorByTime();
    } else {
      getData();
    }
  }, [currentPage]);

  useEffect(() => {
    DataFilter();
  }, []);

  const handleDelete = (param) => {
    // getData();
    if (param === "date") {
      setStartDate("");
      setEndDate("");
      handleChip(false);
      // getData();
    } else if (param === "building") {
      setBuildingData("");
    } else if (param === "flat") {
      setFlatData("");
    } else {
      setVisitorSearch("");
    }
  };

  const filterByCategory = async () => {
    setLoader(true);
    const data = await axiosClient
      .get(
        `visitings/logs?page=1&limit=100000000000&buildingId=${buildingId}&startDate=${
          formatStartDate !== "Invalid date" ? formatStartDate : ""
        }&endDate=${
          formatEndDate !== "Invalid date" ? formatEndDate : ""
        }&category_en=${visitorSearch}`
      )
      .then((result) => {
        let csv = result.data.data.rows.map((item) => {
          let obj = {};
          obj.VisitorName = item?.name;
          obj.MobileNumber = `+${item.mobileNumber}`;
          obj.BuildingName = item?.buildingName_en;
          obj.Flat = item?.flatName_en;
          obj.Floor = item?.floor;
          obj.Category = item?.category_en;
          obj.CompanyName = item?.company_en;
          obj.InDate = item.inTime && item.inTime.slice(0, 10);
          obj.InTime = item.inTime && item.inTime.slice(11, 19);
          obj.OutDate = item.outTime && item.outTime.slice(0, 10);
          obj.OutTime = item.outTime && item.outTime.slice(11, 19);
          obj.VisitorsCount = item.visitorsCount;
          obj.Email = item?.additionalDetails?.email;
          obj.Gender = item?.additionalDetails?.gender;
          obj.DateOfBirth = item?.additionalDetails?.dateOfBirth;
          obj.vehicleNumber = item?.additionalDetails?.vehicleNumber;
          obj.nameOnDocument = item?.additionalDetails?.nameOnDocument;
          obj.numberOnDocument = item?.additionalDetails?.numberOnDocument;
          obj.Description = item?.descripition;
          obj.DocumentId = item?.documentId;
          obj.DocumentImage = item?.documentImage;
          obj.DocumentCountry = item?.documentCountry;
          obj.documentExpiry = item?.documentExpiry;
          obj.documentIssueState = item?.documentIssueState;
          obj.documentIssueDate = item?.documentIssueDate;
          obj.passportNumber = item?.passportNumber;
          obj.ApprovedBy = item.approvedBy;
          obj.documentIssueDate = item?.documentIssueDate;
          // obj.documentIssueState = item?.documentIssueState;
          // obj.passportNumber = item?.passportNumber;

          return obj;
        });
        setCsvFile(csv);
        setHandleDefault(csv);
        setLoader(false);
        return csv;
      })
      .catch((err) => {
        toast("Csv file is not downloaded please try again.");
        setLoader(false);
      });
  };
  const hanldefilterChip = () => {
    getVisitorByTime("1");
    setHanldeChip(true);
  };

  const filterDate = `${startDate} to ${endDate}`;

  useEffect(() => {
    if (open) {
      dispatch(fetchBuildingName());
      dispatch(fetchUnitName());
    }
  }, [open]);

  const buildingFilter = useMemo(
    () =>
      buildingName?.rows?.filter((curr) => {
        return curr.id === buildingData;
      }),
    [buildingData]
  );

  const unitFilter = useMemo(
    () =>
      flatName?.rows?.filter((curr) => {
        return curr.id === flatData;
      }),
    [flatData]
  );

  const handleNavigate = (id) => {
    navigate(`/visitordetail/${id}`);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "60px",
          justifyContent: "space-between",
        }}
      >
        {loader && <Loader />}
        <div className="Property" style={{ marginRight: "20px" }}>
          Visitors
        </div>
        <div
          style={{
            display: "flex",
            order: 2,
            alignItems: "center",
            gap: "1.5rem",
          }}
        >
          <div
            className="bg-white"
            style={{ borderRadius: "10px", padding: "3px 10px" }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
                fill="#111120"
              />
              <path
                d="M22.0014 22.7495C21.8114 22.7495 21.6214 22.6795 21.4714 22.5295L19.4714 20.5295C19.1814 20.2395 19.1814 19.7595 19.4714 19.4695C19.7614 19.1795 20.2414 19.1795 20.5314 19.4695L22.5314 21.4695C22.8214 21.7595 22.8214 22.2395 22.5314 22.5295C22.3814 22.6795 22.1914 22.7495 22.0014 22.7495Z"
                fill="#111120"
              />
            </svg>
            <input
              style={{
                marginRight: "5px",
                borderRadius: "5px",
                padding: "10px",
                width: "145px",
                border: "none",
                outline: "none",
              }}
              type="search"
              placeholder="Search"
              name="Search"
              value={searchByUnit}
              onChange={(e) => {
                setSearchByUnit(e.target.value);
              }}
            />
          </div>
          <div>
            <ExportCsvFile
              function={filterByCategory}
              csvFile={csvFile}
              handleDefault={handleDefault}
              fileName="Visitor Data"
            />
          </div>
          <div>
            <Button
              className="Button"
              variant="contained"
              color="success"
              onClick={() => {
                // maintenanceType();
                handleOpen();
                // flatDetailFilter();
              }}
              style={{
                border: "1px solid #0C344E",
                color: "#0C344E",
                background: "white",
                width: "120px",
                height: "48px",
                fontFamily: "Barlow",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "19px",
                borderRadius: "10px",
                textTransform: "capitalize",
              }}
            >
              Add Filter
            </Button>
          </div>
        </div>
      </div>
      <div className="mb-4 juned">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box display="flex" justifyContent="space-between">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Add Filter
              </Typography>
              <Close onClick={handleClose} />
            </Box>
            <Box>
              <select
                style={{
                  width: "50%",
                  // marginTop: "10px",
                  border: "2px solid black",
                  padding: "10px",
                  borderRadius: "5px",
                }}
                onChange={(e) => setHandleFilterState(e.target.value)}
              >
                <option>Select Filter </option>
                {arrFilter?.map((curElem, index) => (
                  <>
                    <option key={curElem} value={curElem}>
                      {curElem}
                    </option>
                  </>
                ))}
              </select>
              <Button
                variant="contained"
                width="50%"
                sx={{
                  width: "45%",
                  marginLeft: "1rem",
                  borderRadius: "1rem",
                  height: "3rem",
                  background: "#0C344E",
                }}
                onClick={() => handleClose()}
              >
                Save
              </Button>
            </Box>
            <Box mt={2}>
              {handleFilterState === "Date" ? (
                <Box display="flex" justifyContent="space-around">
                  <input
                    type="date"
                    name="startDate"
                    placeholder="Start Date"
                    onChange={(e) => setStartDate(e.target.value)}
                    value={startDate}
                    className="startDate"
                  />
                  <input
                    name="endDate"
                    type="date"
                    placeholder="End Date"
                    onChange={(e) => setEndDate(e.target.value)}
                    value={endDate}
                    min={startDate}
                    className="startDate"
                  />
                </Box>
              ) : handleFilterState === "Category" ? (
                <select
                  style={{
                    width: "50%",
                    height: "45px",
                    border: "2px solid black",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                  onChange={(e) => setVisitorSearch(e.target.value)}
                  value={visitorSearch}
                >
                  <option value="">Select Visitor</option>
                  {data.length > 1 &&
                    data?.map((item) => (
                      <>
                        <option key={item.category} value={item.category}>
                          {item.category}
                        </option>
                      </>
                    ))}
                  <option value="">Clear All</option>
                </select>
              ) : handleFilterState === "Building" ? (
                <select
                  style={{
                    width: "50%",
                    height: "45px",
                    border: "2px solid black",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                  defaultValue={selectedBuilding}
                  onChange={(e) => setBuildingData(e.target.value)}
                  value={buildingData}
                >
                  <option value="">Building Name</option>
                  {buildingName?.rows?.length > 1 &&
                    buildingName?.rows?.map((item) => {
                      return (
                        <>
                          <option key={item.key} value={item.id}>
                            {item.name_en}
                          </option>
                        </>
                      );
                    })}
                  <option value="">Clear All</option>
                </select>
              ) : handleFilterState === "Unit Name" ? (
                <Box>
                  <select
                    style={{
                      width: "50%",
                      height: "45px",
                      border: "2px solid black",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                    onChange={(e) => setFlatData(e.target.value)}
                    value={flatData}
                  >
                    <option value="">Unit Name</option>
                    {flatName?.rows?.length > 1 &&
                      flatName?.rows?.map((item) => (
                        <>
                          <option key={item.id} value={item.id}>
                            {item.name_en}
                          </option>
                        </>
                      ))}
                    <option value="">Clear All</option>
                  </select>
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </Modal>
      </div>
      <div>
        {startDate && endDate && (
          <Chip
            label={`${startDate} to ${endDate}`}
            variant="outlined"
            onDelete={() => handleDelete("date")}
          ></Chip>
        )}

        {visitorSearch && (
          <Chip
            label={visitorSearch}
            variant="outlined"
            onDelete={() => handleDelete(visitorSearch)}
          ></Chip>
        )}
        {buildingData && buildingFilter[0]?.name_en && (
          <Chip
            label={buildingFilter[0]?.name_en}
            variant="outlined"
            onDelete={() => handleDelete("building")}
          ></Chip>
        )}
        {flatData && unitFilter[0]?.name_en && (
          <Chip
            label={unitFilter[0]?.name_en}
            variant="outlined"
            onDelete={() => handleDelete("flat")}
          ></Chip>
        )}
      </div>
      {visitor.length > 0 ? (
        <>
          <TableContainer component={Paper} sx={{ marginTop: "10px" }}>
            <Table sx={{}} aria-label="simple table">
              <TableHead className="thead">
                <TableRow>
                  <TableCell className="Sbold" align="center">
                    Vistiors Name
                  </TableCell>
                  <TableCell className="Sbold" align="center">
                    Contact No.
                  </TableCell>
                  <TableCell className="Sbold" align="center">
                    Visitor Category
                  </TableCell>
                  <TableCell className="Sbold" align="center">
                    Company Name
                  </TableCell>
                  <TableCell className="Sbold" align="center">
                    Building
                  </TableCell>

                  <TableCell className="Sbold" align="center">
                    Unit No.
                  </TableCell>

                  <TableCell className="Sbold" align="center">
                    Date of Visit
                  </TableCell>

                  <TableCell className="Sbold" align="center">
                    In Time
                  </TableCell>
                  <TableCell className="Sbold" align="center">
                    Out Time
                  </TableCell>
                  <TableCell className="Sbold" align="center">
                    Checkout Date
                  </TableCell>
                  <TableCell className="Sbold" align="center">
                    Approved By
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visitor.map((row) => (
                  <TableRow
                    className="rowTable"
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell
                      align="center"
                      onClick={() => handleNavigate(row?.id)}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell className="makespace" align="center">
                      +
                      {row.mobileNumber.split("").splice(0, 4).join("") +
                        "******"}
                    </TableCell>

                    <TableCell
                      align="center"
                      onClick={() => handleNavigate(row?.id)}
                    >
                      {row.category_en}
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={() => handleNavigate(row?.id)}
                    >
                      {row?.company_en ? row?.company_en : "-"}
                    </TableCell>
                    <TableCell
                      className="makespace"
                      align="center"
                      onClick={() => handleNavigate(row?.id)}
                    >
                      {row.buildingName_en.slice(0, 12) + "..."}
                    </TableCell>
                    <TableCell
                      className="makespace"
                      align="center"
                      onClick={() => handleNavigate(row?.id)}
                    >
                      {row.flatName_en}
                    </TableCell>

                    <TableCell
                      className="makespace"
                      align="center"
                      onClick={() => handleNavigate(row?.id)}
                    >
                      {row.inTime?.split("T")[0]}
                    </TableCell>
                    <TableCell
                      className="makespace"
                      align="center"
                      onClick={() => handleNavigate(row?.id)}
                    >
                      {formatAMPM(row.inTime)}
                    </TableCell>
                    <TableCell
                      className="makespace"
                      align="center"
                      onClick={() => handleNavigate(row?.id)}
                    >
                      {row.outTime ? formatAMPM(row.outTime) : "-"}
                    </TableCell>
                    <TableCell
                      className="makespace"
                      align="center"
                      onClick={() => handleNavigate(row?.id)}
                    >
                      {row.outTime ? row.outTime.split("T")[0] : "-"}
                    </TableCell>
                    <TableCell
                      className="makespace"
                      align="center"
                      onClick={() => handleNavigate(row?.id)}
                    >
                      {row.approvedBy}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex justify-content-between height-200px mt-2">
            <div className="">
              <p>
                Showing {postPerPage * (currentPage - 1) + 1}
                &nbsp;-
                {currentPage * postPerPage > showTotalData
                  ? showTotalData
                  : currentPage * postPerPage}{" "}
                out of {showTotalData} entries
              </p>
            </div>
            <div className="">
              <Pagination
                count={totalCount}
                onChange={(event, value) => {
                  setCurrentPage(value);
                }}
                shape="rounded"
                style={{ margin: "10px", float: "right" }}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center mt-5">
          <p className="nofound">
            <NoDataFound />
          </p>
        </div>
      )}
    </>
  );
}
