import {
  Chip,
  FormControl,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../components/AllRequestProperty.css";
import AllRequesttable from "../../components/AllRequesttable";
import SearchBar from "../../components/SearchBar";
import { buildingContext } from "../../login/AuthProvider";
import {
  TotalCountStatus,
  exportAllRequests,
  getAllRequests,
  getMantinanceType,
} from "../../services/allServisesApi";

import { Close } from "@mui/icons-material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Box } from "@mui/system";
import { useRef } from "react";
import { AiOutlineLeftCircle, AiOutlineRightCircle } from "react-icons/ai";
import ExportCsvFile from "../../components/ExportCsvFile";
import { getflatServiceRequestFilter } from "../../services/allCommunityApi";
import { formatAMPM } from "../../util/index";

const AllRequest = () => {
  // const getSetterState = localStorage.getItem("handlePage");
  const lela = localStorage.getItem("initialStatus");
  const location = useLocation();
  const navigate = useNavigate();
  const [requests, setRequests] = useState([]);
  const [requestStatus, setRequestStatus] = useState(
    lela ? lela : () => (location?.state?.from ? location?.state?.from : "")
  );

  const getOpenTab = localStorage.getItem("openTab");

  const [searchText, setSearchText] = useState("");
  const [requestType, setRequestType] = useState("");
  const [totalCount, settotalCount] = useState(0);
  const [postPerpage, setPostPerPage] = useState(10);
  const [currentPage, setcurrentPage] = useState(
    (getOpenTab && JSON.parse(getOpenTab)) || {
      "": "1",
      PENDING: "1",
      ASSIGNED: "1",
      PROCESSING: "1",
      CANCELLED: "1",
      COMPLETED: "1",
      ON_HOLD: "1",
      RE_ASSIGNED: "1",
      REJECTED: "1",
      RE_OPEN: "1",
    }
  );

  const [age, setAge] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [requestIsUrgent, setRequestIsUrgent] = useState("");
  const [tabName, setTabName] = useState("incomplete");
  const [mantinanceType, setMantinanceType] = useState([]);
  const [selectedFlatForFilter, setSelectedFlatForFilter] = useState("");
  const [getFLatDetailForFilter, setGetFLatDetailForFilter] = useState([]);
  const { selectedBuilding } = React.useContext(buildingContext);
  const [selectedTab, setSelectedTab] = useState();
  const elementRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);
  const [activeTab, setActiveTab] = useState("All");

  let buildingId = selectedBuilding;

  const reload = location?.state?.from;

  const [handleFilterState, setHandleFilterState] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    setHandleFilterSaveBtn(false);
  };

  let formatStartDate = startDate ? moment(startDate).format("DD/MM/YYYY") : "";
  let formatEndDate = endDate
    ? moment(endDate).endOf("days").format("DD/MM/YYYY hh:mm a")
    : "";

  const onSearchChange = async (value) => {
    setSearchText(value);

    const resp = await getAllRequests(
      currentPage,
      postPerpage,
      value,
      buildingId,
      startDate,
      endDate,
      requestType,
      requestStatus,
      requestIsUrgent,
      selectedFlatForFilter
    );
    let totalData = resp.data.data.count;
    let pagesCount = Math.ceil(totalData / postPerpage);
    settotalCount(pagesCount);
    setRequests(resp.data.data.rows);
  };

  const [data, setData] = useState([]);
  const [handleDefault, setHandleDefault] = useState([]);

  const allRequestData = async () => {
    const resp = await exportAllRequests(
      1,
      10000000000,
      buildingId,
      formatStartDate,
      formatEndDate,
      requestType,
      requestStatus,
      requestIsUrgent,
      selectedFlatForFilter
    );

    let data1 = resp.data.data.rows.map((item) => {
      let obj = {};
      obj.RequestId = item.requestId;
      obj.Building = item?.flat?.building.name_en;
      obj.Flat = item.flat.name_en;
      obj.CreationDate = item.createdAt.split("T")[0];
      obj.CreationTime = formatAMPM(item.createdAt);
      obj.RequestStatus = item.status;
      obj.Category = item.type;
      obj.Description = item.description;
      obj.RequestedFor = item.isBuilding ? "Public Area" : "Unit";
      obj.Type = item.isUrgent ? "Urgent" : "Normal";
      obj.UpdateDate = item.updatedAt.split("T")[0];
      obj.UpdateTime = formatAMPM(item.updatedAt);
      obj.StaffName = item?.staff && item?.staff?.name;
      obj.VisitInTime = formatAMPM(item?.staffTimeSlot?.start);
      obj.VisitInDate = item?.staffTimeSlot?.start.split("T")[0];
      obj.VisitEndTime = formatAMPM(item?.staffTimeSlot?.end);
      obj.VisitEndDate = item?.staffTimeSlot?.end.split("T")[0];
      obj.UserName = item.user && item?.user.name;
      obj.Filesname = item?.files && item?.files?.map((item) => item.name);
      obj.Fileurl = item?.files && item?.files?.map((item) => item.url);

      return obj;
    });
    setData(data1);
    setHandleDefault(data1);
  };

  const handleClose = () => {
    setOpen(false);
    setHandleFilterState(null);
  };

  const arrFilter = ["Date", "Request Category", "Request Type", "Unit No"];

  const maintenanceType = async () => {
    const resp = await getMantinanceType();
    setMantinanceType(resp?.data?.data?.rows);
  };

  const RequestData = [
    {
      key: true,
      status: "Urgent",
    },
    {
      key: false,
      status: "Normal",
    },
  ];
  const [handleFilterSaveBtn, setHandleFilterSaveBtn] = useState(true);
  const handleSaveFilter = (event) => {
    handleClose();
    setHandleFilterSaveBtn(true);
  };

  const handleDelete = (param) => {
    if (param === "requestStatus") {
      setRequestStatus("");
    } else if (param === "reload") {
      navigate("/allrequest", { state: { from: undefined } });
      setRequestStatus("");
    } else if (param === "requestType") {
      setRequestType("");
    } else if (param === "unitNo") {
      setSelectedFlatForFilter("");
    } else if (param === "requestTypeForFilter") {
      setRequestIsUrgent("");
    } else {
      setEndDate("");
      setStartDate("");
    }
  };
  const handleTabState = (request) => {
    if (request === "COMPLETED") {
      setRequestStatus("COMPLETED");
      setTabName();
      localStorage.removeItem("CurrPage");
      setTabName();
      setcurrentPage(1);
    } else {
      setRequestStatus("");
      setTabName("incomplete");
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff",
    borderRadius: "5px",
    p: 2,
    boxShadow: "0px -2px 20px rgb(0 0 0 / 23%)",
    borderRadius: "16px",
    maxHeight: "400px",
    overflow: "auto",
  };

  const flatDetailFilter = async () => {
    const resp = await getflatServiceRequestFilter();
    setGetFLatDetailForFilter(resp?.data?.data?.rows);
  };
  console.log(getFLatDetailForFilter,"lllllll")
  const filterded = useMemo(() => {
    return getFLatDetailForFilter.filter(
      (item) => item.id === selectedFlatForFilter
    );
  }, [selectedFlatForFilter]);

  useEffect(() => {
    setTabName();
  }, [requestStatus]);

  const [tabCountData, setTabCountData] = useState({});

  const fetchTotalCountStatus = async () => {
    let data = {
      formatStartDate,
      formatEndDate,
      requestType,
      requestStatus,
      requestIsUrgent,
      selectedFlatForFilter,
    };
    const resp = await TotalCountStatus(buildingId, data);
    setTabCountData(resp.data.data);
  };

  useEffect(() => {
    fetchTotalCountStatus();
  }, [
    buildingId,
    endDate,
    requestType,
    requestIsUrgent,
    selectedFlatForFilter,
  ]);
  localStorage.setItem("initialStatus", requestStatus);

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };
  const newFilterItemsshow = useMemo(() => {
    const getNewRequest = mantinanceType?.filter((item) => {
      if (item?.id === requestType) {
        return item;
      }
    });
    return getNewRequest;
  }, [JSON.stringify(mantinanceType), requestType]);

  return (
    <>
      <div className="home">
        <div className="d-flex align-items-center">
          <div className="Property" style={{ marginRight: "40px" }}>
            All Request
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "auto",
              order: 2,
              gap: ".7rem",
            }}
          >
            <div>
              <SearchBar
                style={{ marginRight: "20px" }}
                placeholder="Search"
                onChange={onSearchChange}
              />
            </div>

            <div>
              <ExportCsvFile
                function={allRequestData}
                csvFile={data}
                handleDefault={handleDefault}
                fileName="All Request Data"
              />
            </div>
            <div>
              <Button
                className="Button"
                variant="contained"
                color="success"
                onClick={() => {
                  maintenanceType();
                  handleOpen();
                  flatDetailFilter();
                }}
                style={{
                  border: "1px solid #0C344E",
                  color: "#0C344E",
                  background: "white",
                  width: "120px",
                  height: "48px",
                  fontFamily: "Barlow",
                  fontStyle: "normal",
                  fontweight: "500",
                  fontSize: "16px",
                  lineHeight: "19px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                }}
              >
                Add Filter
              </Button>
            </div>

            <div>
              <Link to="/addallrequest" style={{ textDecoration: "none" }}>
                <Button
                  className="Button"
                  variant="contained"
                  color="success"
                  style={{
                    border: "1px solid #0C344E",
                    color: "#0C344E",
                    background: "white",
                    width: "170px",
                    height: "48px",
                    fontFamily: "Barlow",
                    textTransform: "capitalize",
                    fontSize: "16px",
                    borderRadius: "10px",
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 5V19"
                      stroke="#0C344E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5 12H19"
                      stroke="#0C344E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Add Request
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <Box>
          <div className="tabsFlow">
            {!arrowDisable && (
              <div
                style={{ marginRight: "10px" }}
                onClick={() => {
                  handleHorizantalScroll(elementRef.current, 25, 100, -10);
                }}
              >
                <AiOutlineLeftCircle size={23} />
              </div>
            )}

            <div className="tabs" ref={elementRef}>
              <div className="tabdetail">
                <span
                  className="tabtext"
                  onClick={() => {
                    setRequestStatus("");
                    setActiveTab("All");
                  }}
                >
                  All
                </span>
                <span
                  className={`"" ${
                    activeTab && activeTab === "All" ? "tabActive" : "tabCount"
                  }`}
                >
                  {tabCountData && tabCountData?.ALL}
                </span>
                <div
                  className={`"" ${requestStatus === "" && "underline"}`}
                ></div>
              </div>
              <div className="tabdetail">
                <span
                  className="tabtext"
                  onClick={() => {
                    setRequestStatus("PENDING");
                    setActiveTab();
                  }}
                >
                  Open
                </span>
                <span
                  className={`"" ${
                    requestStatus === "PENDING" ? "tabActive" : "tabCount"
                  }`}
                >
                  {tabCountData?.PENDING}
                </span>
                <div
                  className={`"" ${requestStatus === "PENDING" && "underline"}`}
                ></div>
              </div>
              <div className="tabdetail">
                <span
                  className="tabtext"
                  onClick={() => {
                    setRequestStatus("ASSIGNED");
                    setActiveTab();
                  }}
                >
                  Assigned
                </span>
                <span
                  className={`"" ${
                    requestStatus === "ASSIGNED" ? "tabActive" : "tabCount"
                  }`}
                >
                  {tabCountData?.ASSIGNED}
                </span>
                <div
                  className={`"" ${
                    requestStatus === "ASSIGNED" && "underline"
                  }`}
                ></div>
              </div>
              <div className="tabdetail">
                <span
                  className="tabtext"
                  onClick={() => {
                    setRequestStatus("PROCESSING");
                    setActiveTab();
                  }}
                >
                  In-Process
                </span>
                <span
                  className={`"" ${
                    requestStatus === "PROCESSING" ? "tabActive" : "tabCount"
                  }`}
                >
                  {tabCountData?.PROCESSING}
                </span>
                <div
                  className={`"" ${
                    requestStatus === "PROCESSING" && "underline"
                  }`}
                ></div>
              </div>
              <div className="tabdetail">
                <span
                  className="tabtext"
                  onClick={() => {
                    setRequestStatus("COMPLETED");
                    setActiveTab();
                  }}
                >
                  Completed
                </span>
                <span
                  className={`"" ${
                    requestStatus === "COMPLETED" ? "tabActive" : "tabCount"
                  }`}
                >
                  {tabCountData?.COMPLETED}
                </span>
                <div
                  className={`"" ${
                    requestStatus === "COMPLETED" && "underline"
                  }`}
                ></div>
              </div>

              <div className="tabdetail">
                <span
                  className="tabtext"
                  onClick={() => {
                    setRequestStatus("ON_HOLD");
                    setActiveTab();
                  }}
                >
                  Hold
                </span>
                <span
                  className={`"" ${
                    requestStatus === "ON_HOLD" ? "tabActive" : "tabCount"
                  }`}
                >
                  {tabCountData?.ON_HOLD}
                </span>
                <div
                  className={`"" ${requestStatus === "ON_HOLD" && "underline"}`}
                ></div>
              </div>
              <div className="tabdetail">
                <span
                  className="tabtext"
                  onClick={() => {
                    setRequestStatus("RE_OPEN");
                    setActiveTab();
                  }}
                >
                  Re-Open
                </span>
                <span
                  className={`"" ${
                    requestStatus === "RE_OPEN" ? "tabActive" : "tabCount"
                  }`}
                >
                  {tabCountData?.RE_OPEN}
                </span>
                <div
                  className={`"" ${requestStatus === "RE_OPEN" && "underline"}`}
                ></div>
              </div>
              <div className="tabdetail">
                <span
                  className="tabtext"
                  onClick={() => {
                    setRequestStatus("RE_ASSIGNED");
                    setActiveTab();
                  }}
                >
                  Re-Assigned
                </span>
                <span
                  className={`"" ${
                    requestStatus === "RE_ASSIGNED" ? "tabActive" : "tabCount"
                  }`}
                >
                  {tabCountData?.RE_ASSIGNED}
                </span>
                <div
                  className={`"" ${
                    requestStatus === "RE_ASSIGNED" && "underline"
                  }`}
                ></div>
              </div>

              <div className="tabdetail">
                <span
                  className="tabtext"
                  onClick={() => {
                    setRequestStatus("REJECTED");
                    setActiveTab();
                  }}
                >
                  Rejected
                </span>
                <span
                  className={`"" ${
                    requestStatus === "REJECTED" ? "tabActive" : "tabCount"
                  }`}
                >
                  {tabCountData?.REJECTED}
                </span>
                <div
                  className={`"" ${
                    requestStatus === "REJECTED" && "underline"
                  }`}
                ></div>
              </div>
              <div className="tabdetail">
                <span
                  className="tabtext"
                  onClick={() => {
                    setRequestStatus("CANCELLED");
                    setActiveTab();
                  }}
                >
                  Cancelled
                </span>
                <span
                  className={`"" ${
                    requestStatus === "CANCELLED" ? "tabActive" : "tabCount"
                  }`}
                >
                  {tabCountData?.CANCELLED}
                </span>
                <div
                  className={`"" ${
                    requestStatus === "CANCELLED" && "underline"
                  }`}
                ></div>
              </div>
            </div>

            <div
              style={{ marginLeft: "15px" }}
              onClick={() => {
                handleHorizantalScroll(elementRef.current, 25, 100, 10);
              }}
            >
              <AiOutlineRightCircle size={23} />
            </div>
          </div>

          <Stack direction="row" spacing={1} sx={{ marginTop: "10px" }}>
            {reload && (
              <Chip
                label={reload}
                variant="outlined"
                onDelete={() => handleDelete("reload")}
              />
            )}
            {requestType && (
              <Chip
                label={newFilterItemsshow?.[0]?.name_en}
                variant="outlined"
                onDelete={() => handleDelete("requestType")}
              />
            )}

            {endDate && (
              <>
                <Chip
                  label={`${startDate} to ${endDate}`}
                  variant="outlined"
                  onDelete={() => handleDelete("startDate")}
                />
              </>
            )}
            {requestIsUrgent && (
              <>
                <Chip
                  label={requestIsUrgent === "true" ? "Urgent" : "Normal"}
                  variant="outlined"
                  onDelete={() => handleDelete("requestTypeForFilter")}
                />
              </>
            )}
            {filterded[0]?.name_en && (
              <>
                <Chip
                  label={`${filterded[0]?.name_en}`}
                  variant="outlined"
                  onDelete={() => handleDelete("unitNo")}
                />
              </>
            )}
          </Stack>
        </Box>
        {/* filter modal --------------------------------------------------------------------------------------------------------------------- */}
        <div className="mb-4 juned">
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box display="flex" justifyContent="space-between">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Add Filter
                </Typography>
                <Close onClick={handleClose} />
              </Box>
              <Box>
                <select
                  style={{
                    width: "50%",
                    // marginTop: "10px",
                    border: "2px solid black",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                  onChange={(e) => setHandleFilterState(e.target.value)}
                >
                  <option>Select Filter </option>
                  {arrFilter?.map((curElem, index) => (
                    <>
                      <option key={curElem} value={curElem}>
                        {curElem}
                      </option>
                    </>
                  ))}
                </select>
                <Button
                  variant="contained"
                  width="50%"
                  sx={{
                    width: "45%",
                    marginLeft: "1rem",
                    borderRadius: "1rem",
                    height: "3rem",
                    background: "#0C344E",
                  }}
                  onClick={handleSaveFilter}
                >
                  Save
                </Button>
              </Box>
              <Box mt={2}>
                {handleFilterState === "Date" ? (
                  <Box display="flex" justifyContent="space-around">
                    <input
                      type="date"
                      name="startDate"
                      placeholder="Start Date"
                      onChange={(e) => setStartDate(e.target.value)}
                      value={startDate}
                      className="startDate"
                      // min={min}
                    />
                    <input
                      name="endDate"
                      type="date"
                      placeholder="End Date"
                      onChange={(e) => setEndDate(e.target.value)}
                      value={endDate}
                      className="startDate"
                      min={startDate}
                    />
                  </Box>
                ) : handleFilterState === "Request Category" ? (
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      onChange={(e) => setRequestType(e.target.value)}
                    >
                      {mantinanceType?.map((curElem) => (
                        <>
                          <FormControlLabel
                            value={curElem.id}
                            control={<Radio />}
                            label={curElem.name_en}
                          />
                        </>
                      ))}
                    </RadioGroup>
                  </FormControl>
                ) : handleFilterState === "Request Type" ? (
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      onChange={(e) => setRequestIsUrgent(e.target.value)}
                    >
                      {RequestData?.map((cur) => (
                        <>
                          <FormControlLabel
                            value={cur.key}
                            control={<Radio />}
                            label={cur.status}
                          />
                        </>
                      ))}
                    </RadioGroup>
                  </FormControl>
                ) : handleFilterState === "Unit No" ? (
                  <div>
                    <TextField
                      id="Urgent"
                      select
                      label="Select unit no"
                      name="unitNo"
                      variant="standard"
                      value={selectedFlatForFilter}
                      onChange={(e) => {
                        setSelectedFlatForFilter(e.target.value);
                      }}
                      style={{
                        fontFamily: "Barlow",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        width: "278px",
                        lineHeight: "19px",
                      }}
                    >
                      {getFLatDetailForFilter?.length > 0 ? (
                        getFLatDetailForFilter?.map((option) => {
                          return (
                            <MenuItem key={option?.id} value={option?.id}>
                              {option?.name_en}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <option
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          No Data Found
                        </option>
                      )}
                    </TextField>
                  </div>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Modal>
        </div>
        <AllRequesttable
          handleFilterSaveBtn={handleFilterSaveBtn}
          searchText={searchText}
          postPerpage={postPerpage}
          currentPage={currentPage}
          totalCount={totalCount}
          setcurrentPage={setcurrentPage}
          settotalCount={settotalCount}
          setRequests={setRequests}
          requests={requests}
          startDate={formatStartDate}
          endDate={formatEndDate}
          requestType={requestType}
          requestStatus={requestStatus}
          setRequestStatus={setRequestStatus}
          tabName={tabName}
          requestIsUrgent={requestIsUrgent}
          selectedFlatForFilter={selectedFlatForFilter}
          selectedTab={selectedTab}
        />
      </div>
    </>
  );
};

export default AllRequest;
