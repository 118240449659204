import { BASE_URL, axiosClient } from "./axiosClient";

export const DashboardAPI = async (params) => {
  const resp = await axiosClient.get(
    `${BASE_URL}/dashboard?startDate=${params.startDate}&endDate=${params.endDate}&buildingId=${params.buildingId}`
  );
  return resp;
};

export const GetDashboardData = async (
  startDate,
  endDate,
  selectedBuilding
) => {
  const resp = await axiosClient.get(
    `${BASE_URL}/dashboard/request-statistics?startDate=${startDate}&endDate=${endDate}&buildingId=${selectedBuilding}`
  );
  return resp;
};

export const GetDashboardVisitorData = async (
  startDate,
  endDate,
  selectedBuilding
) => {
  const resp = await axiosClient.get(
    `${BASE_URL}/dashboard/visitor-statistics?startDate=${startDate}&endDate=${endDate}&buildingId=${selectedBuilding}`
  );
  return resp;
};
