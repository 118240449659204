import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "../Lease/Lease.css";
import { getDate } from "../util/index";
import Loader from "./Loader";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useState } from "react";
import NoDataFound from "./NoDataFound";
import moment from "moment";

export default function LeaseTable({
  totalCount,
  setPage,
  postPerpage,
  page,
  showTotalData,
}) {
  const navigate = useNavigate();

  const header = [
    "ContractId",
    "Building",
    "Unit No",
    "Status",
    "Lease Expiry",
    "Owner",
    "Tenant",
    "Move In",
    "Move Out",
    "Contract Start Date",
    "Contract End Date",
    "Deposit",
    "Rental Amount",
  ];

  const { leaseListData, isLoading } = useSelector((state) => state.LeaseData);

  const handleNavigate = (id, buildingId) => {
    navigate(`/unitdetail/${id}`, {
      state: { buildingId },
    });
  };

  function diffInDays(startTime, endTime) {
    return Math.trunc((endTime - startTime) / (24 * 60 * 60 * 1000));
  }

  function getLeftDays(startDate, endDate) {
    const startTime = new Date(startDate).getTime();
    const endTime = new Date(endDate).getTime();

    if (startTime > Date.now()) {
      return diffInDays(startTime, endTime);
    } else {
      return diffInDays(Date.now(), endTime);
    }
  }
 
  return (
    <>
      {isLoading && <Loader />}
      {leaseListData?.rows?.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table sx={{}} aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    "&:nth-of-type(even)": {
                      backgroundColor: "#EFF0F6",
                    },
                  }}
                >
                  {header.map((h) => (
                    <TableCell
                      align="center"
                      className="makespace"
                      style={{
                        backgroundColor: "#FFF7F3",
                      }}
                    >
                      {h}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {leaseListData?.rows?.map((row) => (
                  <>
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:nth-of-type(even)": {
                          backgroundColor: "#EFF0F6",
                        },
                      }}
                    >
                      <TableCell>
                        <Link to={`/leasedetail/${row.id}`} padding="7px">
                          {row.contractId}
                        </Link>
                      </TableCell>
                      <TableCell
                        align="center"
                        className="makespace"
                        padding="7px"
                      >
                        <span className="inprocess">
                          {row?.flat?.building?.name_en}
                        </span>
                      </TableCell>

                      <TableCell
                        padding="7px"
                        className="makespace"
                        onClick={() =>
                          handleNavigate(row?.flatId, row?.flat?.building?.id)
                        }
                      >
                        <span
                          style={{
                            borderBottom: "1px solid blue",
                            color: "blue",
                            cursor: "pointer",
                          }}
                        >
                          {row?.flat?.name_en}
                        </span>
                      </TableCell>

                      <TableCell
                        align="center"
                        className="makespace"
                        padding="7px"
                      >
                        {row?.isExpired ? (
                          <span className="InactiveBc">InActive</span>
                        ) : (
                          <>
                            <span className="activeBc">Active</span>
                          </>
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        className="makespace"
                        padding="7px"
                      >
                        {!row?.isExpired ? (
                          <span style={{ color: "red", fontSize: "13px" }}>
                            {getLeftDays(
                              row?.contractStartDate,
                              row?.contractEndDate
                            )}{" "}
                            days left
                          </span>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        className="makespace"
                        padding="7px"
                      >
                        {row?.flat?.owner?.name ? (
                          <Link to={`/userdetail/${row?.flat?.owner?.id}`}>
                            <span align="center">
                              {row?.flat?.owner?.name
                                ? row?.flat?.owner?.name
                                : "-"}
                            </span>
                          </Link>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        className="makespace"
                        padding="7px"
                      >
                        <Link to={`/userdetail/${row?.resident?.id}`}>
                          <span align="center">{row?.resident?.name}</span>
                        </Link>
                      </TableCell>

                      <TableCell
                        align="center"
                        className="makespace"
                        padding="7px"
                      >
                        {row?.moveInDate ? getDate(row?.moveInDate) : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        className="makespace"
                        padding="7px"
                      >
                        {row?.moveOutDate ? getDate(row?.moveOutDate) : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        className="makespace"
                        padding="7px"
                      >
                        {row?.contractStartDate
                          ? getDate(row?.contractStartDate)
                          : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        className="makespace"
                        padding="7px"
                      >
                        {row?.contractEndDate
                          ? getDate(row?.contractEndDate)
                          : "-"}
                      </TableCell>
                      {/* <TableCell
                        align="center"
                        className="makespace"
                        padding="7px"
                      >
                        {row?.flatUsage}
                      </TableCell> */}
                      <TableCell
                        align="center"
                        className="makespace"
                        padding="7px"
                      >
                        {row?.securityDeposit} {row.currency}
                      </TableCell>
                      <TableCell
                        align="center"
                        className="makespace"
                        padding="7px"
                      >
                        {row?.rentAmount} {row.currency}
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="space-between" marginTop="15px">
            <Box>
              <Typography>
                Showing {postPerpage * (page - 1) + 1}
                &nbsp;-
                {page * postPerpage > showTotalData
                  ? showTotalData
                  : page * postPerpage}{" "}
                out of {showTotalData} entries
              </Typography>
            </Box>
            <Pagination
              count={totalCount}
              onChange={(event, value) => {
                setPage(value);
              }}
              shape="rounded"
            />
          </Box>
        </>
      ) : (
        <div className="d-flex justify-content-center mt-5">
          <p className="nofound">
            <NoDataFound />
          </p>
        </div>
      )}
    </>
  );
}
