import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import "./UploadDocUI.css";
import { uploadFile } from "../services/allPropertiesAPI";
import OpenDoc from "../PropertyName/OpenDoc";
import { toast } from "react-toastify";

export default function UploadDocUI({
  setUserDocument,
  setOpenDocument,
  openDocument,
  userDocument,
  url,
  setUrl,
}) {
  const [docType, setDocType] = useState();
  const [getImageDataDoc, setGetImageDataDoc] = useState([]);
  const [getLengthDoc, setGetLengthDoc] = useState();

  const Document = [
    {
      value: "passportImage",
      label: "Passport",
    },
    {
      value: "govIdImage",
      label: "Government ID",
    },
  ];

  const getDocUrl = async () => {
    try {
      const res = await uploadFile(getImageDataDoc);
      if (res.data.status === "success") {
        const getUrl = res.data.data.map((current) => current.location);
        setUrl({
          ...url,
          [docType]: [getUrl[0]],
        });
      } else {
        toast("Document is not uploaded. Please check your connection");
      }
    } catch {}
  };
  const handleSaveBtn = () => {
    getDocUrl();
    setOpenDocument(!openDocument);
  };

  return (
    <>
      <Grid width="100%" textAlign="center">
        <Box>
          <OpenDoc
            setGetImageDataDoc={setGetImageDataDoc}
            setGetLengthDoc={setGetLengthDoc}
          />
        </Box>
        {getLengthDoc && <Typography>{getLengthDoc[0]?.name}</Typography>}
        <TextField
          className="bottomOption"
          id="Document"
          select
          label="Document Type"
          value={docType}
          onChange={(e) => setDocType(e.target.value)}
          variant="standard"
        >
          {Document.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <br />
        <Button
          variant="contained"
          onClick={handleSaveBtn}
          style={{
            border: "1px solid #0C344E",
            color: "white",
            background: "rgb(12, 52, 78)",
            width: "150px",
            height: "48px",
            borderRadius: "10px",
            textTransform: "capitalize",
            marginTop: "2rem",
          }}
        >
          Save
        </Button>
      </Grid>
    </>
  );
}
