import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import OpenDoc from "../PropertyName/OpenDoc";
import { getUser, updateUser, uploadFile } from "../services/allPropertiesAPI";
import edit from "../StaffDetail/assets/edit.png";
import Image from "../StaffDetail/assets/gallery.png";
import pdfPhoto from "../StaffDetail/assets/pdfPhoto.png";
import { removeCountryCode, showDash } from "../util";
import EditTenantDetail from "./EditTenantDetail";
import "./UserDetail.css";

const UserDetailPage = () => {
  const [propertyFields, setPropertyFields] = useState({
    userid: "",
    userImage: "",
    name: "",
    nationality: "",
    nationalityid: "",
    email: "",
    countryCode: "",
    mobileNumber: "",
    document: [],
    documentId: "",
    documentType: "",
    alternateContactEmail: "",
    alternateContactMobileNumber: "",
    alternateCountryCode: "",
    allocatedFlatExpired: "",
    allocatedFlat: "",
    dateOfBirth: "",
    gender: "",
    accountNumber: "",
    bankName: "",
    building: "",
    masterUserId: "",
    passportImage: "",
    govIdImage: "",
    allocatedFlatId: "",
    ownedFlats: [],
    allocatedFlatId: "",
    buildingId: "",
  });
  const location = useLocation();
  const userId = location.pathname.split("/")[2];
  const [handleEditMode, setHandleEditMode] = useState(false);
  const [handleEditDocMode, setHandleEditDocMode] = useState(false);
  const [openDocument, setOpenDocument] = useState(false);
  const [userDocument, setUserDocument] = useState([]);
  const [docType, setDocType] = useState("");
  const [handleLoader, setHandleLoader] = useState(false);
  const navigate = useNavigate();

  const [url, setUrl] = useState({
    passportImage: "",
    govIdImage: "",
  });
  const [getImageDataDoc, setGetImageDataDoc] = useState([]);
  const [getLengthDoc, setGetLengthDoc] = useState();

  const Document = [
    {
      value: "passportImage",
      label: "Passport",
    },
    {
      value: "govIdImage",
      label: "Government ID",
    },
  ];

  const fetchUser = async () => {
    setHandleLoader(true);
    const params = {
      userId,
    };
    const res = await getUser(params);
    if (res.data.status === "success") {
      setPropertyFields({
        userid: res.data.data.id,
        userImage: res.data.data.profilePicture
          ? res.data.data.profilePicture
          : "",
        name: res.data.data.name,
        nationality: res.data.data.nationality,
        nationalityid: res.data.data.emiratesId,
        document: res.data.data.documents,
        mobileNumber: removeCountryCode(
          res?.data?.data?.countryCode,
          res.data?.data?.mobileNumber
        ),
        email: res.data.data.email,
        documentType: res.data.data.documentType,
        documentId: res.data.data.documentId,
        countryCode: `+${res.data.data.countryCode}`,
        alternateContactEmail: res.data.data.alternateContact.email,
        alternateContactMobileNumber: removeCountryCode(
          res.data?.data?.alternateContact?.countryCode,
          res.data.data.alternateContact?.mobileNumber
        ),
        alternateCountryCode: res.data.data.alternateContact?.countryCode,
        allocatedFlatExpired: res.data.data?.contractDetails[0]?.isExpired,
        allocatedFlat: res.data.data?.contractDetails[0]?.flat.name_en,
        allocatedFlatId: res.data.data?.contractDetails[0]?.flat.id,
        accountNumber: res.data.data.bankDetails.accountNumber,
        bankName: res.data.data.bankDetails.bankName,
        dateOfBirth: res.data.data.dateOfBirth,
        gender: res.data.data.gender,
        ownedFlats: res.data.data.ownedFlats,
        building: res.data.data?.contractDetails[0]?.flat?.building?.name_en,
        alternateCountryCode: `+${res.data?.data?.alternateContact?.countryCode}`,
        allocatedFlatId: res.data.data?.contractDetails[0]?.flatId,
        buildingId: res.data.data?.contractDetails[0]?.flat?.building?.id,
        swiftCode: res.data.data.bankDetails.swiftCode,
        iban: res.data.data.bankDetails.iban,
      });
      setUrl({
        passportImage:
          res?.data?.data?.documentDetails?.passportImage &&
          res?.data?.data?.documentDetails?.passportImage,
        govIdImage:
          res?.data?.data?.documentDetails?.govIdImage &&
          res?.data?.data?.documentDetails?.govIdImage,
      });
    } else {
      toast("Please Check Your Connection");
    }
    setHandleLoader(false);
  };
  useEffect(() => {
    fetchUser();
  }, [userId, handleEditMode]);

  const handleEditSwitch = () => {
    setHandleEditMode(true);
  };

  const handleEditDocButton = () => {
    setHandleEditDocMode(true);
  };

  const handleRemoveDoc = (type) => {
    setUrl({
      ...url,
      [type]: null,
    });
  };

  const handleUpdateData = async () => {
    setHandleLoader(true);
    const data = {
      documentDetails: { ...url },
      masterUserId: propertyFields?.userid,
    };
    const postData = await updateUser(data);
    if (postData.data.status === "success") {
      setHandleEditDocMode(false);
      toast("Successfully Upload");
    } else {
      toast("Uploading fail");
      setHandleEditDocMode(false);
    }
    setHandleLoader(false);
  };

  const getDocUrl = async () => {
    try {
      const res = await uploadFile(getImageDataDoc);
      if (res.data.status === "success") {
        const getUrl = res.data.data.map((current) => current.location);
        if ([docType]) {
          setUrl({
            ...url,
            [docType]: [getUrl[0]],
          });
        }
      } else {
        toast("Document is not uploaded. Please check your connection");
      }
    } catch {}
  };
  const handleSaveBtn = () => {
    getDocUrl();
    setOpenDocument(!openDocument);
    setDocType("");
  };

  const handleNavigate = (id, buildingId) => {
    navigate(`/unitdetail/${id}`, {
      state: { buildingId },
    });
  };
  const handleRedirectionOwnedFlat = (id, buildingId) => {
    navigate(`/unitdetail/${id}`, {
      state: { buildingId },
    });
  };

  return (
    <div className="home">
      {handleLoader && <Loader />}
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Box className="card" p={2}>
              {handleEditMode === true ? (
                <EditTenantDetail
                  propertyFields={propertyFields}
                  setPropertyFields={setPropertyFields}
                  setHandleEditMode={setHandleEditMode}
                  setHandleLoader={setHandleLoader}
                />
              ) : (
                <>
                  <Box display="flex" justifyContent="space-between">
                    <Box display="flex">
                      <img
                        src={
                          propertyFields?.userImage
                            ? propertyFields?.userImage
                            : Image
                        }
                        style={{
                          height: "8rem",
                          width: "8rem",
                          padding: "2px",
                        }}
                        alt=""
                      />
                      <Box px={2}>
                        <Typography
                          color="#636764"
                          fontSize="14px"
                          fontWeight="600"
                        >
                          Name
                        </Typography>
                        <Typography fontWeight="500">
                          {showDash(propertyFields?.name)}
                        </Typography>
                        <Typography
                          mt={1}
                          color="#636764"
                          fontSize="14px"
                          fontWeight="600"
                        >
                          Email
                        </Typography>
                        <Typography color="#111120" fontWeight="400">
                          {showDash(propertyFields?.email)}
                        </Typography>
                        <Typography color=" #727272" mt={1} fontWeight="600">
                          Phone No.
                        </Typography>
                        <Typography fontWeight="400">
                          {propertyFields?.mobileNumber
                            ? `${propertyFields?.countryCode}${propertyFields?.mobileNumber}`
                            : "-"}
                        </Typography>
                      </Box>
                    </Box>
                    <Box onClick={handleEditSwitch}>
                      <img src={edit} alt="" />
                    </Box>
                  </Box>
                  <Box my={4}>
                    <Typography color="#111120" fontSize={20}>
                      Other Details
                    </Typography>
                    {/* dob  */}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      bgcolor="#FAFAFA"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        D.O.B
                      </Typography>
                      <Typography>
                        {propertyFields?.dateOfBirth
                          ? propertyFields?.dateOfBirth
                          : "-"}
                      </Typography>
                    </Box>
                    {/* gender  */}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Gender
                      </Typography>
                      <Typography>
                        {propertyFields?.gender ? propertyFields?.gender : "-"}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      px={1.5}
                      my={2}
                      bgcolor="#FAFAFA"
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Owned Flat
                      </Typography>
                      <Typography
                        display="flex"
                        width="50%"
                        style={{
                          placeContent: "flex-end",
                          display: "flex",
                          flexWrap: "wrap",
                          overflow: "auto",
                        }}
                      >
                        {propertyFields?.ownedFlats?.length > 0
                          ? propertyFields?.ownedFlats.map((curElem, index) => (
                              <Typography
                                ml={1}
                                onClick={() =>
                                  handleRedirectionOwnedFlat(
                                    curElem?.id,
                                    curElem?.buildingId
                                  )
                                }
                              >
                                <Link to="#">
                                  {curElem?.name_en}
                                  {propertyFields?.ownedFlats.length - 1 ===
                                  index
                                    ? ""
                                    : ","}
                                </Link>
                              </Typography>
                            ))
                          : "-"}
                      </Typography>
                    </Box>
                    {/* allocatedFlat  */}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      px={1.5}
                      my={2}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Allocated-Unit
                      </Typography>
                      {propertyFields?.allocatedFlatExpired === false ? (
                        <Typography
                          onClick={() =>
                            handleNavigate(
                              propertyFields?.allocatedFlatId,
                              propertyFields?.buildingId
                            )
                          }
                        >
                          <Link to="#">
                            {propertyFields?.allocatedFlatExpired === false &&
                              propertyFields?.allocatedFlat}
                          </Link>
                        </Typography>
                      ) : (
                        <Typography> - </Typography>
                      )}
                    </Box>
                    {/* building details  */}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                      bgcolor="#FAFAFA"
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Building Name
                      </Typography>
                      <Typography>
                        {propertyFields?.allocatedFlatExpired === false
                          ? propertyFields?.building
                          : "-"}
                      </Typography>
                    </Box>
                    {/* alternateContactEmail  */}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Alternative Contact Email
                      </Typography>
                      <Typography>
                        {showDash(propertyFields.alternateContactEmail)}
                      </Typography>
                    </Box>
                    {/* alternateContactMobileNumber  */}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      px={1.5}
                      bgcolor="#FAFAFA"
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Alternative Contact Number
                      </Typography>
                      <Typography>
                        {propertyFields.alternateContactMobileNumber
                          ? `${propertyFields.alternateCountryCode}${propertyFields.alternateContactMobileNumber}`
                          : "-"}
                      </Typography>
                    </Box>
                    {/* Id Type  */}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Id Type
                      </Typography>
                      <Typography>
                        {showDash(propertyFields?.documentType)}
                      </Typography>
                    </Box>
                    {/* Id Number  */}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      px={1.5}
                      bgcolor="#FAFAFA"
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Id Number
                      </Typography>
                      <Typography>
                        {showDash(propertyFields?.documentId)}
                      </Typography>
                    </Box>
                    {/* Nationality  */}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      px={1.5}
                      my={2}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Nationality
                      </Typography>
                      <Typography>
                        {showDash(propertyFields?.nationality)}
                      </Typography>
                    </Box>
                    {/* Bank Details  */}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                      bgcolor="#FAFAFA"
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Bank Name
                      </Typography>
                      <Typography>
                        {showDash(propertyFields?.bankName)}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Bank Account Number
                      </Typography>
                      <Typography>
                        {showDash(propertyFields?.accountNumber)}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                      bgcolor="#FAFAFA"
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Swift Code
                      </Typography>
                      <Typography>
                        {showDash(propertyFields?.swiftCode)}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Iban
                      </Typography>
                      <Typography>{showDash(propertyFields?.iban)}</Typography>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="card" height="100vh">
              {handleEditDocMode === true ? (
                <>
                  <Box display="flex" justifyContent="space-between" m={2}>
                    <Typography color="#111120" fontSize={20} lineHeight={1.5}>
                      Edit Documents
                    </Typography>
                    <Box
                      color=" #0C344E"
                      fontSize={16}
                      sx={{ cursor: "pointer", textDecoration: "underLine" }}
                      onClick={handleUpdateData}
                    >
                      Save Changes
                    </Box>
                  </Box>

                  <Box
                    width="90%"
                    textAlign="center"
                    style={{
                      border: "1px dashed  #efefef",
                      borderRadius: "10px",
                    }}
                    m={2}
                    p={1.5}
                    fontSize={18}
                  >
                    <Typography
                      onClick={() => {
                        setOpenDocument(!openDocument);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <AddIcon /> Select Document
                    </Typography>
                  </Box>
                  {openDocument && (
                    <>
                      <Grid width="100%" textAlign="center">
                        <Box>
                          <OpenDoc
                            setGetImageDataDoc={setGetImageDataDoc}
                            setGetLengthDoc={setGetLengthDoc}
                          />
                        </Box>
                        {getLengthDoc && (
                          <Typography>{getLengthDoc[0]?.name}</Typography>
                        )}
                        <TextField
                          className="bottomOption"
                          id="Document"
                          select
                          label="Document Type"
                          value={docType}
                          onChange={(e) => setDocType(e.target.value)}
                          variant="standard"
                        >
                          {Document.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        <br />
                        <Button
                          variant="contained"
                          onClick={handleSaveBtn}
                          style={{
                            border: "1px solid #0C344E",
                            color: "white",
                            background: "rgb(12, 52, 78)",
                            width: "150px",
                            height: "48px",
                            borderRadius: "10px",
                            textTransform: "capitalize",
                            marginTop: "2rem",
                          }}
                        >
                          Save
                        </Button>
                      </Grid>
                    </>
                  )}
                  {url?.passportImage && (
                    <Box m={2}>
                      <Typography color="#727272" mb={1}>
                        Passport
                      </Typography>
                      <Box
                        className="card"
                        display="flex"
                        justifyContent="space-between"
                        p={2}
                        style={{
                          boxShadow: " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                          borderStyle: "none",
                        }}
                      >
                        <Box display="flex" justifyContent="space-between">
                          <Typography>
                            <img
                              src={pdfPhoto}
                              style={{ marginRight: "10px" }}
                              alt=""
                            />
                            {url?.passportImage[0]
                              ?.split("/")[3]
                              ?.split("-")[0]
                              ?.slice(0, 20)}
                          </Typography>
                          <Typography>
                            {handleEditDocMode && (
                              <CloseIcon
                                onClick={() => handleRemoveDoc("passportImage")}
                              />
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {url?.govIdImage && (
                    <Box m={2}>
                      <Typography color="#727272" mb={1}>
                        Government Id
                      </Typography>
                      <Box
                        className="card"
                        display="flex"
                        justifyContent="space-between"
                        p={2}
                        style={{
                          boxShadow: " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                          borderStyle: "none",
                        }}
                      >
                        <Box display="flex" justifyContent="space-between">
                          <Typography>
                            <img
                              src={pdfPhoto}
                              style={{ marginRight: "10px" }}
                              alt=""
                            />
                            {url?.govIdImage[0]
                              ?.split("/")[3]
                              ?.split("-")[0]
                              ?.slice(0, 20)}
                          </Typography>
                          <Typography>
                            {handleEditDocMode && (
                              <CloseIcon
                                onClick={() => handleRemoveDoc("govIdImage")}
                              />
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <Box display="flex" justifyContent="space-between" m={2}>
                    <Typography color="#111120" fontSize={20} lineHeight={1.5}>
                      Attached Documents
                    </Typography>
                    <Box onClick={handleEditDocButton}>
                      <img src={edit} alt="" />
                    </Box>
                  </Box>
                  {url?.passportImage && (
                    <Box m={2}>
                      <Typography color="#727272" mb={1}>
                        Passport
                      </Typography>
                      <Box
                        className="card"
                        display="flex"
                        justifyContent="space-between"
                        p={2}
                        style={{
                          boxShadow: " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                          borderStyle: "none",
                        }}
                      >
                        <Box display="flex" justifyContent="space-between">
                          <Typography>
                            <img
                              src={pdfPhoto}
                              style={{ marginRight: "10px" }}
                              alt=""
                            />
                            {url?.passportImage[0]
                              ?.split("/")[3]
                              ?.split("-")[0]
                              ?.slice(0, 20)}
                          </Typography>
                          <Typography>
                            <a download href={url?.passportImage[0]}>
                              <DownloadForOfflineIcon />
                            </a>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {url?.govIdImage && (
                    <Box m={2}>
                      <Typography color="#727272" mb={1}>
                        Government Id
                      </Typography>
                      <Box
                        className="card"
                        display="flex"
                        justifyContent="space-between"
                        p={2}
                        style={{
                          boxShadow: " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                          borderStyle: "none",
                        }}
                      >
                        <Box display="flex" justifyContent="space-between">
                          <Typography>
                            <img
                              src={pdfPhoto}
                              style={{ marginRight: "10px" }}
                              alt=""
                            />
                            {url?.govIdImage[0]
                              ?.split("/")[3]
                              ?.split("-")[0]
                              .slice(0, 20)}
                          </Typography>
                          <Typography>
                            <a download href={url?.govIdImage[0]}>
                              <DownloadForOfflineIcon />
                            </a>
                            {handleEditDocMode && (
                              <CloseIcon
                                onClick={() =>
                                  handleRemoveDoc("passportDocument")
                                }
                              />
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {url?.passportImage === null &&
                    url?.passportImage === null && (
                      <Typography textAlign="center"> No Document </Typography>
                    )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default UserDetailPage;
