import { BASE_URL, axiosClient } from "./axiosClient";

export const getReportedPosts = async () => {
  const resp = await axiosClient.get(`${BASE_URL}/posts/reported`);
  return resp;
};

export const DeleteReportedPost = async (id) => {
  const resp = await axiosClient.get(`${BASE_URL}/posts/${id}`);
  return resp;
};

export const getAllUnitDataExport = async (buildingId) => {
  const resp = await axiosClient.get(
    `${BASE_URL}/flats/admin?page=1&limit=10000000000000&buildingId=${buildingId}`
  );

  return resp;
};
export const getflatDetail = async (buildingId) => {
  const resp = await axiosClient.get(
    `${BASE_URL}/flats/admin?page=1&limit=100000000000&buildingId=${buildingId}`
  );

  return resp;
};
export const editFlatDetail = async (data) => {
  const resp = await axiosClient.patch(`/flats`, data);
  return resp;
};
export const getflatDetailforFilter = async (buildingId) => {
  const resp = await axiosClient.get(
    `${BASE_URL}/flats/admin?page=1&limit=100000000000&buildingId=${buildingId}`
  );

  return resp;
};
export const getflatDetailforRequestEdit = async ({ buildingId }) => {
  const resp = await axiosClient.get(
    `${BASE_URL}/flats/admin?page=1&limit=100000000000&buildingId=${buildingId}`
  );

  return resp;
};
export const getflatServiceRequestFilter = async () => {
  const resp = await axiosClient.get(
    `${BASE_URL}/flats/admin?page=1&limit=100000000000`
  );

  return resp;
};
