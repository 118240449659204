import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import { Link, useNavigate } from "react-router-dom";
import {
  getAllproperties,
  getDeleteProperty,
  getSoftDeleteProperty,
} from "../services/allPropertiesAPI";
import Loader from "../components/Loader";
import { showDash } from "../util";
import NoDataFound from "./NoDataFound";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import ConfirmBox from "./ConfirmBox";
import { toast } from "react-toastify";
import { deletePropertyDetail } from "../Redux/PropertySlice";
import { useDispatch, useSelector } from "react-redux";

function createData(id, propertyName, area, location, type, contact) {
  return { id, propertyName, area, location, type, contact };
}

export default React.memo(function BasicTable({
  searchText,
  postPerpage,
  currentPage,
  setcurrentPage,
  totalCount,
  settotalCount,
  Building,
  setBuilding,
}) {
  const [loader, setLoader] = useState(false);
  const [showtotalData, setshowtotalData] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [buildingForDelete, setBuildingForDelete] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isDeleteSuccess } = useSelector((state) => state.PropertyInfo);

  const fetchAllBuildingData = async () => {
    try {
      setLoader(true);
      const resp = await getAllproperties(currentPage, postPerpage, searchText);

      let totalData = resp.data.data.count;
      setshowtotalData(totalData);
      let pagesCount = Math.ceil(totalData / postPerpage);
      settotalCount(pagesCount);
      setBuilding(resp.data.data.rows);

      setLoader(false);
    } catch (error) {}
  };

  useEffect(() => {
    fetchAllBuildingData();
  }, [currentPage, searchText, isDeleteSuccess]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };
  const handleCloseConfirmBox = () => {
    setOpenConfirm(false);
  };

  const deleteProperty = async () => {
    dispatch(deletePropertyDetail(buildingForDelete));
  };

  return (
    <>
      {Building?.length > 0 ? (
        <div>
          <TableContainer component={Paper}>
            {loader && <Loader />}
            <Table aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#FFF9F6" }}>
                  <TableCell align="center">Property Name</TableCell>
                  <TableCell align="center">Address Line</TableCell>
                  <TableCell align="center">Property Type</TableCell>
                  <TableCell align="center">Locality Name</TableCell>
                  <TableCell align="center">Government Property Id</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Building.map((row) => (
                  <TableRow
                    key={row.id}
                    className="rowTable"
                    onClick={() => {
                      setBuildingForDelete(row?.id);
                    }}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell align="center">
                      <Link to={`/property/${row.id}`}>{row.name_en}</Link>
                    </TableCell>
                    <TableCell align="center">
                      {row.address_en?.length > 10
                        ? row.address_en?.slice(0, 35) + "..."
                        : showDash(row?.address_en)}
                    </TableCell>
                    <TableCell align="center">
                      {showDash(row.buildingType)}
                    </TableCell>
                    <TableCell align="center">
                      {showDash(row.locality?.name_en)}
                    </TableCell>
                    <TableCell align="center">
                      {showDash(row.governmentPropertyId)}
                    </TableCell>
                    <ConfirmBox {...{ openConfirm, handleCloseConfirmBox }}>
                      <Typography variant="h6">
                        Are you sure, You want to delete ?
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          columnGap: "20px",
                          margin: "30px 0px 0px 15px",
                        }}
                      >
                        <Button
                          sx={{
                            textTransform: "capitalize",
                            border: "1px solid #0c344e",
                            color: "#0c344e",
                          }}
                          onClick={handleCloseConfirmBox}
                        >
                          No
                        </Button>
                        <Button
                          onClick={(e) => {
                            deleteProperty();
                            setOpenConfirm(false);
                            e.stopPropagation();
                          }}
                          variant="standard"
                          sx={{
                            textTransform: "capitalize",
                            border: "1px solid #0c344e",
                            color: "white",
                            background: "#0c344e",
                            "&:hover": {
                              backgroundColor: "white",
                              color: "#0c344e",
                            },
                          }}
                        >
                          Yes
                        </Button>
                      </Box>
                    </ConfirmBox>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={(e) => {
                          navigate(`/property/${buildingForDelete}`);
                          e.stopPropagation();
                        }}
                      >
                        Update
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          handleClose();
                          handleOpenConfirm();
                          e.stopPropagation();
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                    <TableCell align="center">
                      <MoreVertIcon onClick={handleClick} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex justify-content-between height-200px mt-2">
            <div>
              <p>
                Showing {postPerpage * (currentPage - 1) + 1}
                &nbsp;-
                {currentPage * postPerpage > showtotalData
                  ? showtotalData
                  : currentPage * postPerpage}{" "}
                out of {showtotalData} entries
              </p>
            </div>
            <div className="">
              <Pagination
                count={totalCount}
                onChange={(event, value) => {
                  setcurrentPage(value);
                }}
                shape="rounded"
                style={{
                  margin: "10px",
                  float: "right",
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="nofound">
          <NoDataFound />
        </div>
      )}
    </>
  );
});
