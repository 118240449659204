import { Box, TextField } from "@mui/material";
import React from "react";
import Textfiled from "../../components/Textfiled";
import { useForm } from "react-hook-form";
import CountryCodeDropDown from "../../components/CountryCodeDropDown";
import { addUserForLease } from "../../services/LeaseApi";
import { toast } from "react-toastify";
import { useEffect } from "react";

const AddTenant = ({
  setAddTenantCreation,
  handleCloseforUnit,
  masterUserIdShowData,
}) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      TenantName: "",
      TenantEmail: "",
      TenantCountryCode: "+971",
      TenantMobileNumber: "",
    },
  });
  const [TenantCountryCode, TenantEmail] = watch([
    "TenantCountryCode",
    "TenantEmail",
  ]);
  useEffect(() => {
    setValue("TenantEmail", TenantEmail);
  }, [TenantEmail]);

  const onSubmit = async (data) => {
    const payload = {
      name: data.TenantName,
      email: data.TenantEmail,
      countryCode: data.TenantCountryCode.replace("+", ""),
      mobileNumber: `${data?.TenantCountryCode.replace("+", "")}${
        data?.TenantMobileNumber
      }`,
    };
    const resp = await addUserForLease(payload);
    if (resp.data.status == "success") {
      handleCloseforUnit();
      setAddTenantCreation(resp.data.data);
      setValue("masterUserIdShowData", resp.data.data);
      toast.success(resp.data.msg);
    } else {
      toast.error(resp.data.msg);
    }
  };
  return (
    <Box sx={{ width: "500px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Textfiled
            label="Tenant Name*"
            inputProps={{ maxLength: 50 }}
            type="text"
            width="220px"
            name="TenantName"
            register={register}
          />
        </Box>
        <Box>
          <TextField
            label="Tenant Email*"
            inputProps={{ maxLength: 100 }}
            multiline
            maxRows={4}
            {...register("TenantEmail")}
            name="TenantEmail"
            variant="standard"
            style={{
              fontFamily: "Barlow",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              width: "220px",
              lineHeight: "19px",
              marginTop: "16px",
            }}
          />
          {/* <Textfiled
            label="Tenant Email"
            inputProps={{ maxLength: 50 }}
            type="text"
            width="220px"
            name="TenantEmail"
            register={register}
          /> */}
        </Box>
      </Box>
      <Box className="d-flex">
        <Box sx={{ marginTop: "28px", marginRight: "5px" }}>
          <CountryCodeDropDown
            name="TenantCountryCode"
            // value={ownerCountryCode}
            register={register}
          />
        </Box>
        <Box className="ml-2">
          <Textfiled
            label={"Mobile Number*"}
            inputProps={{ maxLength: 50 }}
            type="text"
            width="145px"
            IntialvalueForCountryCode={TenantCountryCode}
            name="TenantMobileNumber"
            register={register}
          />
        </Box>
      </Box>
      <button
        type="submit"
        style={{
          border: "1px solid #0C344E",
          color: "#0C344E",
          background: "#0c344e",
          width: "160px",
          height: "40px",
          fontFamily: "Barlow",
          fontSize: "16px",
          marginTop: "20px",
          borderRadius: "10px",
          textTransform: "capitalize",
          color: "white",
        }}
        onClick={handleSubmit(onSubmit)}
      >
        Add Tenant
      </button>
    </Box>
  );
};

export default AddTenant;
