import { yupResolver } from "@hookform/resolvers/yup";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import "../Lease/Lease.css";
import OpenGallery from "../PropertyName/OpenGallery";
import { fetchBuildingName } from "../Redux/MultiUseSlice";
import CountryCodeDropDown from "../components/CountryCodeDropDown";
import SearchSelectBox from "../components/SearchSelectBox";
import { editFlatDetail } from "../services/allCommunityApi";
import {
  getOwnerDataDropDownList,
  uploadFile,
} from "../services/allPropertiesAPI";
import { getMobileNumber } from "../util";
import { removeStringFunction } from "../util/removeStringFunction";

const EditUnitDetail = ({
  propertyFields,
  setPropertyFields,
  setHandleEditMode,
  setHandleLoader,
}) => {
  const dispatch = useDispatch();
  const { buildingName: buildingNameList } = useSelector(
    (state) => state.MultiUseReducerData
  );

  const [searchText, setSearchText] = useState("");
  const [getImageData, setGetImageData] = useState([]);
  const [getLength, setGetLength] = useState();
  const [imageUrl, setImageUrl] = useState([...propertyFields.Image]);
  const [handleEmail, setHandleEmail] = useState("");
  const [localImgUrl, setLocalImgUrl] = useState(() => {
    return [propertyFields?.Image];
  });
  const [ownerData, setOwnerData] = useState([]);

  const subFinalArray = imageUrl.map((current) => current.location);

  const FLAT_TYPES = [
    {
      id: "Apartment",
      name: "Apartment",
    },
    {
      id: "Beach House",
      name: "Beach House",
    },
    {
      id: "Duplex",
      name: "Duplex",
    },
    {
      id: "Farm",
      name: "Farm",
    },
    {
      id: "Office",
      name: "Office",
    },
    {
      id: "Studio",
      name: "Studio",
    },
    {
      id: "Shop",
      name: "Shop",
    },

    {
      id: "Villa",
      name: "Villa",
    },

    {
      id: "Storage",
      name: "Storage",
    },

    {
      id: "Others",
      name: "Others",
    },
  ];

  useEffect(() => {
    setLocalImgUrl([...propertyFields?.Image, ...subFinalArray]);
  }, [imageUrl]);

  const handleRemoveImg = (att, type) => {
    if (type) {
      const filtered = imageUrl.filter((item) => item !== att);
      setImageUrl(filtered);
    } else {
      const dataFiltered = propertyFields?.Image.filter((item) => item !== att);
      setPropertyFields({
        ...propertyFields,
        Image: dataFiltered,
      });
    }
  };
  const handleValue = (name, event) => {
    let newValue = event.target.value;
    if (newValue.length > 3) {
      newValue = newValue.slice(0, 10); // restrict to 3 digits
    }
    setValue(`${name}`, newValue);
  };

  const unitSchemaForEdit = yup.object().shape({
    name_en: yup.string().trim().required("This field is required"),
    ...(handleEmail?.length > 0 && {
      contactEmail: yup
        .string()
        .matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, {
          message: "Invalid Contact Email",
          excludeEmptyString: true,
        }),
    }),
    contactMobileNumber: yup
      .string()
      .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]/, {
        message: "Phone number is not valid",
        excludeEmptyString: true,
      }),
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      flatId: propertyFields.flatId,
      name_en: propertyFields.name,
      buildingName_en: propertyFields.buildingName,
      buildingId: propertyFields.buildingId,
      floor: propertyFields.floor,
      flatType: propertyFields.flatType,
      size: propertyFields.size,
      bedroom: propertyFields.bedroom,
      bathroom: propertyFields.bathroom,
      ownerName: propertyFields?.owner?.name,
      ownerId: propertyFields?.owner,
      unitId: propertyFields.unitId,
      contactName: propertyFields.contactName,
      contactEmail: propertyFields.contactEmail,
      contactMobileNumber: propertyFields.contactMobileNumber,
      contactCountryCode:
        propertyFields.contactCountryCode !== "+null" || null
          ? propertyFields.contactCountryCode
          : "+971",
      tenantName:
        propertyFields?.isExpired === false ? propertyFields.tenant?.name : "",
    },
    mode: "onChange",
    resolver: yupResolver(unitSchemaForEdit),
  });

  const [
    IntialvalueForCountryCode,
    selectedBuildingOnThisPage,
    flatType,
    buildingName,
    ownerId,
    contactEmail,
    ownerName,
  ] = watch([
    "contactCountryCode",
    "buildingId",
    "flatType",
    "buildingName",
    "ownerId",
    "contactEmail",
    "ownerName",
  ]);
  console.log(propertyFields?.contactCountryCode, "propertyFields");
  const buildingFilterData = (building) => {
    const unique = buildingNameList?.find((cur) => {
      return cur.name_en === building;
    });
    return unique?.id;
  };

  console.log(ownerId, "hhhhhhh");
  const fetchOwnerData = async () => {
    const resp = await getOwnerDataDropDownList();
    setOwnerData(resp.data.data.rows);
  };
  useEffect(() => {
    fetchOwnerData();
  }, []);

  const onSubmit = async (data) => {
    const nameBuild_en = buildingFilterData(data.buildingName_en);

    data["buildingId"] = nameBuild_en;
    // delete data.buildingName;

    delete data.ownerName;
    delete data.tenantName;
    setHandleLoader(true);
    data["images"] = [...imageUrl];
    data["contactCountryCode"] = getMobileNumber(
      data.contactCountryCode,
      data.contactMobileNumber
    ).countryCode;
    data["contactMobileNumber"] = getMobileNumber(
      data.contactCountryCode,
      data.contactMobileNumber
    ).mobileNumber;
    // data.buildingId = selectedBuildingOnThisPage;
    data.ownerId = ownerId?.id;
    const getData = removeStringFunction(data);

    const postData = await editFlatDetail(getData);
    if (postData.data.status === "success") {
      toast("Saved Successful");
      setHandleEditMode(false);
    } else {
      toast(postData.data.msg);
      setHandleEditMode(false);
    }
    setHandleLoader(false);
  };

  const getImageUrl = async () => {
    setHandleLoader(true);
    try {
      const res = await uploadFile(getImageData);
      if (res.data.status === "success") {
        const getUrl = res.data.data.map((current) => current.location);
        setImageUrl([...imageUrl, ...getUrl]);
      } else {
        toast("Document is not uploaded. Please check your connection");
      }
    } catch {}
    setHandleLoader(false);
  };

  useEffect(() => {
    if (getLength) {
      getImageUrl();
    }
  }, [getLength]);

  useEffect(() => {
    dispatch(fetchBuildingName());
    setHandleEmail(contactEmail);
  }, []);
  console.log(ownerId?.name, "check");
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography color="#111120" fontSize={18}>
                Edit Unit Details
              </Typography>
            </Box>
            <Box>
              <Button
                style={{ textDecoration: "underLine" }}
                onClick={() => {
                  setHandleEditMode(false);
                }}
              >
                Cancel
              </Button>
              <Button type="submit" style={{ textDecoration: "underLine" }}>
                Save Changes
              </Button>
            </Box>
          </Box>
          <Box
            display="flex"
            style={{ overflowX: "scroll" }}
            className="hiddenScroll"
          >
            <OpenGallery
              setGetImageData={setGetImageData}
              setGetLength={setGetLength}
            />
            <Box m={2} display="flex" gap={2}>
              <Box display="flex" gap={2}>
                {imageUrl.map((curElem, id) => (
                  <Box position="relative" width="10rem" height="10rem">
                    <img
                      style={{ height: "100%", width: "100%" }}
                      alt="..."
                      src={curElem}
                      name="images"
                    />
                    <CancelIcon
                      style={{
                        position: "absolute",
                        right: "2px",
                        top: "5px",
                        color: "#000",
                      }}
                      onClick={() => handleRemoveImg(curElem, "local")}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <Grid>
            <Box
              className="inputColor"
              width="100%"
              display="flex"
              justifyContent="space-between"
              my={5}
            >
              <Box>
                <FormControl fullWidth>
                  <InputLabel
                    id="search-select-label"
                    sx={{ marginLeft: "-14px" }}
                  >
                    Building Name*
                  </InputLabel>
                  <Select
                    MenuProps={{ autoFocus: false }}
                    sx={{ width: "250px" }}
                    labelId="search-select-label"
                    id="search-select"
                    variant="standard"
                    defaultValue={propertyFields?.buildingName}
                    {...register("buildingName_en")}
                    name="buildingName_en"
                    label={"Building Name*"}
                    className="inputopacity"
                    onClose={() => setSearchText("")}
                  >
                    <ListSubheader>
                      <TextField
                        size="small"
                        variant="standard"
                        placeholder="Type to search..."
                        inputProps={{ maxLength: "30" }}
                        sx={{
                          width: "250px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                    </ListSubheader>
                    {buildingNameList
                      .filter((option) =>
                        option?.name_en
                          ?.toLowerCase()
                          .includes(searchText.toLowerCase())
                      )
                      .map((option, i) => (
                        <MenuItem key={option?.id} value={option?.name_en}>
                          {option.name_en}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <Typography
                  sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
                >
                  {errors?.buildingId?.message}
                </Typography>
              </Box>
              <Box>
                <Controller
                  name="name_en"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="standard-basic"
                      label="Unit Number*"
                      variant="standard"
                      sx={{ width: "250px" }}
                    />
                  )}
                />

                <Typography
                  sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
                >
                  {errors?.name_en?.message}
                </Typography>
              </Box>
            </Box>
            <Box
              className="inputColor"
              width="100%"
              display="flex"
              justifyContent="space-between"
              my={2}
            >
              <Box>
                <FormControl fullWidth>
                  <InputLabel
                    id="search-select-label"
                    sx={{ marginLeft: "-14px" }}
                  >
                    Owner Name
                  </InputLabel>
                  <Select
                    MenuProps={{ autoFocus: false }}
                    sx={{ width: "250px" }}
                    labelId="search-select-label"
                    id="search-select"
                    className="inputopacity"
                    variant="standard"
                    value={ownerId?.name ? ownerId?.name : ""}
                    onChange={(e) => {
                      setValue("ownerId", e.target.value);
                      console.log(e.target.value, "value");
                    }}
                    label="Owner Name"
                    onClose={() => setSearchText("")}
                  >
                    <ListSubheader>
                      <TextField
                        size="small"
                        variant="standard"
                        placeholder="Type to search..."
                        inputProps={{ maxLength: "30" }}
                        sx={{
                          width: "250px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                    </ListSubheader>
                    {ownerData
                      .filter(
                        (option) =>
                          option?.name
                            ?.toLowerCase()
                            .includes(searchText.toLowerCase()) ||
                          option?.email
                            ?.toLowerCase()
                            .includes(searchText.toLowerCase()) ||
                          option?.mobileNumber
                            ?.toLowerCase()
                            .includes(searchText.toLowerCase())
                      )
                      .map((option, i) => (
                        <MenuItem key={option?.id} value={option}>
                          <span>{option.name}</span>
                          <span
                            style={{
                              background: "#eff0f6",
                              color: "#0c344e",
                              padding: "0px 10px 0px 10px",
                              borderRadius: "10px",
                              marginLeft: "5px",
                            }}
                          >
                            {option.userType}
                          </span>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {/* <Controller
                  name="ownerName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled
                      id="standard-basic"
                      label="Owner Name"
                      variant="standard"
                      sx={{ width: "250px" }}
                    />
                  )}
                /> */}
                <Typography
                  sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
                >
                  {/* {errors?.floor?.message.slice(0, 25)} */}
                </Typography>
              </Box>
              <Box>
                <Controller
                  name="tenantName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="standard-basic"
                      disabled
                      label="Tenant Name"
                      variant="standard"
                      sx={{ width: "250px" }}
                    />
                  )}
                />
                <Typography
                  sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
                >
                  {/* {errors?.flatType?.message} */}
                </Typography>
              </Box>
            </Box>
            <Box
              className="inputColor"
              width="100%"
              display="flex"
              justifyContent="space-between"
            >
              <Box>
                <Controller
                  name="bedroom"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="standard-basic"
                      label="No. of BedRoom"
                      onChange={(e) => {
                        handleValue("bedroom", e);
                      }}
                      type="number"
                      variant="standard"
                      sx={{ width: "250px" }}
                    />
                  )}
                />
                <Typography
                  sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
                >
                  {errors?.bedroom?.message.slice(0, 26)}
                </Typography>
              </Box>

              <Box>
                <Controller
                  name="bathroom"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="standard-basic"
                      type="number"
                      onChange={(e) => {
                        handleValue("bathroom", e);
                      }}
                      label="No. of BathRoom"
                      variant="standard"
                      sx={{ width: "250px" }}
                    />
                  )}
                />
                <Typography
                  sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
                >
                  {errors?.bathroom?.message.slice(0, 26)}
                </Typography>
              </Box>
            </Box>
            <Box
              className="inputColor"
              width="100%"
              display="flex"
              justifyContent="space-between"
              my={2}
            >
              <Box>
                <Controller
                  name="unitId"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="standard-basic"
                      label="Government Id"
                      variant="standard"
                      sx={{ width: "250px" }}
                    />
                  )}
                />
                <Typography
                  sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
                >
                  {/* {errors?.floor?.message.slice(0, 25)} */}
                </Typography>
              </Box>
              <Box>
                <Controller
                  name="contactName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="standard-basic"
                      label="POC Name"
                      // onChange={(e) => {
                      //   handleText(e, "contactName");
                      // }}
                      variant="standard"
                      sx={{ width: "250px" }}
                    />
                  )}
                />
                <Typography
                  sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
                >
                  {/* {errors?.flatType?.message} */}
                </Typography>
              </Box>
            </Box>

            <Box
              className="inputColor"
              width="100%"
              display="flex"
              justifyContent="space-between"
              my={2}
            >
              <Box>
                <Controller
                  name="contactEmail"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="standard-basic"
                      label="POC Email"
                      variant="standard"
                      sx={{ width: "250px" }}
                    />
                  )}
                />
                <Typography
                  sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
                >
                  {errors?.contactEmail?.message && "Email is invalid"}
                </Typography>
              </Box>
              <Box width="250px" marginTop="15px">
                <Box display="flex">
                  <Box>
                    <CountryCodeDropDown
                      name="contactCountryCode"
                      register={register}
                    />
                  </Box>
                  <Controller
                    name="contactMobileNumber"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="POC No."
                        inputProps={{
                          maxLength: 13 - IntialvalueForCountryCode?.length,
                          minLength: 13 - IntialvalueForCountryCode?.length,
                        }}
                        id="standard-basic"
                        variant="standard"
                        sx={{
                          width: "70%",
                          marginLeft: "10px",
                          marginTop: "-18px",
                        }}
                      />
                    )}
                  />
                </Box>
                <Typography
                  sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
                >
                  {errors?.contactMobileNumber?.message}
                </Typography>
              </Box>
            </Box>

            <Box
              className="inputColor"
              width="100%"
              display="flex"
              justifyContent="space-between"
              my={2}
            >
              <Box>
                <Controller
                  name="floor"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="standard-basic"
                      inputProps={{ maxLength: 15 }}
                      label="Floor"
                      variant="standard"
                      sx={{ width: "250px" }}
                    />
                  )}
                />
                <Typography
                  sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
                >
                  {errors?.floor?.message.slice(0, 25)}
                </Typography>
              </Box>
              <Box>
                <SearchSelectBox
                  displayedOptions={FLAT_TYPES}
                  name="flatType"
                  register={register}
                  label="Type of Unit*"
                  width="250px"
                  innerWidth="250px"
                  value={flatType}
                />
                <Typography
                  sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
                >
                  {errors?.flatType?.message}
                </Typography>
              </Box>
            </Box>

            <Box>
              <Controller
                name="size"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="unitSize"
                    type="number"
                    variant="standard"
                    onChange={(e) => {
                      handleValue("size", e);
                    }}
                    sx={{ width: "250px" }}
                    label="Unit Size (in sqft)"
                  />
                )}
              />
              <Typography
                sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
              >
                {errors?.unit?.message}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default EditUnitDetail;
