import { yupResolver } from "@hookform/resolvers/yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { resetPassword } from "../components/AuthSchema";
import { changePassword } from "../services/allServisesApi";
import "./index.css";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);

  const handleClickShowPassword = (type) =>
    type === "new"
      ? setShowNewPassword((show) => !show)
      : setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    mode: "onChange",
    resolver: yupResolver(resetPassword),
  });

  const onSubmit = async (data) => {
    const resp = await changePassword(data);
    if (resp?.data?.status === "success") {
      toast.success(resp?.data?.data);
      navigate("/dashboard");
    }
    if (resp?.data?.status === "fail") {
      toast.warning(resp?.data?.msg);
    }
  };
  return (
    <>
      <div className="top-level-container">
        <div className="home_container home">
          <div className="d-flex justify-content-between">
            <div>
              <h4>Update Password</h4>
            </div>
          </div>
          <div className="form d-flex justify-content-between align-items-start flex-column mt-5">
            <FormControl sx={{ m: 1, width: "50ch" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">
                Current Password
              </InputLabel>
              <Input
                id="standard-adornment-password"
                name="currentPassword"
                {...register("currentPassword")}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("")}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl sx={{ m: 1, width: "50ch" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">
                New Password
              </InputLabel>
              <Input
                id="standard-adornment-password"
                {...register("newPassword")}
                name="newPassword"
                type={showNewPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("new")}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />

              <Typography
                sx={{
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "red",
                }}
              >
                {errors?.newPassword?.message}
              </Typography>
            </FormControl>
          </div>
          <Grid mt={4}>
            <button
              type="submit"
              id="savebtn"
              className="Button"
              variant="contained"
              color="success"
              style={{
                border: "1px solid #0C344E",
                color: "#0C344E",
                background: "#0c344e",
                width: "187px",
                height: "48px",
                fontFamily: "Barlow",
                fontSize: "16px",

                marginTop: "20px",
                borderRadius: "10px",
                textTransform: "capitalize",
                color: "white",
              }}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </button>
          </Grid>
        </div>
      </div>
    </>
  );
}
