import React, { useState } from "react";
import { Form } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import "./Dialog.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import InputText from "./InputText";
import DatePicker from "./DatePicker";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Hidden,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CurrencyList from "./CurrencyList";

function ApproveDialog({
  message,
  onDialog,
  nameUser,
  handleSubmit,
  register,
  control,
  onSubmit,
  Controller,
  isDiscountRequired,
  applicableOn,
  errors,
  currency,
  setValue,
  discount,
}) {
  const DISCOUNT_APPLICABILITY = ["Deposit", "Rent", "Grace Period"];
  const FLAT_USAGE = ["Commercial", "Residential", "Industrial"];
  const PAYMENT_MODES = ["Cheque", "Cash", "Online"];
  const PAYMENT_FREQUENCIES = ["Monthly", "Quaterly", "Yearly"];

  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
      onClick={() => onDialog(false)}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          background: "white",
          padding: "20px",
          borderRadius: "10px",
          height: "600px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <h3 stlye={{ color: "#111", fontSize: "16px", fontFamily: "" }}>
          {message}
        </h3>
        <h1
          style={{
            color: "Black",
            fontSize: "20px",
            margin: "15px 0px",
          }}
        >
          {nameUser[0].toUpperCase() + nameUser.substring(1)}
        </h1>
        <div>
          <div className="d-flex">
            <div style={{ marginRight: "20px" }}>
              <DatePicker
                label="Contract Start Date"
                Controller={Controller}
                control={control}
                name="contractStartDate"
                register={register}
                width={"250px"}
                minDate=" "
              />
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "red",
                  marginRight: "62px",
                }}
              >
                {errors?.contractStartDate?.message &&
                  "ContractStartDate must be date"}
              </Typography>
            </div>
            <div>
              <DatePicker
                label="Contract End Date"
                Controller={Controller}
                control={control}
                name="contractEndDate"
                register={register}
                width={"250px"}
                minDate=" "
              />
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "red",
                  marginRight: "70px",
                }}
              >
                {errors?.contractEndDate?.message &&
                  "ContractEndDate must be date"}
              </Typography>
            </div>
          </div>
          <div className="d-flex mt-3">
            <div style={{ marginRight: "20px" }}>
              <TextField
                select
                label="Purpose"
                inputProps={{ maxLength: 100 }}
                multiline
                maxRows={4}
                maxLength={10}
                {...register("flatUsage")}
                name="flatUsage"
                variant="standard"
                style={{
                  fontFamily: "Barlow",
                  fontStyle: "normal",
                  textAlign: "start",
                  fontWeight: 400,
                  fontSize: "16px",
                  width: "250px",
                  lineHeight: "19px",
                }}
              >
                {FLAT_USAGE?.map((option) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </TextField>
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "red",
                  marginRight: "130px",
                }}
              >
                {errors?.flatUsage?.message && "This field is required"}
              </Typography>
            </div>
            <div>
              <TextField
                select
                label="Payment Mode"
                inputProps={{ maxLength: 100 }}
                multiline
                maxRows={4}
                maxLength={10}
                {...register("paymentMode")}
                name="paymentMode"
                variant="standard"
                style={{
                  fontFamily: "Barlow",
                  fontStyle: "normal",
                  textAlign: "start",
                  fontWeight: 400,
                  fontSize: "16px",
                  width: "250px",
                  lineHeight: "19px",
                }}
              >
                {PAYMENT_MODES?.map((option) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </TextField>
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "red",
                  marginRight: "130px",
                }}
              >
                {errors?.paymentMode?.message && "This field is required"}
              </Typography>
            </div>
          </div>
          <div className="d-flex mt-3">
            <div style={{ marginRight: "20px" }}>
              <TextField
                select
                label="Payment Frequency"
                inputProps={{ maxLength: 100 }}
                multiline
                maxRows={4}
                maxLength={10}
                {...register("paymentFrequency")}
                name="paymentFrequency"
                variant="standard"
                style={{
                  fontFamily: "Barlow",
                  textAlign: "start",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  width: "250px",
                  lineHeight: "19px",
                }}
              >
                {PAYMENT_FREQUENCIES?.map((option) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </TextField>
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "red",
                  marginRight: "130px",
                }}
              >
                {errors?.paymentFrequency?.message && "This filed is required"}
              </Typography>
            </div>
            <div>
              
              <Box>
                <TextField
                  select
                  label="Select Your Currency"
                  inputProps={{ maxLength: 100 }}
                  multiline
                  maxRows={4}
                  maxLength={10}
                  className="DropCurrency"
                  defaultValue="AED"
                  {...register("currency")}
                  name="currency"
                  variant="standard"
                  sx={{
                    fontFamily: "Barlow",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    width: "250px",
                    lineHeight: "19px",
                  }}
                >
                  {CurrencyList?.map((cur, key) => {
                    return (
                      <MenuItem value={cur.currency_code}>
                        {cur.currency_code}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Box>
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "red",
                  marginRight: "130px",
                }}
              >
                {errors?.rentAmount?.message && "This filed is required"}
              </Typography>
            </div>
          </div>
          <div className="d-flex mt-3">
            <div style={{ marginRight: "20px" }}>
              <div style={{ display: "flex" }}>
                {" "}
                <p
                  style={{
                    borderBottom: "1px solid #727272",
                    width: "30px",
                    margin: "23px 15px 0px 0px",
                  }}
                >
                  {currency}
                </p>
                <InputText
                  label="Goverment charges"
                  name="activationFee"
                  register={register}
                  width="200px"
                />
              </div>

              <Typography
                sx={{
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "red",
                  marginRight: "130px",
                }}
              >
                {errors?.activationFee?.message && "This filed is required"}
              </Typography>
            </div>
            <div>
              <div style={{ display: "flex" }}>
                <p
                  style={{
                    borderBottom: "1px solid #727272",
                    width: "30px",
                    margin: "23px 15px 0px 0px",
                  }}
                >
                  {currency}
                </p>
                <InputText
                  label="Security Deposit Amount"
                  name="securityDeposit"
                  register={register}
                  width="210px"
                />
              </div>
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "red",
                  marginRight: "130px",
                }}
              >
                {errors?.securityDeposit?.message && "This filed is required"}
              </Typography>
            </div>
          </div>
          <div className="d-flex mt-3">
            <div style={{ marginRight: "20px" }}>
              <div style={{ display: "flex" }}>
                <p
                  style={{
                    borderBottom: "1px solid #727272",
                    width: "30px",
                    margin: "23px 15px 0px 0px",
                  }}
                >
                  {currency}
                </p>
                <InputText
                  label="Payment Rent Amount"
                  register={register}
                  name="rentAmount"
                  width="210px"
                />
              </div>
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "red",
                  marginRight: "130px",
                }}
              >
                {errors?.rentAmount?.message && "This filed is required"}
              </Typography>
            </div>
            <div>
              <InputText
                label={"Notice Period"}
                name="noticePeriod"
                register={register}
                width="250px"
              />
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "red",
                  marginRight: "130px",
                }}
              >
                {errors?.noticePeriod?.message && "This filed is required"}
              </Typography>
            </div>
          </div>
          <div className="d-flex mt-3">
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                marginRight: "95px",
              }}
            >
              <div>
                <Controller
                  name="isDiscountRequired"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      {...register("isDiscountRequired")}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <h6>Discount(Optional)</h6>
              </div>
            </div>

            {/* <div>
              <InputText
                label={"Notice Period"}
                name="noticePeriod"
                register={register}
                width="250px"
              />
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "red",
                  marginRight: "130px",
                }}
              >
                {errors?.noticePeriod?.message && "This filed is required"}
              </Typography>
            </div> */}
          </div>
          {/* <div style={{ float: "left" }}>
            {isDiscountRequired && (
              <div>
                <TextField
                  select
                  label="Applicable On"
                  inputProps={{ maxLength: 100 }}
                  multiline
                  className="applicableMode"
                  maxRows={4}
                  maxLength={10}
                  {...register("applicableOn")}
                  name="applicableOn"
                  variant="standard"
                  style={{
                    fontFamily: "Barlow",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    width: "240px",
                    lineHeight: "19px",
                  }}
                >
                  {DISCOUNT_APPLICABILITY?.map((option) => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            )}
          </div> */}

          {isDiscountRequired && (
            <div style={{ float: "left" }}>
              <div>
                <TextField
                  select
                  label="Applicable On"
                  inputProps={{ maxLength: 100 }}
                  multiline
                  className="applicableMode"
                  maxRows={4}
                  maxLength={10}
                  {...register("applicableOn")}
                  name="applicableOn"
                  variant="standard"
                  style={{
                    fontFamily: "Barlow",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    width: "240px",
                    lineHeight: "19px",
                  }}
                >
                  {DISCOUNT_APPLICABILITY?.map((option) => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            width: "400px",
          }}
        >
          {
            <div>
              {applicableOn.length > 0 && isDiscountRequired && (
                <div>
                  {applicableOn === "Grace Period" ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "400px",
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ marginLeft: "-60px" }}>
                        <InputText
                          label="Grace Period(month)"
                          name="grace"
                          register={register}
                          width="250px"
                        />
                      </div>
                      <TextField
                        placeholder="Nothing"
                        sx={{ visibility: "hidden" }}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="mt-4" style={{ marginLeft: "-60px" }}>
                        <div>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="percentage"
                            name="discount"
                            onChange={(event) =>
                              setValue("discount", event.target.value)
                            }
                            sx={{ display: "flex" }}
                          >
                            <Box display={"flex"}>
                              <Box>
                                <FormControlLabel
                                  value="percentage"
                                  control={<Radio />}
                                  label="Percentage"
                                />
                              </Box>
                              <Box>
                                <FormControlLabel
                                  value="Fixed"
                                  control={<Radio />}
                                  label="Fixed"
                                />
                              </Box>
                            </Box>
                          </RadioGroup>
                        </div>
                        <div>
                          <div
                            className="d-flex justify-content-between"
                            style={{ width: "50vw" }}
                          >
                            <div>
                              <div className="d-flex gap-2">
                                <p
                                  style={{
                                    marginTop: "22px",
                                    borderBottom: "1px solid #727272",
                                  }}
                                >
                                  {discount === "Fixed" ? currency : "%"}
                                </p>
                                <InputText
                                  label={
                                    discount === "Fixed"
                                      ? "Amount"
                                      : "Percentage"
                                  }
                                  name="discountAmount"
                                  register={register}
                                  width="200px"
                                />
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginTop: "26px",
                                      borderBottom: "1px solid #727272",
                                      width: "30px",
                                      marginRight: "20px",
                                    }}
                                  >
                                    {currency}
                                  </p>
                                  <InputText
                                    label={`Total ${applicableOn} after Discount`}
                                    name="totalDiscount"
                                    register={register}
                                    width="200px"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          }
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            columnGap: "20px",
            marginTop: "20px",
          }}
        >
          <button
            type="submit"
            className={"dialogGreen"}
            onClick={handleSubmit(onSubmit)}
            style={{
              width: "80px",
              height: "40px",
              background: "#0C344E",
              color: "#F0F0F0",
              borderRadius: "5px",
            }}
          >
            Yes
          </button>
          <button
            onClick={() => onDialog(false)}
            className={"dialogRed"}
            style={{
              width: "80px",
              height: "40px",
              background: "#F0F0F0",
              color: "#0C344E",
              borderRadius: "5px",
            }}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
}
export default ApproveDialog;
