import React from "react";
import AllRequestProperty from "./AllRequestProperty";

const AllRequestpropertyMain = () => {
  return (
    <>
     
      <div className="top-level-container">
     
        <AllRequestProperty />
      </div>
    </>
  );
};

export default AllRequestpropertyMain;
