import {
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getDate } from "../util";

const ContractDetails = ({ propertyFields }) => {
  return (
    <Grid bgcolor="#fff" mt={2} p={2} height="65vh">
      <Box>
        {propertyFields.contractDetails.length > 0 ? (
          <TableContainer style={{ height: "60vh" }}>
            {/* {loader && <Loader />} */}
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{ backgroundColor: "#FFF9F6", whiteSpace: "nowrap" }}
                >
                  <TableCell align="center">Resident Name</TableCell>
                  <TableCell align="center">Flat Usage</TableCell>
                  <TableCell align="center">Contract Start Date</TableCell>
                  <TableCell align="center">Contract End Date</TableCell>
                  <TableCell align="center">Move In Date</TableCell>
                  <TableCell align="center">Move Out Date</TableCell>
                  <TableCell align="center">Payment Frequency</TableCell>
                  <TableCell align="center">Payment Mode</TableCell>
                  <TableCell align="center">Security Deposit</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {propertyFields?.contractDetails?.map((curElem) => (
                  <TableRow
                    className="rowTable"
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell align="center">
                      {curElem?.resident?.name}
                    </TableCell>
                    <TableCell align="center">{curElem?.flatUsage}</TableCell>
                    <TableCell align="center">
                      {curElem?.contractStartDate
                        ? getDate(curElem?.contractStartDate)
                        : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {curElem?.contractEndDate
                        ? getDate(curElem?.contractEndDate)
                        : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {curElem?.moveInDate ? getDate(curElem?.moveInDate) : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {curElem?.moveOutDate
                        ? getDate(curElem?.moveOutDate)
                        : "-"}
                    </TableCell>

                    <TableCell align="center">
                      {curElem?.paymentFrequency}
                    </TableCell>
                    <TableCell align="center">{curElem?.paymentMode}</TableCell>
                    <TableCell align="center">
                      {curElem?.securityDeposit}
                    </TableCell>

                    <TableCell align="center">
                      {!curElem?.isExpired && curElem.isValid
                        ? "Active"
                        : "In-active"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="h6" textAlign="center">
            No Data found
          </Typography>
        )}
      </Box>
    </Grid>
  );
};

export default ContractDetails;
