import { Box } from "@mui/system";
import React from "react";
import nodatafoundbanner from "./../assets/nodatafoundbanner.png";

const NoDataFound = () => {
  return (
    <Box mt={4}>
      <img src={nodatafoundbanner} alt="..." />
    </Box>
  );
};

export default NoDataFound;
