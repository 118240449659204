import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";

const NationalityDropDown = ({ value, label, name, register }) => {
  const [nationality, setNationality] = useState([]);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const fetchNationality = async () => {
      const res = await axios.get(
        "https://gist.githubusercontent.com/anubhavshrimal/75f6183458db8c453306f93521e93d37/raw/f77e7598a8503f1f70528ae1cbf9f66755698a16/CountryCodes.json"
      );
      setNationality(res.data);
    };
    fetchNationality();
  }, []);
  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="search-select-label" sx={{ marginLeft: "-14px" }}>
          {label}
        </InputLabel>
        <Select
          MenuProps={{ autoFocus: false }}
          sx={{ width: "300px" }}
          labelId="search-select-label"
          id="search-select"
          variant="standard"
          {...register(name)}
          name={name}
          label={label}
          value={value}
          onClose={() => setSearchText("")}
        >
          <ListSubheader>
            <TextField
              size="small"
              variant="standard"
              placeholder="Type to search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {nationality
            .filter((option) =>
              option.name.toLowerCase().includes(searchText.toLowerCase())
            )
            .map((option, i) => (
              <MenuItem key={option?.id} value={option?.name}>
                {option?.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default NationalityDropDown;
