import { TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import React from "react";
// import { Controller } from "react-hook-form";

const DatePicker = ({
  Controller,
  label,
  control,
  name,
  register,
  width,
  minDate,
  onChange,
  maxDate,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        render={({ field, formState }) => {
          return (
            <DesktopDatePicker
              {...field}
              label={label}
              className="changecolor"
              disableHighlightToday
              minDate={minDate}
              maxDate={maxDate}
              required
              inputFormat="yyyy-MM-dd"
              renderInput={(params) => (
                <TextField {...params} sx={{ width: width }} />
              )}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
