import Button from "@mui/material/Button";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import GuardTable from "../../components/GuardTable";
import SearchBar from "../../components/SearchBar";
import { getGuardData } from "../../services/allServisesApi";

const Guardmangement = () => {
  const [guardDetail, setGuardDetail] = React.useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalCount, settotalCount] = useState(0);
  const [postPerpage, setPostPerPage] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const [showtotalData, setShowtotalData] = useState("");
  const onSearchChange = async (value) => {
    setSearchText(value);

    const resp = await getGuardData(currentPage, postPerpage, value);
    let totalData = resp.data.data.count;
    let pagesCount = Math.ceil(totalData / postPerpage);
    // settotalCount(pagesCount);
    setGuardDetail(resp.data.data.rows);
    setShowtotalData(totalData);
  };
  return (
    <div className="home">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <h3>Guard</h3>
        </div>

        <div className="d-flex">
          <SearchBar
            style={{ marginRight: "40px" }}
            onChange={onSearchChange}
            placeholder="Search "
            maxLength="10"
          />
          <Link to="/addguard" style={{ textDecoration: "none" }}>
            <Button
              className="Button"
              variant="contained"
              color="success"
              style={{
                border: "1px solid #0C344E",
                color: "#0C344E",
                background: "white",
                width: "187px",
                height: "48px",
                fontFamily: "Barlow",
                fontStyle: "normal",
                fontweight: "500",
                fontSize: "16px",
                lineHeight: "19px",
                borderRadius: "10px",
                textTransform: "capitalize",
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 5V19"
                  stroke="#0C344E"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5 12H19"
                  stroke="#0C344E"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Add New Guard
            </Button>
          </Link>
        </div>
      </div>
      <div className="mt-4">
        <GuardTable
          searchText={searchText}
          currentPage={currentPage}
          setcurrentPage={setcurrentPage}
          postPerpage={postPerpage}
          totalCount={totalCount}
          settotalCount={settotalCount}
          guardDetail={guardDetail}
          setGuardDetail={setGuardDetail}
          showtotalData={showtotalData}
          setShowtotalData={setShowtotalData}
        />
      </div>
    </div>
  );
};

export default Guardmangement;
