import { Grid, Typography } from "@mui/material";
import { Card } from "@tremor/react";
import Chart from "react-apexcharts";
import noData from "../assets/no data found.png";

function PieChart({ handleVisitorData }) {
  const TrafficData = handleVisitorData?.trafficByCategory?.map(
    (curElem, id) => {
      return +curElem.count;
    }
  );
  const TrafficDataLabel = handleVisitorData?.trafficByCategory?.map(
    (curElem, id) => {
      return curElem.category;
    }
  );

  const series = TrafficData;

  return (
    <>
      <Card maxWidth="max-w-lg">
        <Typography>Traffic Data</Typography>
        {handleVisitorData?.trafficByCategory?.length > 0 ? (
          <Grid my={5.7}>
            <Chart
              series={series}
              options={{
                labels: TrafficDataLabel,
                colors: [
                  "#F66D44",
                  "#feae65",
                  "#e6f69d",
                  "#aadea7",
                  "#64c2a6",
                  "#2d87bb",
                ],
                dataLabels: {
                  enabled: true,
                  offsetX: 30,
                  style: {
                    fontSize: "16px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: "bold",
                  },
                },
                stroke: {
                  width: 0,
                },
                legend: {
                  fontSize: "14px",
                  fontWeight: "bold",
                  itemMargin: "left",
                },
              }}
              type="pie"
              height={400}
              width="100%"
            />
          </Grid>
        ) : (
          <>
            <img src={noData} style={{ height: "100%", width: "100%" }} />
          </>
        )}
      </Card>
    </>
  );
}
export default PieChart;
