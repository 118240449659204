import React from "react";
import AddLease from "./AddLease";

export default function PropertyName() {
  return (
    <>
      <div className="top-level-container">
        <AddLease />
      </div>
    </>
  );
}
