import CryptoJS from "crypto-js";

const SECRET_KEY = {
  development: {
    SECRET_KEY: "db14fe5d41d63a6e716e5d101d2693368cdcfb08",
  },
  production: {
    SECRET_KEY: "7209c620b9b7c2600600c07511887a73f9759ed8",
  },
  homeTherapy: {
    SECRET_KEY: "a3886598e844107d51cec39eae6720f237c2384d",
  },
};
const key = CryptoJS.SHA256(
  "HOME-THERAPY-" + SECRET_KEY.homeTherapy.SECRET_KEY
).toString();

export const decrypt = (encryptedText) => {
  const bytes = CryptoJS.AES.decrypt(encryptedText, key);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const encrypt = (plainText) => {
  return CryptoJS.AES.encrypt(plainText, key).toString();
};
