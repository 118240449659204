import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import CountryCodeDropDown from "../../components/CountryCodeDropDown";
import Textfiled from "../../components/Textfiled";
import SearchIcon from "@mui/icons-material/Search";
import {
  addUserForLease,
  updateOwner,
  updateUserValue,
} from "../../services/LeaseApi";
import { getOwnerDataDropDownList } from "../../services/allPropertiesAPI";

const EditOwner = ({
  ownerData,
  flatId,
  setOpenModel,
  handleClose,
  getOwnerData,
  setAddOwnerCreation,
}) => {
  const [searchText, setSearchText] = useState("");
  const [ownerDropDownListForEdit, setOwnerDropDownListForEdit] = useState([]);
  const [checkChangeValue, setCheckChangeValue] = useState(false);
  const [checkOwnerListValue, setCheckOwnerListValue] = useState(false);
  const [checkOwnerCreateValue, setCheckOwnerCreateValue] = useState(false);
  const [first, setFirst] = useState(false);
  const [ownerIdNewOwner, setOwnerIdNewOwner] = useState(false);
  const [handleNewAdd, setHandleNewAdd] = useState(false);

  const EditOwnerSchema = yup.object().shape({
    ownerEmail: yup
      .string()
      .matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, {
        message: "Invalid Contact Email",
        excludeEmptyString: true,
      }),
    ownerMobileNumber: yup
      .string()
      .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]/, {
        message: "Phone number is not valid",
        excludeEmptyString: true,
      }),
  });
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      owner: ownerData?.name ? ownerData : "No Owner Name",
      ownerCountryCode: ownerData?.countryCode
        ? `+${ownerData?.countryCode}`
        : "+971",
      ownerEmail: ownerData?.email ? ownerData?.email : "No Email",
      ownerMobileNumber: ownerData?.mobileNumber
        ? ownerData?.mobileNumber?.replace(ownerData?.countryCode, "")
        : "No Mobile Number",
    },
    mode: "onChange",
    resolver: yupResolver(EditOwnerSchema),
  });

  const [ownerCountryCode, owner] = watch(["ownerCountryCode", "owner"]);

  const fetchOwnerData = async () => {
    const resp = await getOwnerDataDropDownList();
    setOwnerDropDownListForEdit(resp.data.data.rows);
  };
  useEffect(() => {
    fetchOwnerData();
  }, []);

  useEffect(() => {
    if (typeof owner === "string" && ownerData?.name) {
      setValue("ownerEmail", JSON.parse(owner).email);
      setValue(
        "ownerMobileNumber",
        JSON.parse(owner).mobileNumber?.replace(
          JSON.parse(owner).countryCode,
          ""
        )
      );
      setValue(
        "ownerCountryCode",
        JSON.parse(owner).countryCode
          ? `+${JSON.parse(owner)?.countryCode}`
          : "+971"
      );
    } else {
      setValue(
        "ownerEmail",
        owner.length > 15 ? JSON.parse(owner)?.email : owner.email
      );
      setValue(
        "ownerMobileNumber",
        owner?.length > 15
          ? JSON.parse(owner)?.mobileNumber?.replace(
              JSON.parse(owner)?.countryCode,
              ""
            )
          : owner.mobileNumber?.replace(owner.countryCode, "")
      );
      setValue(
        "ownerCountryCode",
        owner?.length > 15
          ? JSON.parse(owner)?.countryCode
            ? `+${JSON.parse(owner)?.countryCode}`
            : "+971"
          : owner.countryCode
          ? `+${owner?.countryCode}`
          : "+971"
      );
    }
  }, [JSON.stringify(owner)]);

  //  useEffect(() => {
  //    setValue("")
  //  }, [flatId])

  const onSubmit = async (data) => {
    if (checkChangeValue) {
      const payload1 = {
        mobileNumber: `${data.ownerCountryCode.replace("+", "")}${
          data.ownerMobileNumber
        }`,
        countryCode: data.ownerCountryCode.replace("+", ""),
        email: data.ownerEmail,
        masterUserId:
          typeof owner == "string" ? JSON.parse(owner).id : owner?.id,
      };

      const respUpdate = await updateUserValue(payload1);

      if (respUpdate.data.status === "success") {
        handleClose();
        getOwnerData(flatId);
        toast.success(respUpdate.data.data);
      } else {
        toast.error(respUpdate.data.msg);
      }
    }
    if (checkOwnerCreateValue) {
      const payloadCreate = {
        name: data?.OwnerCreateName,
        countryCode: data?.ownerCountryCode.replace("+", ""),
        mobileNumber: `${data?.ownerCountryCode.replace("+", "")}${
          data?.ownerMobileNumber
        }`,
        email: data?.ownerEmail,
      };
      const respCreate = await addUserForLease(payloadCreate);
      if (respCreate.data.status === "success") {
        setAddOwnerCreation(respCreate.data.data);
        getOwnerData(flatId);
        handleClose();
        getOwnerData(flatId);
      } else {
        toast.error(respCreate.data.msg);
      }
      return;
    }

    const payload = {
      masterUserId: JSON.parse(owner).id,
      flatId: flatId,
    };
    const resp = await updateOwner(payload);

    if (resp.data.status === "success") {
      handleClose();
      getOwnerData(flatId);
      toast.success(resp.data.data);
    } else {
      toast.error(resp.data.msg);
    }
  };
  console.log(ownerDropDownListForEdit, "sett");
  return (
    <div>
      <div style={{ width: "500px" }}>
        <h5>Edit Owner</h5>
        <div>
          {" "}
          <div className="d-flex justify-content-between">
            <div>
              <Box>
                {handleNewAdd ? (
                  <>
                    <Box marginTop="-10px">
                      <Textfiled
                        label="Owner Name"
                        inputProps={{ maxLength: 50 }}
                        type="text"
                        width="220px"
                        name="OwnerCreateName"
                        register={register}
                        setCheckChangeValue={setCheckOwnerCreateValue}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    {/* <TextField
                      select
                      InputLabelProps={{
                        shrink: true,
                      }}
                      id="standard-basic"
                      label="Owner Name"
                      variant="standard"
                      className="inputopacity"
                      sx={{ width: "220px" }}
                      onChange={(e) => setValue("owner", e.target.value)}
                      value={
                        typeof owner == "string"
                          ? owner !== "No Owner Name"
                            ? JSON.parse(owner)?.name
                            : ""
                          : owner.name
                      }
                      defaultValue={""}
                    >
                      <MenuItem
                        onClick={() => {
                          setHandleNewAdd(true);
                          setValue("ownerEmail", "");
                          setValue("ownerMobileNumber", "");
                          setValue("ownerCountryCode", "+971");
                        }}
                      >
                        + Add New Owner
                      </MenuItem>
                      {ownerDropDownListForEdit?.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={JSON.stringify(option)}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField> */}

                    <FormControl fullWidth>
                      <InputLabel
                        id="search-select-label"
                        sx={{ marginLeft: "-14px" }}
                      ></InputLabel>
                      <Select
                        MenuProps={{ autoFocus: false }}
                        sx={{ width: "225px" }}
                        labelId="search-select-label"
                        id="search-select"
                        className="inputopacity"
                        variant="standard"
                        // {...register("owner")}
                        value={
                          typeof owner == "string"
                            ? owner !== "No Owner Name"
                              ? JSON.parse(owner)?.name
                              : ""
                            : owner.name
                        }
                        onChange={(e) => setValue("owner", e.target.value)}
                        name="owner"
                        onClose={() => setSearchText("")}
                      >
                        <ListSubheader>
                          <TextField
                            size="small"
                            variant="standard"
                            placeholder="Type to search..."
                            inputProps={{ maxLength: "30" }}
                            sx={{
                              width: "220px",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        <MenuItem
                          onClick={() => {
                            setHandleNewAdd(true);
                            setValue("ownerEmail", "");
                            setValue("ownerMobileNumber", "");
                            setValue("ownerCountryCode", "+971");
                          }}
                        >
                          + Add New Owner
                        </MenuItem>
                        {ownerDropDownListForEdit
                          ?.filter((option) =>
                            option?.name
                              ?.toLowerCase()
                              .includes(searchText.toLowerCase())
                          )
                          .map((option, i) => (
                            <MenuItem
                              key={option?.id}
                              value={JSON.stringify(option)}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </>
                )}
              </Box>
              {/* ) : ( */}
              {/* <Box marginTop="-10px">
                  <Textfiled
                    label="Owner Name"
                    inputProps={{ maxLength: 50 }}
                    type="text"
                    width="220px"
                    name="OwnerCreateName"
                    register={register}
                    setCheckChangeValue={setCheckOwnerCreateValue}
                  />
                </Box>
              )} */}

              {/* <FormControl fullWidth>
                <InputLabel
                  id="search-select-label"
                  sx={{ marginLeft: "-14px" }}
                ></InputLabel>
                <Select
                  MenuProps={{ autoFocus: false }}
                  sx={{ width: "225px" }}
                  labelId="search-select-label"
                  id="search-select"
                  className="inputopacity"
                  variant="standard"
                  {...register("owner")}
                  // onChange={(e) => {
                
                  //   setValue("owner", JSON.parse(e.target.value))
                  // }}
                  // value={owner.name}
                  name="owner"
                  //   onChange={() => {
                  //     setCheckOwnerListValue(true);
                  //   }}
                  onClose={() => setSearchText("")}
                  // inputProps={{ maxLength: 15 }}
                >
                  <ListSubheader>
                    <TextField
                      size="small"
                      variant="standard"
                      placeholder="Type to search..."
                      inputProps={{ maxLength: "30" }}
                      sx={{
                        width: "220px",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                          e.stopPropagation();
                        }
                      }}
                    />
                  </ListSubheader>
                  {ownerDropDownListForEdit
                    .filter((option) => option?.name)
                    .map((option, i) => (
                      <MenuItem key={option?.id} value={JSON.stringify(option)}>
                        {option.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}
            </div>
            <div style={{ marginTop: "-12px" }}>
              <Textfiled
                label="Owner Email"
                inputProps={{ maxLength: 50 }}
                type="text"
                width="220px"
                name="ownerEmail"
                register={register}
                checkOwnerCreateValue={checkOwnerCreateValue}
                setCheckChangeValue={setCheckChangeValue}
                shrink={true}
              />
            </div>
          </div>
          <div className="d-flex">
            <div style={{ marginTop: "28px", marginRight: "5px" }}>
              <CountryCodeDropDown
                name="ownerCountryCode"
                value={ownerCountryCode}
                register={register}
              />
            </div>
            <div className="ml-2">
              <Textfiled
                label={"Mobile Number"}
                inputProps={{ maxLength: 50 }}
                type="text"
                width="145px"
                IntialvalueForCountryCode={ownerCountryCode}
                name="ownerMobileNumber"
                checkOwnerCreateValue={checkOwnerCreateValue}
                setCheckChangeValue={setCheckChangeValue}
                register={register}
                shrink={true}
              />
            </div>
          </div>
        </div>
        <button
          type="submit"
          style={{
            border: "1px solid #0C344E",
            color: "#0C344E",
            background: "#0c344e",
            width: "160px",
            height: "40px",
            fontFamily: "Barlow",
            fontSize: "16px",
            marginTop: "20px",
            borderRadius: "10px",
            textTransform: "capitalize",
            color: "white",
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Edit Owner
        </button>
      </div>
    </div>
  );
};

export default EditOwner;
