import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import { Link } from "react-router-dom";
import { UserMappingAPI } from "../services/allPropertiesAPI";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "bootstrap";
import ApproveDeny from "./ApproveDeny";
import { buildingContext } from "../login/AuthProvider";

export default function UserMappingTable({
  id,
  currentPage,
  postPerpage,
  setCurrentPage,
  setPostPerpage,
  setTotalCount,
  totalCount,
  searchText,
}) {
  const { selectedBuilding } = React.useContext(buildingContext);
  const [users, setUsers] = useState([]);
  const [showtotalData, setShowtotalData] = useState("");

  let buildingId = selectedBuilding;

  const header = [
    "Name",
    "Email ID",
    "Requested-Flat",
    "Building Name",
    "Country-Code",
    "Mobile Number",
    "Role",
    "Action",
  ];

  useEffect(() => {
    const params = {
      page: currentPage,
      limit: postPerpage,
      searchText,
      buildingId,
    };
    UserMappingAPI(params).then((response) => {
      setTotalCount(Math.ceil(response.data.data.count / 10));
      setShowtotalData(Math.ceil(response.data.data.count / 10));
      const resp = response.data.data.rows;
      setUsers(resp);
    });
  }, [currentPage, buildingId, searchText]);

  const handlePageClick = (e) => {
    const selectedPage = e.target.innerText;

    setCurrentPage(selectedPage);
  };
  const [handleNoData, setHandleNoData] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setHandleNoData(true);
    }, 1000);
  }, []);

  return (
    <>
      {users.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table sx={{}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {header.map((h, i) => (
                    <TableCell
                      key={i}
                      align="center"
                      className="fw-bold"
                      style={{
                        backgroundColor: "#FFF7F3",
                      }}
                    >
                      {h}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  .filter((curr) => {
                    return curr?.name
                      ?.toLowerCase()
                      ?.includes(id.toLowerCase());
                  })
                  .map((row) => (
                    <TableRow
                      key={row.id}
                      className="rowTable"
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">{row.email}</TableCell>
                      <TableCell align="center">
                        {row.flatRequested.name_en}
                      </TableCell>
                      <TableCell align="center">
                        {row.flatRequested?.building?.name_en}
                      </TableCell>
                      <TableCell align="center">+ {row.countryCode}</TableCell>
                      <TableCell align="center">
                        {row.mobileNumber.replace(row.countryCode, " ")}
                      </TableCell>
                      <TableCell align="center">{row.role}</TableCell>
                      <TableCell align="right" style={{ width: "10rem" }}>
                        <ApproveDeny
                          id={id}
                          users={users}
                          setUsers={setUsers}
                          row={row}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex justify-content-between height-200px mt-2">
            <div className="">
              <p>
                Showing {postPerpage * (currentPage - 1) + 1}
                &nbsp;-
                {currentPage * postPerpage > showtotalData
                  ? showtotalData
                  : currentPage * postPerpage}{" "}
                out of {showtotalData} entries
              </p>
            </div>
            <div className="">
              <Pagination
                count={totalCount}
                onChange={handlePageClick}
                shape="rounded"
                style={{ margin: "10px", float: "right" }}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center mt-5">
          <p className="nofound">{handleNoData && "No Data Found"}</p>
        </div>
      )}
    </>
  );
}
