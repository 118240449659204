import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import { Link } from "react-router-dom";
import "./AllRequesttable.css";
import { axiosClient } from "../services/axiosClient";
import moment from "moment";
export default function AllInvoicetable() {
    const [invoice, setInvoice] = React.useState([]);
    const AllInvoice = async () => {
        const resp = await axiosClient.get("/charges/admin");
        const result = await resp.data.data;
        setInvoice(result);
    };
    useEffect(() => {
        AllInvoice();
    }, []);
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{}} aria-label="simple table">
                    <TableHead className="thead">
                        <TableRow>
                            <TableCell align="center" className="Sbold">
                                Invoice No
                            </TableCell>
                            <TableCell align="center" className="Sbold">
                                payment Status
                            </TableCell>
                            <TableCell align="center" className="Sbold">
                                Amount
                            </TableCell>
                            <TableCell align="center" className="Sbold">
                                Due Date
                            </TableCell>
                            <TableCell align="center" className="Sbold">
                                Invoice Type
                            </TableCell>
                            {/* <TableCell align="center" className="Sbold">
                Category
              </TableCell> */}
                            <TableCell align="center" className="Sbold">
                                Unit No
                            </TableCell>
                            <TableCell align="center" className="Sbold">
                                User Name
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoice?.length > 0 &&
                            invoice?.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell align="center">
                                        <Link to="/">{row.invoiceId}</Link>
                                    </TableCell>
                                    <TableCell align="center">
                                        <div className="bgurgent d-flex justify-content-center align-items-center">
                                            {row.payStatus === null
                                                ? "Pending"
                                                : ""}
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.amount}
                                    </TableCell>
                                    <TableCell align="center">
                                        {moment(row.dueDate).format(
                                            "DD/MM/YYYY"
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.chargeType}
                                    </TableCell>
                                    {/* <TableCell align="center">{row.Category}</TableCell> */}
                                    <TableCell align="center">
                                        {row.flatname_en}
                                    </TableCell>
                                    <TableCell align="center">-</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                style={{ margin: "10px", float: "right" }}
                count={10}
                shape="rounded"
            />
        </>
    );
}
