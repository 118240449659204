import React from "react";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { addDays, isAfter } from "date-fns";
import { Box, Button, Fade, Popper, Typography } from "@mui/material";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./dateFilter.css";

export default function DateFilter({
  state,
  setState,
  shownDateChangeValue,
  setShownDateChangeValue,
  setIsNextMonth,
  formatStartDate,
  formatEndDate,
}) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };
  const handleClickAway = (event) => {
    setOpen(false);
  };
  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Button
          aria-describedby={id}
          type="button"
          onClick={handleClick}
          sx={{
            border: "1px solid #d7d7d7",
            backgroundColor: "#d7d7d7",
            color: "#000",
          }}
        >
          {formatStartDate} to {formatEndDate}
          <KeyboardArrowDownIcon />
        </Button>
        {open ? (
          <Popper id={id} open={open} anchorEl={anchorEl} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Box
                  sx={{
                    border: 1,
                    p: 1,
                    borderRadius: "10px",
                    bgcolor: "background.paper",
                  }}
                >
                  <DateRangePicker
                    onChange={(item) => {
                      setState([item.selection]);
                    }}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={state}
                    editableDateInputs={true}
                    direction="vertical"
                    /*** set the current month ***/
                    shownDate={shownDateChangeValue}
                    /*** Change shownDateChangeValue and isNextMonth states, dispatching the transition ***/
                    onShownDateChange={(month) => {
                      /* check if user click next or previous month */
                      const isNext = isAfter(month, shownDateChangeValue);
                      setIsNextMonth(isNext ? true : false);
                      setShownDateChangeValue(month);
                    }}
                  />
                </Box>
              </Fade>
            )}
          </Popper>
        ) : (
          ""
        )}
      </div>
    </ClickAwayListener>
  );
}
