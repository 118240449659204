import CampaignIcon from "@mui/icons-material/Campaign";
import GridViewIcon from "@mui/icons-material/GridView";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import PhotoCameraFrontOutlinedIcon from "@mui/icons-material/PhotoCameraFrontOutlined";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import SecurityIcon from "@mui/icons-material/Security";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { Typography } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Accordion } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import "../App.css";
import LogoutDialog from "./LogoutDialog";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import BuildIcon from "@mui/icons-material/Build";

export default function LeftNav() {
  const { pathname } = useLocation();
  return (
    <>
      {pathname !== "/" ? (
        <Accordion className="header">
          <Accordion.Item eventKey="0" className="upper">
            <Accordion.Header className="feature_Mp">
              <GridViewIcon
                className="dashboardIcon"
                style={{ color: "#fff" }}
              />
              <h6 className="navDev"> Dashboard </h6>
            </Accordion.Header>
            <Accordion.Body className="elements">
              <NavLink
                to="/dashboard"
                style={{
                  textDecoration: "none",
                  color: "#fff",
                }}
              >
                <ul
                  style={{ paddingLeft: "3.2rem" }}
                  className={
                    pathname === "/dashboard" ? "uniqueactive" : "" + " navDev"
                  }
                >
                  Service Request
                </ul>
              </NavLink>
            </Accordion.Body>
            <Accordion.Body className="elements">
              <NavLink to="/visitorstats" style={{ textDecoration: "none" }}>
                <ul
                  style={{ paddingLeft: "3.2rem" }}
                  className={
                    pathname === "/visitorstats"
                      ? "uniqueactive"
                      : "" + "navDev"
                  }
                >
                  Visitor Statistics
                </ul>
              </NavLink>
            </Accordion.Body>
            {/* <Accordion.Body className="elements">
              <NavLink
                to="/propertyoverview"
                style={{ textDecoration: "none" }}
              >
                <ul
                  style={{ paddingLeft: "3.2rem" }}
                  className={
                    pathname === "/propertyoverview"
                      ? "uniqueactive"
                      : "" + "navDev"
                  }
                >
                  Property Overview
                </ul>
              </NavLink>
            </Accordion.Body> */}
          </Accordion.Item>
          <Accordion.Item eventKey="1" className="upper">
            <Accordion.Header className="feature_Mp">
              <MapsHomeWorkIcon
                className="dashboardIcon"
                style={{ color: "#fff" }}
              />
              <Typography className="navDev">Property Details</Typography>
            </Accordion.Header>
            <Accordion.Body className="elements">
              <NavLink to="/allproperties" className="navDev">
                <ul
                  style={{ paddingLeft: "3.2rem" }}
                  className={
                    pathname === "/allproperties" ? "uniqueactive" : ""
                  }
                >
                  My Properties
                </ul>
              </NavLink>
              <NavLink to="/units" className="navDev">
                <ul
                  style={{ paddingLeft: "3.2rem" }}
                  className={
                    pathname === "/units" ? "uniqueactive" : "" + "navDev"
                  }
                >
                  Units
                </ul>
              </NavLink>
              <NavLink
                to="/user"
                style={{
                  textDecoration: "none",
                  color: "#fff",
                }}
              >
                <ul
                  style={{ paddingLeft: "3.2rem" }}
                  className={
                    pathname === "/user" ? "uniqueactive" : "" + "navDev"
                  }
                >
                  Users
                </ul>
              </NavLink>
              {/* <NavLink to="/usermapping" className="navDev">
                <ul
                  style={{ paddingLeft: "3.2rem" }}
                  className={pathname === "/usermapping" ? "uniqueactive" : ""}
                >
                  User-Mapping
                </ul>
              </NavLink> */}
              <NavLink to="/staff" className="navDev">
                <ul
                  style={{ paddingLeft: "3.2rem" }}
                  className={pathname === "/staff" ? "uniqueactive" : ""}
                >
                  Staff
                </ul>
              </NavLink>
            </Accordion.Body>
          </Accordion.Item>

          <div
            className="feature"
            style={{
              paddingLeft: "1.2rem",
              marginTop: "1rem",
              backgroundColor: pathname === "/visitor" ? "#f79e34" : "",
            }}
          >
            <PhotoCameraFrontOutlinedIcon
              className="dashboardIcon"
              style={{ color: "#fff" }}
            />
            <NavLink
              to="/visitor"
              isActive={() => ["/visitor"].includes(pathname)}
              className={({ isActive }) =>
                (isActive ? "uniqueactive" : "") + "navDev"
              }
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Visitors Management
            </NavLink>
          </div>
          <div
            className="feature"
            style={{
              paddingLeft: "1.2rem",
              marginTop: "1.7rem",
              backgroundColor: pathname === "/helpline" ? "#f79e34" : "",
            }}
          >
            <RecentActorsIcon
              className="dashboardIcon"
              style={{ color: "#fff" }}
            />
            <NavLink
              to="/helpline"
              isActive={() => ["/helpline"].includes(pathname)}
              className={({ isActive }) =>
                (isActive ? "uniqueactive" : "") + "navDev"
              }
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Emergency Contact
            </NavLink>
          </div>
          <div
            className="feature"
            style={{
              paddingLeft: "1.2rem",
              marginTop: "1.7rem",
              backgroundColor: pathname === "/notices" ? "#f79e34" : "",
            }}
          >
            <CampaignIcon className="dashboardIcon" style={{ color: "#fff" }} />
            <NavLink
              to="/notices"
              isActive={() => ["/notices"].includes(pathname)}
              className={({ isActive }) =>
                (isActive ? "uniqueactive" : "") + "navDev"
              }
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Notices
            </NavLink>
          </div>
          <div
            className="feature"
            style={{
              paddingLeft: "1.2rem",
              marginTop: "2rem",
              backgroundColor: pathname === "/asset" ? "#f79e34" : "",
              color: pathname === "/asset" ? "#fff" : "",
            }}
          >
            <AnalyticsIcon style={{ marginRight: "10px", color: "#fff" }} />
            <NavLink
              to="/asset"
              isActive={() => ["/asset"].includes(pathname)}
              className={({ isActive }) => (isActive ? "uniqueactive" : "")}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Asset Management
            </NavLink>
          </div>
          <div
            className="feature"
            style={{
              paddingLeft: "1.2rem",
              marginTop: "1.7rem",
              backgroundColor: pathname === "/preventative" ? "#f79e34" : "",
            }}
          >
            <BuildIcon className="dashboardIcon" style={{ color: "#fff" }} />
            <NavLink
              to="/preventative"
              isActive={() => ["/preventative"].includes(pathname)}
              className={({ isActive }) =>
                (isActive ? "uniqueactive" : "") + "navDev"
              }
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Preventative Management
            </NavLink>
          </div>
          <div
            className="feature"
            style={{
              paddingLeft: "1.2rem",
              marginTop: "1.7rem",
              backgroundColor: pathname === "/lease" ? "#f79e34" : "",
            }}
          >
            <SupervisedUserCircleIcon
              className="dashboardIcon"
              style={{ color: "#fff" }}
            />
            <NavLink
              to="/lease"
              isActive={() => ["/lease"].includes(pathname)}
              className={({ isActive }) =>
                (isActive ? "uniqueactive" : "") + "navDev"
              }
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Lease Management
            </NavLink>
          </div>

          <div
            className="feature"
            style={{
              paddingLeft: "1.2rem",
              marginTop: "2rem",
              backgroundColor: pathname === "/guard" ? "#f79e34" : "",
              color: pathname === "/guard" ? "#fff" : "",
            }}
          >
            <SecurityIcon style={{ marginRight: "10px", color: "#fff" }} />
            <NavLink
              to="/guard"
              isActive={() => ["/guard"].includes(pathname)}
              className={({ isActive }) => (isActive ? "uniqueactive" : "")}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Guard Management
            </NavLink>
          </div>

          {/* <div
            className="feature"
            style={{
              paddingLeft: "1.2rem",
              marginTop: "2rem",
              backgroundColor: pathname === "/userrole" ? "#f79e34" : "",
              color: pathname === "/userrole" ? "#fff" : "",
            }}
          >
            <AnalyticsIcon style={{ marginRight: "10px", color: "#fff" }} />
            <NavLink
              to="/userrole"
              isActive={() => ["/userrole"].includes(pathname)}
              className={({ isActive }) => (isActive ? "uniqueactive" : "")}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              User Role Management
            </NavLink>
          </div>
          {/* <div
            style={{
              paddingLeft: "1.2rem",
              marginTop: "2rem",
              marginBottom: "2rem",
              backgroundColor: pathname === `/allrequest` ? "#f79e34" : "",
              color: pathname === "/allrequest" ? "#fff" : "",
            }}
            className="feature"
          >
            <HomeRepairServiceIcon
              style={{ marginRight: "10px", color: "#fff" }}
            />
            <NavLink
              to="/allrequest"
              isActive={() => ["/allrequest"].includes(pathname)}
              className={({ isActive }) => (isActive ? "uniqueactive" : "")}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Service Request
            </NavLink>
          </div> */}

          <Accordion.Item eventKey="6" className="upper">
            <Accordion.Header className="feature_Sr" style={{ color: "#fff" }}>
              <HomeRepairServiceIcon
                style={{ marginRight: "10px", color: "#fff" }}
              />
              <Typography className="navDev"> Service Request </Typography>
            </Accordion.Header>
            <Accordion.Body className="elements">
              <NavLink to="/category" className="navDev">
                <ul
                  style={{ paddingLeft: "3.2rem" }}
                  className={
                    pathname === "/category" ? "uniqueactive" : "" + "navDev"
                  }
                >
                  Category
                </ul>
              </NavLink>
              <NavLink to="/allrequest" className="navDev">
                <ul
                  style={{ paddingLeft: "3.2rem" }}
                  className={
                    pathname === "/allrequest" ? "uniqueactive" : "" + "navDev"
                  }
                >
                  All Requests
                </ul>
              </NavLink>
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey="3" className="upper">
          <Accordion.Header className="feature">
            <img
              style={{ marginRight: "10px" }}
              src={CommunityImage}
              alt="My Properties"
            />
            Community Notices
          </Accordion.Header>
          <Accordion.Body>
            <Link
              to="/allposts"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <ul>All Posts</ul>
            </Link>
            <Link
              to="/createannouncement"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <ul>Create Announcement</ul>
            </Link>
            <Link
              to="/createvent"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <ul>Create Event</ul>
            </Link>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className="upper">
          <Accordion.Header className="feature">
            <img
              style={{ marginRight: "10px" }}
              src={PaymentsImage}
              alt="My Properties"
            />
            Payments
          </Accordion.Header>
          <Accordion.Body>
            <Link
              to="/allinvoice"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <ul>All Invoices</ul>
            </Link>
            <Link
              to="/createinvoice"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <ul>Create an Invoice</ul>
            </Link>
            <Link
              to="/budget"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <ul>Budget</ul>
            </Link>
            <ul>Expenses</ul>
            <Link
              to="/reminder"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <ul>Reminder</ul>
            </Link>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header className="feature">
            <img
              style={{ marginRight: "10px" }}
              src={ReportsImage}
              alt="My Properties"
            />
            Reports
          </Accordion.Header>
          <Accordion.Body></Accordion.Body>
        </Accordion.Item> */}

          <Accordion.Item eventKey="7" className="upper">
            <Accordion.Header className="feature_Sr handlespacenav navDev">
              <SettingsOutlinedIcon
                style={{ marginRight: "10px", color: "#fff" }}
              />
              <Typography className="navDev">Settings</Typography>
            </Accordion.Header>
            <Accordion.Body className="elements">
              <NavLink
                to="/resetpassword"
                style={{
                  textDecoration: "none",
                  color: "#fff",
                }}
              >
                <ul
                  style={{ paddingLeft: "3.2rem" }}
                  className={
                    pathname === "/resetpassword "
                      ? "uniqueactive"
                      : "" + "navDev"
                  }
                >
                  ResetPassword
                </ul>
              </NavLink>
            </Accordion.Body>
          </Accordion.Item>

          <div
            className="feature handlespacenav"
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "13px",
            }}
          >
            <LogoutDialog />
          </div>
        </Accordion>
      ) : (
        ""
      )}
    </>
  );
}
