import React from "react";
import Lease from "./Lease";

export default function PropertyName() {
  return (
    <>
      <div className="top-level-container">
        <Lease />
      </div>
    </>
  );
}
