import { Box, Grid } from "@mui/material";
import React from "react";

const PropertyOverview = () => {
  return (
    <div className="top-level-container">
      <div className="home"></div>
    </div>
  );
};

export default PropertyOverview;

