import React, { useState } from "react";

import { AiOutlinePlus } from "react-icons/ai";

export default function AddImageUi() {
    const requestStyle = {
        border: " 1px dashed #BDBDBD",
        width: "300px",
        height: "54px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "4px",
    };
    return (
        <>
            <div style={requestStyle}>
                <div>
                    <p>
                        <p style={{ marginTop: "12px" }}>
                            <AiOutlinePlus /> Add Attachments
                        </p>
                    </p>
                </div>
            </div>
        </>
    );
}
