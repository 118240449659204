import { Box, Grid, MenuItem, TextField } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import Textfiled from "../components/Textfiled";

const AddUserRole = () => {
  const USER_TYPE = ["Admin", "My customer", "My team", "Vender"];

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    mode: "onChange",
    // resolver: yupResolver(assetSchema),
  });
  return (
    <Box className="top-level-container">
      <Box className="home">
        <Box>
          <h4>Add User Role</h4>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Box marginTop="50px">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box marginTop="-14px">
                  <Textfiled
                    label={"Role Name*"}
                    type="text"
                    width="300px"
                    name="name"
                    maxLength="35"
                    register={register}
                  />
                </Box>
                <Box>
                  <TextField
                    select
                    label="Select role type*"
                    inputProps={{ maxLength: 100 }}
                    multiline
                    maxRows={4}
                    maxLength={10}
                    // {...register("buildingId")}
                    name="name"
                    variant="standard"
                    style={{
                      fontFamily: "Barlow",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      width: "300px",
                      lineHeight: "19px",
                    }}
                  >
                    {USER_TYPE?.map((option) => {
                      return (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AddUserRole;
