import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { async } from "q";
import { toast } from "react-toastify";
import {
  addLease,
  avoidLeaseService,
  getLeaseDetailSevises,
  LeaseAllData,
} from "../services/LeaseApi";

const initialState = {
  isLoading: false,
  isSuccess: false,
  leaseInfo: null,
  leaseListData: [],
  leaseDetail: null,
  isAvoid: false,
};

export const addlease = createAsyncThunk(
  "LeaseManagement/flat/post",
  async (data) => {
    try {
      const { navigate, paylaod } = data;
      const resp = await addLease(paylaod);
      if (resp.data.status == "fail") {
        toast.error(resp.data.msg);
      } else {
        toast.success("Lease create successfully");
        navigate("/lease");
      }
    } catch (error) {
      // toast.error(error.message, "err");
    }
  }
);

export const getLeaseData = createAsyncThunk(
  "LeaseManagement/flat-contracts",
  async (payload, thunkAPI) => {
    try {
      const resp = await LeaseAllData(payload);
      return resp;
    } catch (error) {}
  }
);
export const getLeaseDetail = createAsyncThunk(
  "/flat-contracts/detail",
  async (payload) => {
    try {
      const resp = await getLeaseDetailSevises(payload);
      return resp?.data?.data;
    } catch (error) {}
  }
);

export const avoidLease = createAsyncThunk(
  "/flat-contracts/void/",
  async (payload) => {
    try {
      const { data, id } = payload;
      const resp = await avoidLeaseService({ data, id });

      if (resp.data.status == "fail") {
        toast.error(resp.data.msg);
      } else {
        toast.success("Contract Cancel Successfully");
      }
    } catch (error) {}
  }
);

const LeaseManagementSlice = createSlice({
  name: "LeaseManagement",
  initialState,
  reducers: {},

  extraReducers: {
    [getLeaseData.rejected]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getLeaseData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.leaseListData = action.payload.data.data;
    },
    [getLeaseData.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [addlease.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [addlease.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },

    [addlease.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },

    [getLeaseDetail.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getLeaseDetail.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.leaseDetail = action.payload;
    },
    [getLeaseDetail.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [avoidLease.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [avoidLease.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isAvoid = true;
    },
    [avoidLease.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});

export const { setLeaseData } = LeaseManagementSlice.actions;
export default LeaseManagementSlice.reducer;
