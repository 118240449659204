import { useState, useEffect } from "react";
import "../index.css";
import CatalogForm from "./CatalogForm";
import CatalogForm2 from "./CatalogForm2";
import { axiosClient } from "../../../services/axiosClient";
import CircularProgress from "@mui/material/CircularProgress";

const EditCatalouge = () => {
    //Get Charges Catalogues
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const getCatalogues = async () => {
        try {
            setLoading(true);
            let resp = await axiosClient.get("maintenance-catalogues/charges");
            let result = resp.data.data;
            setData(result.Engineering);
            setData2(result.Housekeeping);
            setData3(result.Other);
            setLoading(false);
        } catch (error) {
            
            setLoading(false);
        }
    };

    useEffect(() => {
        getCatalogues();
    }, []);

    // Charges By CATALOG Concat
    const chargesData = data?.concat(data2)?.concat(data3);

    if (loading) {
        return (
            <div
                className="d-flex justify-content-center"
                style={{ margin: "0 auto", alignItems: "center" }}
            >
                <CircularProgress color="secondary" />
            </div>
        );
    }

    return (
        <div
            className="container bg-white"
            style={{ borderTopLeftRadius: "50px" }}
        >
            <div className="p-4">
                <div></div>
                <div className="Cform2">
                    <CatalogForm CategoryData={chargesData} />
                </div>
            </div>
        </div>
    );
};

export default EditCatalouge;
