import { yupResolver } from "@hookform/resolvers/yup";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SearchIcon from "@mui/icons-material/Search";
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import OpenDoc from "../PropertyName/OpenDoc";
import OpenGallery from "../PropertyName/OpenGallery";
import pdfPhoto from "../StaffDetail/assets/pdfPhoto.png";
import { unitSchema } from "../components/AuthSchema";
import BuildingDropDown from "../components/BuildingDropDown";
import CountryCodeDropDown from "../components/CountryCodeDropDown";
import Loader from "../components/Loader";
import SearchSelectBox from "../components/SearchSelectBox";
import { buildingContext } from "../login/AuthProvider";
import {
  addUnit,
  getAllBuilding,
  getOwnerDataDropDownList,
  uploadFile,
} from "../services/allPropertiesAPI";
import { getMobileNumber } from "../util";
import { removeStringFunction } from "../util/removeStringFunction";
import "./index.css";

const FLAT_TYPES = [
  {
    id: "Apartment",
    name: "Apartment",
  },
  {
    id: "Beach House",
    name: "Beach House",
  },
  {
    id: "Duplex",
    name: "Duplex",
  },
  {
    id: "Farm",
    name: "Farm",
  },
  {
    id: "Office",
    name: "Office",
  },
  {
    id: "Studio",
    name: "Studio",
  },
  {
    id: "Shop",
    name: "Shop",
  },

  {
    id: "Villa",
    name: "Villa",
  },

  {
    id: "Storage",
    name: "Storage",
  },

  {
    id: "Others",
    name: "Others",
  },
];

export default function PropertyName() {
  const navigate = useNavigate();
  const [ownerData, setOwnerData] = useState([]);
  const [handleLoader, setHandleLoader] = useState(false);
  const [getImageData, setGetImageData] = useState([]);
  const [getLength, setGetLength] = useState();
  const [getImageDataDoc, setGetImageDataDoc] = useState([]);
  const [documentUrl, setDocumentUrl] = useState([]);
  const [getLengthDoc, setGetLengthDoc] = useState();
  const [imageUrl, setImageUrl] = useState([]);
  const [buildingDataList, setBuildingDataList] = useState([]);
  let buildingFromStorage = sessionStorage.getItem("building");
  const [searchText, setSearchText] = useState("");

  const fetchOwnerData = async () => {
    setHandleLoader(true);
    const resp = await getOwnerDataDropDownList();
    setOwnerData(resp.data.data.rows);
    setHandleLoader(false);
  };

  const handleRemoveImg = (att) => {
    const filtered = imageUrl.filter((item) => item !== att);
    setImageUrl(filtered);
  };

  const handleRemoveDoc = (att) => {
    const filtered = documentUrl.filter((item) => item !== att);
    setDocumentUrl(filtered);
  };

  const { selectedBuilding } = React.useContext(buildingContext);

  const {
    control,
    handleSubmit,
    setValue,
    register,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      buildingId: selectedBuilding,
      name_en: "",
      floor: "",
      size: "",
      flatType: "",
      bedroom: "",
      bathroom: "",
      ownerId: "",
      contactMobileNumber: "",
      contactName: "",
      contactEmail: "",
      unitId: "",
      contactCountryCode: "",
    },
    resolver: yupResolver(unitSchema),
  });

  const [IntialvalueForCountryCode, building, contactEmail] = watch([
    "contactCountryCode",
    "buildingId",
    "contactEmail",
  ]);

  const fetchAllBuildingDataList = async () => {
    setHandleLoader(true);
    if (buildingFromStorage?.length > 0) {
      const resp = await getAllBuilding();
      let specificBuilding = resp?.data?.data?.rows?.filter(
        (building) => building.id === buildingFromStorage
      );
      setBuildingDataList(specificBuilding);
    } else {
      const resp = await getAllBuilding();
      setBuildingDataList(resp.data.data.rows);
    }
    setHandleLoader(false);
  };

  useEffect(() => {
    fetchAllBuildingDataList();
  }, [selectedBuilding]);

  useEffect(() => {
    fetchOwnerData();
    setValue("contactCountryCode", "+971");
  }, []);

  const getImageUrl = async () => {
    setHandleLoader(true);
    try {
      const res = await uploadFile(getImageData);
      if (res.data.status === "success") {
        const getUrl = res.data.data.map((current) => current.location);
        setImageUrl([...imageUrl, ...getUrl]);
      } else {
        toast("Document is not uploaded. Please check your connection");
      }
    } catch {
      toast.error("Could not load image. Please select valid file");
    }
    setHandleLoader(false);
  };
  const getDocUrl = async () => {
    setHandleLoader(true);
    try {
      const res = await uploadFile(getImageDataDoc);
      if (res.data.status === "success") {
        const getUrl = res.data.data.map((current) => current.location);
        setDocumentUrl([...documentUrl, ...getUrl]);
      } else {
        // toast("Document is not uploaded. Please check your connection");
      }
    } catch {}
    setHandleLoader(false);
  };

  useEffect(() => {
    if (getLength) {
      getImageUrl();
    }
  }, [getLength]);

  useEffect(() => {
    if (getLengthDoc) {
      getDocUrl();
    }
  }, [getLengthDoc]);

  const handleValue = (name, event) => {
    let newValue = event.target.value;
    if (newValue.length > 3) {
      newValue = newValue.slice(0, 10); // restrict to 3 digits
    }
    setValue(`${name}`, newValue);
  };

  const onSubmit = async (data) => {
    setHandleLoader(true);
    data["name_ar"] = data.name_en;
    data["images"] = imageUrl;
    data["documents"] = documentUrl;
    data["ownerId"] = data?.ownerId === "" ? null : data.ownerId;
    data["contactCountryCode"] = getMobileNumber(
      data.contactCountryCode,
      data.contactMobileNumber
    ).countryCode;
    data["contactMobileNumber"] = getMobileNumber(
      data.contactCountryCode,
      data.contactMobileNumber
    ).mobileNumber;
    const getData = removeStringFunction(data);
    const postData = await addUnit(getData);
    if (postData.data.status === "success") {
      navigate("/units");
      toast("Saved Successfully");
    } else {
      toast(postData.data.msg);
    }
    setHandleLoader(false);
  };

  return (
    <>
      <div className="top-level-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          {handleLoader && <Loader />}
          <Grid className="home">
            <Box display="flex" justifyContent="space-between" width="100%">
              <Typography fontSize={20}>Add New Unit</Typography>
            </Box>
            <Box>
              <Box width="20%" display="flex">
                <OpenGallery
                  setGetImageData={setGetImageData}
                  setGetLength={setGetLength}
                  getImageDataDoc={getImageDataDoc}
                />
                <Box m={2} display="flex" gap={2}>
                  <Box display="flex" gap={2}>
                    {imageUrl.map((curElem, id) => (
                      <Box position="relative" width="10rem" height="10rem">
                        <img
                          style={{ height: "100%", width: "100%" }}
                          alt="..."
                          src={curElem}
                          name="images"
                        />
                        <CancelIcon
                          style={{
                            position: "absolute",
                            right: "2px",
                            top: "5px",
                            color: "#000",
                          }}
                          onClick={() => handleRemoveImg(curElem)}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
              <Grid display="flex" width="100%">
                <Grid width="65%">
                  <Box width="100%" lineHeight="2rem">
                    <BuildingDropDown
                      displayedOptions={buildingDataList}
                      name="buildingId"
                      register={register}
                      label="Select Property Name"
                      width="675px"
                      innerWidth="600px"
                      buildingId={building}
                      selectedBuilding={selectedBuilding}
                    />
                    <Typography
                      sx={{
                        fontStyle: "italic",
                        fontSize: "13px",
                        color: "red",
                      }}
                    >
                      {errors?.buildingId?.message.slice(0, 30)}
                    </Typography>
                    <Controller
                      name="name_en"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="standard-basic"
                          fullWidth
                          label="Unit Number*"
                          inputProps={{ maxLength: 75 }}
                          variant="standard"
                          sx={{ marginTop: "1rem" }}
                        />
                      )}
                    />
                    <Typography
                      sx={{
                        fontStyle: "italic",
                        fontSize: "13px",
                        color: "red",
                      }}
                    >
                      {errors?.name_en?.message}
                    </Typography>
                    <Box sx={{ marginTop: "15px" }}>
                      <SearchSelectBox
                        displayedOptions={FLAT_TYPES}
                        name="flatType"
                        register={register}
                        label="Type of Unit*"
                        width="670px"
                        innerWidth="600px"
                      />
                      <Typography
                        sx={{
                          fontStyle: "italic",
                          fontSize: "13px",
                          color: "red",
                        }}
                      >
                        {errors?.flatType?.message && "This field is  required"}
                      </Typography>
                    </Box>
                    <Box sx={{ marginTop: "15px" }}>
                      <FormControl fullWidth>
                        <InputLabel
                          id="search-select-label"
                          sx={{ marginLeft: "-14px" }}
                        >
                          Owner Name
                        </InputLabel>
                        <Select
                          MenuProps={{ autoFocus: false }}
                          sx={{ width: "670px" }}
                          labelId="search-select-label"
                          id="search-select"
                          variant="standard"
                          {...register("ownerId")}
                          onChange={(e) => {
                            setValue("ownerId", e.target.value);
                          }}
                          name="ownerId"
                          label="Owner Name"
                          onClose={() => setSearchText("")}
                        >
                          <ListSubheader>
                            <TextField
                              size="small"
                              variant="standard"
                              placeholder="Type to search..."
                              inputProps={{ maxLength: "30" }}
                              sx={{
                                width: "670px",
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => setSearchText(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key !== "Escape") {
                                  e.stopPropagation();
                                }
                              }}
                            />
                          </ListSubheader>
                          {ownerData
                            .filter(
                              (option) =>
                                option?.name
                                  ?.toLowerCase()
                                  .includes(searchText.toLowerCase()) ||
                                option?.email
                                  ?.toLowerCase()
                                  .includes(searchText.toLowerCase()) ||
                                option?.mobileNumber
                                  ?.toLowerCase()
                                  .includes(searchText.toLowerCase())
                            )
                            .map((option, i) => (
                              <MenuItem key={option?.id} value={option.id}>
                                <span>{option.name}</span>
                                <span
                                  style={{
                                    background: "#eff0f6",
                                    color: "#0c344e",
                                    padding: "0px 10px 0px 10px",
                                    borderRadius: "10px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {option.userType}
                                </span>
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Controller
                      name="floor"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="standard-basic"
                          fullWidth
                          label="Floor"
                          inputProps={{ maxLength: 20 }}
                          variant="standard"
                          sx={{ marginTop: "1rem" }}
                        />
                      )}
                    />

                    <Controller
                      name="size"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="standard-basic"
                          fullWidth
                          type="number"
                          inputProps={{ maxLength: "20" }}
                          label="Unit Size (in sqft)"
                          onChange={(e) => {
                            handleValue("size", e);
                          }}
                          variant="standard"
                          sx={{ marginTop: "1rem" }}
                        />
                      )}
                    />
                    <Typography
                      sx={{
                        fontStyle: "italic",
                        fontSize: "13px",
                        color: "red",
                      }}
                    >
                      {errors?.size?.message && "Unit size must be number"}
                    </Typography>
                    <Controller
                      name="unitId"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="standard-basic"
                          fullWidth
                          inputProps={{ maxLength: 20 }}
                          label="Government Id"
                          variant="standard"
                          sx={{ marginTop: "1rem" }}
                        />
                      )}
                    />

                    <Box
                      className="inputColor"
                      width="100%"
                      display="flex"
                      justifyContent="space-between"
                      my={2}
                    >
                      <Box>
                        <Controller
                          name="bathroom"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="standard-basic"
                              type="number"
                              onChange={(e) => {
                                handleValue("bathroom", e);
                              }}
                              label="No. of Bathroom"
                              inputProps={{ maxLength: 10 }}
                              variant="standard"
                              sx={{ width: "300px" }}
                            />
                          )}
                        />
                        <Typography
                          sx={{
                            fontStyle: "italic",
                            fontSize: "13px",
                            color: "red",
                          }}
                        ></Typography>
                      </Box>
                      <Box>
                        <Controller
                          name="bedroom"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="bedroom"
                              type="number"
                              label="No. of Bedroom"
                              onChange={(e) => {
                                handleValue("bedroom", e);
                              }}
                              variant="standard"
                              sx={{ width: "300px" }}
                            />
                          )}
                        />
                        <Typography
                          sx={{
                            fontStyle: "italic",
                            fontSize: "13px",
                            color: "red",
                          }}
                        ></Typography>
                      </Box>
                    </Box>

                    <Controller
                      name="contactName"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="standard-basic"
                          label=" POC Name"
                          inputProps={{ maxLength: 50 }}
                          variant="standard"
                          fullWidth
                        />
                      )}
                    />
                    <Controller
                      name="contactEmail"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="standard-basic"
                          label=" POC Email"
                          variant="standard"
                          fullWidth
                          sx={{ marginTop: "1rem" }}
                        />
                      )}
                    />
                    <Typography
                      sx={{
                        fontStyle: "italic",
                        fontSize: "13px",
                        color: "red",
                      }}
                    >
                      {errors?.contactEmail?.message &&
                        contactEmail.length > 0 &&
                        "Contact email invalid"}
                    </Typography>
                    <Grid width="100%" mt={4}>
                      <Box display="flex">
                        <Box>
                          <CountryCodeDropDown
                            name="contactCountryCode"
                            value={IntialvalueForCountryCode}
                            register={register}
                          />
                        </Box>
                        <Box marginLeft="10px">
                          <Controller
                            name="contactMobileNumber"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                inputProps={{
                                  maxLength:
                                    13 - IntialvalueForCountryCode.length,
                                  minLength:
                                    13 - IntialvalueForCountryCode.length,
                                }}
                                id="mobileNumber"
                                label="Contact No."
                                variant="standard"
                                sx={{ width: "590px", marginTop: "-17px" }}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                      <Typography
                        sx={{
                          fontStyle: "italic",
                          fontSize: "13px",
                          color: "red",
                        }}
                      >
                        {errors?.contactMobileNumber?.message}
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
                <Grid ml={4}>
                  <Typography textAlign="center" fontSize="20px">
                    Attach Document
                  </Typography>
                  <OpenDoc
                    setGetImageDataDoc={setGetImageDataDoc}
                    setGetLengthDoc={setGetLengthDoc}
                  />
                  {documentUrl.length > 0 &&
                    documentUrl?.map((curElem, id) => (
                      <Box
                        className="card"
                        display="flex"
                        justifyContent="space-between"
                        p={2}
                        m={2}
                        style={{
                          boxShadow: " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                          borderStyle: "none",
                        }}
                        width="18rem"
                      >
                        <Box display="flex" justifyContent="space-between">
                          <Typography>
                            <img
                              src={pdfPhoto}
                              style={{ marginRight: "10px" }}
                              alt=""
                            />
                            {curElem
                              ?.split("/")[3]
                              ?.split(".")[0]
                              ?.slice(0, 10)}
                          </Typography>
                          <Typography>
                            <a
                              download
                              href={curElem}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <RemoveRedEyeIcon />
                            </a>
                            <CloseIcon
                              onClick={() => handleRemoveDoc(curElem)}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                </Grid>
              </Grid>
            </Box>
            <Button
              type="submit"
              className="Button"
              variant="contained"
              color="success"
              style={{
                border: "1px solid #0C344E",
                color: "white",
                background: "rgb(12, 52, 78)",
                width: "150px",
                height: "48px",
                borderRadius: "10px",
                textTransform: "capitalize",
                marginTop: "2rem",
              }}
            >
              Save
            </Button>
          </Grid>
        </form>
      </div>
    </>
  );
}
