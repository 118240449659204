import React, { useState } from "react";
import InputField from "../InputField";
import Form from "../Form";

const CatalogForm = ({ CategoryData }) => {
    const [data, setData] = useState({
        chargeName: "",
        serviceCost: "",
        category: "",
    });

    const { chargeName, serviceCost, category } = data;
    const handleChangeInput = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    return (
        <>
            <Form>
                <div className="d-flex  col-12">
                    <h6>{CategoryData.category}</h6>
                </div>
                <InputField
                    placeholder="Carpet Shampoo"
                    label="Charge Text"
                    value={CategoryData.chargeName}
                    onChange={handleChangeInput}
                    name="chargeName"
                    disabled
                />
                <InputField
                    placeholder="400"
                    label="Service Cost (AED)"
                    name="serviceCost"
                    value={CategoryData.serviceCost}
                    onChange={handleChangeInput}
                    disabled
                />
                {/* <InputField
          placeholder="Additional Bedding"
          label="Charge Text"
          disabled
        />
        <InputField
          placeholder="200"
          label="Service Cost (AED)"
          name="category"
          value={category}
          onChange={handleChangeInput}
          disabled
        /> */}
            </Form>
        </>
    );
};

export default CatalogForm;
