import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { uploadFile } from "../services/allPropertiesAPI";

const UploadImage = ({
  getImageData,
  setImageUrl,
  getLength,
  setHandleLoader,
  imageUrl,
}) => {
  const getImageUrl = async () => {
    // setHandleLoader(true);
    try {
      const res = await uploadFile(getImageData);
      if (res.data.status === "success") {
        // setImageUrl(res.data.data);
        setImageUrl([...imageUrl, ...res.data.data]);
      } else {
        toast("Document is not uploaded. Please check your connection");
      }
    } catch {}
    // setHandleLoader(false);
  };

  useEffect(() => {
    if (getLength > 0) {
      getImageUrl();
    }
  }, [getImageData]);

  return <div></div>;
};

export default UploadImage;
