import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { Stack } from "@mui/system";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Textfiled from "../components/Textfiled";
import { buildingContext } from "../login/AuthProvider";
import { fetchAllAssetName } from "../Redux/AssetSlice";
import { getflatDetail } from "../services/allCommunityApi";
import { getAllBuilding } from "../services/allPropertiesAPI";
import {
  CategoryDetailSlice,
  editPreventativeSlice,
} from "../Redux/PreventativeSlice";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { fetchBuildingName, fetchUnitName } from "../Redux/MultiUseSlice";
import { current } from "immer";
// import "../ServisesRequest/AllRequest/index.css";

const EditPreventative = ({ setHandleEditMode, getData }) => {
  const dispatch = useDispatch();
  const { allAssetName, loading } = useSelector((state) => state.AssetData);
  const { getCategoriesData, isLoading } = useSelector(
    (state) => state.PreventativeData
  );
  const { buildingName, flatName } = useSelector(
    (state) => state.MultiUseReducerData
  );

  const { isSuccess } = useSelector((state) => state.PreventativeData);
  const [addNewCategory, setAddNewCategory] = useState(false);
  const [buildingDataList, setBuildingDataList] = useState([]);
  const [units, setUnits] = useState([]);
  const { selectedBuilding } = React.useContext(buildingContext);
  const [cronExpression, setCronExpression] = useState({});
  const [payload, setPayload] = useState([]);
  const navigate = useNavigate();
  const [chip, setChip] = useState({
    Sun: false,
    Mon: false,
    Tue: false,
    Wed: false,
    Thru: false,
    Fri: false,
    Sat: false,
  });

  const dateList = [
    { name: "1st Day", value: "1" },
    { name: "2nd Day", value: "2" },
    { name: "3rd Day", value: "3" },
    { name: "4th Day", value: "4" },
    { name: "5th Day", value: "5" },
    { name: "6th Day", value: "6" },
    { name: "7th Day", value: "7" },
    { name: "8th Day", value: "8" },
    { name: "9th Day", value: "9" },
    { name: "10th Day", value: "10" },
    { name: "11th Day", value: "11" },
    { name: "12th Day", value: "12" },
    { name: "13th Day", value: "13" },
    { name: "14th Day", value: "14" },
    { name: "15th Day", value: "15" },
    { name: "16th Day", value: "16" },
    { name: "17th Day", value: "17" },
    { name: "18th Day", value: "18" },
    { name: "19th Day", value: "19" },
    { name: "20th Day", value: "20" },
    { name: "21th Day", value: "21" },
    { name: "22th Day", value: "22" },
    { name: "23th Day", value: "23" },
    { name: "24th Day", value: "24" },
    { name: "25th Day", value: "25" },
    { name: "26th Day", value: "26" },
    { name: "27th Day", value: "27" },
    { name: "28th Day", value: "28" },
  ];
  const MonthsList = ["1", "2", "3", "4", "5", "6"];
  const MonthName = [
    { name: "January", value: "1" },
    { name: "February", value: "2" },
    { name: "March", value: "3" },
    { name: "April", value: "4" },
    { name: "May", value: "5" },
    { name: "June", value: "6" },
    { name: "July", value: "7" },
    { name: "August", value: "8" },
    { name: "September", value: "9" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];

  const handleDaysChange = (key, index) => {
    setChip((prevState) => {
      return {
        ...prevState,
        [`${key}`]: !chip[key],
      };
    });

    if (payload.includes(index)) {
      const filteredArr = payload.filter((item) => item !== index);
      setPayload(filteredArr);
    } else {
      setPayload([...payload, index]);
    }
  };
  useEffect(() => {
    if (getData?.frequency === "Daily") {
      setCronExpression({
        daily: ["0", "0", "0", "?", "*", "*", "*"],
      });
    } else if (getData?.frequency === "Weekly") {
      setCronExpression({
        weekly: ["0", "0", "0", "?", "*", "d", "*"],
      });
    } else if (getData?.frequency === "Monthly") {
      setCronExpression({
        monthly: ["0", "0", "0", "d", "d", "?", "*"],
      });
    } else {
      setCronExpression({
        yearly: ["0", "0", "0", "d", "d", "?", "*"],
      });
    }
  }, [getData?.frequency]);

  const handlePattern = (pattern) => {
    if (pattern === "daily") {
      setCronExpression({
        daily: ["0", "0", "0", "?", "*", "*", "*"],
      });
    } else if (pattern === "weekly") {
      setCronExpression({
        weekly: ["0", "0", "0", "?", "*", "d", "*"],
      });
    } else if (pattern === "monthly") {
      setCronExpression({
        monthly: ["0", "0", "0", "d", "d", "?", "*"],
      });
    } else {
      setCronExpression({
        yearly: ["0", "0", "0", "d", "d", "?", "*"],
      });
    }
  };
  const ppmSchema = yup.object().shape({
    name: yup.string().trim().required("This field  is required"),
    frequencyType: yup.string().trim().required("This field  is required"),
    category: yup.string().trim().required("This field  is required"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: getData?.id,
      type: "Asset",
      typeFor: getData?.asset?.isForFlat === false ? "Building" : "Flat",
      priority: getData?.priority,
      category: getData?.category,
      name: getData?.name,
      buildingId: getData?.asset?.building?.name_en,
      assetId: getData?.asset?.name_en,
      flatId:
        getData?.asset?.isForFlat === false ? getData?.asset?.flat?.id : "",
      typeId: "",
      validTill: "",
      validFrom: "",
      daysCountForMonths: "1st Day",
      monthCountForMonths: "1",
      dayCountForYear: "1st Day",
      monthsNameForYear: "January",
      endTime: "",
      startTime: "",
      frequencyType: getData?.frequencyType,
      cron: [],
      runDate: "",
    },
    mode: "onChange",
    resolver: yupResolver(ppmSchema),
  });
  const [frequencyType, startTime, endTime, buildingId, type] = watch([
    "frequencyType",
    "startTime",
    "endTime",
    "buildingId",
    "type",
  ]);

  const fetchAllBuildingDataList = async () => {
    let buildingFromStorage = sessionStorage.getItem("building");
    if (buildingFromStorage?.length > 0) {
      const resp = await getAllBuilding();
      let specificBuilding = resp?.data?.data?.rows?.filter(
        (building) => building.id === buildingFromStorage
      );

      setBuildingDataList(specificBuilding);
    } else {
      const resp = await getAllBuilding();
      setBuildingDataList(resp.data.data.rows);
    }
  };

  useEffect(() => {
    dispatch(CategoryDetailSlice());
    dispatch(fetchBuildingName());
  }, []);
  useEffect(() => {
    dispatch(fetchAllAssetName());
  }, []);

  const FetchUnitData = async (id) => {
    const resp = await getflatDetail(id);
    setUnits(resp.data?.data?.rows);
  };
  useEffect(() => {
    FetchUnitData(
      selectedBuilding ? selectedBuilding : getBuildingName(buildingId)
    );
  }, [buildingId, selectedBuilding]);

  useEffect(() => {
    fetchAllBuildingDataList();
  }, []);

  const getBuildingName = (building) => {
    const unique = buildingName.find((cur) => {
      return cur.name_en === building;
    });
    return unique?.id;
  };

  const getAssetName = (asset) => {
    const unique = allAssetName?.rows.find((cur) => {
      return cur.name === asset;
    });
    return unique?.id;
  };

  const getFlatName = (flat) => {
    const unique = units?.find((cur) => {
      return cur.name_en === flat;
    });
    return unique?.id;
  };

  const onSubmit = (data) => {
    delete data.frequencyType;
    data["buildingId"] = getBuildingName(data.buildingId);
    data["typeId"] = getAssetName(data.assetId);
    data["flatId"] = getFlatName(data.flatId);
    dispatch(editPreventativeSlice(data));
    // eslint-disable-next-line no-lone-blocks
    {
      isSuccess && setHandleEditMode(false);
    }
  };
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <h5>Edit Preventative Details</h5>
        <Box>
          <Button
            style={{ textDecoration: "underLine" }}
            onClick={() => {
              setHandleEditMode(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            style={{ textDecoration: "underLine" }}
            onClick={handleSubmit(onSubmit)}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
      <Grid>
        <Box display="flex" justifyContent="space-between">
          <Box marginTop="-12px">
            <Textfiled
              label={"Title*"}
              type="text"
              width="300px"
              name="name"
              maxLength="50"
              register={register}
            />
            <Typography
              sx={{
                fontStyle: "italic",
                fontSize: "13px",
                color: "red",
              }}
            >
              {errors?.name?.message && "This field is required"}
            </Typography>
          </Box>
          <Box>
            {addNewCategory ? (
              <>
                <Box marginTop="-12px">
                  <Textfiled
                    label="Category*"
                    type="text"
                    width="300px"
                    name="category"
                    defaultValue={getData?.category}
                    maxLength="25"
                    register={register}
                    autofocus={true}
                  />
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "red",
                    }}
                  >
                    {errors?.category?.message && "This field is required"}
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <TextField
                  select
                  label="Category*"
                  inputProps={{ maxLength: 50 }}
                  multiline
                  defaultValue={getData?.category}
                  maxRows={4}
                  maxLength={10}
                  name="category"
                  {...register("category")}
                  variant="standard"
                  style={{
                    fontFamily: "Barlow",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    width: "300px",
                    lineHeight: "19px",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setAddNewCategory(true);
                    }}
                  >
                    + Add New
                  </MenuItem>
                  {getCategoriesData?.map((option) => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </TextField>
                <Typography
                  sx={{
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "red",
                  }}
                >
                  {errors?.category?.message && "This field is required"}
                </Typography>
              </>
            )}
          </Box>
        </Box>
        <Box display="display" justifyContent="space-between" mt={2}>
          <FormLabel id="demo-radio-buttons-group-label">
            Selected Asset From
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="typeFor"
            value={getData?.asset?.isForFlat === false ? "Building" : "Flat"}
            disabled
            onChange={(event) => setValue("type", event.target.value)}
            row
          >
            <FormControlLabel
              value="Building"
              control={<Radio />}
              label="Building"
              disabled
            />
            <FormControlLabel
              value="Flat"
              control={<Radio />}
              label="Unit"
              disabled
            />
          </RadioGroup>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            my={2}
          >
            <Box>
              <TextField
                select
                label="Building Name*"
                inputProps={{ maxLength: 100 }}
                multiline
                maxRows={4}
                disabled
                defaultValue={getData?.asset?.building?.name_en}
                value={getData?.asset?.building?.name_en}
                maxLength={10}
                {...register("buildingId")}
                name="buildingId"
                variant="standard"
                style={{
                  fontFamily: "Barlow",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  width: "300px",
                  lineHeight: "19px",
                }}
              >
                {buildingDataList?.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option.name_en}>
                      {option.name_en}
                    </MenuItem>
                  );
                })}
              </TextField>
              
            </Box>
            {type === "Flat" && (
              <Box>
                <TextField
                  select
                  label="Flat Name*"
                  inputProps={{ maxLength: 50 }}
                  multiline
                  disabled
                  defaultValue={
                    getData?.asset?.isForFlat === false
                      ? getData?.asset?.flat?.id
                      : ""
                  }
                  value={
                    getData?.asset?.isForFlat === false
                      ? getData?.asset?.flat?.id
                      : ""
                  }
                  {...register("flatId")}
                  name="flatId"
                  variant="standard"
                  style={{
                    fontFamily: "Barlow",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    width: "300px",
                    lineHeight: "19px",
                  }}
                >
                  {units?.map((option) => {
                    return (
                      <MenuItem key={option.id} value={option.name_en}>
                        {option.name_en}
                      </MenuItem>
                    );
                  })}
                </TextField>
                
              </Box>
            )}
          </Box>

          <Box mb={4}>
            <TextField
              select
              label="Asset Name*"
              inputProps={{ maxLength: 100 }}
              multiline
              defaultValue={getData?.asset?.name}
              value={getData?.asset?.name}
              disabled
              maxRows={4}
              maxLength={10}
              {...register("assetId")}
              name="assetId"
              variant="standard"
              style={{
                fontFamily: "Barlow",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "16px",
                width: "300px",
                lineHeight: "19px",
              }}
            >
              {allAssetName?.rows?.map((option) => {
                return (
                  <MenuItem key={option.id} value={option.name}>
                    {option.name}
                  </MenuItem>
                );
              })}
            </TextField>
            {/* <Typography
              sx={{
                fontStyle: "italic",
                fontSize: "13px",
                color: "red",
              }}
            >
              {errors?.assetId?.message && "This field is required"}
            </Typography> */}
          </Box>
          <FormLabel id="demo-radio-buttons-group-label">Priority</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={getData?.priority}
            name="priority"
            onChange={(event) => setValue("priority", event.target.value)}
            row
          >
            <FormControlLabel
              value="Normal"
              control={<Radio />}
              label="Normal"
            />
            <FormControlLabel value="High" control={<Radio />} label="High" />
            <FormControlLabel value="Low" control={<Radio />} label="Low" />
          </RadioGroup>
        </Box>
        <Box my={2}>
          <FormLabel id="demo-radio-buttons-group-label">Checklist</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="frequencyType"
            defaultValue={getData?.frequencyType}
            onChange={(event) => setValue("frequencyType", event.target.value)}
            row
          >
            <FormControlLabel
              value="Pattern"
              control={<Radio />}
              label="Pattern"
            />
            <FormControlLabel
              value="Custom"
              control={<Radio />}
              label="Custom"
            />
          </RadioGroup>
          <Typography fontSize={12}>
            <DoNotDisturbIcon />
            You are not allowed edit to PPM ticket generation pattern/custom
            while update.
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

export default EditPreventative;
