import { Box, Card, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDumpImageList } from "../Redux/HelplineSlice";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const DumpImageUi = ({ setValue, handleClose }) => {
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState("");

  const { isLoading, imageIconsList } = useSelector(
    (state) => state.HelplineData
  );

  useEffect(() => {
    dispatch(fetchDumpImageList(searchData));
  }, [searchData]);
  return (
    <Box>
      <Box sx={{ float: "right" }} onClick={handleClose}>
        <CloseIcon />
      </Box>
      <Box sx={{ padding: "10px 20px 50px 20px", marginTop: "30px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "500px",
            padding: "15px 30px 15px 15px",
            boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 12px",
            borderRadius: "50px",
          }}
        >
          <SearchIcon sx={{ fontSize: "30px" }} />
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => {
              setSearchData(e.target.value);
            }}
            style={{
              width: "500px",
              border: "none",
              outline: "none",
              paddingLeft: "10px",
            }}
          />
        </Box>
        <Box sx={{ marginTop: "15px" }}>
          <Typography>{imageIconsList?.count} matching results</Typography>
        </Box>
        <Box
          sx={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "between",
            alignItems: "center",
            flexWrap: "wrap",
            rowGap: "10px",
            columnGap: "10px",
          }}
        >
          {imageIconsList?.rows?.map((icon) => (
            <Card
              sx={{ width: "50px", height: "45px" }}
              onClick={() => {
                setValue("image", icon?.image);
              }}
            >
              <img
                src={icon?.image}
                style={{
                  width: "50px",
                  height: "45px",
                  objectFit: "contain",
                }}
                onClick={handleClose}
              />
            </Card>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default DumpImageUi;
