import {
  Box,
  Button,
  Card,
  Pagination,
  Switch,
  Typography
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ConfirmBox from "../../components/ConfirmBox";
import Model from "../../components/Dailog";
import Loader from "../../components/Loader";
import SearchBar from "../../components/SearchBar";
import {
  deleteCategory,
  getCategoryData,
  markedCategory
} from "../../Redux/CategorySlice";
import EditCategory from "./EditCategory";

const Category = () => {
  const [openModel, setOpenModel] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [id, setId] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [categoryDetail, setCategoryDetail] = useState({});
  const dispatch = useDispatch();
  const { categoryList, isDelete, isSuccessEdit } = useSelector(
    (state) => state.CategoryData
  );

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleOpenConfirmBox = () => {
    setOpenConfirm(true);
  };
  const handleCloseConfirmBox = () => {
    setOpenConfirm(false);
  };
  const handleClickOpen = () => {
    setOpenModel(true);
  };
  const handleClose = () => {
    setOpenModel(false);
  };

  const handleChange = (categoryId) => {
    dispatch(markedCategory(categoryId));
  };
  const totalCount = useMemo(() => {
    const newTotalCount = Math.ceil(categoryList?.count / limit);
    return newTotalCount;
  }, [limit, categoryList?.count]);

  useEffect(() => {
    dispatch(getCategoryData({ page, limit, searchText }));
  }, [page, searchText, isSuccessEdit, isDelete]);

  const handleDeleteCategory = (id) => {
    dispatch(deleteCategory(id));
  };
  const getIdForApi = async (category) => {
    setCategoryDetail(category);
    if (category) {
      handleClickOpen();
    }
  };

  return (
    <Box className="top-level-container">
      {isLoader && <Loader />}
      <Model {...{ handleClose, openModel }}>
        <EditCategory {...{ categoryDetail, setOpenModel }} />
      </Model>

      <ConfirmBox {...{ handleCloseConfirmBox, openConfirm }}>
        <Box>
          <Box>
            <Typography variant="h6">
              Are you sure, You want to delete ?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "20px",
              margin: "30px 0px 0px 15px",
            }}
          >
            <Button
              sx={{
                textTransform: "capitalize",
                border: "1px solid #0c344e",
                color: "#0c344e",
              }}
              onClick={handleCloseConfirmBox}
            >
              No
            </Button>
            <Button
              onClick={() => {
                handleDeleteCategory(deleteId);
                setOpenConfirm(false);
              }}
              variant="standard"
              sx={{
                textTransform: "capitalize",
                border: "1px solid #0c344e",
                color: "white",
                background: "#0c344e",
                "&:hover": {
                  backgroundColor: "white",
                  color: "#0c344e",
                },
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </ConfirmBox>
      <Box className="home">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <h4>Category</h4>
          </Box>
          <Box display="flex">
            <SearchBar placeholder="Search" onChange={handleSearch} />
            <Link to="/addcategory" style={{ textDecoration: "none" }}>
              <Button
                className="Button"
                variant="contained"
                color="success"
                style={{
                  border: "1px solid #0C344E",
                  color: "#0C344E",
                  background: "white",
                  width: "250px",
                  height: "48px",
                  fontFamily: "Barlow",
                  fontStyle: "normal",
                  fontweight: "500",
                  fontSize: "16px",
                  lineHeight: "19px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 5V19"
                    stroke="#0C344E"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5 12H19"
                    stroke="#0C344E"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Add Category
              </Button>
            </Link>
          </Box>
        </Box>
        {categoryList?.rows?.length > 0 ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                columnGap: "50px",
                flexWrap: "wrap",
                rowGap: "20px",
                marginTop: "50px",
              }}
            >
              {categoryList?.rows?.map((currItem, index) => (
                <>
                  <Card
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "300px",
                    }}
                  >
                    <Box sx={{ padding: "20px 15px 20px 15px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          columnGap: "50px",
                          rowGap: "50px",
                          marginBottom: "30px",
                        }}
                      >
                        <img
                          src={currItem?.image}
                          alt="catPic"
                          width="50px"
                          height="50px"
                        />
                        <Typography>{currItem?.name_en}</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-end",
                          columnGap: "70px",
                        }}
                      >
                        <Box sx={{ display: "flex", columnGap: "20px" }}>
                          <Button
                            sx={{
                              textTransform: "capitalize",
                              border: "1px solid #0c344e",
                              color: "#0c344e",
                            }}
                            onClick={() => {
                              getIdForApi(currItem);
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            onClick={() => {
                              setDeleteId(currItem?.id);
                              handleOpenConfirmBox();
                            }}
                            variant="standard"
                            sx={{
                              textTransform: "capitalize",
                              border: "1px solid #0c344e",
                              color: "white",
                              background: "#0c344e",
                              "&:hover": {
                                backgroundColor: "white",
                                color: "#0c344e",
                              },
                            }}
                          >
                            Delete
                          </Button>
                        </Box>
                        <Box>
                          <Switch
                            size="small"
                            name={currItem.name}
                            checked={currItem?.isVisible}
                            onChange={(e) => {
                              handleChange(currItem.id);
                            }}
                            inputProps={{
                              "aria-label": "controlled",
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                </>
              ))}
            </Box>
            <Box>
              <Pagination
                count={totalCount}
                style={{ float: "right", marginTop: "10px" }}
                onChange={(event, value) => {
                  setPage(value);
                }}
                shape="rounded"
              />
            </Box>
          </>
        ) : (
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "50px",
            }}
          >
            No Data Found
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Category;
