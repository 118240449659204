import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { buildingContext } from "../login/AuthProvider";
import { AllUserAPI, getDeleteUser } from "../services/allPropertiesAPI";
import { showDash } from "../util";
import Loader from "./Loader";
import NoDataFound from "./NoDataFound";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import ConfirmBox from "./ConfirmBox";
import { toast } from "react-toastify";

export default function UserTable({
  searchText,
  postPerpage,
  currentPage,
  setcurrentPage,
  totalCount,
  settotalCount,
  users,
  setUsers,
}) {
  const { selectedBuilding } = React.useContext(buildingContext);
  const [showTotalData, setShowTotalData] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [masterUserId, setMasterUserId] = useState("");
  const [loader, setLoader] = useState(false);
  const header = [
    "Name",
    "Country-Code",
    "Mobile Number",
    "User Type",
    "Email ID",
    "Id Type",
    "Id Number",
    "Gender",
    "Nationality",
    "D.O.B",
    "Allocated-Unit",
    "Building",
    "Action",
  ];
  let buildingId = selectedBuilding;
  const UserData = async () => {
    setLoader(true);
    const resp = await AllUserAPI(
      currentPage,
      postPerpage,
      searchText,
      buildingId
    );
    let totalData = resp.data.data.count;
    setShowTotalData(totalData);
    let pagesCount = Math.ceil(totalData / postPerpage);
    settotalCount(pagesCount);
    setUsers(resp.data.data.rows);
    setLoader(false);
  };
  useEffect(() => {
    UserData();
  }, [currentPage, searchText, buildingId]);

  const [handleNoData, setHandleNoData] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setHandleNoData(true);
    }, 1000);
  }, []);

  const navigate = useNavigate();
  const handleNavigateBuildingId = (id, buildingId) => {
    navigate(`/userdetail/${id}`, {
      state: { buildingId },
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseConfirmBox = () => {
    setOpenConfirm(false);
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const deleteUser = async () => {
    const resp = await getDeleteUser(masterUserId);
    if (resp.data.status == "fail") {
      toast.error(resp.data.msg);
    } else {
      toast.success(resp.data.msg);
      UserData();
    }
  };

  return (
    <>
      {loader && <Loader />}
      {users?.length > 0 ? (
        <>
          {loader && <Loader />}
          {users?.length > 0 ? (
            <>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {header.map((h) => (
                        <TableCell
                          align="center"
                          className="fw-bold"
                          style={{
                            backgroundColor: "#FFF7F3",
                          }}
                        >
                          {h}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users?.map((row) => (
                      <TableRow
                        className="rowTable"
                        key={row.id}
                        onClick={() => {
                          setMasterUserId(row?.id);
                        }}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell align="center">
                          <p
                            className="unitname makeAnchor"
                            onClick={() =>
                              handleNavigateBuildingId(row.id, row.buildingId)
                            }
                          >
                            {row.name}
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          {showDash(`+${row.countryCode}`)}
                        </TableCell>
                        <TableCell align="center">
                          {showDash(
                            row.mobileNumber.replace(row.countryCode, "")
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {showDash(row.userType)}
                        </TableCell>
                        <TableCell align="center">
                          {showDash(row.email)}
                        </TableCell>
                        <TableCell align="center">
                          {showDash(row?.documentType)}
                        </TableCell>
                        <TableCell align="center">
                          {showDash(row?.documentId)}
                        </TableCell>
                        <TableCell align="center">
                          {showDash(row?.gender)}
                        </TableCell>
                        <TableCell align="center">
                          {showDash(row?.nationality)}
                        </TableCell>
                        <TableCell align="center">
                          {showDash(row?.dateOfBirth)}
                        </TableCell>
                        <TableCell align="center">
                          {row?.contractDetails?.isExpired === false
                            ? row?.contractDetails?.flat?.name_en
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {row?.contractDetails?.isExpired === false
                            ? row?.contractDetails?.flat?.building?.name_en
                            : "-"}
                        </TableCell>
                        <ConfirmBox {...{ openConfirm, handleCloseConfirmBox }}>
                          <Typography variant="h6">
                            Are you sure, You want to delete ?
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              columnGap: "20px",
                              margin: "30px 0px 0px 15px",
                            }}
                          >
                            <Button
                              sx={{
                                textTransform: "capitalize",
                                border: "1px solid #0c344e",
                                color: "#0c344e",
                              }}
                              onClick={handleCloseConfirmBox}
                            >
                              No
                            </Button>
                            <Button
                              onClick={(e) => {
                                deleteUser();
                                setOpenConfirm(false);
                                handleClose();
                                e.stopPropagation();
                              }}
                              variant="standard"
                              sx={{
                                textTransform: "capitalize",
                                border: "1px solid #0c344e",
                                color: "white",
                                background: "#0c344e",
                                "&:hover": {
                                  backgroundColor: "white",
                                  color: "#0c344e",
                                },
                              }}
                            >
                              Yes
                            </Button>
                          </Box>
                        </ConfirmBox>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem
                            onClick={(e) => {
                              handleNavigateBuildingId(masterUserId);
                              e.stopPropagation();
                            }}
                          >
                            Update
                          </MenuItem>
                          <MenuItem
                            onClick={(e) => {
                              handleOpenConfirm();
                              e.stopPropagation();
                            }}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                        <TableCell align="center">
                          <MoreVertIcon onClick={handleClick} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="d-flex justify-content-between height-200px mt-2">
                <div className="">
                  <p>
                    Showing {postPerpage * (currentPage - 1) + 1}
                    &nbsp;-
                    {currentPage * postPerpage > showTotalData
                      ? showTotalData
                      : currentPage * postPerpage}
                    out of {showTotalData} entries
                  </p>
                </div>
                <div className="">
                  <Pagination
                    count={totalCount}
                    onChange={(event, value) => {
                      setcurrentPage(value);
                    }}
                    shape="rounded"
                    style={{
                      margin: "10px",
                      float: "right",
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-center mt-5">
              <p className="nofound">{handleNoData && <NoDataFound />}</p>
            </div>
          )}
        </>
      ) : (
        <div className="d-flex justify-content-center mt-5">
          <p className="nofound">{handleNoData && <NoDataFound />}</p>
        </div>
      )}
    </>
  );
}
