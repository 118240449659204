import React, { useState, useEffect } from "react";
import SearchBar from "../../components/SearchBar";
import { MdDateRange } from "react-icons/md";
import SinglePost from "./SinglePost";
import HeaderAllPost from "../HeaderAllPost";
import { getReportedPosts } from "../../services/allCommunityApi";
import { DeleteReportedPost } from "../../services/allCommunityApi";

const ReportedPost = () => {
    const [post, setPost] = useState([]);

    const ReportedPost = async () => {
        const response = await getReportedPosts();
        setPost(response.data.data);
    };
    const getDeleteReportedPost = async (id) => {
        const response = await DeleteReportedPost(post.id);
       
    };

    useEffect(() => {
        ReportedPost();
    }, []);
    return (
        <div
            className="container bg-white"
            style={{ borderTopLeftRadius: "50px" }}
        >
            <div className="d-flex justify-content-center mt-5">
                <div style={{ marginRight: "15px" }}>
                    <SearchBar />
                </div>
                <button
                    style={{
                        padding: "0px 29px 0px 16px",
                        border: "2px solid #D65400",
                        borderRadius: "8px",
                        background: "#FFE3D2",
                        color: "#D65400",
                    }}
                >
                    <MdDateRange /> Filter
                </button>
            </div>

            <div className="d-flex justify-content-center align-items-center flex-column mt-5 lllll">
                <HeaderAllPost />
                {post.map((item, index) => (
                    <SinglePost
                        item={item}
                        key={item.id}
                        getDeleteReportedPost={getDeleteReportedPost}
                    />
                ))}
            </div>
        </div>
    );
};

export default ReportedPost;
