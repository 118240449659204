import React from "react";

import LeftNav from "../../components/leftNav";
import TopNav from "../../components/topNav";
import AddGuard from "./AddGuard";

export default function AddGuardMain() {
  return (
    <>
      <div className="top-level-container">
        <AddGuard />
      </div>
    </>
  );
}
