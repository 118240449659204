import { BoxContainer, FormContainer, Input, SubmitButton } from "./common";
import { Marginer } from "./marginer";
import { axiosClient } from "../../services/axiosClient";
import { useRef, useState, useEffect, useContext } from "react";
import AuthContext from "../../login/AuthProvider";
import { Navigate, useNavigate } from "react-router-dom";
import usePasswordToggle from "../../login/usePasswordToggle";
import { toast } from "react-toastify";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Loader from "../Loader";
export function LoginForm() {
  const { setAuth, auth } = useContext(AuthContext);
  const userRef = useRef();
  const errRef = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [handleLoader, setHandleLoader] = useState(false);
  const [PasswordInputType, setPasswordInputType] = useState(false);
  useEffect(() => {
    userRef.current.focus();
  }, []);
  useEffect(() => {
    setErrMsg("");
  }, [username, password]);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setHandleLoader(true);
    try {
      const response = await axiosClient.post(`admins/login`, {
        username,
        password,
      });
    
      if (response.data.status !== "fail") {
        const accessToken = response?.data?.data?.token;
        const roles = response?.data?.a_t;
        setAuth({ username, password, accessToken, roles });
        localStorage.setItem("accessToken", accessToken);
        setUsername("");
        setPassword("");
        navigate("/dashboard");
        setHandleLoader(false);
        toast.success("Login successfully");
      } else {
        toast.error(response.data.msg);
        setHandleLoader(false);
        navigate("/");
      }
    } catch (error) {}
  };
  const handlePassword = () => {
    setPasswordInputType(!PasswordInputType);
  };
  return (
    <>
      {handleLoader && <Loader />}
      <BoxContainer>
        <FormContainer>
          <Input
            type="email"
            placeholder="Email"
            id="username"
            ref={userRef}
            onChange={(e) => setUsername(e.target.value)}
            value={username}
            required
            style={{
              height: "50px",
              border: "none",
              backgroundColor: "#E8F0FE",
              borderRadius: "5px",
            }}
          />
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "#E8F0FE",
              marginTop: "10px",
              paddingRight: "10px",
              height: "50px",
              borderRadius: "5px",
            }}
          >
            <Input
              type={PasswordInputType ? "text" : "password"}
              placeholder="Password"
              style={{
                border: "none",
                backgroundColor: "#E8F0FE",
              }}
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
            <span onClick={handlePassword}>
              {PasswordInputType ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
            </span>
          </div>
        </FormContainer>
        <Marginer direction="vertical" margin={10} />
        <Marginer direction="vertical" margin="1.6em" />
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <SubmitButton type="submit" onClick={handleSubmit}>
          Sign-in
        </SubmitButton>
        <Marginer direction="vertical" margin="1em" />
      </BoxContainer>
    </>
  );
}
