import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Textfiled from "../../components/Textfiled";
import DumpImage from "../../Helpline/createHelpLine/DumpImage";
import DumpImageUi from "../../Helpline/DumpImageUi";
import { createCategory } from "../../Redux/CategorySlice";
import Image from "../../StaffDetail/assets/gallery.png";
import CancelIcon from "@mui/icons-material/Cancel";
import { categorySchema } from "../../components/AuthSchema";
import { yupResolver } from "@hookform/resolvers/yup";

const AddCategory = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name_en: "",
      image: "",
    },
    mode: "onChange",
    resolver: yupResolver(categorySchema),
  });
  const [image] = watch(["image"]);
  const onSubmit = (data) => {
    const payload = {
      ...data,
      name_ar: data.name_en,
    };
    dispatch(createCategory({ navigate, payload }));
  };
  return (
    <Box className="top-level-container">
      {/* {handleLoader && <Loader />} */}
      <Box className="home">
        <Box>
          <h4>Add Category</h4>
        </Box>
        <Box display="flex" marginTop="30px">
          <DumpImage {...{ handleClose, open, setValue }}>
            <DumpImageUi {...{ setValue, handleClose }} />
          </DumpImage>
          <Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              bgcolor="transparent"
              borderRadius={1}
              style={{ border: "1px dashed #dfe0e0", cursor: "pointer" }}
              p={2.2}
              width="13rem"
              onClick={handleClickOpen}
            >
              <img
                alt=""
                src={Image}
                style={{ height: "4rem", width: "4rem", padding: "" }}
              />
              <Typography my={2}>
                <AddIcon /> Add Image
              </Typography>
            </Box>
            <Typography
              sx={{
                fontStyle: "italic",
                fontSize: "13px",
                color: "red",
              }}
            >
              {errors?.image?.message && "Please select a image"}
            </Typography>
          </Box>
          <Box>
            {image?.length > 0 && (
              <Box position="relative" width="10rem" height="10rem">
                <img
                  style={{ height: "100%", width: "100%", marginLeft: "15px" }}
                  alt="..."
                  src={image}
                  name="images"
                />

                <CancelIcon
                  style={{
                    position: "absolute",
                    right: "-10px",
                    top: "5px",
                    color: "#000",
                  }}
                  onClick={() => setValue("image", "")}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ marginTop: "40px" }}>
          <Textfiled
            label={"Category Name*"}
            inputProps={{ maxLength: 50 }}
            type="text"
            width="40%"
            name="name_en"
            register={register}
          />
          <Typography
            sx={{
              fontStyle: "italic",
              fontSize: "13px",
              color: "red",
            }}
          >
            {errors?.name?.message}
          </Typography>
        </Box>
        <Box>
          <button
            type="submit"
            id="savebtn"
            className="Button"
            variant="contained"
            color="success"
            style={{
              border: "1px solid #0C344E",
              color: "#0C344E",
              background: "#0c344e",
              width: "187px",
              height: "48px",
              fontFamily: "Barlow",
              fontSize: "16px",

              marginTop: "20px",
              borderRadius: "10px",
              textTransform: "capitalize",
              color: "white",
            }}
            onClick={handleSubmit(onSubmit)}
          >
            Add Category
          </button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddCategory;
