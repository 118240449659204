import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import { useRef } from "react";
import image from "../StaffDetail/assets/gallery.png";

const OpenGallery = ({
  setGetImageData,
  setGetLength,
  showAddDocument,
  getImageDataDoc,
  accept,
}) => {
  const ref = useRef();

  const handleImageData = async (event) => {
    const fileListArray = event.target.files;
    const formData = new FormData();

    for (let i = 0; i < fileListArray.length; i++) {
      formData.append("file", fileListArray[i]);
    }
    setGetImageData(formData);
    setGetLength(formData.getAll("file"));
    event.target.value = null;
  };

  return (
    <Box my={2} style={{ position: "relative" }}>
      <input
        type="file"
        ref={ref}
        style={{ display: "none" }}
        accept={".png,.jpeg,.jpg,.webp,.svg"}
        multiple
        onChange={(event) => handleImageData(event)}
      />
      {showAddDocument ? (
        <Typography
          onClick={() => ref.current.click()}
          style={{ cursor: "pointer", textAlign: "center" }}
        >
          Add Document
        </Typography>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          bgcolor="transparent"
          borderRadius={1}
          style={{ border: "1px dashed #dfe0e0", cursor: "pointer" }}
          p={2.2}
          width="13rem"
          onClick={() => ref.current.click()}
        >
          <img
            alt=""
            src={image}
            style={{ height: "4rem", width: "4rem", padding: "" }}
          />
          <Typography my={2}>
            <AddIcon /> Add Image
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(OpenGallery);
