import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import BuildingDropDown from "../components/BuildingDropDown";
import Loader from "../components/Loader";
import Textfiled from "../components/Textfiled";
import { buildingContext } from "../login/AuthProvider";
import OpenDoc from "../PropertyName/OpenDoc";
import { addAsset } from "../Redux/AssetSlice";
import { getflatDetail } from "../services/allCommunityApi";
import { getAllBuilding, uploadFile } from "../services/allPropertiesAPI";
import pdfPhoto from "../StaffDetail/assets/pdfPhoto.png";

const AddAsset = () => {
  const [buildingDataList, setBuildingDataList] = useState([]);
  const [units, setUnits] = useState([]);
  const [addNewCategory, setAddNewCategory] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedBuilding } = React.useContext(buildingContext);
  const [isBuilding, setIsBuilding] = useState(false);
  const [documentUrl, setDocumentUrl] = useState([]);
  const [getLengthDoc, setGetLengthDoc] = useState();
  const [getImageDataDoc, setGetImageDataDoc] = useState([]);
  const [handleLoader, setHandleLoader] = useState(false);
  const [searchText, setSearchText] = useState("");

  const ASSET_CONDITIONS = ["Working", "Maintenance", "Decomissioned"];
  const ASSET_CATEGORY = [
    "Cutlery/Kitchen Items",
    "Fixtures",
    "Furnitures",
    "Machinery",
  ];

  const assetSchema = yup.object().shape({
    name: yup.string().trim().required("This field  is required"),
    buildingId: yup.string().required("This field  is required"),
    category: yup.string().trim().required("This field  is required"),
    condition: yup.string().required("This field  is required"),
    ...(isBuilding && {
      flatId: yup.string().required("This field  is required"),
    }),
    ...(!isBuilding && {
      floor: yup.string().trim().required("This field  is required"),
    }),
    ...(!isBuilding && {
      location: yup.string().trim().required("This field  is required"),
    }),
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      buildingId: "",
      category: "",
      flatId: "",
      condition: "",
      floor: "",
      location: "",
      brand: "",
      model: "",
      description: "",
    },
    mode: "onChange",
    resolver: yupResolver(assetSchema),
  });

  const handleValue = (name, event) => {
    let newValue = event.target.value;
    if (newValue.length > 1) {
      newValue = newValue.slice(0, 15); // restrict to 3 digits
    }
    setValue(`${name}`, newValue);
  };

  const handleChange = (event) => {
    setIsBuilding(event.target.checked);
  };

  const [buildingId] = watch(["buildingId"]);

  const fetchAllBuildingDataList = async () => {
    let buildingFromStorage = sessionStorage.getItem("building");
    if (buildingFromStorage?.length > 0) {
      const resp = await getAllBuilding();
      let specificBuilding = resp?.data?.data?.rows?.filter(
        (building) => building.id === buildingFromStorage
      );

      setBuildingDataList(specificBuilding);
    } else {
      const resp = await getAllBuilding();

      setBuildingDataList(resp.data.data.rows);
    }
  };

  const FetchUnitData = async (id) => {
    const resp = await getflatDetail(id);
    setUnits(resp.data?.data?.rows);
  };

  const handleRemoveDoc = (att) => {
    const filtered = documentUrl.filter((item) => item !== att);
    setDocumentUrl(filtered);
  };

  const getDocUrl = async () => {
    setHandleLoader(true);
    try {
      const res = await uploadFile(getImageDataDoc);
      if (res.data.status === "success") {
        const getUrl = res.data.data.map((current) => current.location);
        setDocumentUrl([...documentUrl, ...getUrl]);
      } else {
        // toast("Document is not uploaded. Please check your connection");
      }
    } catch {}
    setHandleLoader(false);
  };

  useEffect(() => {
    if (getLengthDoc) {
      getDocUrl();
    }
  }, [getLengthDoc]);

  useEffect(() => {
    FetchUnitData(selectedBuilding ? selectedBuilding : buildingId);
  }, [buildingId, selectedBuilding]);

  useEffect(() => {
    fetchAllBuildingDataList();
  }, [selectedBuilding]);

  const onSubmit = (data) => {
    const payload = {
      name: data?.name,
      category: data.category,
      condition: data.condition,
      ...(!isBuilding && {
        buildingId: data.buildingId ? data.buildingId : null,
      }),
      ...(isBuilding && {
        flatId: data.flatId ? data.flatId : null,
      }),
      ...(!isBuilding && {
        floor: data.floor ? data.floor : null,
      }),
      ...(!isBuilding && {
        location: data.location ? data.location : null,
      }),
      brand: data.brand,
      model: data.model,
      description: data.description,
      documents: documentUrl,
    };
    dispatch(addAsset({ payload, navigate }));
  };
  return (
    <Box className="top-level-container">
      {handleLoader && <Loader />}
      <Box className="home">
        <Box>
          <h4>Add Asset</h4>
        </Box>
        <Box sx={{ marginTop: "50px", display: "flex" }}>
          <Typography>Asset for Building</Typography>
          <Switch
            size="small"
            name="isBuilding"
            checked={isBuilding}
            onChange={handleChange}
            inputProps={{
              "aria-label": "controlled",
            }}
          />
          <Typography>Asset for Flat</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Box marginTop="30px">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box>
                  <BuildingDropDown
                    displayedOptions={buildingDataList}
                    name="buildingId"
                    register={register}
                    label="Select Property Name*"
                    width="300px"
                    innerWidth="300px"
                    buildingId={buildingId}
                    selectedBuilding={selectedBuilding}
                  />
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "red",
                    }}
                  >
                    {errors?.buildingId?.message && "This field is required"}
                  </Typography>
                </Box>

                <Box sx={{ marginTop: "-13px" }}>
                  <Textfiled
                    label={"Name*"}
                    type="text"
                    width="300px"
                    name="name"
                    maxLength="35"
                    setValue={setValue}
                    register={register}
                  />
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "red",
                    }}
                  >
                    {errors?.name?.message && "This field is required"}
                  </Typography>
                </Box>
              </Box>
              {isBuilding && (
                <Box>
                  <Box marginTop="30px">
                    <FormControl fullWidth>
                      <InputLabel
                        id="search-select-label"
                        sx={{ marginLeft: "-14px" }}
                      >
                        Select Unit*
                      </InputLabel>
                      <Select
                        MenuProps={{ autoFocus: false }}
                        sx={{ width: "680px" }}
                        labelId="search-select-label"
                        id="search-select"
                        variant="standard"
                        {...register("flatId")}
                        name="flatId"
                        label={"Select Unit"}
                        onClose={() => setSearchText("")}
                      >
                        <ListSubheader>
                          <TextField
                            size="small"
                            variant="standard"
                            placeholder="Type to search..."
                            sx={{
                              width: "560px",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        {units
                          ?.filter((option) =>
                            option?.name_en
                              ?.toLowerCase()
                              .includes(searchText.toLowerCase())
                          )
                          .map((option, i) => (
                            <MenuItem key={option?.id} value={option?.id}>
                              {option?.name_en}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <Typography
                      sx={{
                        fontStyle: "italic",
                        fontSize: "13px",
                        color: "red",
                      }}
                    >
                      {errors?.flatId?.message && "This field is required"}
                    </Typography>
                  </Box>
                </Box>
              )}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "40px",
                }}
              >
                <Box>
                  <TextField
                    select
                    label="Condition*"
                    inputProps={{ maxLength: 100 }}
                    multiline
                    maxRows={4}
                    maxLength={10}
                    {...register("condition")}
                    name="condition"
                    variant="standard"
                    style={{
                      fontFamily: "Barlow",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      width: "300px",
                      lineHeight: "19px",
                    }}
                  >
                    {ASSET_CONDITIONS?.map((option) => {
                      return (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "red",
                    }}
                  >
                    {errors?.condition?.message && "This field is required"}
                  </Typography>
                </Box>
                <Box>
                  {addNewCategory ? (
                    <>
                      <Box marginTop="-12px">
                        <Textfiled
                          label="Category*"
                          type="text"
                          width="300px"
                          name="category"
                          maxLength="25"
                          register={register}
                          autofocus={true}
                        />
                        <Typography
                          sx={{
                            fontStyle: "italic",
                            fontSize: "13px",
                            color: "red",
                          }}
                        >
                          {errors?.category?.message &&
                            "This field is required"}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <>
                      <TextField
                        select
                        label="Category*"
                        inputProps={{ maxLength: 50 }}
                        multiline
                        maxRows={4}
                        maxLength={10}
                        name="category"
                        {...register("category")}
                        variant="standard"
                        style={{
                          fontFamily: "Barlow",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "16px",
                          width: "300px",
                          lineHeight: "19px",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            setAddNewCategory(true);
                          }}
                        >
                          + Add New
                        </MenuItem>
                        {ASSET_CATEGORY?.map((option) => {
                          return (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                      <Typography
                        sx={{
                          fontStyle: "italic",
                          fontSize: "13px",
                          color: "red",
                        }}
                      >
                        {errors?.category?.message && "This field is required"}
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
              {!isBuilding && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "40px",
                    }}
                  >
                    <Box>
                      <Textfiled
                        label={"Floor*"}
                        type="text"
                        width="300px"
                        name="floor"
                        maxLength="50"
                        register={register}
                      />
                      <Typography
                        sx={{
                          fontStyle: "italic",
                          fontSize: "13px",
                          color: "red",
                        }}
                      >
                        {errors?.floor?.message && "This field is required"}
                      </Typography>
                    </Box>
                    <Box>
                      <Textfiled
                        label={"Location*"}
                        type="text"
                        width="300px"
                        name="location"
                        maxLength="50"
                        register={register}
                      />
                      <Typography
                        sx={{
                          fontStyle: "italic",
                          fontSize: "13px",
                          color: "red",
                        }}
                      >
                        {errors?.location?.message && "This field is required"}
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "40px",
                }}
              >
                <Box>
                  <Textfiled
                    label={"Brand"}
                    type="text"
                    width="300px"
                    name="brand"
                    maxLength="50"
                    register={register}
                  />
                </Box>
                <Box>
                  <Textfiled
                    label={"Model"}
                    type="text"
                    width="300px"
                    name="model"
                    maxLength="50"
                    register={register}
                  />
                </Box>
              </Box>
              <Box width="100%">
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      {...field}
                      aria-label="minimum height"
                      minRows={2}
                      maxLength={300}
                      placeholder="Description"
                      style={{
                        width: "100%",
                        minHeight: "50px",
                        marginTop: "20px",
                        paddingLeft: "10px",
                        borderRadius: "10px",
                      }}
                    />
                  )}
                />
              </Box>
              <Box>
                <button
                  type="submit"
                  id="savebtn"
                  className="Button"
                  variant="contained"
                  color="success"
                  style={{
                    border: "1px solid #0C344E",
                    color: "#0C344E",
                    background: "#0c344e",
                    width: "187px",
                    height: "48px",
                    fontFamily: "Barlow",
                    fontSize: "16px",

                    marginTop: "20px",
                    borderRadius: "10px",
                    textTransform: "capitalize",
                    color: "white",
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Add Asset
                </button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ marginTop: "30px" }}>
              <Typography textAlign="center" fontSize="20px">
                Attach Document
              </Typography>
              <OpenDoc
                setGetImageDataDoc={setGetImageDataDoc}
                setGetLengthDoc={setGetLengthDoc}
              />
              {documentUrl.length > 0 &&
                documentUrl?.map((curElem, id) => (
                  <Box
                    className="card"
                    display="flex"
                    justifyContent="space-between"
                    p={2}
                    m={2}
                    style={{
                      boxShadow: " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                      borderStyle: "none",
                    }}
                    width="18rem"
                  >
                    <Box display="flex" justifyContent="space-between">
                      <Typography>
                        <img
                          src={pdfPhoto}
                          style={{ marginRight: "10px" }}
                          alt=""
                        />
                        {curElem?.split("/")[3]?.split(".")[0]?.slice(0, 10)}
                      </Typography>
                      <Typography>
                        <a
                          download
                          href={curElem}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <RemoveRedEyeIcon />
                        </a>
                        <CloseIcon onClick={() => handleRemoveDoc(curElem)} />
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AddAsset;
