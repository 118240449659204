import { TextField } from "@mui/material";
import React from "react";

const InputText = ({ label, name, fieldName, register, width }) => {
  return (
    <TextField
      id="standard-multiline-flexible"
      label={label}
      type={"number"}
      inputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*",
      }}
      InputProps={{
        inputProps: { min: 0 },
      }}
      {...register(name)}
      // onChange={}
      variant="standard"
      style={{
        fontFamily: "Barlow",
        width: `${width}`,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19px",
        marginLeft: "-0.1rem",
      }}
    />
  );
};

export default InputText;
