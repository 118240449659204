import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import DumpImageUi from "../../Helpline/DumpImageUi";
import DumpImage from "../../Helpline/createHelpLine/DumpImage";
import { updateCategory } from "../../Redux/CategorySlice";
import Image from "../../StaffDetail/assets/gallery.png";
import Textfiled from "../../components/Textfiled";

const EditCategory = ({ categoryDetail, setOpenModel }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { isSuccessEdit } = useSelector((state) => state.CategoryData);
  const [handleJan, setHandleJan] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name_en: categoryDetail.name_en,
      image: categoryDetail.image,
    },
    mode: "onChange",
    // resolver: yupResolver(categorySchema),
  });

  const [image] = watch(["image"]);
  const onSubmit = (data) => {
    setOpenModel(false);
    const categoryId = categoryDetail?.id;
    const data1 = {
      name_en: data.name_en,
      name_ar: data.name_en,
      image: data.image,
    };
    dispatch(updateCategory({ categoryId, data1 }));
    if (isSuccessEdit) {
      setOpenModel(false);
    }
  };
  return (
    <Box>
      <Box sx={{ padding: "10px 200px 20px 20px" }}>
        <Box sx={{ padding: "0px 0px 20px 0px" }}>
          <Typography variant="h5">Edit Category</Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            bgcolor="transparent"
            borderRadius={1}
            style={{ border: "1px dashed #dfe0e0", cursor: "pointer" }}
            p={2.2}
            width="13rem"
            onClick={handleClickOpen}
          >
            <img
              alt=""
              src={Image}
              style={{ height: "4rem", width: "4rem", padding: "" }}
            />
            <Typography my={2}>
              <AddIcon /> Add Image
            </Typography>
          </Box>
          <Box>
            {image?.length > 0 && (
              <Box position="relative" width="10rem" height="10rem">
                <img
                  style={{ height: "100%", width: "100%", marginLeft: "15px" }}
                  alt="..."
                  src={image}
                  name="images"
                />

                <CancelIcon
                  style={{
                    position: "absolute",
                    right: "-10px",
                    top: "5px",
                    color: "#000",
                  }}
                  onClick={() => setValue("image", "")}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box paddingTop="8px">
          <Textfiled
            label={"Category Name*"}
            inputProps={{ maxLength: 50 }}
            type="text"
            width="350px"
            name="name_en"
            register={register}
          />
        </Box>
        <Box>
          <Button
            type="submit"
            id="savebtn"
            className="Button"
            variant="contained"
            color="success"
            style={{
              border: "1px solid #0C344E",
              color: "#0C344E",
              background: "#0c344e",
              width: "187px",
              height: "48px",
              fontFamily: "Barlow",
              fontSize: "16px",
              marginTop: "20px",
              borderRadius: "10px",
              textTransform: "capitalize",
              color: "white",
            }}
            onClick={handleSubmit(onSubmit)}
          >
            Edit Category
          </Button>
        </Box>
        <Typography
          sx={{
            fontStyle: "italic",
            fontSize: "13px",
            color: "red",
          }}
        >
          {/* {errors?.image?.message && "Please select a image"} */}
        </Typography>
      </Box>
      <DumpImage {...{ handleClose, open, setValue }}>
        <DumpImageUi {...{ setValue, handleClose }} />
      </DumpImage>
    </Box>
  );
};

export default EditCategory;
