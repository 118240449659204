import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { AllUnitAPI, getDeleteFlat } from "../services/allPropertiesAPI";
import "../App.css";
import Loader from "./Loader";
import { buildingContext } from "../login/AuthProvider";
import { showDash } from "../util";
import NoDataFound from "./NoDataFound";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import ConfirmBox from "./ConfirmBox";
import { toast } from "react-toastify";

export default function UnitTable({
  searchText,
  postPerpage,
  currentPage,
  setcurrentPage,
  totalCount,
  settotalCount,
  setUnit,
  units,
  csvimport,

  setCsvimport,
}) {
  const { selectedBuilding } = React.useContext(buildingContext);
  const [loader, setLoader] = useState(false);
  const [showtotalData, setshowtotalData] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [flatId, setFlatId] = useState("");
  // const [getDelete, setGetDelete] = useState(false);
  const header = [
    "Number",
    "Property Name",
    "Floor",
    "Size",
    "Status",
    "Owner Name",
    "Bedroom",
    "Bathroom",
    "Action",
  ];
  let buildingId = selectedBuilding;

  const UnitData = async () => {
    setLoader(true);
    const resp = await AllUnitAPI(
      currentPage,
      postPerpage,
      searchText,
      buildingId
    );

    let totalData = resp.data.data.count;

    setshowtotalData(totalData);
    let pagesCount = Math.ceil(totalData / postPerpage);
    settotalCount(pagesCount);
    setUnit(resp.data.data.rows);
    setLoader(false);
  };

  useEffect(() => {
    UnitData();
  }, [currentPage, csvimport]);

  useEffect(() => {
    UnitData();
  }, [buildingId]);

  const [handleNoData, setHandleNoData] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setHandleNoData(true);
    }, 1000);
  }, []);

  const navigate = useNavigate();
  const handleNavigate = (id, buildingId) => {
    navigate(`/unitdetail/${id}`, {
      state: { buildingId },
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirmBox = () => {
    setOpenConfirm(false);
  };

  const deleteUnit = async () => {
    const resp = await getDeleteFlat(flatId);
    if (resp.data.status == "fail") {
      toast.error(resp.data.msg);
    } else {
      toast.success("Flat delete successfully");
      UnitData();
    }
  };

  return (
    <>
      {units.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            {loader && <Loader />}
            <Table sx={{}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {header.map((h) => (
                    <TableCell
                      key={h.id}
                      align="center"
                      className="fw-bold"
                      style={{
                        backgroundColor: "#FFF7F3",
                      }}
                    >
                      {h}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {units.map((row) => (
                  <TableRow
                    key={row.id}
                    className="rowTable"
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                    onClick={() => {
                      setFlatId(row?.id);
                    }}
                  >
                    <TableCell align="center" padding="7px">
                      <p
                        onClick={() => handleNavigate(row?.id, row?.buildingId)}
                        style={{
                          textDecoration: "underline",
                          color: "blue",
                          cursor: "pointer",
                        }}
                      >
                        {showDash(row.name_en)}
                      </p>
                    </TableCell>
                    <TableCell align="center" padding="7px">
                      {row.building?.name_en?.length > 15
                        ? row.building.name_en.slice(0, 12) + "..."
                        : row.building.name_en}
                    </TableCell>
                    <TableCell align="center" padding="7px">
                      <span>{showDash(row.floor)}</span>
                    </TableCell>
                    <TableCell align="center" padding="7px">
                      <span className="floorCatagory">
                        {row.size}
                        {row.size ? "sqft" : "-"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      {row.contractDetails.length > 0 &&
                      !row.contractDetails[0].isExpired ? (
                        <span
                          style={{
                            background: " #C9FFE1",
                            padding: "5px 13px",
                            color: "#009944 ",
                            borderRadius: "5px",
                          }}
                        >
                          Occupied
                        </span>
                      ) : (
                        <span
                          style={{
                            background: "#EFF0F6",
                            padding: "5px 13px",
                            color: "#0C344E",
                            borderRadius: "5px",
                          }}
                        >
                          Vacant
                        </span>
                      )}
                    </TableCell>
                    <TableCell align="center" padding="7px">
                      {showDash(row?.owner?.name)}
                    </TableCell>
                    <TableCell align="center" padding="7px">
                      {showDash(row?.flatInfo?.bedroom)}
                    </TableCell>
                    <TableCell align="center" padding="7px">
                      {showDash(row?.flatInfo?.bathroom)}
                    </TableCell>
                    <ConfirmBox {...{ openConfirm, handleCloseConfirmBox }}>
                      <Typography variant="h6">
                        Are you sure, You want to delete ?
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          columnGap: "20px",
                          margin: "30px 0px 0px 15px",
                        }}
                      >
                        <Button
                          sx={{
                            textTransform: "capitalize",
                            border: "1px solid #0c344e",
                            color: "#0c344e",
                          }}
                          onClick={handleCloseConfirmBox}
                        >
                          No
                        </Button>
                        <Button
                          onClick={(e) => {
                            deleteUnit();
                            setOpenConfirm(false);
                            e.stopPropagation();
                          }}
                          variant="standard"
                          sx={{
                            textTransform: "capitalize",
                            border: "1px solid #0c344e",
                            color: "white",
                            background: "#0c344e",
                            "&:hover": {
                              backgroundColor: "white",
                              color: "#0c344e",
                            },
                          }}
                        >
                          Yes
                        </Button>
                      </Box>
                    </ConfirmBox>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleNavigate(flatId);
                        }}
                      >
                        Update
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          handleClose();
                          handleOpenConfirm();
                          e.stopPropagation();
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                    <TableCell align="center" padding="7px">
                      <MoreVertIcon onClick={handleClick} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex justify-content-between height-200px mt-2">
            <div>
              <p>
                Showing {postPerpage * (currentPage - 1) + 1}
                &nbsp;-
                {currentPage * postPerpage > showtotalData
                  ? showtotalData
                  : currentPage * postPerpage}{" "}
                out of {showtotalData} entries
              </p>
            </div>
            <div>
              <Pagination
                count={totalCount}
                onChange={(event, value) => {
                  setcurrentPage(value);
                  localStorage.setItem("unitPage", value);
                }}
                page={Number(currentPage)}
                shape="rounded"
                style={{
                  margin: "10px",
                  float: "right",
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center mt-5">
          <p className="nofound">{handleNoData && <NoDataFound />}</p>
        </div>
      )}
    </>
  );
}
