import { useEffect, useState } from "react";
import "./index.css";
import SearchBar from "../../components/SearchBar";
import { AiOutlinePlus } from "react-icons/ai";
import SelectInput from "./SelectInput";
import { TextField } from "@mui/material";
import Textfiled from "../../components/Textfiled";
import { InputTextArea } from "../../components/InputTextArea";
import { Link, Navigate } from "react-router-dom";
import { axiosClient } from "../../services/axiosClient";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import { toast } from "react-toastify";

const CreateInvoice = () => {
  const [bulk, setbulk] = useState(true);

  const handleSearchVisible = () => {
    setbulk(false);
  };
  const handleSearchHidden = () => {
    setbulk(true);
  };

  // Charge Type API
  const [chargesType, setChargesType] = useState([]);
  const [value, setValue] = useState("");

  const getChargesTypes = async () => {
    try {
      const resp = await axiosClient.get(
        `maintenance-catalogues/charges/types`
      );
      const result = await resp.data.data;
      setChargesType(result);
    } catch (error) {}
  };

  useEffect(() => {
    getChargesTypes();
    getchargeType();
  }, []);

  // Units Api
  const [units, setUnits] = useState(null);

  const [date, setDate] = useState("");
  let formatedDate = moment(date).format("DD/MM/YYYY");

  const [charge, setCharge] = useState();

  const sendInvisualData = async () => {
    try {
      const resp = await axiosClient.get("/maintenance-catalogues/charges");
      const result = await resp.data.data;
    } catch (error) {}
  };

  useEffect(() => {
    sendInvisualData();
  }, []);

  //!Get Charge Type
  const [chargeType, setChargeType] = useState([]);
  const [chargeTypeValue, setChargeTypeValue] = useState("");
  const getchargeType = () => {
    axiosClient
      .get("/charges/types")
      .then((result) => {
        setChargeType(result.data.data);
      })
      .catch((err) => {});
  };

  // !GET FLAT ID
  let buildingId = localStorage.getItem("buildingId");
  const [flatID, setFlatId] = useState([]);
  const getFlatID = () => {
    axiosClient
      .get(`/flats?buildingId=${buildingId}&size=${units}`)
      .then((result) => {
        setFlatId(result.data.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getFlatID();
  }, [units]);

  //Individual App Data Post
  const [description, setDescription] = useState("");
  const sendCharges = async () => {
    try {
      await axiosClient.post("charges", {
        amount: charge,
        currency: "aed",
        chargeType: chargeTypeValue,
        description: description,
        dueDate: formatedDate,
        flatId: value,
      });
    } catch (error) {}
  };

  //Send Bulk Data
  const sendChargesBulk = async () => {
    try {
      await axiosClient.post("charges/bulk", {
        amount: charge,
        currency: "aed",
        chargeType: chargeTypeValue,
        description: description,
        dueDate: formatedDate,
      });
      toast.success("Bulk invoice created successfully");
    } catch (error) {
      toast.error("Error creating bulk invoice");
    }
  };

  return (
    <>
      <div
        className="container bg-white"
        style={{ borderTopLeftRadius: "50px" }}
      >
        <div className="p-4">
          <div className="d-flex justify-content-between">
            <h5>Create Invoice</h5>
            <button className="Cinvoice">Cancel</button>
          </div>
          <div className="d-flex gap-3 mt-4">
            <button
              className={bulk ? "individual-btn-active" : "individual"}
              onClick={handleSearchHidden}
            >
              Individual
            </button>
            <button
              className={bulk ? "bulk-btn-active" : "bulk"}
              onClick={handleSearchVisible}
            >
              Bulk
            </button>
          </div>

          <div className="d-flex gap-5">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-start gap-5 mt-5">
                {bulk && (
                  <div className="Sinvoice ">
                    <SearchBar placeholder="Search User Name or Unit Number" />
                    <div className="d-flex justify-content-start align-items-center gap-2 mt-3">
                      OR <div className="line"></div>
                    </div>
                    <div></div>
                  </div>
                )}
              </div>
              <div className="rform">
                <div className="d-flex justify-content-between mt-4">
                  {/* <SelectInput label="Select Property" /> */}
                  {/* <SelectInput label="Unit Category" /> */}
                  <select
                    value={units}
                    onChange={(e) => setUnits(e.target.value)}
                    style={{
                      width: "280px",
                      height: "50px",
                      border: ".5px solid #DFE0E0",
                    }}
                  >
                    <option>Unit Category</option>
                    <option>1 BHK</option>
                    <option>2 BHK</option>
                    <option>3 BHK</option>
                    <option>4 BHK</option>
                    <option>5 BHK</option>
                    <option>Studio</option>
                  </select>
                </div>
                <div className="d-flex justify-content-between mt-4">
                  <select
                    style={{
                      width: "280px",
                      height: "50px",
                      marginTop: "41px",
                      border: ".5px solid #DFE0E0",
                    }}
                    onChange={(e) => setValue(e.target.value)}
                  >
                    <option>Select Unit</option>
                    {flatID?.map((item) => (
                      <option key={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                  {/* <Textfiled /> */}
                  <div>
                    <input
                      type="date"
                      style={{
                        width: "280px",
                        marginTop: "50px",
                        height: "40px",
                      }}
                      onChange={(e) => setDate(e.target.value)}
                      value={date}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-4">
                  {/* <SelectInput label="Charges Category" /> */}
                  <select
                    style={{
                      width: "280px",
                      height: "50px",
                      marginTop: "41px",
                      border: ".5px solid #DFE0E0",
                    }}
                    onChange={(e) => setChargeTypeValue(e.target.value)}
                  >
                    <option>Charges Category</option>
                    {chargeType?.map((item) => (
                      <option key={item?.type} value={item?.key}>
                        {item?.type}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    value={charge}
                    onChange={(e) => setCharge(e.target.value)}
                    name="amount"
                    placeholder="amount"
                    style={{
                      width: "280px",
                      marginTop: "40px",
                      height: "40px",
                    }}
                  />
                </div>
              </div>
              <div className="mt-3">
                <InputTextArea
                  placeholder="Add a note"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="Gsection mt-3">
            <button
              className="Ginvoicebtn"
              onClick={bulk ? sendCharges : sendChargesBulk}
            >
              Generate Invoice
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateInvoice;
