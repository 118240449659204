import AddIcon from "@mui/icons-material/Add";
import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdDownloadForOffline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import DocImage from "../../assets/DocImage.png";
import { getLeaseDetail } from "../../Redux/LeaseSlice";
import AvoidLease from "./AvoidLease";
import EditLease from "./EditLease";
import { getDate, showDash } from "../../util";

const LeaseDetailAndEdit = () => {
  const [handleEditInformation, setHandleEditInformation] = useState(false);
  const [handleEditDocMode, setHandleEditDocMode] = useState(false);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { leaseDetail, isAvoid } = useSelector((state) => state.LeaseData);

  useEffect(() => {
    dispatch(getLeaseDetail(id));
  }, [isAvoid]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNavigate = (id, buildingId) => {
    navigate(`/unitdetail/${id}`, {
      state: { buildingId },
    });
  };

  return (
    <div className="top-level-container">
      <div className="home">
        <AvoidLease {...{ open, handleClose, id }} />
        <Grid container mt={2} gap={2}>
          <Grid xs={7}>
            <Box className="card" p={2}>
              {handleEditInformation == true ? (
                <EditLease />
              ) : (
                <>
                  <Box display="flex" justifyContent="space-between">
                    <Box display="flex" columnGap="10px">
                      <Typography>
                        Lease Id:{leaseDetail?.contractId}
                      </Typography>
                      {leaseDetail?.isExpired ? (
                        <Typography
                          sx={{
                            background: "#EFF0F6",
                            padding: "1px 15px",
                            textAlign: "center",
                            borderRadius: "5px",
                            color: "#0C344E",
                          }}
                        >
                          {" "}
                          In Active
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            background: " #D7FFE9",
                            padding: "1px 15px",
                            textAlign: "center",
                            borderRadius: "5px",
                            color: "#009944",
                          }}
                        >
                          {" "}
                          Active
                        </Typography>
                      )}
                      {new Date(leaseDetail?.contractStartDate) >
                        Date.now() && (
                        <Typography
                          sx={{
                            background: "#eff0f6",
                            padding: "1px 15px",
                            textAlign: "center",
                            borderRadius: "5px",
                            color: "#0c344e",
                          }}
                        >
                          Future
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      {leaseDetail?.isExpired ? (
                        ""
                      ) : (
                        <Link to="#" onClick={handleClickOpen}>
                          Cancel Lease
                        </Link>
                      )}
                    </Box>
                  </Box>
                  <Box my={4}>
                    <Typography color="#111120" fontSize={20}>
                      Lease Details
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Building
                      </Typography>
                      <Typography>
                        {leaseDetail?.flat?.building?.name_en}
                      </Typography>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      bgcolor="#FAFAFA"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Unit No
                      </Typography>
                      <Typography
                        onClick={() =>
                          handleNavigate(
                            leaseDetail?.flatId,
                            leaseDetail?.flat?.building?.id
                          )
                        }
                      >
                        <Link to="#">{leaseDetail?.flat?.name_en}</Link>
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Status
                      </Typography>
                      <Typography>
                        {leaseDetail?.isExpired ? "InActive" : "Active"}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      bgcolor="#FAFAFA"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Owner
                      </Typography>
                      {leaseDetail?.flat?.owner?.id ? (
                        <Link
                          to={`/userdetail/${leaseDetail?.flat?.owner?.id}`}
                        >
                          <Typography align="center">
                            {leaseDetail?.flat?.owner?.name
                              ? leaseDetail?.flat?.owner?.name
                              : "-"}
                          </Typography>
                        </Link>
                      ) : (
                        <>-</>
                      )}
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Tenant
                      </Typography>
                      <Link to={`/userdetail/${leaseDetail?.resident?.id}`}>
                        <Typography>{leaseDetail?.resident?.name}</Typography>
                      </Link>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      bgcolor="#FAFAFA"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Move In Date
                      </Typography>
                      <Typography>
                        {leaseDetail?.moveInDate
                          ? leaseDetail?.moveInDate?.split("T")[0]
                          : "-"}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Move Out Date
                      </Typography>
                      <Typography>
                        {leaseDetail?.moveOutDate
                          ? leaseDetail?.moveOutDate.split("T")[0]
                          : "-"}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      bgcolor="#FAFAFA"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Contract Start Date
                      </Typography>
                      <Typography>
                        {leaseDetail?.contractStartDate.split("T")[0]}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Contract End Date
                      </Typography>
                      <Typography>
                        {leaseDetail?.contractEndDate.split("T")[0]}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      bgcolor="#FAFAFA"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Total Rent Amount
                      </Typography>
                      <Typography>
                        {leaseDetail?.contractPayments &&
                          leaseDetail?.contractPayments[0]?.amount}
                        &nbsp;
                        {leaseDetail?.currency ? leaseDetail?.currency : ""}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Government Charges
                      </Typography>
                      {leaseDetail?.activationFee ? (
                        <Typography>
                          {leaseDetail?.activationFee}&nbsp;
                          {leaseDetail?.activationFee
                            ? leaseDetail?.currency
                            : ""}
                        </Typography>
                      ) : (
                        <>-</>
                      )}
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      bgcolor="#FAFAFA"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Lease Type
                      </Typography>
                      <Typography>{leaseDetail?.flatUsage}</Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Deposit
                      </Typography>
                      <Typography>
                        {leaseDetail?.securityDeposit}&nbsp;
                        {leaseDetail?.currency ? leaseDetail?.currency : ""}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      bgcolor="#FAFAFA"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Payment frequency
                      </Typography>
                      <Typography>{leaseDetail?.paymentFrequency}</Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Notice Period
                      </Typography>
                      <Typography>
                        {showDash(leaseDetail?.noticePeriod)}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      bgcolor="#FAFAFA"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        {`Discount Detail(${
                          leaseDetail?.discount?.applicableOn || ""
                        })`}
                      </Typography>
                      <Typography>
                        {showDash(
                          leaseDetail?.discount?.applicableOn !== "Grace Period"
                            ? leaseDetail?.discount?.amount
                            : `${leaseDetail?.discount?.grace}  Months`
                        )}
                        &nbsp;
                        {leaseDetail?.discount?.amount && leaseDetail?.currency
                          ? leaseDetail?.currency
                          : ""}
                      </Typography>
                    </Box>
                    {leaseDetail?.discount?.applicableOn !== "Grace Period" && (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        my={2}
                        px={1.5}
                      >
                        <Typography color="#111120" lineHeight={2.5}>
                          {` Total ${
                            leaseDetail?.discount?.applicableOn || ""
                          } Amount After discount`}
                        </Typography>
                        <Typography>
                          {showDash(
                            leaseDetail?.discount?.applicableOn == "Rent"
                              ? leaseDetail?.contractPayments[0]?.amount -
                                  leaseDetail?.discount?.amount
                              : leaseDetail?.securityDeposit -
                                  leaseDetail?.discount?.amount
                          )}{" "}
                          {leaseDetail?.discount?.amount &&
                          leaseDetail?.currency
                            ? leaseDetail?.currency
                            : ""}
                        </Typography>
                      </Box>
                    )}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Lease Expiry Date
                      </Typography>
                      <Typography>
                        {showDash(leaseDetail?.expiredAt?.split("T")[0])}
                      </Typography>
                    </Box>
                    <Box
                      // display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Lease Expiry Reason:-
                      </Typography>
                      <Typography>
                        {showDash(leaseDetail?.expiryReason)}
                      </Typography>
                    </Box>
                    <Box
                      // display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Description
                      </Typography>
                      <Typography>
                        {showDash(leaseDetail?.description)}
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Grid>

          <Grid xs={4}>
            <Box className="card" height="100vh">
              {handleEditDocMode == true ? (
                <>
                  <Box display="flex" justifyContent="space-between" m={2}>
                    <Typography color="#111120" fontSize={20} lineHeight={1.5}>
                      Edit Documents
                    </Typography>
                    <Box
                      color=" #0C344E"
                      fontSize={16}
                      sx={{ cursor: "pointer", textDecoration: "underLine" }}
                      // onClick={handleUpdateData}
                    >
                      Save Changes
                    </Box>
                  </Box>
                  <Box
                    width="90%"
                    textAlign="center"
                    style={{
                      border: "1px dashed  #efefef",
                      borderRadius: "10px",
                    }}
                    m={2}
                    p={1.5}
                    fontSize={18}
                  >
                    <input
                      multiple
                      id="uploadUserProfile"
                      style={{ display: "none" }}
                      // onClick={() => {
                      //   setOpenDocument(!openDocument);
                      // }}
                    />
                    <label
                      htmlFor="uploadUserProfile"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AddIcon />
                      Add Document
                    </label>
                  </Box>
                </>
              ) : (
                <Box m={2}>
                  <Typography color="#111120" fontSize={20} lineHeight={1.5}>
                    Attached Documents
                  </Typography>
                  {leaseDetail?.contractImage?.length > 0 ? (
                    <Box>
                      {
                        <div className="mt-2">
                          {leaseDetail?.contractImage?.map((fileInfo) => {
                            if (fileInfo.contentType == "application/pdf") {
                              return (
                                <div
                                  className="d-flex justify-content-between align-items-center"
                                  style={{
                                    background: "white",
                                    boxShadow:
                                      "0px 3px 9px rgba(112, 112, 112, 0.22)",
                                    padding: "8px 20px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div>
                                    <div>
                                      <img
                                        src={DocImage}
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                      <span style={{ marginLeft: "10px" }}>
                                        {fileInfo.name.slice(0, 20)}
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    <a href={fileInfo.url} target="_blank">
                                      <MdDownloadForOffline />
                                    </a>
                                  </div>
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  className="d-flex justify-content-between align-items-center mb-2"
                                  style={{
                                    background: "white",
                                    boxShadow:
                                      "0px 3px 9px rgba(112, 112, 112, 0.22)",
                                    padding: "8px 20px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div>
                                    <div>
                                      <img
                                        src={fileInfo.url}
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                      <span style={{ marginLeft: "10px" }}>
                                        {fileInfo.name.slice(0, 10)}
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    <a href={fileInfo.url} target="_blank">
                                      <MdDownloadForOffline />
                                    </a>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>
                      }
                    </Box>
                  ) : (
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      No Document
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default LeaseDetailAndEdit;
