import React from "react";
import Guardmangement from "./Guardmangement";

export default function PropertyName() {
  return (
    <>
    
      <div className="top-level-container">
      

        <Guardmangement />
      </div>
    </>
  );
}
