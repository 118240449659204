import React from "react";
import loaderanimation2 from "../assets/loader2.gif";

const Loader = () => {
  return (
    <>
      <div className="spinner-container">
        <div className="loading-spinner"></div>
      </div>
    </>
  );
};

export default Loader;
