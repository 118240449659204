import React from "react";
import "../index.css";
import CatalogForm from "./CatalogForm";
import CatalogForm2 from "./CatalogForm2";

const CreateChargesCatalouge = () => {
    return (
        <div
            className="container bg-white"
            style={{ borderTopLeftRadius: "50px" }}
        >
            <div className="p-4">
                <div>
                    <CatalogForm />
                </div>
                {/* <div className="Cform2">
          <CatalogForm2 />
        </div> */}
            </div>
        </div>
    );
};

export default CreateChargesCatalouge;
