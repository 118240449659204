import SearchIcon from "@mui/icons-material/Search";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

const SearchSelectBox = ({
  displayedOptions,
  name,
  register,
  label,
  width,
  innerWidth,
  value,
}) => {
  const [searchText, setSearchText] = useState("");
  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="search-select-label" sx={{ marginLeft: "-14px" }}>
          {label}
        </InputLabel>
        <Select
          MenuProps={{ autoFocus: false }}
          sx={{ width: width ? width : "300px" }}
          labelId="search-select-label"
          id="search-select"
          variant="standard"
          value={value}
          {...register(name)}
          name={name}
          label={label}
          onClose={() => setSearchText("")}
          // inputProps={{ maxLength: 15 }}
        >
          <ListSubheader>
            <TextField
              size="small"
              variant="standard"
              placeholder="Type to search..."
              inputProps={{ maxLength: "30" }}
              sx={{
                width: innerWidth ? innerWidth : "300px",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {displayedOptions
            .filter((option) =>
              option?.name?.toLowerCase().includes(searchText.toLowerCase())
            )
            .map((option, i) => (
              <MenuItem key={option?.id} value={option?.id}>
                {option.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default React.memo(SearchSelectBox);
