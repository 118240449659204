import moment from "moment";
import React, { useEffect, useState } from "react";
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import "../../../App.css";
import DocImage from "../../../assets/DocImage.png";
import csvimage from "../../../assets/csvimage.png";
import edit from "../../../assets/edit.png";
import pdficon from "../../../assets/pdficon.jpg";
import { formatAMPM } from "../../../util";
import AllRequestPropertydailoc from "./AllRequestPropertiesdailoc";
import EditDetaildailog from "./EditDetailDailog";
import EditDetaildailog2 from "./EditDetailDailog2";
const PropertyTitle = ({
  requestId,
  IsBuilding,
  generatedBy,
  isUrgent,
  type,
  unitNo,
  ResidentName,
  date,
  status,
  time,
  property,
  description,
  images,
  preferredTime,
  user,
  createdAt,
  fetchAllRequestProperty,
  id,
  AllRequestProperty,
  subCategory,
}) => {
  const [active, setActive] = useState("");
  const [open, setOpen] = React.useState({ status: false, index: 0 });
  const [editdetailOpen, setEditDetailOpen] = useState(false);
  const [editdetailOpenTwo, setEditDetailOpenTwo] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (AllRequestProperty?.statusDetails) {
      setActive(AllRequestProperty?.statusDetails[0]?.status);
    }
  }, [AllRequestProperty]);
  const imageStyle = {
    width: "100px",
    height: "100px",
    borderRadius: "5px",
  };

  const handleClose = () => {
    setOpen({
      ...open,
      status: false,
    });
  };
  const EditDetailsClose = () => {
    setEditDetailOpen(false);
  };
  const EditDetailsCloseTwo = () => {
    setEditDetailOpenTwo(false);
  };
  const [OneEgoTime, setOneEgoTime] = useState("");
  useEffect(() => {
    if (preferredTime?.start) {
      var returned_endate = moment(preferredTime.start).add(1, "hours");

      setOneEgoTime(formatAMPM(new Date(returned_endate)));
    }
  }, [preferredTime?.start]);
  return (
    <>
      <div>
        <div className="backbtn" onClick={() => navigate(-1)}>
          <RiArrowLeftLine size={20} style={{ marginRight: "8px" }} />
          Back
        </div>
        <div className=" d-flex justify-content-between align-items-center">
          <div className="d-flex gap-3">
            <p className="headtitle">Request ID - {requestId}</p>
            {active == "Pending" && (
              <span className="pending">
                {AllRequestProperty?.statusDetails[0]?.status == "Pending" &&
                  "Open"}
              </span>
            )}
            {active == "In-Process" && (
              <span className="inprocess">
                {AllRequestProperty?.statusDetails[0]?.status}
              </span>
            )}
            {active == "Re-Assigned" && (
              <span className="assinged">
                {AllRequestProperty?.statusDetails[0]?.status}
              </span>
            )}
            {active == "Completed" && (
              <span className="success">
                {AllRequestProperty?.statusDetails[0]?.status}
              </span>
            )}
            {active == "Need Quotation" && (
              <span className="success">
                {AllRequestProperty?.statusDetails[0]?.status}
              </span>
            )}
            {active == "Quotation Sent" && (
              <span className="success">
                {AllRequestProperty?.statusDetails[0]?.status}
              </span>
            )}
            {active == "Quotation Approved" && (
              <span className="success">
                {AllRequestProperty?.statusDetails[0]?.status}
              </span>
            )}
            {active == "Quotation Rejected" && (
              <span className="success">
                {AllRequestProperty?.statusDetails[0]?.status}
              </span>
            )}
            {active == "Cancelled" && (
              <span className="Cancelledlist">
                {AllRequestProperty?.statusDetails[0]?.status}
              </span>
            )}
            {active == "Re-open" && (
              <span className="re-open">
                {AllRequestProperty?.statusDetails[0]?.status}
              </span>
            )}
            {active == "Assigned" && (
              <span className="assinged">
                {AllRequestProperty?.statusDetails[0]?.status}
              </span>
            )}
            {active == "On Hold" && (
              <span className="On-hold">
                {AllRequestProperty?.statusDetails[0]?.status}
              </span>
            )}
            {active == "Rejected" && (
              <span className="On-hold">
                {AllRequestProperty?.statusDetails[0]?.status}
              </span>
            )}

            <span className={isUrgent ? "urgent" : "normal"}>
              {isUrgent ? "Urgent" : "Normal"}
            </span>

            <span className=" typestatus">{type}</span>
            <span className=" urgent">
              {IsBuilding ? "Public area" : "Unit"}
            </span>
          </div>
          <div>
            <h5 style={{ color: "red" }}>
              {AllRequestProperty?.statusDetails &&
                AllRequestProperty?.statusDetails[0]?.status !== "Completed" &&
                moment(createdAt).fromNow()}
            </h5>
          </div>
        </div>
        <div className="d-flex gap-2">
          <div className="requestcard">
            <div className="d-flex justify-content-between">
              <h5 className="mb-3">Request details</h5>
              {AllRequestProperty?.statusDetails &&
                AllRequestProperty?.statusDetails[0]?.status === "Pending" && (
                  <img
                    src={edit}
                    alt="editimage"
                    onClick={() => {
                      setEditDetailOpen(true);
                    }}
                    style={{ width: "25px", height: "25px" }}
                  />
                )}
            </div>
            <EditDetaildailog
              {...{
                editdetailOpen,
                EditDetailsClose,
                generatedBy,
                property,
                unitNo,
                preferredTime,
                id,
                fetchAllRequestProperty,
                OneEgoTime,
                setOneEgoTime,
              }}
            />
            <EditDetaildailog2
              {...{
                editdetailOpenTwo,
                generatedBy,
                EditDetailsCloseTwo,
                description,
                type,
                images,
                id,
                fetchAllRequestProperty,
                AllRequestProperty,
                subCategory,
              }}
            />
            <div className="d-flex">
              <div className="d-flex gap-5">
                <div className="detaillist">
                  <p>Building name & Unit no</p>

                  <p>Requested by </p>
                  <p>Requested date </p>
                  <p>Requested time </p>
                  <p>Requested date slot </p>
                  <p>Requested time slot </p>
                </div>
                <div>
                  <p>
                    {property} & {unitNo}
                  </p>
                  <p>{user?.name ? user.name : generatedBy}</p>
                  <p>{date?.split("T")[0]}</p>
                  <p>{time}</p>

                  <p>{preferredTime && preferredTime.start.split("T")[0]}</p>
                  <p>
                    {" "}
                    {formatAMPM(preferredTime && preferredTime.start)}-
                    {formatAMPM(preferredTime && preferredTime.end)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <AllRequestPropertydailoc
            images={images}
            open={open.status}
            url={images?.[open.index]?.url}
            handleClose={handleClose}
          />
          <div className="requestcatagorycard">
            <div className="d-flex justify-content-between">
              <div>
                <h5 className="mb-3 ">Request Category</h5>
              </div>
              {AllRequestProperty?.statusDetails &&
                AllRequestProperty?.statusDetails[0].status == "Pending" && (
                  <img
                    src={edit}
                    alt="editimage"
                    style={{ width: "25px", height: "25px" }}
                    onClick={() => {
                      setEditDetailOpenTwo(true);
                    }}
                  />
                )}
            </div>
            <div className="requeststatus">
              <div className="d-flex gap-3">
                <p>Request Admin</p>
                <p>{type}</p>
              </div>
              <div className="d-flex gap-3">
                <p>Request User</p>
                <p>{subCategory}</p>
              </div>
              <div>
                <p>Request Description</p>
                <p>{description}</p>
              </div>

              <div className="d-flex flex-wrap gap">
                {images &&
                  images.map((docData) => {
                    if (docData.contentType == "application/pdf") {
                      return (
                        <>
                          <a href={docData.url} target="_blank">
                            <img src={pdficon} style={imageStyle} />
                          </a>
                        </>
                      );
                    } else if (docData.contentType == "application/x-msi") {
                      return (
                        <>
                          <a href={docData.url} target="_blank">
                            <img src={DocImage} style={imageStyle} />
                          </a>
                        </>
                      );
                    } else if (
                      docData.contentType == "application/octet-stream"
                    ) {
                      return (
                        <>
                          <a href={docData.url} target="_blank">
                            <img src={csvimage} style={imageStyle} />
                          </a>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <a href={docData.url} target="_blank">
                            <img src={docData.url} style={imageStyle} />
                          </a>
                        </>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(PropertyTitle);
