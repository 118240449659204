import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { getAllRequests } from "../services/allServisesApi";
import "./AllRequesttable.css";
import Loader from "./Loader";
import { buildingContext } from "../login/AuthProvider";
import { formatAMPM } from "../util";
import moment from "moment";
import { Tooltip } from "@mui/material";

function createData(
  RequestID,
  Urgency,
  unitNo,
  RequestType,
  RequestStatus,
  StaffAssigned,
  UserName
) {
  return {
    RequestID,
    Urgency,
    unitNo,
    RequestType,
    RequestStatus,
    StaffAssigned,
    UserName,
  };
}
export default function AllRequesttable({
  handleFilterSaveBtn,
  searchText,
  postPerpage,
  currentPage,
  setcurrentPage,
  totalCount,
  settotalCount,
  setRequests,
  requests,
  startDate,
  endDate,
  requestType,
  requestStatus,
  selectedTab,
  tabName,
  requestIsUrgent,
  selectedFlatForFilter,
  setRequestStatus,
}) {
  const [active, setActive] = useState("");
  const [loading, setLoading] = useState(false);
  const [handleNoData, setHandleNoData] = useState(false);
  const [showtotalData, setshowtotalData] = useState("");
  const [handleInnerCase, setHandleInnerCase] = useState(false);

  const [localPageState, setLocalPageState] = useState(currentPage);
  const { selectedBuilding } = React.useContext(buildingContext);
  let buildingId = selectedBuilding;

  const allRequestData = async () => {
    setLoading(true);
    const resp = await getAllRequests(
      currentPage[requestStatus],
      postPerpage,
      searchText,
      buildingId,
      startDate,
      endDate,
      requestType,
      requestStatus,
      requestIsUrgent,
      selectedFlatForFilter,
      reload
    );
    let totalData = resp?.data?.data?.count;
    setshowtotalData(totalData);

    let pagesCount = Math.ceil(totalData / postPerpage);
    settotalCount(pagesCount);
    setRequests(resp?.data?.data?.rows);
    setLoading(false);
  };

  const location = useLocation();
  const reload = location?.state?.from;

  useEffect(() => {
    allRequestData();
  }, [
    currentPage,
    endDate,
    requestType,
    requestStatus,
    buildingId,
    tabName,
    requestIsUrgent,
    selectedFlatForFilter,
    selectedTab,
    searchText,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setHandleNoData(true);
    }, 2000);
  }, []);

  const navigate = useNavigate();
  const handleSend = (id) => {
    navigate(`/allrequest/${id}`, { state: { current: currentPage } });
  };

  return (
    <>
      {loading && <Loader />}
      {requests?.length > 0 ? (
        <>
          <TableContainer component={Paper} className="pagalcheck">
            <Table aria-label="simple table">
              <TableHead className="thead">
                <TableRow>
                  <TableCell align="center" className="makespace">
                    Request ID
                  </TableCell>
                  <TableCell align="center" className="makespace">
                    Requested date
                  </TableCell>
                  <TableCell align="center" className="makespace">
                    Requested time
                  </TableCell>
                  <TableCell align="center" className="makespace">
                    Request type
                  </TableCell>
                  <TableCell align="center" className="Sbold ">
                    Building
                  </TableCell>
                  <TableCell align="center" className="makespace">
                    Unit No.
                  </TableCell>
                  <TableCell align="center" className="makespace">
                    Requested for
                  </TableCell>
                  <TableCell align="center" className="makespace">
                    Request category
                  </TableCell>
                  <TableCell align="center" className="makespace">
                    Request Status
                  </TableCell>
                  <TableCell align="center" className="makespace">
                    Staff Assigned
                  </TableCell>
                  <TableCell align="center" className="makespace">
                    User Name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  cursor: "pointer",
                }}
              >
                {requests.map((row) => {
                  return (
                    <>
                      <TableRow key={row.requestId} className="rowTable">
                        <TableCell
                          align="center"
                          className="makespace"
                          sx={{ padding: "7px" }}
                        >
                          <div
                            onClick={() => {
                              handleSend(row.id);
                              setHandleInnerCase(true);
                            }}
                            className={
                              row.status !== "Cancelled"
                                ? `${"unitname"}`
                                : `${"fadeStatus"}`
                            }
                          >
                            {row.requestId}
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          className="makespace"
                          sx={{ padding: "7px" }}
                          onClick={() => {
                            handleSend(row.id);
                            setHandleInnerCase(true);
                          }}
                        >
                          {row.createdAt.split("T")[0]}
                          <br />
                          {moment(row.createdAt).format("dddd")}
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ padding: "7px" }}
                          onClick={() => {
                            handleSend(row.id);
                            setHandleInnerCase(true);
                          }}
                        >
                          {formatAMPM(row.createdAt)}
                          <br />
                          {row?.statusDetails?.status !== "Completed" && (
                            <span
                              style={{
                                color: "red",
                                marginBottom: "7px !important",
                              }}
                            >
                              {moment(row.createdAt).fromNow()}
                            </span>
                          )}
                        </TableCell>

                        <TableCell
                          align="center"
                          className="makespace"
                          sx={{ padding: "7px" }}
                          onClick={() => {
                            handleSend(row.id);
                            setHandleInnerCase(true);
                          }}
                        >
                          {row.isUrgent ? (
                            <div className="bgurgent d-flex justify-content-center align-items-center">
                              Urgent
                            </div>
                          ) : (
                            <div className="bgnormal d-flex justify-content-center align-items-center">
                              Normal
                            </div>
                          )}
                        </TableCell>
                        <Tooltip title={row.flat?.building?.name_en}>
                          <TableCell
                            align="center"
                            className="makespace"
                            sx={{ padding: "7px" }}
                            onClick={() => {
                              handleSend(row.id);
                              setHandleInnerCase(true);
                            }}
                          >
                            {row.flat?.building?.name_en.slice(0, 15) + "..."}
                          </TableCell>
                        </Tooltip>
                        <TableCell
                          align="center"
                          className="makespace"
                          sx={{ padding: "7px" }}
                          onClick={() => {
                            handleSend(row.id);
                            setHandleInnerCase(true);
                          }}
                        >
                          {row.flat?.name_en}
                        </TableCell>
                        <TableCell
                          align="center"
                          className="makespace"
                          sx={{ padding: "7px" }}
                          onClick={() => {
                            handleSend(row.id);
                            setHandleInnerCase(true);
                          }}
                        >
                          {row.isBuilding ? "Public Area" : "Unit"}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ padding: "7px" }}
                          className={row.type === "success" ? "green" : "pink"}
                          onClick={() => {
                            handleSend(row.id);
                            setHandleInnerCase(true);
                          }}
                        >
                          {row.category.name_en}
                        </TableCell>
                        <TableCell
                          align="center"
                          className="makespace"
                          sx={{ padding: "7px" }}
                          onClick={() => {
                            handleSend(row.id);
                            setHandleInnerCase(true);
                          }}
                        >
                          {row?.statusDetails?.status === "Pending" && (
                            <span className="pending">Open</span>
                          )}
                          {row?.statusDetails?.status === "Assigned" && (
                            <span className="assinged">
                              {row?.statusDetails?.status}
                            </span>
                          )}
                          {row?.statusDetails?.status === "Re-Assigned" && (
                            <span className="assinged">
                              {row?.statusDetails?.status}
                            </span>
                          )}
                          {row?.statusDetails?.status === "In-Process" && (
                            <span className="inprocess">
                              {row?.statusDetails?.status}
                            </span>
                          )}
                          {row?.statusDetails?.status === "Completed" && (
                            <span className="success">
                              {row?.statusDetails?.status}
                            </span>
                          )}
                          {row?.statusDetails?.status === "Need Quotation" && (
                            <span className="success">
                              {row?.statusDetails?.status}
                            </span>
                          )}
                          {row?.statusDetails?.status === "Quotation Sent" && (
                            <span className="success">
                              {row?.statusDetails?.status}
                            </span>
                          )}
                          {row?.statusDetails?.status ===
                            "Quotation Approved" && (
                            <span className="success">
                              {row?.statusDetails?.status}
                            </span>
                          )}
                          {row.statusDetails.status ===
                            "Quotation Rejected" && (
                            <span className="success">
                              {row?.statusDetails?.status}
                            </span>
                          )}
                          {row.statusDetails.status === "Cancelled" && (
                            <span className="Cancelledlist">
                              {row?.statusDetails?.status}
                            </span>
                          )}
                          {row.statusDetails.status === "Re-open" && (
                            <span className="re-open">
                              {row?.statusDetails?.status}
                            </span>
                          )}
                          {row.statusDetails.status === "On Hold" && (
                            <span className="On-hold">
                              {row?.statusDetails?.status}
                            </span>
                          )}
                          {row.statusDetails.status === "Rejected" && (
                            <span className="On-Rejected">
                              {row?.statusDetails?.status}
                            </span>
                          )}
                        </TableCell>

                        <TableCell
                          align="center"
                          className="makespace"
                          sx={{ padding: "7px" }}
                          onClick={() => {
                            handleSend(row.id);
                            setHandleInnerCase(true);
                          }}
                        >
                          {row?.staff?.name ? row?.staff?.name : "-"}
                        </TableCell>
                        <TableCell
                          align="center"
                          className="makespace"
                          sx={{ padding: "7px" }}
                          onClick={() => {
                            handleSend(row.id);
                            setHandleInnerCase(true);
                          }}
                        >
                          {row?.user?.name ? row.user?.name : <p>-</p>}
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex justify-content-between height-200px mt-2">
            <div className="">
              <p>
                Showing {postPerpage * (currentPage[requestStatus] - 1) + 1}
                &nbsp;-
                {currentPage[requestStatus] * postPerpage > showtotalData
                  ? showtotalData
                  : currentPage[requestStatus] * postPerpage}{" "}
                out of {showtotalData} entries
              </p>
            </div>
            <div className="">
              <Pagination
                count={totalCount}
                onChange={(event, value) => {
                  setcurrentPage({
                    ...currentPage,
                    [requestStatus]: value,
                  });

                  localStorage.setItem(
                    "openTab",
                    JSON.stringify({
                      ...currentPage,
                      [requestStatus]: value,
                    })
                  );
                  // requestStatus == ""
                  //   ? localStorage.setItem("handlePage", value)
                  //   : localStorage.setItem("openTab", value);
                }}
                shape="rounded"
                style={{ margin: "10px", float: "right" }}
                page={Number(currentPage[requestStatus])}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center mt-5">
          <p className="nofound">{handleNoData && "No Data Found"}</p>
        </div>
      )}
    </>
  );
}
