import { Card, CardMedia, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import edit from "../StaffDetail/assets/edit.png";

import CloseIcon from "@mui/icons-material/Close";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import pdfPhoto from "../StaffDetail/assets/pdfPhoto.png";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { toast } from "react-toastify";
import Carousol from "../components/Carousol";
import Loader from "../components/Loader";
import {
  getBuildingData,
  patchAddNewBuilding,
  uploadFile,
} from "../services/allPropertiesAPI";
import { removeCountryCode, showDash } from "../util";
import EditBuildingDetail from "./EditBuildingDetail";
import "./index.css";
import OpenDoc from "./OpenDoc";
import Image from "../StaffDetail/assets/gallery.png";

const Propertyname = () => {
  const [handleEditDocMode, setHandleEditDocMode] = useState(false);
  const [propertyFields, setPropertyFields] = useState({
    name_en: "",
    name_ar: "",
    location: [],
    address_en: "",
    address_ar: "",
    buildingType: "",
    governmentPropertyId: "",
    description_en: "",
    description_ar: "",
    url: "",
    documents: [],
    contactName: "",
    contactEmail: "",
    contactMobileNumber: "",
    buildingId: "",
    images: [],
    countryCode: "",
  });
  const [handleLoader, setHandleLoader] = useState(false);
  const [getLength, setGetLength] = useState();
  const [imageUrl, setImageUrl] = useState([]);
  const [getImageDataDoc, setGetImageDataDoc] = useState([]);
  const [getLengthDoc, setGetLengthDoc] = useState();
  const [docUrl, setDocUrl] = useState([]);
  const [handleEditMode, setHandleEditMode] = useState(false);

  const navigate = useNavigate();
  let { id } = useParams();
  
  const fetchData = async () => {
    setHandleLoader(true);
    const response = await getBuildingData(id);
    setPropertyFields({
      buildingId: response?.data?.data?.id,
      name_en: response?.data?.data?.name_en,
      name_ar: response?.data?.data?.name_ar,
      location: response?.data?.data?.location.coordinates,
      address_en: response?.data?.data?.address_en,
      address_ar: response?.data?.data?.address_ar,
      buildingType: response?.data?.data?.buildingType,
      governmentPropertyId: response?.data?.data?.governmentPropertyId,
      description_en: response?.data?.data?.description_en,
      description_ar: response?.data?.data?.description_ar,
      url: response?.data?.data?.url,
      documents: response?.data?.data?.documents,
      contactName: response?.data?.data?.primaryContact?.name,
      contactEmail: response?.data?.data?.primaryContact?.email,
      contactMobileNumber: removeCountryCode(
        response?.data?.data?.primaryContact?.countryCode,
        response?.data?.data?.primaryContact?.mobileNumber
      ),
      images: response?.data?.data?.images,
      countryCode: `+${response?.data?.data?.primaryContact?.countryCode} `,
    });
    setHandleLoader(false);
  };

  useEffect(() => {
    fetchData();
  }, [id, !handleEditMode]);

  const handleEditButton = () => {
    setHandleEditMode(true);
  };
  const handleEditDocButton = () => {
    setHandleEditDocMode(true);
  };
  const handleRemoveDoc = (att, type) => {
    if (type) {
      const filtered = docUrl.filter((item) => item !== att);
      setDocUrl(filtered);
    } else {
      const dataFiltered = propertyFields?.documents?.filter(
        (item) => item !== att
      );
      // setGetImageDataDoc(dataFiltered);
      setPropertyFields({
        ...propertyFields,
        documents: dataFiltered,
      });
    }
  };

  const handleUpdateData = async () => {
    setHandleLoader(true);
    const data = {
      documents: [...docUrl, ...propertyFields.documents],
      buildingId: propertyFields?.buildingId,
      masterUserId: propertyFields?.userid,
    };
    const postData = await patchAddNewBuilding(data);
    if (postData.data.status === "success") {
      setHandleEditDocMode(false);
      setImageUrl([]);
      setDocUrl([]);
      fetchData();
      toast("Successfully Upload");
    } else {
      toast("Uploading fail");
      setHandleEditDocMode(false);
    }
    setHandleLoader(false);
  };

  const getDocUrl = async () => {
    setHandleLoader(true);
    try {
      const res = await uploadFile(getImageDataDoc);
      if (res.data.status === "success") {
        const getUrl = res.data.data.map((current) => current.location);
        setDocUrl([...docUrl, ...getUrl]);
      } else {
        toast("Document is not uploaded. Please check your connection");
      }
    } catch {}
    setHandleLoader(false);
  };
  useEffect(() => {
    if (getLengthDoc) {
      getDocUrl();
    }
  }, [getLengthDoc]);

  return (
    <>
      <div className="top-level-container">
        {handleLoader && <Loader />}
        <div className="home">
          <h4> Details</h4>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Box className="card" p={2}>
                  <Box>
                    {!handleEditMode && (
                      <img
                        alt="..."
                        src={edit}
                        onClick={handleEditButton}
                        className="editImage"
                      />
                    )}
                  </Box>
                  {handleEditMode === true ? (
                    <EditBuildingDetail
                      propertyFields={propertyFields}
                      setHandleEditMode={setHandleEditMode}
                      setHandleLoader={setHandleLoader}
                    />
                  ) : (
                    <>
                      {propertyFields?.images?.length > 0 ? (
                        <Carousol>
                          {propertyFields?.images.map((curElem, id) => (
                            <a href={curElem} target="_blank" rel="noreferrer">
                              <Card
                                key={id}
                                sx={{ maxWidth: 345, p: 1, boxShadow: "none" }}
                              >
                                <CardMedia
                                  component="img"
                                  alt="green iguana"
                                  height="140"
                                  image={curElem}
                                />
                              </Card>
                            </a>
                          ))}
                        </Carousol>
                      ) : (
                        <img
                          src={Image}
                          alt="emptystate"
                          width="130"
                          height="130"
                        />
                      )}

                      <Box my={4}>
                        <Typography color="#111120" fontSize={20}>
                          Other Details
                        </Typography>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          my={2}
                          px={1.5}
                        >
                          <Typography color="#111120" lineHeight={2.5}>
                            Property Name
                          </Typography>
                          <Typography>
                            {showDash(propertyFields?.name_en)}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          bgcolor="#FAFAFA"
                          alignItems="center"
                          my={2}
                          px={1.5}
                        >
                          <Typography color="#111120" lineHeight={2.5}>
                            Address Line
                          </Typography>
                          <Typography width="50%" textAlign="right">
                            {showDash(propertyFields?.address_en)}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          px={1.5}
                        >
                          <Typography color="#111120">Longitude</Typography>
                          <Typography>
                            {showDash(propertyFields?.location?.[0])}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          bgcolor="#FAFAFA"
                          alignItems="center"
                          my={2}
                          px={1.5}
                        >
                          <Typography color="#111120" lineHeight={2.5}>
                            Latitude
                          </Typography>
                          <Typography>
                            {showDash(propertyFields?.location?.[1])}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          px={1.5}
                        >
                          <Typography color="#111120">Property Type</Typography>
                          <Typography>
                            {showDash(propertyFields?.buildingType)}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          bgcolor="#FAFAFA"
                          alignItems="center"
                          my={2}
                          px={1.5}
                        >
                          <Typography color="#111120" lineHeight={2.5}>
                            Government Property Id
                          </Typography>
                          <Typography>
                            {showDash(propertyFields?.governmentPropertyId)}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          px={1.5}
                        >
                          <Typography color="#111120">POC Name</Typography>
                          <Typography>
                            {showDash(propertyFields?.contactName)}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          bgcolor="#FAFAFA"
                          alignItems="center"
                          my={2}
                          px={1.5}
                        >
                          <Typography color="#111120" lineHeight={2.5}>
                            POC Email
                          </Typography>
                          <Typography>
                            {showDash(propertyFields?.contactEmail)}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          px={1.5}
                        >
                          <Typography color="#111120">Mobile No.</Typography>
                          <Typography>
                            {propertyFields?.contactMobileNumber
                              ? `${propertyFields?.countryCode}${propertyFields?.contactMobileNumber}`
                              : "-"}
                          </Typography>
                        </Box>
                        <Box
                          // display="flex"
                          // justifyContent="space-between"
                          alignItems="center"
                          my={2}
                          px={1.5}
                        >
                          <Typography
                            color="#111120"
                            lineHeight={2.5}
                            bgcolor="#FAFAFA"
                          >
                            Description:-
                          </Typography>
                          <br />
                          <Typography>
                            {showDash(propertyFields?.description_en)}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="card" height="100vh">
                  {handleEditDocMode === true ? (
                    <>
                      <Box display="flex" justifyContent="space-between" m={2}>
                        <Typography
                          color="#111120"
                          fontSize={20}
                          lineHeight={1.5}
                        >
                          Edit Documents
                        </Typography>
                        <Box
                          color=" #0C344E"
                          fontSize={16}
                          sx={{
                            cursor: "pointer",
                            textDecoration: "underLine",
                          }}
                          onClick={handleUpdateData}
                        >
                          Save Changes
                        </Box>
                      </Box>
                      <Grid>
                        <Typography textAlign="center" fontSize="20px">
                          Attach Document
                        </Typography>
                        <OpenDoc
                          setGetImageDataDoc={setGetImageDataDoc}
                          setGetLengthDoc={setGetLengthDoc}
                        />
                      </Grid>
                      <Grid>
                        {docUrl.length > 0 &&
                          docUrl?.map((current) => (
                            <Box
                              className="card"
                              display="flex"
                              justifyContent="space-between"
                              p={2}
                              m={2}
                              style={{
                                boxShadow:
                                  " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                                borderStyle: "none",
                              }}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography>
                                  <img
                                    src={pdfPhoto}
                                    style={{ marginRight: "10px" }}
                                    alt=""
                                  />
                                  {current
                                    ?.split("/")[3]
                                    ?.split(".")[0]
                                    ?.slice(0, 10)}
                                </Typography>
                                <Typography>
                                  <a
                                    href={current}
                                    download
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <RemoveRedEyeIcon />
                                  </a>
                                  <CloseIcon
                                    onClick={() =>
                                      handleRemoveDoc(current, "local")
                                    }
                                  />
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                        {propertyFields.documents?.map((current) => (
                          <Box
                            className="card"
                            display="flex"
                            justifyContent="space-between"
                            p={2}
                            m={2}
                            style={{
                              boxShadow:
                                " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                              borderStyle: "none",
                            }}
                          >
                            <Box display="flex" justifyContent="space-between">
                              <Typography>
                                <img
                                  src={pdfPhoto}
                                  style={{ marginRight: "10px" }}
                                  alt=""
                                />
                                {current
                                  ?.split("/")[3]
                                  ?.split(".")[0]
                                  ?.slice(0, 10)}
                              </Typography>
                              <Typography>
                                <a
                                  href={current}
                                  download
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <RemoveRedEyeIcon />
                                </a>
                                <CloseIcon
                                  onClick={() => handleRemoveDoc(current, "")}
                                />
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Box display="flex" justifyContent="space-between" m={2}>
                        <Typography
                          color="#111120"
                          fontSize={20}
                          lineHeight={1.5}
                        >
                          Attached Documents
                        </Typography>
                        <Box onClick={handleEditDocButton}>
                          <img src={edit} alt="" />
                        </Box>
                      </Box>
                      <Grid>
                        {propertyFields?.documents?.length > 0 ? (
                          propertyFields?.documents.map((current) => (
                            <Box
                              className="card"
                              display="flex"
                              justifyContent="space-between"
                              p={2}
                              m={2}
                              style={{
                                boxShadow:
                                  " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                                borderStyle: "none",
                              }}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography>
                                  <img
                                    src={pdfPhoto}
                                    style={{ marginRight: "10px" }}
                                    alt=""
                                  />
                                  {current
                                    ?.split("/")[3]
                                    ?.split(".")[0]
                                    ?.slice(0, 15)}
                                </Typography>
                                <Typography>
                                  <a
                                    href={current}
                                    download
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <DownloadForOfflineIcon />
                                  </a>
                                </Typography>
                              </Box>
                            </Box>
                          ))
                        ) : (
                          <Box textAlign="center">No Document</Box>
                        )}
                      </Grid>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </>
  );
};

export default Propertyname;
