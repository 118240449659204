import React, { useEffect, useState } from "react";
import DataManulist from "./DataManulist";
import GeneratePropertyTitle from "./GeneratePropertyTitle";
import DailogBox from "./DailogBox";
import "./index.css";
import { getGenerateCatalouge } from "../../../services/allServisesApi";
import { useParams } from "react-router-dom";
import { formatAMPM } from "../../../util";

const GenerateInvoiceProperty = () => {
  const urlParams = useParams();
  const [handleDisabled, setHandleDisabled] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [GenerateCalalouge, setGenerateCalalouge] = React.useState({});
  const [requestID, setRequestID] = React.useState(urlParams?.id);

  const fetchGenerateCatalouge = async () => {
    const response = await getGenerateCatalouge(urlParams?.id);
    setGenerateCalalouge(response.data.data);
  };

  useEffect(() => {
    fetchGenerateCatalouge();
  }, []);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="home bg-white" style={{ borderTopLeftRadius: "50px" }}>
      <div className="p-4">
        <div>
          <GeneratePropertyTitle
            requestId={GenerateCalalouge?.request?.requestId}
            isUrgent={GenerateCalalouge?.request?.isUrgent}
            type={GenerateCalalouge?.request?.type}
            property={GenerateCalalouge?.request?.flat?.building?.name_en}
            unitNo={GenerateCalalouge?.request?.flat?.name_en}
            ResidentName={GenerateCalalouge?.request?.user?.name}
            status={GenerateCalalouge?.request?.status}
            date={GenerateCalalouge?.request?.createdAt}
            time={formatAMPM(GenerateCalalouge?.request?.createdAt)}
            staffName={GenerateCalalouge?.request?.staff?.name}
          />
        </div>
        <div>
          <DataManulist
            requestID={requestID}
            setOpen={setOpen}
            open={open}
            urlParams={urlParams.id}
          />
        </div>
      </div>
      <DailogBox
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        requestID={requestID}
      />
    </div>
  );
};

export default GenerateInvoiceProperty;
