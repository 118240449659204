import { BASE_URL, axiosClient } from "./axiosClient";

export const getVisitorData = async (
  visitorSearch,
  buildingId,
  limit,
  currentPage,
  getStartDate,
  getEndDate,
  pageValue,
  searchByUnit,
  buildingData,
  flatData
) => {
  const resp = await axiosClient.get(
    `${BASE_URL}/visitings/logs?page=${
      pageValue ? pageValue : currentPage
    }&limit=${limit}&startDate=${getStartDate}&endDate=${getEndDate}&category_en=${visitorSearch}&search=${searchByUnit}&buildingId=${
      buildingId ? buildingId : buildingData
    }&flatId=${flatData}`
  );
  return resp;
};

export const getFilterKey = async () => {
  {
    const resp = await axiosClient.get(`${BASE_URL}/visitors/categories`);
    return resp;
  }
};
