import React, { useState } from "react";

import { Link } from "react-router-dom";

import "./AllProperties.css";

import BasicTable from "../components/BasicTable";

import SearchBar from "../components/SearchBar";

import Button from "@mui/material/Button";


export default function Home() {
  const [Building, setBuilding] = useState([]);

  const [searchText, setSearchText] = useState("");

  const [totalCount, settotalCount] = useState(0);

  const [postPerpage, setPostPerPage] = useState(10);

  const [currentPage, setcurrentPage] = useState(1);

  const onSearchChange = async (value) => {
    setSearchText(value);
  };

  return (
    <>
      <div className="home">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="Property" style={{ marginRight: "40px" }}>
            All Properties
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "auto",
              order: 2,
            }}
          >
            <SearchBar
              onChange={onSearchChange}
              placeholder="Search"
              maxLength="30"
            />

            <div className="properties-button">
              <Link to="/addnewproperty" style={{ textDecoration: "none" }}>
                <Button
                  className="Button"
                  variant="contained"
                  color="success"
                  style={{
                    border: "1px solid #0C344E",

                    color: "#0C344E",

                    background: "white",

                    width: "12rem",

                    height: "48px",

                    fontFamily: "Barlow",

                    fontStyle: "normal",

                    fontweight: "500",

                    fontSize: "14px",

                    lineHeight: "19px",

                    margintLeft: "24px",

                    textTransform: "capitalize",
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: "0.5rem",
                      marginLeft: "-0.8rem",
                    }}
                  >
                    <path
                      d="M12 5V19"
                      stroke="#0C344E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />

                    <path
                      d="M5 12H19"
                      stroke="#0C344E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Add New Property
                </Button>
              </Link>
            </div>
          </div>
        </div>

        <BasicTable
          searchText={searchText}
          postPerpage={postPerpage}
          currentPage={currentPage}
          totalCount={totalCount}
          setcurrentPage={setcurrentPage}
          settotalCount={settotalCount}
          Building={Building}
          setBuilding={setBuilding}
        />
      </div>
    </>
  );
}
