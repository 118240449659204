import React from "react";
import "../AllRequestProperty/Timeline.css";
import { formatAMPM } from "../../../util";

const Timeline = ({ AllRequestProperty }) => {
    return (
        <div class="timeline">
            <div class="outer">
                {AllRequestProperty?.statusDetails?.map((currEle) => {
                    return (
                        <>
                            {currEle.status == "Pending" && (
                                <div class="card-title Pending">
                                    <div class="info">
                                        <h6 class="title Pending">
                                            {currEle.status == "Pending" && "Open"}
                                        </h6>
                                        <p>
                                            {currEle.updatedAt.split("T")[0]},
                                            {formatAMPM(currEle.updatedAt)}
                                        </p>
                                    </div>
                                </div>
                            )}

                            {currEle.status == "Assigned" && (
                                <div class="card-title ">
                                    <div class="info">
                                        <h6 class="title Assigned">
                                            {currEle.status}
                                        </h6>
                                        <p>
                                            {currEle.updatedAt.split("T")[0]},
                                            {formatAMPM(currEle.updatedAt)}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {currEle.status == "Re-Assigned" && (
                                <div class="card-title ">
                                    <div class="info">
                                        <h6 class="title Assigned">
                                            {currEle.status}
                                        </h6>
                                        <p>
                                            {currEle.updatedAt.split("T")[0]},
                                            {formatAMPM(currEle.updatedAt)}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {currEle.status == "Re-open" && (
                                <div class="card-title ">
                                    <div class="info">
                                        <h6 class="title Assigned">
                                            {currEle.status}
                                        </h6>
                                        <p>
                                            {currEle.updatedAt.split("T")[0]},
                                            {formatAMPM(currEle.updatedAt)}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {currEle.status == "On Hold" && (
                                <div class="card-title ">
                                    <div class="info">
                                        <h6 class="title Assigned">
                                            {currEle.status}
                                        </h6>
                                        <p>
                                            {currEle.updatedAt.split("T")[0]},
                                            {formatAMPM(currEle.updatedAt)}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {currEle.status == "In-Process" && (
                                <div class="card-title In-Process">
                                    <div class="info">
                                        <h6 class="title In-Process">
                                            {currEle.status}
                                        </h6>
                                        <p>
                                            {currEle.updatedAt.split("T")[0]},
                                            {formatAMPM(currEle.updatedAt)}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {currEle.status == "Completed" && (
                                <div class="card-title  Completed">
                                    <div class="info">
                                        <h6 class="title Completed">
                                            {currEle.status}
                                        </h6>
                                        <p>
                                            {currEle.updatedAt.split("T")[0]},
                                            {formatAMPM(currEle.updatedAt)}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {currEle.status == "Cancelled" && (
                                <div class="card-title">
                                    <div class="info">
                                        <h6 class="title  Cancelled">
                                            {currEle.status}
                                        </h6>
                                        <p>
                                            {currEle.updatedAt.split("T")[0]},
                                            {formatAMPM(currEle.updatedAt)}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {currEle.status == "On-Hold " && (
                                <div class="card-title">
                                    <div class="info">
                                        <h6 class="title  On-Hold">
                                            {currEle.status}
                                        </h6>
                                        <p>
                                            {currEle.updatedAt.split("T")[0]},
                                            {formatAMPM(currEle.updatedAt)}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {currEle.status == "Rejected" && (
                                <div class="card-title">
                                    <div class="info">
                                        <h6 class="title  On-Hold">
                                            {currEle.status}
                                        </h6>
                                        <p>
                                            {currEle.updatedAt.split("T")[0]},
                                            {formatAMPM(currEle.updatedAt)}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </>
                    );
                })}
            </div>
        </div>
    );
};

export default Timeline;
