import { Grid, TextField, Typography } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment/moment";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import dayjs from "dayjs";
import { useEffect } from "react";
import { getCalendarData, updateTimeSlot } from "../services/allPropertiesAPI";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import "./calendar.css";
import Loader from "../components/Loader";

const CalenderSlot = () => {
  const defaultDate = new Date();
  const formatDate = defaultDate;
  const location = useLocation();
  const userId = location.pathname.split("/")[2];
  const [dateValue, setDateValue] = useState(formatDate);
  const [localDate, setLocalDate] = useState(dateValue);
  const [calendarData, setCalendarData] = useState([]);
  const [handleLoader, setHandleLoader] = useState(false);
  const [dayData, setDayData] = useState({
    sunday: "",
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [popUpData, setPopUpData] = useState({
    id: "",
    startTime: "",
    endTime: "",
    date: "",
    status: "",
  });
  const fetchCalendarData = async () => {
    const date = moment(dateValue).format("DD-MM-YYYY");
    setHandleLoader(true);
    try {
      const resp = await getCalendarData({ userId, date });
      setCalendarData(resp.data.data);
    } catch {
      toast("error");
    }
    setHandleLoader(false);
  };
  useEffect(() => {
    if (calendarData) {
      setDayData({
        sunday: calendarData?.slice(0, 24),
        monday: calendarData?.slice(24, 48),
        tuesday: calendarData?.slice(48, 72),
        wednesday: calendarData?.slice(72, 96),
        thursday: calendarData?.slice(96, 120),
        friday: calendarData?.slice(120, 144),
        saturday: calendarData?.slice(144, 168),
      });
    }
  }, [calendarData]);

  useEffect(() => {
    fetchCalendarData();
  }, [dateValue, localDate]);

  const handleDateChange = (e) => {
    // const time = e.$d;
    // const formatDate = moment(time).format("DD-MM-YYYY");
    setDateValue(e.$d);
    setLocalDate(e);
  };
  let time = [
    "12:00 AM",
    "1:00 AM",
    "2:00 AM",
    "3:00 AM",
    "4:00 AM",
    "5:00 AM",
    "6:00 AM",
    "7:00 AM",
    "8:00 AM",
    "9:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "1:00 PM",
    "2:00 PM",
    "3:00 PM",
    "4:00 PM",
    "5:00 PM",
    "6:00 PM",
    "7:00 PM",
    "8:00 PM",
    "9:00 PM",
    "10:00 PM",
    "11:00 PM",
  ];
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
  };

  const getSelectedStaffId = (id, start, end, status) => {
    if (status === "Booked") {
      toast("Booked status cannot be changed.");
    } else {
      const startTime =
        start?.split(",")[2]?.split(" ")[2] +
        start?.split(",")[2]?.split(" ")[3];
      const endTime =
        end?.split(",")[2]?.split(" ")[2] + end?.split(",")[2]?.split(" ")[3];
      const date =
        end?.split(" ")[1] +
        " " +
        end?.split(" ")[2] +
        " " +
        end?.split(" ")[3];
      setPopUpData({
        id: id,
        startTime: startTime,
        endTime: endTime,
        date: date,
        status: status === "Unavailable" ? "Available" : "Unavailable",
      });
      handleOpen();
    }
  };
  const handleSaveData = async (id, status) => {
    setHandleLoader(true);
    const payload = {
      staffSlots: [id],
      status: status,
    };
    const data = await updateTimeSlot({ userId, payload });
    if (data.data.status === "success") {
      handleClose();
      fetchCalendarData();
      toast("Status Update Successfully");
    } else {
      toast(data.data.status);
    }
    setHandleLoader(false);
  };

  return (
    <>
      <Grid>
        {handleLoader && <Loader />}
        {/* modal ui  */}
        <Grid>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Grid sx={style}>
              <Box>
                <Typography
                  fontSize={20}
                  color="#111120"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  Are You Sure?
                </Typography>
                <Typography>
                  Are you sure, you want to mark as {popUpData.status} From
                  &nbsp; {popUpData.startTime} to {popUpData.endTime} (
                  {popUpData.date})
                </Typography>
                <Box my={2}>
                  <Button
                    variant="outlined"
                    style={{
                      width: "45%",
                      color: "#C24C4C",
                      border: "1px solid #C24C4C",
                      borderRadius: "10px",
                      height: "2.5rem",
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      marginLeft: "1rem",
                      width: "45%",
                      borderRadius: "10px",
                      height: "2.5rem",
                      backgroundColor: "#0c344e",
                    }}
                    status
                    onClick={() =>
                      handleSaveData(popUpData.id, popUpData.status)
                    }
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Modal>
        </Grid>
        {/* date input field  */}
        <Grid my={2}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            className="dateFeild"
          >
            <DesktopDatePicker
              disablePast
              variant="standard"
              label="Enter the Date"
              inputFormat="YYYY-MM-DD"
              value={localDate}
              onChange={handleDateChange}
              formatDate={(date) => moment(date).format("YYYY-MM-DD")}
              renderInput={(params) => (
                <TextField {...params} className="dateField" />
              )}
            />
          </LocalizationProvider>
        </Grid>
        {calendarData?.length > 0 && (
          <Grid display="flex" overflow="auto" bgcolor="#F6F6F6">
            <Grid width={0.2}>
              <Box className="mainBox" borderBottom="1px dashed #D9DBE9">
                <Typography></Typography>
                <Typography></Typography>
              </Box>
              {time?.map((element) => (
                <>
                  <Typography className="boxSlot">{element}</Typography>
                </>
              ))}
            </Grid>
            <Grid width={0.2}>
              <Box className="mainBox">
                <Typography>Sunday</Typography>
                <Typography>
                  {calendarData[0]?.startTime.split(",")[1]?.split(" ")[2]}
                </Typography>
              </Box>

              {dayData?.sunday?.map((element, staffTimeSlotId) => (
                <>
                  <Typography
                    className="boxSlot"
                    key={element.index}
                    onClick={() =>
                      getSelectedStaffId(
                        element.staffTimeSlotId,
                        element.startTime,
                        element.endTime,
                        element.status
                      )
                    }
                    bgcolor={
                      element.status === "Booked"
                        ? "#FFCCCB"
                        : element.status === "Available"
                        ? "#8CDCB0"
                        : ""
                    }
                  >
                    {element.status === "Unavailable" ? "" : element.status}
                  </Typography>
                </>
              ))}
            </Grid>
            <Grid width={0.2}>
              <Box className="mainBox">
                <Typography>Monday</Typography>
                <Typography>
                  {calendarData[24]?.startTime.split(",")[1]?.split(" ")[2]}
                </Typography>
              </Box>
              {dayData?.monday?.map((element, staffTimeSlotId) => (
                <>
                  <Typography
                    className="boxSlot"
                    key={element.index}
                    onClick={() =>
                      getSelectedStaffId(
                        element.staffTimeSlotId,
                        element.startTime,
                        element.endTime,
                        element.status
                      )
                    }
                    bgcolor={
                      element.status === "Booked"
                        ? "#FFCCCB"
                        : element.status === "Available"
                        ? "#8CDCB0"
                        : ""
                    }
                  >
                    {element.status === "Unavailable" ? "" : element.status}
                  </Typography>
                </>
              ))}
            </Grid>
            <Grid width={0.2}>
              <Box className="mainBox">
                <Typography>Tuesday</Typography>
                <Typography>
                  {calendarData[48]?.startTime.split(",")[1]?.split(" ")[2]}
                </Typography>
              </Box>
              {dayData?.tuesday?.map((element, staffTimeSlotId) => (
                <>
                  <Typography
                    className="boxSlot"
                    key={element.index}
                    onClick={() =>
                      getSelectedStaffId(
                        element.staffTimeSlotId,
                        element.startTime,
                        element.endTime,
                        element.status
                      )
                    }
                    bgcolor={
                      element.status === "Booked"
                        ? "#FFCCCB"
                        : element.status === "Available"
                        ? "#8CDCB0"
                        : ""
                    }
                  >
                    {element.status === "Unavailable" ? "" : element.status}
                  </Typography>
                </>
              ))}
            </Grid>
            <Grid width={0.2}>
              <Box className="mainBox">
                <Typography>Wednesday</Typography>
                <Typography>
                  {calendarData[72]?.startTime.split(",")[1]?.split(" ")[2]}
                </Typography>
              </Box>
              {dayData?.wednesday?.map((element, staffTimeSlotId) => (
                <>
                  <Typography
                    className="boxSlot"
                    key={element.index}
                    onClick={() =>
                      getSelectedStaffId(
                        element.staffTimeSlotId,
                        element.startTime,
                        element.endTime,
                        element.status
                      )
                    }
                    bgcolor={
                      element.status === "Booked"
                        ? "#FFCCCB"
                        : element.status === "Available"
                        ? "#8CDCB0"
                        : ""
                    }
                  >
                    {element.status === "Unavailable" ? "" : element.status}
                  </Typography>
                </>
              ))}
            </Grid>
            <Grid width={0.2}>
              <Box className="mainBox">
                <Typography>Thursday</Typography>
                <Typography>
                  {calendarData[96]?.startTime.split(",")[1]?.split(" ")[2]}
                </Typography>
              </Box>
              {dayData?.thursday?.map((element, staffTimeSlotId) => (
                <>
                  <Typography
                    className="boxSlot"
                    key={element.index}
                    onClick={() =>
                      getSelectedStaffId(
                        element.staffTimeSlotId,
                        element.startTime,
                        element.endTime,
                        element.status
                      )
                    }
                    bgcolor={
                      element.status === "Booked"
                        ? "#FFCCCB"
                        : element.status === "Available"
                        ? "#8CDCB0"
                        : ""
                    }
                  >
                    {element.status === "Unavailable" ? "" : element.status}
                  </Typography>
                </>
              ))}
            </Grid>
            <Grid width={0.2}>
              <Box className="mainBox">
                <Typography>Friday</Typography>
                <Typography>
                  {calendarData[120]?.startTime.split(",")[1]?.split(" ")[2]}
                </Typography>
              </Box>
              {dayData?.friday?.map((element, staffTimeSlotId) => (
                <>
                  <Typography
                    className="boxSlot"
                    key={element.index}
                    onClick={() =>
                      getSelectedStaffId(
                        element.staffTimeSlotId,
                        element.startTime,
                        element.endTime,
                        element.status
                      )
                    }
                    bgcolor={
                      element.status === "Booked"
                        ? "#FFCCCB"
                        : element.status === "Available"
                        ? "#8CDCB0"
                        : ""
                    }
                  >
                    {element.status === "Unavailable" ? "" : element.status}
                  </Typography>
                </>
              ))}
            </Grid>
            <Grid width={0.2}>
              <Box className="mainBox">
                <Typography>Saturday</Typography>
                <Typography>
                  {calendarData[144]?.startTime.split(",")[1]?.split(" ")[2]}
                </Typography>
              </Box>
              {dayData?.saturday?.map((element, staffTimeSlotId) => (
                <>
                  <Typography
                    className="boxSlot"
                    key={element.index}
                    onClick={() =>
                      getSelectedStaffId(
                        element.staffTimeSlotId,
                        element.startTime,
                        element.endTime,
                        element.status
                      )
                    }
                    bgcolor={
                      element.status === "Booked"
                        ? "#FFCCCB"
                        : element.status === "Available"
                        ? "#8CDCB0"
                        : ""
                    }
                  >
                    {element.status === "Unavailable" ? "" : element.status}
                  </Typography>
                </>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CalenderSlot;
