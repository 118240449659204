import { Box, Grid, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { VisitirDetailById } from "../../services/allServisesApi";
import { formatAMPM } from "../../util";
import VisitorDialogs from "./VisitorDailoc";
import Image from "../../StaffDetail/assets/gallery.png";

import "../index.css";
import Loader from "../../components/Loader";

const Visitordetail = () => {
  const urlParams = useParams();
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const [visitorDetail, setVisitordetail] = useState({
    name: "",
    approvedBy: "",
    documentImage: "",
    buildingName_en: "",
    category_en: "",
    mobileNumber: "",
    description: "",
    documentCountry: "",
    documentExpire: "",
    flatName_en: "",
    floor: "",
    inTime: "",
    outTime: "",
    documentId: "",
    documentType: "",
    visitorsCount: "",
    documentIssueState: "",
    documentIssueDate: "",
    passportNumber: "",
    dateOfBirth: "",
    vehicleNumber: "",
    email: "",
    gender: "",
    nameOnDocument: "",
    numberOnDocument: "",
    occupation: "",
    age: "",
    licenseNumber: "",
    address: "",
    licenseIssueAuthority: "",
    docTypeDetail: "",
    company_en: "",
    cardNumber: "",
    profilePicture: "",
  });

  const fetchVisitorDetail = async () => {
    setLoader(true);
    const resp = await VisitirDetailById(urlParams.id);
  
    setVisitordetail({
      name: resp.data.data.name,
      approvedBy: resp.data.data.approvedBy,
      documentImage: resp.data?.data?.documentImage,
      buildingName_en: resp.data?.data.buildingName_en,
      category_en: resp.data.data?.category_en,
      company_en: resp.data.data?.company_en,
      mobileNumber: resp.data.data?.mobileNumber,
      description: resp.data?.data?.description,
      documentCountry: resp.data.data?.documentCountry,
      documentExpiry: resp.data.data?.documentExpiry,
      flatName_en: resp.data.data.flatName_en,
      floor: resp.data.data.floor,
      inTime: resp.data.data.inTime,
      outTime: resp.data.data.outTime,
      documentId: resp.data.data?.documentId,
      documentType: resp.data.data?.documentType,
      visitorsCount: resp.data.data.visitorsCount,
      documentIssueState: resp.data.data.documentIssueState,
      documentIssueDate: resp.data.data.documentIssueDate,
      passportNumber: resp.data.data.passportNumber,
      profilePicture: resp?.data?.data?.profilePicture,
      dateOfBirth: resp?.data?.data?.additionalDetails?.dateOfBirth,
      vehicleNumber: resp?.data?.data?.additionalDetails?.vehicleNumber,
      email: resp?.data?.data?.additionalDetails?.email,
      gender: resp?.data?.data?.additionalDetails?.gender,
      nameOnDocument: resp?.data?.data?.additionalDetails?.nameOnDocument,
      numberOnDocument: resp?.data?.data?.additionalDetails?.numberOnDocument,
      occupation: resp?.data?.data?.additionalDetails?.occupation,
      age: resp.data.data?.additionalDetails?.age,
      licenseNumber: resp.data.data?.additionalDetails?.licenseNumber,
      address: resp.data.data?.additionalDetails?.address,
      licenseIssueAuthority:
        resp.data.data?.additionalDetails?.licenseIssueAuthority,
      docTypeDetail: resp.data.data?.additionalDetails?.docTypeDetail,
      cardNumber: resp.data.data?.additionalDetails?.cardNumber,
      salesAdvisor: resp?.data?.data?.salesAdvisor,
      agentCompany: resp?.data?.data?.brokerDetails?.agentCompany,
      agentId: resp?.data?.data?.brokerDetails?.agentId,
      agentName: resp?.data?.data?.brokerDetails?.agentName,
      agentMobileNumber: resp?.data?.data?.brokerDetails?.agentMobileNumber,
    });
    setLoader(false);
  };
  useEffect(() => {
    fetchVisitorDetail();
  }, []);

  const handleClickOpen = () => {
    if (visitorDetail?.documentImage.length > 0) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    <div className="home">
      {loader && <Loader />}
      <VisitorDialogs
        open={open}
        handleClose={handleClose}
        visitorImage={visitorDetail?.documentImage}
      />
      <div>
        <h5>Visitor Detail</h5>
        <Grid display="flex">
          <Box onClick={handleClickOpen}>
            <a
              href={visitorDetail.documentImage}
              target="_blank"
              alt="documentimage"
            ></a>

            <img
              src={
                visitorDetail?.documentImage === null
                  ? Image
                  : visitorDetail?.documentImage
              }
              className="frame"
            />
          </Box>
          <Box ml={2}>
            {visitorDetail?.profilePicture?.length > 0 && (
              <img src={visitorDetail?.profilePicture} className="frame" />
            )}
          </Box>
        </Grid>
        <div className="d-flex gap-5 mt-4">
          <div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Name"
                multiline
                name="currentPassword"
                value={visitorDetail?.name ? visitorDetail.name : "-"}
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Mobile Number"
                multiline
                name="currentPassword"
                value={`+${visitorDetail?.mobileNumber}`}
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Flat Name"
                multiline
                name="currentPassword"
                value={
                  visitorDetail?.flatName_en ? visitorDetail.flatName_en : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="In Time"
                multiline
                name="currentPassword"
                value={visitorDetail ? formatAMPM(visitorDetail?.inTime) : "-"}
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="In Date"
                multiline
                name="currentPassword"
                value={
                  visitorDetail ? visitorDetail?.inTime.split("T")[0] : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Total visitor"
                multiline
                name="currentPassword"
                value={
                  visitorDetail?.visitorsCount
                    ? visitorDetail.visitorsCount
                    : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Document Type"
                multiline
                name="currentPassword"
                value={
                  visitorDetail?.documentType === "P"
                    ? "Passport"
                    : visitorDetail?.documentType === "IL"
                    ? "Emirates ID"
                    : visitorDetail?.documentType === "DL"
                    ? "Driving License"
                    : visitorDetail?.documentType === "AADHAR"
                    ? "Aadhaar Card"
                    : visitorDetail?.documentType === "PAN"
                    ? "PAN Card"
                    : visitorDetail?.documentType === "VOTERID"
                    ? "Voter Id Card"
                    : visitorDetail?.documentType === null
                    ? "-"
                    : visitorDetail?.documentType
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Nationality"
                multiline
                name="currentPassword"
                value={
                  visitorDetail?.documentCountry
                    ? visitorDetail?.documentCountry
                    : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Approve By"
                multiline
                name="currentPassword"
                value={
                  visitorDetail?.approvedBy ? visitorDetail.approvedBy : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Document Issue Date"
                multiline
                name="DocumentIssueState"
                value={
                  visitorDetail?.documentIssueDate
                    ? visitorDetail.documentIssueDate
                    : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Email"
                multiline
                name="Email"
                value={visitorDetail?.email ? visitorDetail.email : "-"}
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Gender"
                multiline
                name="Gender"
                value={
                  visitorDetail?.gender
                    ? visitorDetail.gender == "M"
                      ? "Male"
                      : "Female"
                    : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Name on Document"
                multiline
                name="Gender"
                value={
                  visitorDetail?.nameOnDocument
                    ? visitorDetail.nameOnDocument
                    : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Vehicle Number"
                multiline
                name="Gender"
                value={
                  visitorDetail?.vehicleNumber
                    ? visitorDetail.vehicleNumber
                    : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            {/* Age  */}
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Age"
                multiline
                name="Gender"
                value={visitorDetail?.age ? visitorDetail.age : "-"}
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            {/* License Number */}
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="License Number"
                multiline
                name="Gender"
                value={
                  visitorDetail?.licenseNumber
                    ? visitorDetail.licenseNumber
                    : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            {/* Address */}
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Address"
                multiline
                name="Gender"
                value={visitorDetail?.address ? visitorDetail?.address : "-"}
                size="small"
                variant="standard"
                required={false}
                // style={{ width: "300px" }}
                fullWidth
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Sales Advisor"
                multiline
                name="salesAdviser"
                value={
                  visitorDetail?.salesAdvisor
                    ? visitorDetail?.salesAdvisor
                    : "-"
                }
                size="small"
                variant="standard"
                required={false}
                // style={{ width: "300px" }}
                fullWidth
              />
            </div>
          </div>
          <div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Visitor Catagory"
                multiline
                name="currentPassword"
                value={
                  visitorDetail?.category_en ? visitorDetail.category_en : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Building Name"
                multiline
                name="currentPassword"
                value={
                  visitorDetail?.buildingName_en
                    ? visitorDetail.buildingName_en
                    : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Floor"
                multiline
                name="currentPassword"
                value={visitorDetail?.floor ? visitorDetail.floor : "-"}
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Time Out"
                multiline
                value={
                  visitorDetail.outTime
                    ? formatAMPM(visitorDetail?.outTime)
                    : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Out Date"
                multiline
                value={
                  visitorDetail.outTime
                    ? visitorDetail?.outTime.split("T")[0]
                    : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Documents Id"
                multiline
                value={
                  visitorDetail.documentId ? visitorDetail.documentId : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Description"
                multiline
                value={
                  visitorDetail.description ? visitorDetail.description : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Document Expire"
                multiline
                value={
                  visitorDetail.documentExpiry
                    ? visitorDetail.documentExpiry
                    : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Document Issue State"
                multiline
                value={
                  visitorDetail.documentIssueState
                    ? visitorDetail.documentIssueState
                    : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Passport Number"
                multiline
                value={
                  visitorDetail.passportNumber
                    ? visitorDetail.passportNumber
                    : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Date Of Birth"
                multiline
                value={
                  visitorDetail.dateOfBirth ? visitorDetail.dateOfBirth : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Number On Document"
                multiline
                value={
                  visitorDetail.numberOnDocument
                    ? visitorDetail.numberOnDocument
                    : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Occupation"
                multiline
                value={
                  visitorDetail.occupation ? visitorDetail.occupation : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                id="standard-multiline-flexible"
                label="Company Name"
                multiline
                value={
                  visitorDetail?.company_en ? visitorDetail?.company_en : "-"
                }
                size="small"
                variant="standard"
                required={false}
                style={{ width: "300px" }}
              />
              <div />
              <div className="mt-3">
                <TextField
                  id="standard-multiline-flexible"
                  label="Issuing authority"
                  multiline
                  value={
                    visitorDetail?.licenseIssueAuthority
                      ? visitorDetail?.licenseIssueAuthority
                      : "-"
                  }
                  size="small"
                  variant="standard"
                  required={false}
                  style={{ width: "300px" }}
                />
              </div>
              <div className="mt-3">
                <TextField
                  id="standard-multiline-flexible"
                  label="Card Number"
                  multiline
                  value={
                    visitorDetail?.cardNumber ? visitorDetail?.cardNumber : "-"
                  }
                  size="small"
                  variant="standard"
                  required={false}
                  style={{ width: "300px" }}
                />
              </div>
              <div className="mt-3">
                <TextField
                  id="standard-multiline-flexible"
                  label="Document Type Details"
                  multiline
                  value={
                    visitorDetail?.docTypeDetail
                      ? visitorDetail?.docTypeDetail
                      : "-"
                  }
                  size="small"
                  variant="standard"
                  required={false}
                  style={{ width: "300px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Typography className="mt-3" variant="h6">
          Agent Details
        </Typography>
        <div className="mt-4">
          <div
            className="d-flex justify-content-between"
            style={{ width: "640px" }}
          >
            <TextField
              id="standard-multiline-flexible"
              label="Agent Name"
              multiline
              value={visitorDetail?.agentName ? visitorDetail?.agentName : "-"}
              size="small"
              variant="standard"
              required={false}
              style={{ width: "300px" }}
            />
            <TextField
              id="standard-multiline-flexible"
              label="Agent Number"
              multiline
              value={
                visitorDetail?.agentMobileNumber
                  ? `+${visitorDetail?.agentMobileNumber}`
                  : "-"
              }
              size="small"
              variant="standard"
              required={false}
              style={{ width: "300px" }}
            />
          </div>
          <div
            className="d-flex justify-content-between mt-3"
            style={{ width: "640px" }}
          >
            <TextField
              id="standard-multiline-flexible"
              label="Agent ID"
              multiline
              value={visitorDetail?.agentId ? visitorDetail?.agentId : "-"}
              size="small"
              variant="standard"
              required={false}
              style={{ width: "300px" }}
            />
            <TextField
              id="standard-multiline-flexible"
              label="Agent Company"
              multiline
              value={
                visitorDetail?.agentCompany ? visitorDetail?.agentCompany : "-"
              }
              size="small"
              variant="standard"
              required={false}
              style={{ width: "300px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Visitordetail;
