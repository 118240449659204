import { yupResolver } from "@hookform/resolvers/yup";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Grid, MenuItem, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import OpenGallery from "../PropertyName/OpenGallery";
import pdfPhoto from "../StaffDetail/assets/pdfPhoto.png";
import { userSchema } from "../components/AuthSchema";
import CountryCodeDropDown from "../components/CountryCodeDropDown";
import DatePicker from "../components/DatePicker";
import ErrorBox from "../components/ErrorBox";
import Loader from "../components/Loader";
import NationalityDropDown from "../components/NationalityDropDown";
import UploadDocUI from "../components/UploadDocUI";
import { buildingContext } from "../login/AuthProvider";
import { addUser, uploadFile } from "../services/allPropertiesAPI";
import { getMobileNumber } from "../util";
import { removeStringFunction } from "../util/removeStringFunction";
import "./index.css";

export default function PropertyName() {
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState([]);
  const [getImageData, setGetImageData] = useState([]);
  const [getLength, setGetLength] = useState();
  const [openDocument, setOpenDocument] = useState(false);
  const [nationality, setNationality] = useState(null);
  const [userDocument, setUserDocument] = useState([]);
  const [fieldError, setFieldError] = useState({});
  const [handleLoader, setHandleLoader] = useState(false);
  const { selectedBuilding } = React.useContext(buildingContext);
  const [searchText, setSearchText] = useState("");
  let buildingId = selectedBuilding;
  const gender = ["Male", "Female"];
  const idType = ["Emirates Id", "Passport"];
  const [url, setUrl] = useState();

  const getImageUrl = async () => {
    setHandleLoader(true);
    try {
      const res = await uploadFile(getImageData);
      if (res.data.status === "success") {
        const getUrl = res.data.data.map((current) => current.location);
        setImageUrl([...getUrl]);
      } else {
        toast("Document is not uploaded. Please check your connection");
      }
    } catch {}
    setHandleLoader(false);
  };

  useEffect(() => {
    if (getLength) {
      getImageUrl();
    }
  }, [getLength]);

  const handleRemoveImg = (att) => {
    const filtered = imageUrl.filter((item) => item !== att);
    setImageUrl(filtered);
  };

  const handleValue = (name, event) => {
    let newValue = event.target.value;
    if (newValue.length > 3) {
      newValue = newValue.slice(0, 20); // restrict to 3 digits
    }
    setValue(`${name}`, newValue);
  };

  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      email: "",
      mobileNumber: "",
      documentId: "",
      documentType: "",
      alternateEmail: "",
      alternateMobileNumber: "",
      alternateCountryCode: "",
      dateOfBirth: "",
      gender: "",
      bankName: "",
      nationality: "",
      countryCode: "",
      images: "",
      accountNumber: "",
      swiftCode: "",
      iban: "",
    },
    resolver: yupResolver(userSchema),
  });

  const onSubmit = async (data) => {
    const data1 = {
      ...data,
      countryCode: data?.countryCode && data.countryCode.replace("+", ""),
      alternateCountryCode:
        data?.alternateCountryCode &&
        data?.alternateCountryCode.replace("+", ""),
      mobileNumber:
        data.mobileNumber &&
        getMobileNumber(data?.countryCode, data?.mobileNumber).mobileNumber,
      alternateMobileNumber:
        data.alternateMobileNumber &&
        getMobileNumber(data?.alternateCountryCode, data?.alternateMobileNumber)
          .mobileNumber,
      dateOfBirth: data.dateOfBirth
        ? moment(data.dateOfBirth).format("DD/MM/YYYY")
        : null,
      profilePicture: imageUrl[0],
      ...url,
    };
    dataSend(data1);
  };

  //fetching nationality and country code---------------------------------------------------------------------

  const dataSend = async (data) => {
    const getData = removeStringFunction(data);
    const postData = await addUser(getData);
    if (postData.data.status === "success") {
      navigate("/user");
      toast("Saved Successfully");
    } else {
      toast(postData.data.msg);
    }
  };
  useEffect(() => {
    setValue("countryCode", "+971");
    setValue("alternateCountryCode", "+971");
  }, []);

  const [InitialValueForCountryCode, InitialValueForAlternateCountryCode] =
    watch(["countryCode", "alternateCountryCode"]);

  const handleDocRemove = (type) => {
    const removeDoc = { ...url };
    delete removeDoc[type];
    setUrl(removeDoc);
  };
  const handleText = (e, name) => {
    let newValue = e.target.value;
    newValue = newValue.replace(/[^A-Za-z]/gi, "");
    setValue(`${name}`, newValue);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {handleLoader && <Loader />}
        <div className="home">
          <div className="Property-top">
            <div className="details">Add New User</div>

            {/*two action button  1-cancel 2-save------------------------------------------ */}
          </div>

          {/* Add userImage UI 
          ---------------------------------------*/}
          <Box width="20%" display="flex">
            <OpenGallery
              setGetImageData={setGetImageData}
              setGetLength={setGetLength}
              accept=".png,.jpeg,.jpg,.svg"
            />
            <Box m={2} display="flex" gap={2}>
              <Box display="flex" gap={2}>
                {imageUrl.map((curElem, id) => (
                  <Box position="relative" width="10rem" height="10rem">
                    <img
                      style={{ height: "100%", width: "100%" }}
                      alt="..."
                      src={curElem}
                      name="images"
                    />
                    <CancelIcon
                      style={{
                        position: "absolute",
                        right: "2px",
                        top: "5px",
                        color: "#000",
                      }}
                      onClick={() => handleRemoveImg(curElem)}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <div
            style={{
              display: "flex",
            }}
          >
            {/* Left data adding user information------------------------------------------------------------- */}

            <Grid>
              <Box
                className="inputColor"
                width="50vw"
                display="flex"
                justifyContent="space-between"
                my={5}
              >
                <Box width="50vw">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="standard-basic"
                        inputProps={{ maxLength: 50 }}
                        label="Name*"
                        variant="standard"
                        // onChange={(e) => {
                        //   handleText(e, "name");
                        // }}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "red",
                    }}
                  >
                    {errors?.name?.message}
                  </Typography>
                </Box>
              </Box>
              <Box
                className="inputColor"
                width="100%"
                display="flex"
                justifyContent="space-between"
                my={2}
              >
                <Box>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="standard-basic"
                        label="Email*"
                        variant="standard"
                        sx={{ width: "300px" }}
                      />
                    )}
                  />
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "red",
                    }}
                  >
                    {errors?.email?.message && "Email invalid"}
                  </Typography>
                </Box>
                <Grid width="45%">
                  <Box display="flex">
                    <Box sx={{ marginTop: "17px" }}>
                      <CountryCodeDropDown
                        name="countryCode"
                        value={InitialValueForCountryCode}
                        register={register}
                      />
                    </Box>
                    <Box>
                      <Controller
                        name="mobileNumber"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            inputProps={{
                              maxLength: 13 - InitialValueForCountryCode.length,
                              minLength: 13 - InitialValueForCountryCode.length,
                            }}
                            id="mobileNumber"
                            variant="standard"
                            label="Mobile Number*"
                            sx={{ width: "240px", marginLeft: "2%" }}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "red",
                    }}
                  >
                    {errors?.mobileNumber?.message && "Enter the valid number"}
                  </Typography>
                </Grid>
              </Box>
              <Box
                className="inputColor"
                width="100%"
                display="flex"
                justifyContent="space-between"
                my={5}
              >
                <DatePicker
                  label="D.O.B"
                  Controller={Controller}
                  control={control}
                  name="dateOfBirth"
                  register={register}
                  width={"300px"}
                  maxDate={Date.now()}
                />

                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      id="standard-basic"
                      label="Gender"
                      variant="standard"
                      sx={{ width: "45%" }}
                    >
                      {gender?.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Box>
              <Box
                className="inputColor"
                width="100%"
                display="flex"
                justifyContent="space-between"
                my={5}
              >
                <Box>
                  <Controller
                    name="alternateEmail"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="standard-basic"
                        label="Alternate Email"
                        variant="standard"
                        sx={{ width: "310px" }}
                      />
                    )}
                  />
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "red",
                    }}
                  >
                    {errors?.alternateEmail?.message}
                  </Typography>
                </Box>

                <Grid width="45%">
                  <Box display="flex">
                    <Box sx={{ marginTop: "17px" }}>
                      <CountryCodeDropDown
                        name="alternateCountryCode"
                        value={InitialValueForAlternateCountryCode}
                        register={register}
                      />
                    </Box>
                    <Box>
                      <Controller
                        name="alternateMobileNumber"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            inputProps={{
                              maxLength:
                                13 - InitialValueForAlternateCountryCode.length,
                              minLength:
                                13 - InitialValueForAlternateCountryCode.length,
                            }}
                            id="mobileNumber"
                            label="Alternate Mobile Number"
                            variant="standard"
                            sx={{ width: "240px", marginLeft: "2%" }}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "red",
                    }}
                  >
                    {errors?.alternateMobileNumber?.message}
                  </Typography>
                </Grid>
              </Box>
              <Box
                className="inputColor"
                width="100%"
                display="flex"
                justifyContent="space-between"
                my={5}
              >
                <Box>
                  <Controller
                    name="documentType"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        id="standard-basic"
                        label="Id Type"
                        variant="standard"
                        sx={{ width: "300px" }}
                      >
                        {idType?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Box>
                <Box>
                  <Controller
                    name="documentId"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="standard-basic"
                        label="Id Number"
                        inputProps={{ maxLength: 30 }}
                        variant="standard"
                        sx={{ width: "325px" }}
                      />
                    )}
                  />
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "red",
                    }}
                  >
                    {errors?.documentId?.message.slice(0, 30)}
                  </Typography>
                </Box>
              </Box>
              <Box
                className="inputColor"
                width="100%"
                display="flex"
                justifyContent="space-between"
                my={5}
              >
                <NationalityDropDown
                  register={register}
                  name="nationality"
                  label="Select Nationality"
                />
              </Box>
              <Typography variant="h6">Bank Details:-</Typography>
              <Box
                className="inputColor"
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                <Box>
                  <Controller
                    name="bankName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="standard-basic"
                        label="Bank Name"
                        inputProps={{ maxLength: 50 }}
                        variant="standard"
                        sx={{ width: "310px" }}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Box sx={{ marginLeft: "70px" }}>
                    <Controller
                      name="accountNumber"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="number"
                          id="standard-basic"
                          onChange={(e) => {
                            handleValue("accountNumber", e);
                          }}
                          label="Bank Account Number"
                          variant="standard"
                          sx={{ width: "310px" }}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                className="inputColor"
                width="100%"
                display="flex"
                justifyContent="space-between"
                my={5}
              >
                <Box>
                  <Controller
                    name="swiftCode"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="standard-basic"
                        label="Swift Code"
                        variant="standard"
                        sx={{ width: "310px" }}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Controller
                    name="iban"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        id="standard-basic"
                        onChange={(e) => {
                          handleValue("iban", e);
                        }}
                        label="Iban"
                        variant="standard"
                        sx={{ width: "310px" }}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Grid>
            {/* Right data adding document----------------------------------------------------------------------- */}

            <div
              style={{
                position: "relative",
                width: "300px",
                marginLeft: "2rem",
              }}
            >
              <div
                className="details"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Attach Document
                <Button
                  variant="outline-success"
                  className="Button"
                  onClick={() => {
                    setOpenDocument(!openDocument);
                  }}
                  style={{
                    border: "1px dotted #0C344E",
                    color: "#0C344E",
                    borderRadius: "0.5rem",
                    background: "white",
                    width: "13rem",
                    height: "48px",
                    marginLeft: "0rem",
                    marginTop: "1rem",
                  }}
                >
                  + Select Document
                </Button>
              </div>

              {fieldError.document && <ErrorBox mes={fieldError.document} />}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              ></div>

              {openDocument && (
                <UploadDocUI
                  openDocument={openDocument}
                  userDocument={userDocument}
                  setOpenDocument={setOpenDocument}
                  setUserDocument={setUserDocument}
                  url={url}
                  setUrl={setUrl}
                />
              )}
              {url &&
                Object.entries(url)?.map(([key, value], id) => (
                  <Grid my={2}>
                    <Typography ml={2}>
                      {key === "passportImage" ? "Passport Id" : "Gov. Id"}
                    </Typography>
                    <Box
                      className="card"
                      display="flex"
                      justifyContent="space-between"
                      p={2}
                      m={2}
                      style={{
                        boxShadow: " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                        borderStyle: "none",
                      }}
                      width="18rem"
                    >
                      <Box display="flex" justifyContent="space-between">
                        <Typography>
                          <img
                            src={pdfPhoto}
                            style={{ marginRight: "10px" }}
                            alt=""
                          />
                          {value[0]?.split("/")[3]?.split(".")[0]?.slice(0, 10)}
                        </Typography>
                        <Typography>
                          <a
                            download
                            href={value[0]}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <RemoveRedEyeIcon />
                          </a>
                          <CloseIcon onClick={() => handleDocRemove(key)} />
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
            </div>
          </div>
          <Button
            type="submit"
            className="Button"
            variant="contained"
            color="success"
            onClick={handleSubmit}
            style={{
              border: "1px solid #0C344E",
              color: "white",
              background: "rgb(12, 52, 78)",
              width: "150px",
              height: "48px",
              borderRadius: "10px",
              textTransform: "capitalize",
              marginTop: "2rem",
            }}
          >
            Save
          </Button>
        </div>
      </form>
    </>
  );
}
