import {
  Box,
  FormControl,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

const CountryCodeDropDown = ({ name, value, register, display }) => {
  const [countryCodeList, setCountryCodeList] = useState(null);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const fetchCountryCode = async () => {
      const res = await axios.get(
        "https://gist.githubusercontent.com/anubhavshrimal/75f6183458db8c453306f93521e93d37/raw/f77e7598a8503f1f70528ae1cbf9f66755698a16/CountryCodes.json"
      );
      setCountryCodeList(res.data);
    };
    fetchCountryCode();
  }, []);
  return (
    <div>
      <FormControl fullWidth>
        <Select
          MenuProps={{ autoFocus: false }}
          sx={{ width: "70px", display: { display } }}
          labelId="search-select-label"
          id="search-select"
          variant="standard"
          {...register(name)}
          name={name}
          className="customiseCountryCode"
          value={value}
          onClose={() => setSearchText("")}
        >
          <ListSubheader>
            <TextField
              size="small"
              variant="standard"
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {countryCodeList
            ?.filter((option) =>
              option.name.toLowerCase().includes(searchText.toLowerCase())
            )
            .map((option) => (
              <MenuItem
                key={option.key}
                value={option.dial_code}
                sx={{ backgroundColor: "#fff" }}
              >
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                >
                  <img
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.name} ({option.dial_code})
                </Box>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default React.memo(CountryCodeDropDown);
