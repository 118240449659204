import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Typography
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { updateHelpline } from "../../Redux/HelplineSlice";
import Image from "../../StaffDetail/assets/gallery.png";
import { helpLineSchema } from "../../components/AuthSchema";
import BuildingMultiSelectDropdown from "../../components/BuildingMultiSelectDropdown";
import CountryCodeDropDown from "../../components/CountryCodeDropDown";
import Textfiled from "../../components/Textfiled";
import { buildingContext } from "../../login/AuthProvider";
import { getMobileNumber } from "../../util";
import DumpImageUi from "../DumpImageUi";
import DumpImage from "../createHelpLine/DumpImage";

const EditHelpline = ({
  helplineId,
  helplineInfo,
  handleEditButton,
  handleEditDocButton,
  setHandleEditMode,
}) => {
  const dispatch = useDispatch();
  const { selectedBuilding } = React.useContext(buildingContext);
  const [getImageData, setGetImageData] = useState([]);
  const [getLength, setGetLength] = useState();
  const [buildingData, setBuildingData] = useState([]);
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name_en: helplineInfo?.name_en,
      contactNumber: helplineInfo.contactNumber,
      countryCode: helplineInfo.countryCode
        ? `+${helplineInfo.countryCode}`
        : "+971",
      image: helplineInfo.image,
      buildingName: helplineInfo?.building?.name_en,
      buildings: helplineInfo?.helplineBuildings,
      isTollFree: helplineInfo?.isTollFree,
      isForAllBuildings: helplineInfo.isForAllBuildings,
    },
    mode: "onChange",
    resolver: yupResolver(helpLineSchema),
  });

  const [buildings, isTollFree, countryCode, image, isForAllBuildings] = watch([
    "buildings",
    "isTollFree",
    "countryCode",
    "image",
    "isForAllBuildings",
  ]);

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDelete = (CheckedEle, val) => {
    if (val === "old") {
      const result = buildings?.filter((curElem) => {
        return curElem?.building?.name_en !== CheckedEle;
      });
      setValue("buildings", result);
    } else {
      const result = buildings?.filter((curElem) => {
        return curElem?.name_en !== CheckedEle;
      });
      setValue("buildings", result);
    }
  };

  const onSubmit = (data) => {
    setHandleEditMode(false);
    const payload = {
      name_en: data.name_en,
      name_ar: data.name_en,
      contactNumber: data.contactNumber,
      isTollFree: data.isTollFree,
      ...(!isForAllBuildings && {
        buildings: data.buildings.map(({building}) => building.id),
      }),
      image: data.image,
      ...(!isTollFree && {
        countryCode: getMobileNumber(data.countryCode).countryCode,
      }),
      ...(isForAllBuildings && {
        isForAllBuildings,
      }),
    };
    

    dispatch(updateHelpline({ payload, helplineId }));
  };
 
  return (
    <Box padding="20px 0px 50px 20px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" justifyContent="space-between">
          <h4>Emergency Contact Details</h4>
          <Box>
            <Button
              style={{ textDecoration: "underLine" }}
              onClick={() => {
                setHandleEditMode(false);
              }}
            >
              Cancel
            </Button>
            <Button type="submit" style={{ textDecoration: "underLine" }}>
              Save Changes
            </Button>
          </Box>
        </Box>

        <Stack direction="row" spacing={2}>
          <Box>
            <DumpImage {...{ handleClose, open, setValue }}>
              <DumpImageUi {...{ setValue, handleClose }} />
            </DumpImage>
            <Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                bgcolor="transparent"
                borderRadius={1}
                style={{ border: "1px dashed #dfe0e0", cursor: "pointer" }}
                p={2.2}
                width="13rem"
                onClick={handleClickOpen}
              >
                <img
                  alt=""
                  src={Image}
                  style={{ height: "4rem", width: "4rem", padding: "" }}
                />
                <Typography my={2}>
                  <AddIcon /> Add Image
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            {image?.length > 0 && (
              <Box position="relative" width="10rem" height="10rem">
                <img
                  style={{ height: "100%", width: "100%" }}
                  alt="..."
                  src={image || helplineInfo?.name}
                  name="images"
                />

                <CancelIcon
                  style={{
                    position: "absolute",
                    right: "2px",
                    top: "5px",
                    color: "#000",
                  }}
                  onClick={() => setValue("image", "")}
                />
              </Box>
            )}
          </Box>
        </Stack>
        <Box className="d-flex">
          <Box width="75%">
            <Textfiled
              label={"Name*"}
              type="text"
              width="83%"
              name="name_en"
              setValue={setValue}
              register={register}
            />
            <Typography
              sx={{
                fontStyle: "italic",
                fontSize: "13px",
                color: "red",
              }}
            >
              {errors?.name_en?.message}
            </Typography>
            <Box>
              <Box display="flex">
                <Controller
                  name="isTollFree"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={isTollFree ? isTollFree : false}
                      {...register("isTollFree")}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />

                <Typography sx={{ marginTop: "10px" }}>Toll Free</Typography>
              </Box>
              <Box display="flex">
                <Box marginTop="20px" marginRight="5px">
                  <CountryCodeDropDown
                    name="countryCode"
                    display={!isTollFree ? "visible" : "none"}
                    register={register}
                  />
                </Box>
                <Box marginTop="-9px">
                  <Textfiled
                    label={"Mobile Number*"}
                    type="text"
                    width={isTollFree ? "385px" : "317px"}
                    name="contactNumber"
                    register={register}
                  />
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "red",
                    }}
                  >
                    {errors?.contactNumber?.message &&
                      "ContactNumber must be a number"}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: "15px",
                display: "flex",
                width: "300px",
                marginLeft: "-12px",
              }}
            >
              <Controller
                name="isForAllBuildings"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={isForAllBuildings ? isForAllBuildings : false}
                    {...register("isForAllBuildings")}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
              <Typography sx={{ marginTop: "10px" }}>
                ForAllBuildings*
              </Typography>
            </Box>
            <Box>
              <BuildingMultiSelectDropdown
                label="Select Property"
                name="buildings"
                setValue={setValue}
                width="395px"
                innerWidth="395px"
                buildings={buildings}
                disabled={isForAllBuildings}
              />
            </Box>
            <Typography
              sx={{
                fontStyle: "italic",
                fontSize: "13px",
                color: "red",
              }}
            >
              {errors?.buildings?.message}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                columnGap: "10px",
                rowGap: "10px",
                marginTop: "10px",
              }}
            >
              {buildings.map((ele) => (
                <Chip
                  sx={{
                    width: "150px",
                    display: "flex",
                    justifyContent: "space-between",
                    pointerEvents: isForAllBuildings ? "none" : "auto",
                  }}
                  label={ele?.building ? ele?.building?.name_en : ele?.name_en}
                  variant="outlined"
                  onDelete={() => {
                    handleDelete(
                      ele?.building ? ele?.building?.name_en : ele?.name_en,
                      ele?.building ? "old" : "new"
                    );
                  }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default EditHelpline;
