import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import { Link } from "react-router-dom";
import { getGenerateInvoice } from "../services/allServisesApi";
import "./AllRequesttable.css";
import Loader from "./Loader";
import { buildingContext } from "../login/AuthProvider";

export default function GenerateInvoicetable({ id }) {
  const [generateCatalouge, setGenerateCatalouge] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [postPerpage, setPostPerPage] = React.useState(10);
  const [currentPage, setcurrentPage] = React.useState(1);
  const [totalCount, settotalCount] = React.useState(0);
  const { selectedBuilding } = React.useContext(buildingContext);

  let buildingId = selectedBuilding;

  const GenerateInvoice = async () => {
    setLoader(true);
    const resp = await getGenerateInvoice(currentPage, postPerpage, buildingId);
    let totalData = resp.data.data.count;
    let pagesCount = Math.ceil(totalData / postPerpage);
    settotalCount(pagesCount);
    setGenerateCatalouge(resp.data.data.rows);
    setLoader(false);
  };
  useEffect(() => {
    GenerateInvoice();
    // return () => {
    //     setGenerateCatalouge([]);
    // };
  }, [currentPage]);
  const [handleNoData, setHandleNoData] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setHandleNoData(true);
    }, 2000);
  }, []);
  return (
    <>
      {loader && <Loader />}
      {generateCatalouge.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table sx={{}} aria-label="simple table">
              <TableHead className="thead">
                <TableRow>
                  <TableCell align="center" className="Sbold">
                    Request ID
                  </TableCell>
                  <TableCell align="center" className="Sbold">
                    Urgency
                  </TableCell>
                  <TableCell align="center" className="Sbold">
                    Unit No.
                  </TableCell>
                  <TableCell align="center" className="Sbold">
                    Request Type
                  </TableCell>
                  <TableCell align="center" className="Sbold">
                    Request Status
                  </TableCell>
                  <TableCell align="center" className="Sbold">
                    Staff Assigned
                  </TableCell>
                  <TableCell align="center" className="Sbold">
                    User Name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {generateCatalouge.map((row) => (
                  <TableRow
                    key={row.requestId}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell align="center">
                      <Link
                        to={`/generateinvoice/${row.id}`}
                        className="generateId"
                      >
                        {row.requestId}
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      {row.isUrgent ? (
                        <div className=" bgurgent d-flex justify-content-center align-items-center gbgcolor">
                          Urgent
                        </div>
                      ) : null}
                    </TableCell>
                    <TableCell align="center">{row.flat.name_en}</TableCell>
                    <TableCell align="center">{row.type}</TableCell>
                    <TableCell align="center">
                      {row.status == "Pending" && (
                        <span className="pending">{row.status}</span>
                      )}
                      {row.status == "In-Process" && (
                        <span className="inprocess">{row.status}</span>
                      )}
                      {row.status == "Completed" && (
                        <span className="success">{row.status}</span>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row.staff?.name ? row.staff?.name : <p>-</p>}
                    </TableCell>
                    <TableCell align="center">
                      {row.user ? row.name : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex justify-content-between height-200px mt-2">
            <div className="">
              <p>
                Showing {currentPage} out of {totalCount} entries
              </p>
            </div>
            <div className="">
              <Pagination
                count={totalCount}
                onChange={(event, value) => {
                  setcurrentPage(value);
                }}
                shape="rounded"
                style={{ margin: "10px", float: "right" }}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center mt-5">
          <p className="nofound">{handleNoData && "No Data Found"}</p>
        </div>
      )}
    </>
  );
}
