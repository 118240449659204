import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export default function ColorTabs({ status, count, color, icons }) {
  return (
    <>
      <Grid>
        <Card
          sx={{
            width: "180px",
            height: "100px",
            textAlign: "center",
            border: "1px solid #efefef",
            borderRadius: "1rem",
          }}
        >
          <CardContent sx={{ borderBottom: `5px solid ${color}` }}>
            <Box display="flex" justifyContent="space-between">
              <Typography color={color}>{status}</Typography>
              <Typography color={color}>{icons}</Typography>
            </Box>
            <Typography
              textAlign="left"
              fontSize={20}
              fontWeight="800 !important"
            >
              {count}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
