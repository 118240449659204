import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Checkbox, Chip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { helpLineSchema } from "../../components/AuthSchema";
import BuildingMultiSelectDropdown from "../../components/BuildingMultiSelectDropdown";
import CountryCodeDropDown from "../../components/CountryCodeDropDown";
import Loader from "../../components/Loader";
import Textfiled from "../../components/Textfiled";
import { buildingContext } from "../../login/AuthProvider";
import { createHelpline } from "../../Redux/HelplineSlice";
import Image from "../../StaffDetail/assets/gallery.png";
import { getMobileNumber } from "../../util";
import DumpImageUi from "../DumpImageUi";
import DumpImage from "./DumpImage";

const CreateHelpLine = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getImageData, setGetImageData] = useState([]);
  const [getLength, setGetLength] = useState();
  const [image, setImage] = useState("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getImageUrl = async () => {
    try {
      const res = await uploadFile(getImageData);
      if (res.data.status === "success") {
        const getUrl = res.data.data.map((current) => current.location);

        setImage(getUrl[0]);
      } else {
        toast("Document is not uploaded.");
      }
    } catch {}
  };

  useEffect(() => {
    if (getLength) {
      getImageUrl();
    }
  }, [getLength]);

  const { selectedBuilding } = React.useContext(buildingContext);
  const { isLoading } = useSelector((state) => state.HelplineData);
  const [buildingDataList, setBuildingDataList] = useState([]);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name_en: "",
      contactNumber: "",
      buildings: [],
      isTollFree: false,
      countryCode: "",
      image: "",
      isForAllBuildings: false,
    },
    mode: "onChange",
    resolver: yupResolver(helpLineSchema),
  });

  const [
    buildings,
    isTollFree,
    IntialvalueForCountryCode,
    uploadFile,
    isForAllBuildings,
  ] = watch([
    "buildings",
    "isTollFree",
    "countryCode",
    "image",
    "isForAllBuildings",
  ]);
  useEffect(() => {
    setValue("countryCode", "+971");
  }, []);

  const handleDelete = (CheckedEle) => {
    const result = buildings?.filter((curElem) => {
      return curElem?.id !== CheckedEle;
    });
    setValue("buildings", result);
  };

  const onSubmit = (data) => {
    const payload = {
      name_en: data.name_en,
      name_ar: data.name_en,
      contactNumber: data.contactNumber,
      isTollFree: data.isTollFree,
      ...(!isForAllBuildings && {
        buildings: data.buildings.map((building) => building.id),
      }),
      image: data.image,
      ...(!isTollFree && {
        countryCode: getMobileNumber(data.countryCode).countryCode,
      }),
      ...(isForAllBuildings && {
        isForAllBuildings,
      }),
    };

    dispatch(createHelpline({ payload, navigate }));
  };
  return (
    <div className="top-level-container">
      {isLoading && <Loader />}
      <div className="home">
        <h4>Add Emergency Contact</h4>
        <Box display={"flex"}>
          <DumpImage {...{ handleClose, open, setValue }}>
            <DumpImageUi {...{ setValue, handleClose }} />
          </DumpImage>
          <Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              bgcolor="transparent"
              borderRadius={1}
              style={{ border: "1px dashed #dfe0e0", cursor: "pointer" }}
              p={2.2}
              width="13rem"
              onClick={handleClickOpen}
            >
              <img
                alt=""
                src={Image}
                style={{ height: "4rem", width: "4rem", padding: "" }}
              />
              <Typography my={2}>
                <AddIcon /> Add Image
              </Typography>
            </Box>
          </Box>
          <Box>
            {uploadFile.length > 0 && (
              <Box position="relative" width="10rem" height="10rem">
                <img
                  style={{ height: "100%", width: "100%", marginLeft: "15px" }}
                  alt="..."
                  src={uploadFile}
                  name="images"
                />

                <CancelIcon
                  style={{
                    position: "absolute",
                    right: "-10px",
                    top: "5px",
                    color: "#000",
                  }}
                  onClick={() => setValue("image", "")}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box className="d-flex">
          <Box width="75%">
            <Textfiled
              label={"Emergency Contact Name*"}
              inputProps={{ maxLength: 50 }}
              type="text"
              width="83%"
              name="name_en"
              register={register}
              setValue={setValue}
            />
            <Typography
              sx={{
                fontStyle: "italic",
                fontSize: "13px",
                color: "red",
              }}
            >
              {errors?.name_en?.message}
            </Typography>
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                width: "300px",
                marginLeft: "-12px",
              }}
            >
              <Controller
                name="isTollFree"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    {...register("isTollFree")}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />

              <Typography sx={{ marginTop: "10px" }}>Toll Free*</Typography>
            </Box>
            <Box display="flex">
              <Box sx={{ marginTop: "29px", marginRight: "5px" }}>
                <CountryCodeDropDown
                  name="countryCode"
                  display={!isTollFree ? "visible" : "none"}
                  value={IntialvalueForCountryCode}
                  register={register}
                />
              </Box>
              <Box>
                <Textfiled
                  label={"Emergency Contact Number*"}
                  type="text"
                  width={!isTollFree ? "570px" : "640px"}
                  name="contactNumber"
                  IntialvalueForCountryCode={IntialvalueForCountryCode}
                  register={register}
                />
                <Typography
                  sx={{
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "red",
                  }}
                >
                  {errors?.contactNumber?.message &&
                    "Emergency contact must be a number"}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: "15px",
                display: "flex",
                width: "300px",
                marginLeft: "-12px",
              }}
            >
              <Controller
                name="isForAllBuildings"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    {...register("isForAllBuildings")}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
              <Typography sx={{ marginTop: "10px" }}>
                ForAllBuildings*
              </Typography>
            </Box>
            <Box sx={{ marginTop: "5px" }}>
              <Box>
                <BuildingMultiSelectDropdown
                  label="Select Property*"
                  name="buildings"
                  setValue={setValue}
                  width="650px"
                  innerWidth="650px"
                  buildings={buildings}
                  disabled={isForAllBuildings}
                />
              </Box>
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "red",
                }}
              >
                {errors?.buildings?.message}
              </Typography>
              {/* <TextField
                select
                label="Building Name"
                inputProps={{ maxLength: 100 }}
                multiline
                maxRows={4}
                maxLength={10}
                value={selectedBuilding ? selectedBuilding : building}
               
                name="building"
                variant="standard"
                style={{
                  fontFamily: "Barlow",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  width: "645px",
                  lineHeight: "19px",
                }}
              >
                {buildingDataList?.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name_en}
                    </MenuItem>
                  );
                })}
              </TextField> */}
            </Box>
            <Stack direction="row" spacing={2} marginTop="10px">
              {buildings.map((ele) => (
                <Chip
                  sx={{
                    width: "150px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  label={ele?.name_en}
                  variant="outlined"
                  onDelete={() => {
                    handleDelete(ele?.id);
                  }}
                />
              ))}
            </Stack>
            <Box>
              <button
                type="submit"
                id="savebtn"
                className="Button"
                variant="contained"
                color="success"
                style={{
                  border: "1px solid #0C344E",
                  color: "#0C344E",
                  background: "#0c344e",
                  width: "187px",
                  height: "48px",
                  fontFamily: "Barlow",
                  fontSize: "16px",

                  marginTop: "20px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                  color: "white",
                }}
                onClick={handleSubmit(onSubmit)}
              >
                Add Contact
              </button>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default CreateHelpLine;
