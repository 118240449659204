export const removeStringFunction = (data) => {
  const objArr = Object.entries(data)
    ?.map(([key, value], id) => {
      return typeof value === Object
        ? [removeStringFunction(value)]
        : [key, value === "" ? null : value];
    })
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
  return objArr;
};
