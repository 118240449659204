import React from "react";
import InputBase from "@mui/material/InputBase";
import "../App.css";

export default React.memo(function SearchBar({
  placeholder,
  onChange,
  maxLength,
}) {
  return (
    <>
      <div className="d-flex justify-content-around">
        <div className="searchIcon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
              fill="#111120"
            />
            <path
              d="M22.0014 22.7495C21.8114 22.7495 21.6214 22.6795 21.4714 22.5295L19.4714 20.5295C19.1814 20.2395 19.1814 19.7595 19.4714 19.4695C19.7614 19.1795 20.2414 19.1795 20.5314 19.4695L22.5314 21.4695C22.8214 21.7595 22.8214 22.2395 22.5314 22.5295C22.3814 22.6795 22.1914 22.7495 22.0014 22.7495Z"
              fill="#111120"
            />
          </svg>
        </div>
        <InputBase
          style={{
            background: "white",
            height: "50px",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
            width: "100%",
            marginRight: "40px",
          }}
          placeholder={placeholder}
          inputProps={{
            "aria-label": "Search",
            maxLength: `${maxLength}`,
          }}
          name="search"
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    </>
  );
});
