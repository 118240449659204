import React from "react";
import UserDetailPage from "./UserDetail";

export default function UserDetail() {
  return (
    <>
    
      <div className="top-level-container">
    
        <UserDetailPage />
      </div>
    </>
  );
}
