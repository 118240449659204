import CloseIcon from "@mui/icons-material/Close";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import OpenDoc from "../PropertyName/OpenDoc";
import {
  editPreventativeSlice
} from "../Redux/PreventativeSlice";
import edit from "../StaffDetail/assets/edit.png";
import pdfPhoto from "../StaffDetail/assets/pdfPhoto.png";
import Loader from "../components/Loader";
import { uploadFile } from "../services/allPropertiesAPI";
import { getPreventativeDetailService } from "../services/preventativeService";
import { showDash } from "../util";
import EditPreventative from "./EditPreventative";

const PreventativeDetails = () => {
  const { pathname } = useLocation();
  const id = pathname.split("/")[2];
  const dispatch = useDispatch();
  const [handleEditMode, setHandleEditMode] = useState(false);
  const [handleEditDocMode, setHandleEditDocMode] = useState(false);
  const [getImageDataDoc, setGetImageDataDoc] = useState();
  const [documentUrl, setDocumentUrl] = useState([]);
  const [handleLoader, setHandleLoader] = useState(false);
  const [getLengthDoc, setGetLengthDoc] = useState();
  const [getData, setGetData] = useState([]);
  const [documentState, setDocumentState] = useState({
    documents: [],
  });
  const { isSuccess } = useSelector((state) => state.PreventativeData);
  const [handleShift, setHandleShift] = useState(false);

  const heading = ["Job Id", "Job Date", "Job Time"];
  const getDetails = async () => {
    try {
      const res = await getPreventativeDetailService(id);
      if (res.data.status === "success") {
        setGetData(res?.data?.data);
      } else {
        // toast(res.data);
      }
    } catch {}
  };

  useEffect(() => {
    getDetails();
  }, [handleEditMode, !handleEditDocMode]);

  useEffect(() => {
    setDocumentState({
      documents: getData?.documents,
    });
  }, [getData?.documents]);

  const uiArr = [
    { heading: "Preventative Id", value: getData?.pmId },
    { heading: "Name", value: getData?.name },
    // { heading: "Type", value: getData?.type },
    { heading: "Category", value: getData?.category },
    {
      heading: "Type Name",
      value:
        getData?.type === "Asset"
          ? getData?.asset?.name
          : getData?.type === "Building"
          ? getData?.building?.name_en
          : getData?.type === "Flat"
          ? getData?.flat?.name_en
          : "-",
    },
    { heading: "Pattern", value: getData?.cronDescription },
    { heading: "Priority", value: getData?.priority },
    { heading: "Valid From", value: getData?.validFrom },
    { heading: "Valid Till", value: getData?.validTill },
  ];

  //   document upload and delete functionality
  const handleRemoveDoc = (att, type) => {
    const filtered = documentState.documents.filter((item) => item !== att);
    setDocumentUrl(filtered);
    setDocumentState({
      documents: filtered,
    });
  };
  const getDocUrl = async () => {
    setHandleLoader(true);
    try {
      const res = await uploadFile(getImageDataDoc);
      if (res.data.status === "success") {
        const getUrl = res.data.data.map((current) => current.location);
        setDocumentUrl([...documentUrl, ...getUrl]);
        setDocumentState({
          documents: [...getUrl, ...documentState.documents],
        });
      } else {
        toast("Document is not uploaded. Please check your connection");
      }
    } catch {}
    setHandleLoader(false);
  };
  useEffect(() => {
    if (getLengthDoc) {
      getDocUrl();
    }
  }, [getLengthDoc]);

  const handleUpdateData = () => {
    const data = {
      documents: documentState.documents,
      id: getData?.id,
    };
    dispatch(editPreventativeSlice(data));
    getDetails();
    setHandleEditDocMode(false);
  };

  return (
    <Grid className="top-level-container">
      <Grid className="home">
        {handleLoader && <Loader />}
        <Button
          variant={handleShift === false ? "contained" : "outlined"}
          sx={{
            marginRight: "1rem",
            borderRadius: "1rem",
            backgroundColor: handleShift === false ? "#0C344E" : "",
          }}
          onClick={() => setHandleShift(false)}
        >
          PPM Details
        </Button>
        <Button
          variant={handleShift === true ? "contained" : "outlined"}
          sx={{
            borderRadius: "1rem",
            backgroundColor: handleShift === true ? "#0C344E" : "",
          }}
          onClick={() => setHandleShift(true)}
        >
          PPM Schedule
        </Button>
        {!handleShift && (
          <Grid container gap={2} mt={2}>
            <Grid item xs={7.8}>
              <Box className="card" p={2}>
                {handleEditMode ? (
                  <EditPreventative
                    setHandleEditMode={setHandleEditMode}
                    getData={getData}
                  />
                ) : (
                  <>
                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        color="#111120"
                        fontSize={20}
                        lineHeight={1.5}
                      >
                        Preventative Details
                      </Typography>
                      <Box>
                        <img
                          alt="..."
                          src={edit}
                          onClick={() => setHandleEditMode(true)}
                        />
                      </Box>
                    </Box>
                    {uiArr?.map((option) => {
                      return (
                        <>
                          <Box
                            sx={{
                              "&:nth-of-type(even)": {
                                backgroundColor: "#EFF0F6",
                              },
                            }}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            // bgcolor="#fafafa"
                            my={1}
                            px={1.5}
                          >
                            <Typography color="#111120" lineHeight={2.5}>
                              {option?.heading}
                            </Typography>
                            <Typography>{showDash(option?.value)}</Typography>
                          </Box>
                        </>
                      );
                    })}
                    <Box my={2} px={1.5}>
                      <Typography color="#111120" lineHeight={2.5}>
                        Description :-
                      </Typography>
                      <Typography>{showDash(getData?.description)}</Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box className="card" height="100vh">
                {handleEditDocMode === true ? (
                  <>
                    <Box display="flex" justifyContent="space-between" m={2}>
                      <Typography
                        color="#111120"
                        fontSize={20}
                        lineHeight={1.5}
                      >
                        Edit Documents
                      </Typography>
                      <Box
                        color="#0C344E"
                        fontSize={16}
                        sx={{ cursor: "pointer", textDecoration: "underLine" }}
                        onClick={handleUpdateData}
                      >
                        Save Changes
                      </Box>
                    </Box>
                    <Grid>
                      <Typography textAlign="center" fontSize="20px">
                        Attach Document
                      </Typography>
                      <OpenDoc
                        setGetImageDataDoc={setGetImageDataDoc}
                        setGetLengthDoc={setGetLengthDoc}
                      />
                    </Grid>
                    <Grid>
                      {documentState?.documents?.length > 0 &&
                        documentState?.documents?.map((current) => (
                          <Box
                            className="card"
                            display="flex"
                            justifyContent="space-between"
                            p={2}
                            m={2}
                            style={{
                              boxShadow:
                                " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                              borderStyle: "none",
                            }}
                          >
                            <Box display="flex" justifyContent="space-between">
                              <Typography>
                                <img
                                  src={pdfPhoto}
                                  style={{ marginRight: "10px" }}
                                  alt=""
                                />
                                {current
                                  ?.split("/")[3]
                                  ?.split(".")[0]
                                  ?.slice(0, 10)}
                              </Typography>
                              <Typography>
                                <a
                                  download
                                  href={current}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <RemoveRedEyeIcon />
                                </a>
                                <CloseIcon
                                  onClick={() =>
                                    handleRemoveDoc(current, "local")
                                  }
                                />
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Box display="flex" justifyContent="space-between" m={2}>
                      <Typography
                        color="#111120"
                        fontSize={20}
                        lineHeight={1.5}
                      >
                        Attached Documents
                      </Typography>
                      <Box onClick={() => setHandleEditDocMode(true)}>
                        <img src={edit} alt="" />
                      </Box>
                    </Box>
                    <Grid>
                      {documentState?.documents?.length > 0 ? (
                        documentState?.documents?.map((current) => (
                          <Box
                            className="card"
                            display="flex"
                            justifyContent="space-between"
                            p={2}
                            m={2}
                            style={{
                              boxShadow:
                                " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                              borderStyle: "none",
                            }}
                          >
                            <Box display="flex" justifyContent="space-between">
                              <Typography>
                                <img
                                  src={pdfPhoto}
                                  style={{ marginRight: "10px" }}
                                  alt=""
                                />
                                {current
                                  ?.split("/")[3]
                                  ?.split(".")[0]
                                  ?.slice(0, 15)}
                              </Typography>
                              <Typography>
                                <a
                                  href={current}
                                  target="_blank"
                                  download
                                  rel="noreferrer"
                                >
                                  <DownloadForOfflineIcon />
                                </a>
                              </Typography>
                            </Box>
                          </Box>
                        ))
                      ) : (
                        <Box textAlign="center">No Document</Box>
                      )}
                    </Grid>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        )}
        {handleShift && (
          <Grid mt={2}>
            <>
              {getData?.preventativeSchedule?.length > 0 ? (
                <div>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead className="thead">
                        <TableRow>
                          {heading.map((current, i) => (
                            <>
                              <TableCell align="center" className="bold">
                                {current}
                              </TableCell>
                            </>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {getData?.preventativeSchedule?.map((row) => (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              "&:nth-of-type(even)": {
                                backgroundColor: "#EFF0F6",
                              },
                            }}
                          >
                            <TableCell align="center">
                              {showDash(row?.scheduleId)}
                            </TableCell>
                            <TableCell align="center">
                              {showDash(
                                moment(row?.startTime).format("DD/MM/YYYY")
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {showDash(
                                moment(row?.startTime).format("hh:mm A")
                              )}
                              -
                              {showDash(moment(row?.endTime).format("hh:mm A"))}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <Grid className="nofound">No Data Found</Grid>
              )}
            </>
          </Grid>
        )}
        {/* </Box> */}
      </Grid>
    </Grid>
  );
};

export default PreventativeDetails;
