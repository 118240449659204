import { Box, Grid, Pagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import "../components/AllRequesttable.css";
import { showDash } from "../util";

export default function AssetTable({
  assetListingData,
  page,
  setPage,
  showTotalData,
  totalCount,
  setShowTotalData,
  postPerPage,
  selectedBuilding,
}) {
  const heading = [
    "Asset Id",
    "Asset For",
    "Name",
    "Unit",
    "Category",
    "Floor",
    "Location",
    "Building",
    "Created By",
  ];
  return (
    <>
      {assetListingData?.rows?.length > 0 ? (
        <div>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead className="thead">
                <TableRow>
                  {heading.map((current, i) => (
                    <>
                      <TableCell align="center" className="bold">
                        {current}
                      </TableCell>
                    </>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {assetListingData?.rows?.map((row) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                      "&:nth-of-type(even)": {
                        backgroundColor: "#EFF0F6",
                      },
                    }}
                  >
                    <TableCell align="center">
                      <Link to={`/asset/${row.id}`}>{row?.assetId}</Link>
                    </TableCell>
                    <TableCell align="center">
                      {showDash(row?.isForFlat ? " Flat" : " Building")}
                    </TableCell>
                    <TableCell align="center">{showDash(row?.name)}</TableCell>
                    <TableCell align="center">
                      {showDash(row?.flat?.name_en)}
                    </TableCell>
                    <TableCell align="center">
                      {showDash(row?.category)}
                    </TableCell>
                    <TableCell align="center">{showDash(row?.floor)}</TableCell>
                    <TableCell align="center">
                      {showDash(row?.location)}
                    </TableCell>
                    <TableCell align="center">
                      {row?.isForFlat
                        ? row?.flat?.building?.name_en
                        : row?.building?.name_en}
                    </TableCell>
                    <TableCell align="center">{row?.admin?.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* pagination  */}
          <Box display="flex" justifyContent="space-between" marginTop="10px">
            <Box>
              <p>
                Showing {postPerPage * (page - 1) + 1}
                &nbsp;-
                {page * postPerPage > showTotalData
                  ? showTotalData
                  : page * postPerPage}{" "}
                out of {showTotalData} entries
              </p>
            </Box>
            <Box>
              <Pagination
                count={totalCount}
                onChange={(event, value) => {
                  setPage(value);
                }}
                shape="rounded"
              />
            </Box>
          </Box>
        </div>
      ) : (
        <Grid className="nofound">No Data Found</Grid>
      )}
    </>
  );
}
