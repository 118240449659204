import { TextField } from "@mui/material";
import React, { useState } from "react";
import "./AllPropertyNav.css";
const Properties = [
  {
    value: "Property-A",
    label: "Property-A",
  },
  {
    value: "Property-B",
    label: "Property-B",
  },
  {
    value: "Property-C",
    label: "Property-C",
  },
  {
    value: "Property-D",
    label: "Property-D",
  },
];

export default function AllPropertyNav() {
  const [PropertyName, setPropertyName] = useState("EUR");

  const PropertyNameChange = (event) => {
    setPropertyName(event.target.value);
  };
  return (
    <>
      <div style={{ marginTop: "1.05rem" }}></div>
    </>
  );
}
