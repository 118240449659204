import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Chip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { editGuardSchema, guardSchema } from "../../components/AuthSchema";
import BuildingMultiSelectDropdown from "../../components/BuildingMultiSelectDropdown";
import CountryCodeDropDown from "../../components/CountryCodeDropDown";
import Loader from "../../components/Loader";
import Textfiled from "../../components/Textfiled";
import { getAllBuilding } from "../../services/allPropertiesAPI";
import { updateGuard } from "../../services/allServisesApi";
import { decrypt, encrypt } from "../../util/Encryption";

const EditGuard = ({ propertyfields, guardId, setHandleEditMode }) => {
  const [buildingData, setBuildingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: propertyfields.name,
      userName: propertyfields.userName,
      mobileNumber: propertyfields.mobileNumber.replace(
        propertyfields.countryCode,
        ""
      ),
      countryCode: propertyfields.countryCode
        ? `+${propertyfields.countryCode}`
        : "+971",
      buildings: propertyfields.guardBuildings,
      password: decrypt(propertyfields.password),
    },
    mode: "onChange",
    resolver: yupResolver(guardSchema),
  });
  const [buildings, countryCode] = watch(["buildings", "countryCode"]);

  const fetchBuildingData = async () => {
    let buildingFromStorage = sessionStorage.getItem("building");
    if (buildingFromStorage?.length > 0) {
      const resp = await getAllBuilding();
      let specificBuilding = resp?.data?.data?.rows?.filter(
        (building) => building.id === buildingFromStorage
      );

      setBuildingData(specificBuilding);
    } else {
      const resp = await getAllBuilding();

      setBuildingData(resp.data.data.rows);
    }
  };

  useEffect(() => {
    fetchBuildingData();
  }, []);

  const handleDelete = (CheckedEle) => {
    const result = buildings?.filter((curElem) => {
      return curElem?.id !== CheckedEle;
    });
    setValue("buildings", result);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const encryptValue = encrypt(data.password);
    data["password"] = encryptValue;
    const payload = {
      ...data,
      countryCode: data.countryCode.replace("+", ""),
      mobileNumber: `${data.countryCode.replace("+", "")}${data.mobileNumber}`,
    };
    payload.buildings = data.buildings.map((ele) => {
      if (ele.building) {
        return ele.buildingId;
      } else {
        return ele.id;
      }
    });

    const resp = await updateGuard({ guardId, payload });
    if (resp.data.status === "success") {
      toast.success("Guard update successfully");
      setHandleEditMode(false);
    } else {
      toast.error(resp.data.msg);
    }
    setIsLoading(false);
  };

  return (
    <Box padding="20px 0px 50px 20px">
      {isLoading && <Loader />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" justifyContent="space-between">
          <h4>Guard Details</h4>
          <Box>
            <Button
              style={{ textDecoration: "underLine" }}
              onClick={() => {
                setHandleEditMode(false);
              }}
            >
              Cancel
            </Button>
            <Button type="submit" style={{ textDecoration: "underLine" }}>
              Save Changes
            </Button>
          </Box>
        </Box>
        <Box>
          <Box>
            <Textfiled
              label={"Name*"}
              type="text"
              width="100%"
              name="name"
              maxLength="35"
              setValue={setValue}
              register={register}
            />
            <Typography
              sx={{
                fontStyle: "italic",
                fontSize: "13px",
                color: "red",
              }}
            >
              {errors?.name?.message}
            </Typography>
          </Box>
          <Box>
            <Textfiled
              label={"UserName"}
              type="text"
              width="100%"
              name="userName"
              setValue={setValue}
              maxLength="35"
              register={register}
            />
            <Typography
              sx={{
                fontStyle: "italic",
                fontSize: "13px",
                color: "red",
              }}
            >
              {errors?.userName?.message}
            </Typography>
          </Box>

          <Box sx={{ marginTop: "30px", display: "flex" }}>
            <Box marginRight="10px">
              <CountryCodeDropDown
                name="countryCode"
                // value={countryCode}
                register={register}
              />
            </Box>
            <Box marginTop="-29px">
              <Textfiled
                label="Mobile Number"
                type="text"
                width="550px"
                name="mobileNumber"
                maxLength="35"
                register={register}
                IntialvalueForCountryCode={countryCode}
              />
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "red",
                }}
              >
                {errors?.mobileNumber?.message && "This filed is required"}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Textfiled
              label="Password"
              type="text"
              width="100%"
              name="password"
              maxLength="35"
              register={register}
            />
            <Typography
              sx={{
                fontStyle: "italic",
                fontSize: "13px",
                color: "red",
              }}
            >
              {errors?.password?.message}
            </Typography>
          </Box>
          <Box>
            <BuildingMultiSelectDropdown
              label="Select Building"
              name="buildings"
              setValue={setValue}
              width="100%"
              innerWidth="600px"
              buildings={buildings}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              columnGap: "10px",
              rowGap: "10px",
              marginTop: "10px",
            }}
          >
            {buildings.map((ele) => (
              <Chip
                sx={{
                  width: "150px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                label={ele?.building ? ele?.building?.name_en : ele?.name_en}
                variant="outlined"
                onDelete={() => {
                  handleDelete(ele?.id);
                }}
              />
            ))}
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default EditGuard;
