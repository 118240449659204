import { Card } from "@tremor/react";
import moment from "moment/moment";
import ReactApexChart from "react-apexcharts";
import { Typography } from "@mui/material";
import noData from "../assets/no data found.png";

function BarChartComp({ handleVisitorData }) {
  const chartData = handleVisitorData?.dailyTraffic?.map((curElem, id) => {
    return curElem.count;
  });

  const Data = handleVisitorData?.dailyTraffic?.map((curElem, id) => {
    return moment(curElem.date).format("Do MMM");
  });
  const state = {
    series: [
      {
        name: "Inflation",
        data: chartData,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },

      xaxis: {
        categories: Data,
        position: "top",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + "%";
          },
        },
      },
      title: {
        text: "Daily Visitor Traffic",
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444",
        },
      },
    },
  };

  return (
    <>
      {handleVisitorData?.dailyTraffic?.length > 0 ? (
        <Card>
          <div className="mixed-chart">
            <ReactApexChart
              options={state.options}
              series={state.series}
              type="bar"
              width="100%"
              height={350}
            />
          </div>
        </Card>
      ) : (
        <Card>
          <Typography>Daily Traffic Data</Typography>
          <img style={{ width: "100%", height: "215px" }} src={noData} />
        </Card>
      )}
    </>
  );
}

export default BarChartComp;
