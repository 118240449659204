import React from "react";
import Allproperties from "./Allproperties";

export default function AllProperties() {
  return (
    <>
      <div className="top-level-container">
        <Allproperties></Allproperties>
      </div>
    </>
  );
}
