import React, { useContext, useEffect, useState } from "react";
// import { IoMdNotificationsOutline } from "react-icons/io";
// import logo from "../assets/logo.svg";
import { Avatar, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Noprofile from "../assets/Noprofile.png";
import homeTherapylogo from "../assets/Therapy logo.png";
import { buildingContext } from "../login/AuthProvider";
import { getApartment } from "../services/allPropertiesAPI";
import AllPropertyNav from "./AllPropertyNav";

export default function TopNav() {
  const { pathname } = useLocation();
  const [apartment, setApartment] = useState({});
  const [buildingHandle, setBuildingHandle] = useState("");
  const [building, setBuilding] = useState([]);
  const { isSuccess } = useSelector((state) => state.PropertyInfo);
  const { fetchBuildings, onBuildingSelect, buildings, selectedBuilding } =
    useContext(buildingContext);

  const fetchApartment = async () => {
    const response = await getApartment();
    setApartment(response.data.data);
  };

  const Auth = localStorage.getItem("accessToken");

  useEffect(() => {
    fetchApartment();
  }, [Auth]);

  useEffect(() => {
    fetchBuildings();
  }, [Auth, isSuccess]);
  localStorage.setItem("buildingIds", apartment?.buildingId);

  return (
    <>
      {pathname !== "/" ? (
        <div className="TopBar" style={{ height: "4.8rem" }}>
          <div>
            <img className="logo" src={homeTherapylogo} alt="logo" />
          </div>
          <div className="TopBar-child">
            {pathname === "/property" || pathname === "/user" ? (
              <AllPropertyNav style={{ marginleft: "200px" }} />
            ) : null}
            <div className="Top">
              <div style={{ marginRight: "50px" }}>
                <select
                  style={{
                    minWidth: "100px",
                    height: "40px",
                    // marginTop: "10px",
                    border: "1px solid #fff",
                    padding: "0px 10px",
                    borderRadius: "5px",
                    marginTop: "10px",
                    backgroundColor: "#0c344e",
                    color: "#fff",
                  }}
                  value={
                    sessionStorage.getItem("building")
                      ? sessionStorage.getItem("building")
                      : ""
                  }
                  onChange={(e) => onBuildingSelect(e.target.value)}
                >
                  <option value={buildingHandle}>Select Building</option>
                  {buildings?.length > 0 &&
                    buildings.map((item) => (
                      <>
                        <option key={item.id} value={item.id}>
                          {item?.name_en}
                        </option>
                      </>
                    ))}
                </select>
              </div>

              <div style={{ marginRight: "20px", marginTop: "1.2rem" }}>
                <Typography color="#fff">{apartment?.name}</Typography>
              </div>
              <div className="profile-badge">
                <Avatar
                  alt="Remy Sharp"
                  src={Noprofile}
                  sx={{
                    width: 53,
                    height: 53,
                    marginRight: "1rem",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
