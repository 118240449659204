import { Box } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import "../../index.css";

const Morningslot = ({ selectedslot, setSelectedslot, register,index,setValue }) => {
  const [clickableEffect, setClickableEffect] = useState(false);
  const [active, setActive] = useState(null);
  const slotTime = [
    "08:00 AM- 09:00 AM",
    "09:00 AM- 10:00 AM",
    "10:00 AM- 11:00 AM",
    "11:00 AM- 12:00 AM",
  ];
  const selectedSlot = (currtime, i) => {
    setSelectedslot(currtime);
  };

  return (
    <>
      <div className="d-flex  flex-wrap mainslot">
        {slotTime.map((currtime, i) => (
          <Box
            mr={1.5}
            onClick={() => {
              selectedSlot(currtime);
              // setValue={currtime}
              // moment.
              const time = moment(currtime.split('-')[0], "hh:mm a").format("HH:mm:ss");
              
              setValue(`Requests[${index}].time`, time);
            }}
            {...register(`Requests[${index}].time`)}
            className={`slotshow ${selectedslot == currtime && "onEffect"}`}
          >
            {currtime}
          </Box>
        ))}
      </div>
    </>
  );
};

export default Morningslot;
