import { Box, Button, TextareaAutosize, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { avoidLease, getLeaseDetail } from "../../Redux/LeaseSlice";

function AvoidLease({ open, handleClose, id }) {
  const { control, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(avoidLease({ data, id }));
    dispatch(getLeaseDetail(id));
  };
  return (
    <div>
      <Dialog open={open} aria-labelledby="responsive-dialog-title">
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box width="450px">
              <Typography>
                Are you sure you want to cancel this lease ?
              </Typography>

              <Controller
                name="expiryReason"
                control={control}
                render={({ field }) => (
                  <>
                    <TextareaAutosize
                      {...field}
                      aria-label="minimum height"
                      minRows={2}
                      placeholder="Reason"
                      style={{
                        width: "100%",
                        minHeight: "50px",
                        marginTop: "5px",
                        paddingLeft: "10px",
                        borderRadius: "10px",
                      }}
                    />
                  </>
                )}
              />
              <button
                type="submit"
                onClose={handleClose}
                id="savebtn"
                className="Button"
                variant="contained"
                color="success"
                style={{
                  border: "1px solid #0C344E",
                  color: "#0C344E",
                  background: "#0c344e",
                  width: "130px",
                  height: "43px",
                  fontFamily: "Barlow",
                  fontSize: "16px",
                  marginTop: "20px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                  color: "white",
                }}
                onClick={handleClose}
              >
                Save
              </button>
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{
                  "&:hover": { border: "none" },
                  padding: "7px 25px",
                  borderRadius: "8px",
                  marginLeft: "10px",
                  color: "black",
                  outline: "1px solid black",
                  border: "none",
                  textTransform: "capitalize",
                }}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default React.memo(AvoidLease);
