import Button from "react-bootstrap/Button";
import React, { useEffect, useState } from "react";
import ApproveDialog from "./ApproveDialog";
import DenyDialog from "./DenyDialog";
import { buildingContext } from "../login/AuthProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  DenyUserAPI,
  UserMappingAPI,
  ApproveUserAPI,
} from "../services/allPropertiesAPI";

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import { async } from "q";
import { toast } from "react-toastify";
import { approveDeny, userMapping } from "./AuthSchema";

export default function ApproveDeny({ users, setUsers, row, id }) {
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dialog, setDialog] = useState({
    message: "",
    isLoading: false,
    //Update
    nameUser: "",
  });
  let navigate = useNavigate();
  const { selectedBuilding } = React.useContext(buildingContext);

  let buildingId = selectedBuilding;

  const data = {
    requestedFlat: row.flatRequested.id,
    contractStartDate: dayjs(startDate).format("DD/MM/YYYY"),
    contractEndDate: dayjs(endDate).format("DD/MM/YYYY"),
  };
  const idUserRef = React.useRef();
  const handleDialog = (message, isLoading, nameUser) => {
    setDialog({
      message,
      isLoading,
      //Update
      nameUser,
    });
  };

  const handleDelete = (id) => {
    setStatus("Deny");
    const index = users.findIndex((row) => row.id === id);

    handleDialog(
      "Are you sure you want to deny User?",
      true,
      users[index].name
    );
    idUserRef.current = id;
  };
  const handleApprove = (id) => {
    setStatus("Approve");

    //Update
    const index = users.findIndex((row) => row.id === id);
    handleDialog(
      "Are you sure you want to approve User?",
      true,
      users[index].name
    );

    idUserRef.current = id;
  };

  const noDelete = () => {
    handleDialog("", false);
  };
  const params = {
    page: 1,
    limit: 10,
    buildingId: selectedBuilding,
  };
  const areUSureDelete = () => {
    DenyUserAPI(row.flatRequested.id).then((response) => {
      UserMappingAPI(params).then((response) => {
        const resp = response.data.data.rows;
        setUsers(resp);
      });
      handleDialog("", false);
    });
  };

  const areUSureApprove = () => {
    ApproveUserAPI([{ data }, { buildingId }]).then((response) => {
      if (response.status === 200) {
        navigate("/user");
      }
      UserMappingAPI(params).then((response) => {
        const resp = response.data.data.rows;
        setUsers(resp);
      });
      handleDialog("", false);
    });
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      discount: "percentage",
      contractStartDate: "",
      contractEndDate: "",
      flatUsage: "",
      securityDeposit: "",
      activationFee: "",
      paymentFrequency: "",
      paymentMode: "",
      rentAmount: "",
      discountAmount: "",
      noticePeriod: "",
      isDiscountRequired: false,
      applicableOn: "",
      currency: "AED",
    },

    mode: "onChange",
    resolver: yupResolver(approveDeny),
  });

  const [
    isDiscountRequired,
    applicableOn,
    currency,
    contractStartDate,
    discount,
    securityDeposit,
    rentAmount,
    discountAmount,
  ] = watch([
    "isDiscountRequired",
    "applicableOn",
    "currency",
    "contractStartDate",
    "discount",
    "securityDeposit",
    "rentAmount",
    "discountAmount",
  ]);
  useEffect(() => {
    setValue(
      "contractEndDate",
      contractStartDate ? moment(contractStartDate).add(1, "year") : ""
    );
  }, [contractStartDate]);
  const onSubmit = async (data) => {
    delete data.totalDiscount;
    delete data.discount;
    const data1 = {
      ...data,
      contractStartDate: moment(data.contractStartDate).format("DD-MM-YYYY"),
      contractEndDate: moment(data.contractEndDate).format("DD-MM-YYYY"),
      requestedFlat: row.flatRequested.id,
      discountAmount:
        discount === "percentage"
          ? applicableOn === "Deposit"
            ? (securityDeposit * discountAmount) / 100
            : (rentAmount * discountAmount) / 100
          : data.discountAmount,
      grace: data.grace ? data.grace : null,
    };

    const response = await ApproveUserAPI(data1);
    if (response.data.status === "fail") {
      toast.error(response.data.msg);
    } else {
      toast.success(response.data.data);
      navigate("/user");
    }
    const resp = await UserMappingAPI(params);

    setUsers(resp.data.data.rows);
  };

  useEffect(() => {
    if (applicableOn === "Deposit") {
      let updatedDiscount = securityDeposit - discountAmount;
      setValue("totalDiscount", updatedDiscount);
      if (discount === "percentage") {
        let finalPercentageAmount =
          securityDeposit - (securityDeposit * discountAmount) / 100;
        setValue("totalDiscount", finalPercentageAmount);
      }
    } else {
      let updated = rentAmount - discountAmount;
      setValue("totalDiscount", updated);
      if (discount === "percentage") {
        let finalPercentageAmount =
          rentAmount - (rentAmount * discountAmount) / 100;
        setValue("totalDiscount", finalPercentageAmount);
      }
    }
  }, [discountAmount, applicableOn, securityDeposit, rentAmount, discount]);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="properties-button" style={{ marginRight: "1rem" }}>
          <Button
            className="Button"
            onClick={() => handleApprove(row.id)}
            variant="success"
            color="success"
            style={{
              border: "1px solid #0C344E",
              width: "5rem",
              height: "40px",
            }}
          >
            Approve
          </Button>
        </div>
        <div className="properties-button">
          <Button
            className="Button"
            onClick={() => handleDelete(row.id)}
            variant="danger"
            color="success"
            style={{
              border: "1px solid #0C344E",
              width: "5rem",
              height: "40px",
            }}
          >
            Deny
          </Button>
        </div>
        {dialog.isLoading && status === "Approve" && (
          <ApproveDialog
            nameUser={dialog.nameUser}
            onDialog={noDelete}
            message={dialog.message}
            register={register}
            handleSubmit={handleSubmit}
            control={control}
            Controller={Controller}
            onSubmit={onSubmit}
            isDiscountRequired={isDiscountRequired}
            applicableOn={applicableOn}
            errors={errors}
            currency={currency}
            setValue={setValue}
            discount={discount}
          />
        )}
        {dialog.isLoading && status === "Deny" && (
          <DenyDialog
            //Update
            nameUser={dialog.nameUser}
            onDialog={noDelete}
            message={dialog.message}
            areUSureDelete={areUSureDelete}
          />
        )}
      </div>
    </>
  );
}
