import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import SearchBar from "../components/SearchBar";
import UserMappingTable from "../components/UserMappingTable";
import { buildingContext } from "../login/AuthProvider";
export default function Home() {
  const [userCategory, setUserCategery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerpage, setPostPerpage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");

  const onSearchChange = (value) => {
    setSearchText(value);
  };

  return (
    <>
      <div className="home">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="Property" style={{ marginRight: "40px" }}>
            Requested Users
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "auto",
              order: 2,
            }}
          >
            <SearchBar
              style={{ marginRight: "40px" }}
              onChange={onSearchChange}
              id={userCategory}
              setId={setUserCategery}
              placeholder="Search "
            />
          </div>
        </div>

        <UserMappingTable
          id={userCategory}
          currentPage={currentPage}
          postPerpage={postPerpage}
          setCurrentPage={setCurrentPage}
          setPostPerpage={setPostPerpage}
          totalCount={totalCount}
          setTotalCount={setTotalCount}
          searchText={searchText}
        />
      </div>
    </>
  );
}
