import React, { useState } from "react";
import "./index.css";
import VisitorTable from "../components/VisitorTable";
import Model from "./Model";

export default function User() {
    const [show, setShow] = useState(false);
    const [visitorCategory, setVisitorCategory] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Model
                handleClose={handleClose}
                handleShow={handleShow}
                show={show}
                setShow={setShow}
            />
            <div className="home">
                <VisitorTable id={visitorCategory} />
            </div>
        </>
    );
}
