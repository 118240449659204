import { Box, Button, Tooltip } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getDate } from "../util";
import "./AllRequesttable.css";
import NoDataFound from "./NoDataFound";

export default function NoticesTable({
  placeholder,
  totalCount,
  setPage,
  page,
  showTotalData,
  postPerpage,
}) {
  const navigate = useNavigate();
  const { noticeData } = useSelector((state) => state.NoticesData);
  const handleNavigate = (id) => {
    navigate(`/noticeDetail/${id}`);
  };

  return (
    <>
      {noticeData?.rows?.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table sx={{}} aria-label="simple table">
              <TableHead className="thead">
                <TableRow>
                  <TableCell align="center" className="bold">
                    Title
                  </TableCell>
                  <TableCell align="center" className="bold">
                    Start Date
                  </TableCell>
                  <TableCell align="center" className="bold">
                    End Date
                  </TableCell>

                  <TableCell align="center" className="bold">
                    Category
                  </TableCell>
                  <TableCell align="center" className="bold">
                    No. of Building
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {noticeData?.rows?.map((row) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                      "&:nth-of-type(even)": {
                        backgroundColor: "#EFF0F6",
                      },
                    }}
                  >
                    <TableCell
                      align="center"
                      style={{
                        padding: "0px",
                        color: "#0d6efd",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleNavigate(row?.id);
                      }}
                    >
                      {row.title}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ padding: "0px" }}
                      onClick={() => {
                        handleNavigate(row?.id);
                      }}
                    >
                      {row.validFrom ? getDate(row.validFrom) : "-"}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ padding: "0px" }}
                      onClick={() => {
                        handleNavigate(row?.id);
                      }}
                    >
                      {row.validTill ? getDate(row.validTill) : "-"}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ padding: "0px" }}
                      onClick={() => {
                        handleNavigate(row?.id);
                      }}
                    >
                      {row.category}
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{ padding: "0px" }}
                      onClick={() => {
                        handleNavigate(row?.id);
                      }}
                    >
                      <Button sx={{ m: 1, color: "#471f20" }}>
                        {row?.noticeBuildings?.length === 1
                          ? row?.noticeBuildings[0]?.building?.name_en
                          : `${
                              row?.noticeBuildings[0]?.building?.name_en
                            }${","} ${"+"}
                         ${row?.noticeBuildings?.length - 1} ${"more"}`}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="space-between" marginTop="10px">
            <Box>
              <p>
                Showing {postPerpage * (page - 1) + 1}
                &nbsp;-
                {page * postPerpage > showTotalData
                  ? showTotalData
                  : page * postPerpage}{" "}
                out of {showTotalData} entries
              </p>
            </Box>
            <Box>
              {" "}
              <Pagination
                count={totalCount}
                style={{ float: "right", marginTop: "10px" }}
                onChange={(event, value) => {
                  setPage(value);
                }}
                shape="rounded"
              />
            </Box>
          </Box>
        </>
      ) : (
        <div className="d-flex justify-content-center mt-5">
          <p className="nofound">
            <NoDataFound />
          </p>
        </div>
      )}
    </>
  );
}
