import { configureStore } from "@reduxjs/toolkit";
import LeaseReducers from "./LeaseSlice";
import NoticesReducers from "./NoticeSlice";
import HelplineReducer from "./HelplineSlice";
import AssetReducer from "./AssetSlice";
import CategoryReducer from "./CategorySlice";
import PreventativeReducer from "./PreventativeSlice";
import MultiUseReducer from "./MultiUseSlice";
import propertyReducer from "./PropertySlice";

const store = configureStore({
  reducer: {
    LeaseData: LeaseReducers,
    NoticesData: NoticesReducers,
    HelplineData: HelplineReducer,
    AssetData: AssetReducer,
    CategoryData: CategoryReducer,
    PreventativeData: PreventativeReducer,
    MultiUseReducerData: MultiUseReducer,
    PropertyInfo: propertyReducer,
  },
});

export default store;
