import AnnouncementIcon from "@mui/icons-material/Announcement";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { buildingContext } from "../login/AuthProvider";
import { GetDashboardData } from "../services/DashboardApi";
import ColorTabs from "./ColorTabs";
import DateFilter from "./DateFilter";

export default function ServiceRequestTabs() {
  const { selectedBuilding } = useContext(buildingContext);
  var today = new Date();
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(new Date().setDate(today.getDate() - 30)),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // state created to hold the first month that calendar is showing
  const [shownDateChangeValue, setShownDateChangeValue] = useState(new Date());

  // state created to check if use created next Month ou previous month
  const [isNextMonth, setIsNextMonth] = useState(true);
  const [handleData, setHandleData] = useState({});

  let formatStartDate = moment(state[0]?.startDate).format("DD-MM-YYYY");
  let formatEndDate = moment(state[0]?.endDate).format("DD-MM-YYYY");
  const fetchData = async () => {
    try {
      const response = await GetDashboardData(
        formatStartDate,
        formatEndDate,
        selectedBuilding
      );
      if (response?.data?.status) {
        setHandleData(response.data.data);
      } else {
        toast(response.data.status);
      }
    } catch {
      toast("err");
    }
  };
  useEffect(() => {
    fetchData();
  }, [state[0]?.endDate, selectedBuilding]);

  return (
    <>
      {loader && <Loader />}
      <Grid m={2}>
        <Box display="flex" justifyContent="space-between" my={2}>
          <Box display="flex" gap={2}>
            <ColorTabs
              status="Total Raised"
              color="#D65400"
              count={handleData?.total}
              icons=<AssignmentLateIcon />
            />
            <ColorTabs
              status="Cancelled"
              color="#808080"
              count={handleData?.cancelled}
              icons=<BeenhereIcon />
            />
          </Box>
          <Box>
            <DateFilter
              state={state}
              setState={setState}
              shownDateChangeValue={shownDateChangeValue}
              setShownDateChangeValue={setShownDateChangeValue}
              setIsNextMonth={setIsNextMonth}
              formatStartDate={formatStartDate}
              formatEndDate={formatEndDate}
            />
          </Box>
        </Box>
        <Box display="flex" flexWrap="wrap" gap={2}>
          <ColorTabs
            status="Open"
            color="#D65400"
            count={handleData?.pending}
            icons=<BookmarksIcon />
          />
          <ColorTabs
            status="Complete"
            color="#009944"
            count={handleData?.completed}
            icons=<VerifiedIcon />
          />
          <ColorTabs
            status="Re-Open"
            color="#00008b"
            count={handleData?.reOpen}
            icons=<BookmarkRemoveIcon />
          />
          <ColorTabs
            status="In-Process"
            color="#0C344E"
            count={handleData?.processing}
            icons=<PendingActionsIcon />
          />
          <ColorTabs
            status="On-Hold"
            color="#426176"
            count={handleData?.onHold}
            icons=<AnnouncementIcon />
          />
        </Box>
        <Box width="100%" p={1} my={4} bgcolor="#fff" textAlign="center">
          <Typography>Stats till date</Typography>
        </Box>
        <Box display="flex" gap={2} justifyContent="center" alignItems="center">
          <Card
            sx={{
              width: 280,
              height: "100",
              textAlign: "center",
              border: "1px solid #efefef",
              borderRadius: "1rem",
            }}
          >
            <CardContent sx={{ borderBottom: "4px solid yellow" }}>
              <Typography>Total In-process request till date</Typography>
              <Typography>{handleData?.processingTillDate}</Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              width: 280,
              height: "100",
              textAlign: "center",
              border: "1px solid #efefef",
              borderRadius: "1rem",
            }}
          >
            <CardContent sx={{ borderBottom: "4px solid pink" }}>
              <Typography>Total Open request till date</Typography>
              <Typography>{handleData?.pendingTillDate}</Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              width: 280,
              height: "100",
              textAlign: "center",
              border: "1px solid #efefef",
              borderRadius: "1rem",
            }}
          >
            <CardContent sx={{ borderBottom: "4px solid blue" }}>
              <Typography>Total On-Hold request till date</Typography>
              <Typography>{handleData?.onHoldTillDate}</Typography>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </>
  );
}
