import { Box, Typography } from "@mui/material";
import React from "react";
import Textfiled from "../../components/Textfiled";
import { useForm } from "react-hook-form";
import SearchSelectBox from "../../components/SearchSelectBox";
import { async } from "q";
import { addUnit } from "../../services/allPropertiesAPI";
import { toast } from "react-toastify";

const AddUnitInLease = ({ buildingId, handleClose, fetchFlatDetail }) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name_en: "",
    },
  });
  const FLAT_TYPES = [
    {
      id: "Apartment",
      name: "Apartment",
    },
    {
      id: "Beach House",
      name: "Beach House",
    },
    {
      id: "Duplex",
      name: "Duplex",
    },
    {
      id: "Farm",
      name: "Farm",
    },
    {
      id: "Office",
      name: "Office",
    },
    {
      id: "Studio",
      name: "Studio",
    },
    {
      id: "Shop",
      name: "Shop",
    },

    {
      id: "Villa",
      name: "Villa",
    },

    {
      id: "Storage",
      name: "Storage",
    },

    {
      id: "Others",
      name: "Others",
    },
  ];
  const onSubmit = async (data) => {
    const payload = {
      ...data,
      buildingId,
    };
    const postData = await addUnit(payload);
    if (postData.data.status === "success") {
      toast("Unit created Successfully");
      handleClose();
      fetchFlatDetail(buildingId);
    } else {
      toast(postData.data.msg);
    }
  };
  return (
    <Box sx={{ width: "400px", minHeight: "250px" }}>
      <Box>
        <Typography variant="h6">Add Unit</Typography>
      </Box>
      <Box>
        <Box>
          <Textfiled
            label={"Unit Number*"}
            inputProps={{ maxLength: 50 }}
            type="text"
            width="300px"
            name="name_en"
            register={register}
          />
        </Box>
        <Box sx={{ marginTop: "15px" }}>
          <SearchSelectBox
            displayedOptions={FLAT_TYPES}
            name="flatType"
            register={register}
            label="Type of Unit*"
            width="300px"
            innerWidth="300px"
          />
        </Box>
      </Box>
      <button
        type="submit"
        style={{
          border: "1px solid #0C344E",
          color: "#0C344E",
          background: "#0c344e",
          width: "160px",
          height: "40px",
          fontFamily: "Barlow",
          fontSize: "16px",
          marginTop: "20px",
          borderRadius: "10px",
          textTransform: "capitalize",
          color: "white",
        }}
        onClick={handleSubmit(onSubmit)}
      >
        Add Unit
      </button>
    </Box>
  );
};

export default AddUnitInLease;
