import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import edit from "../../StaffDetail/assets/edit.png";
import Loader from "../../components/Loader";
import { getGuardById } from "../../services/allServisesApi";
import { decrypt } from "../../util/Encryption";
import EditGuard from "./EditGuard";
// import { decrypt, encrypt } from "../../util/Encryption";

const GuardDetails = () => {
  const [visible, setVisible] = useState(false);
  const [handleEditMode, setHandleEditMode] = useState(false);
  let { guardId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [handleEditDocMode, setHandleEditDocMode] = useState(false);
  const [propertyfields, setPropertyfields] = useState({
    name: "",
    userName: "",
    password: "",
    countryCode: "",
    mobileNumber: "",
  });

  useEffect(() => {
    const fetchGuard = async () => {
      setIsLoading(true);
      const response = await getGuardById(guardId);

      setPropertyfields({
        name: response?.data?.data?.name,
        userName: response?.data?.data?.userName,
        password: response?.data?.data?.password,
        mobileNumber: response.data.data.mobileNumber,
        countryCode: response.data.data.countryCode,
        guardBuildings: response.data.data.guardBuildings,
      });
    };
    fetchGuard();
    setIsLoading(false);
  }, [guardId, !handleEditMode]);

  const handleEditButton = () => {
    setHandleEditMode(true);
    setVisible(false);
  };

  const decValue = decrypt(propertyfields?.password);

  return (
    <Grid className="top-level-container">
      {isLoading && <Loader />}
      <Grid className="home">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Box className="card" p={2}>
                {handleEditMode ? (
                  <EditGuard
                    {...{ propertyfields, guardId, setHandleEditMode }}
                  />
                ) : (
                  <>
                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        color="#111120"
                        fontSize={20}
                        lineHeight={1.5}
                      >
                        Guard Details
                      </Typography>
                      <Box>
                        <img alt="..." src={edit} onClick={handleEditButton} />
                      </Box>
                    </Box>

                    <Box my={4}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        my={2}
                        px={1.5}
                      >
                        <Typography color="#111120" lineHeight={2.5}>
                          Name
                        </Typography>
                        <Typography>{propertyfields?.name}</Typography>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        my={2}
                        px={1.5}
                      >
                        <Typography color="#111120" lineHeight={2.5}>
                          UserName
                        </Typography>
                        <Typography>{propertyfields?.userName}</Typography>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        my={2}
                        px={1.5}
                      >
                        <Typography color="#111120" lineHeight={2.5}>
                          Contact No.
                        </Typography>
                        <Typography>
                          {`+${propertyfields?.mobileNumber}`}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        my={2}
                        px={1.5}
                      >
                        <Typography color="#111120" lineHeight={2.5}>
                          Password
                        </Typography>
                        <Box display="flex">
                          {!visible && <Typography mr={2}>******</Typography>}
                          {visible && (
                            <Typography mr={2}>{decValue}</Typography>
                          )}
                          {visible && (
                            <Box onClick={() => setVisible(false)}>
                              <RemoveRedEyeIcon />
                            </Box>
                          )}
                          {!visible && (
                            <Box onClick={() => setVisible(true)}>
                              <VisibilityOffIcon />
                            </Box>
                          )}
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        my={2}
                        px={1.5}
                      >
                        <Typography color="#111120" lineHeight={2.5}>
                          Building Name
                        </Typography>
                        <Typography>
                          {propertyfields?.guardBuildings?.map(
                            (item, index) => {
                              return `${item?.building?.name_en} ${
                                propertyfields?.guardBuildings.length - 1 ===
                                index
                                  ? ""
                                  : ","
                              }`;
                            }
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default GuardDetails;
