import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import SearchBar from "../components/SearchBar";
import Button from "@mui/material/Button";
import UserTable from "../components/UserTable";
import { AllUserAPI, exportAllUsersData } from "../services/allPropertiesAPI";
import { CSVLink } from "react-csv";
import { buildingContext } from "../login/AuthProvider";

export default function Home() {
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalCount, settotalCount] = useState(0);
  const [postPerpage, setPostPerPage] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const { selectedBuilding } = React.useContext(buildingContext);

  let buildingId = selectedBuilding;
  const onSearchChange = async (value) => {
    setSearchText(value);
    const resp = await AllUserAPI(currentPage, postPerpage, value, buildingId);
    let totalData = resp.data.data.count;
    let pagesCount = Math.ceil(totalData / postPerpage);
    settotalCount(pagesCount);
    setUsers(resp.data.data.rows);
  };
  const [data, setData] = useState([]);

  useEffect(() => {
    const UserData = async () => {
      const resp = await exportAllUsersData(buildingId);
      let csvFile = resp.data.data.rows.map((item) => {
        let obj = {};

        obj.Name = item.name;
        obj.Email = item.email;
        obj.CountryCode = `+${item.countryCode}`;
        obj.MobileNumber = item.mobileNumber.replace(item?.countryCode, "");
        obj.Building = item.building && item.building.name_en;
        obj.DateOfBirth = item.dateOfBirth;
        obj.UserType = item.userType;
        obj.DocumentId = item.documentId;
        obj.DocumentType = item.documentType;
        obj.Documents = item.documents;
        obj.Gender = item.gender;
        obj.Nationality = item.nationality;
        obj.profilePicture = item.profilePicture;

        return obj;
      });
      setData(csvFile);
    };
    UserData();
  }, []);
  return (
    <>
      <div className="home">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="Property" style={{ marginRight: "40px" }}>
            Users
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "auto",
              order: 2,
              gap: "1.5rem",
            }}
          >
            <SearchBar
              style={{ marginRight: "40px" }}
              onChange={onSearchChange}
              placeholder="Search"
            />
            <div className="properties-button">
              <Link to="/adduser" style={{ textDecoration: "none" }}>
                <Button
                  className="Button"
                  variant="contained"
                  color="success"
                  style={{
                    border: "1px solid #0C344E",
                    color: "#0C344E",
                    background: "white",
                    width: "12rem",
                    height: "48px",
                    fontFamily: "Barlow",
                    marginLeft: "15px",
                    fontStyle: "normal",
                    fontweight: "500",
                    fontSize: "14px",
                    lineHeight: "19px",
                    textTransform: "capitalize",
                    borderRadius: "10px",
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: "0.5rem",
                      marginLeft: "-0.8rem",
                    }}
                  >
                    <path
                      d="M12 5V19"
                      stroke="#0C344E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5 12H19"
                      stroke="#0C344E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Add New User
                </Button>
              </Link>
            </div>
            <CSVLink
              data={data}
              className="csvExport m-auto p-2"
              filename="User Data"
            >
              Export Data
            </CSVLink>
          </div>
        </div>

        <UserTable
          searchText={searchText}
          postPerpage={postPerpage}
          currentPage={currentPage}
          totalCount={totalCount}
          setcurrentPage={setcurrentPage}
          settotalCount={settotalCount}
          users={users}
          setUsers={setUsers}
        />
      </div>
    </>
  );
}
