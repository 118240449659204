import { Box, Chip, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import EditHelpline from "../EditHelpline/EditHelpline";
import edit from "../../StaffDetail/assets/edit.png";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getParticularHelplineDetail } from "../../Redux/HelplineSlice";
import { useEffect } from "react";
import Loader from "../../components/Loader";
import Image from "../../StaffDetail/assets/gallery.png";

const HelplineDetail = () => {
  const [handleEditMode, setHandleEditMode] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();

  const { helplineInfo, isLoading, isSuccess } = useSelector(
    (state) => state.HelplineData
  );

  const handleEditButton = () => {
    setHandleEditMode(true);
  };

  useEffect(() => {
    dispatch(getParticularHelplineDetail(id));
  }, [handleEditMode, isSuccess]);

  return (
    <Grid className="top-level-container">
      <Grid className="home">
        {isLoading && <Loader />}
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Box className="card" p={2}>
                {handleEditMode ? (
                  <EditHelpline
                    helplineId={helplineInfo?.id}
                    setHandleEditMode={setHandleEditMode}
                    helplineInfo={helplineInfo}
                    handleEditButton={handleEditButton}
                  />
                ) : (
                  <>
                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        color="#111120"
                        fontSize={20}
                        lineHeight={1.5}
                      >
                        Emergency Contact Details
                      </Typography>
                      <Box>
                        <img alt="..." src={edit} onClick={handleEditButton} />
                      </Box>
                    </Box>

                    <Box my={4}>
                      {helplineInfo?.image?.length > 0 ? (
                        <img
                          src={helplineInfo?.image}
                          style={{ width: "150px", height: "150px" }}
                          alt="..."
                        />
                      ) : (
                        <img
                          src={Image}
                          alt="emptystate"
                          width="130"
                          height="130"
                        />
                      )}

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        my={2}
                        px={1.5}
                      >
                        <Typography color="#111120" lineHeight={2.5}>
                          Name
                        </Typography>
                        <Typography>{helplineInfo?.name_en}</Typography>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        my={2}
                        px={1.5}
                      >
                        <Typography color="#111120" lineHeight={2.5}>
                          Contact No.
                        </Typography>
                        <Typography>
                          {!helplineInfo.isTollFree && (
                            <span>{`+${helplineInfo?.countryCode}`}</span>
                          )}

                          <span>{helplineInfo?.contactNumber}</span>
                        </Typography>
                      </Box>
                      <Box
                        // display="flex"
                        // justifyContent="space-between"
                        // alignItems="center"
                        my={2}
                        px={1.5}
                      >
                        <Typography color="#111120" lineHeight={2.5}>
                          Building Name:-
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            columnGap: "10px",
                            rowGap: "10px",
                            marginTop: "10px",
                          }}
                        >
                          {helplineInfo?.helplineBuildings?.map(
                            (item, index) => (
                              <Chip
                                sx={{
                                  width: "150px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                                label={item?.building?.name_en}
                                variant="outlined"
                              />
                            )
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HelplineDetail;
