import { faRegistered } from "@fortawesome/free-solid-svg-icons";
import { Box } from "@mui/material";
import moment from "moment";
import React from "react";
import {useForm} from 'react-hook-form'

const AfterNoonslot = ({ selectedslot, setSelectedslot, register, index, setValue }) => {
  const slotTime = [
    "12:00 PM -01:00 PM",
    "01:00 PM- 02:00 PM",
    "02:00 PM- 03:00 PM",
    "03:00 PM- 04:00 PM",
  ];
  const selectedSlot = (currtime) => {
    setSelectedslot(currtime);
  };
  
  return (
    <div className="d-flex  flex-wrap mainslot">
      {slotTime.map((currtime,i) => (
        <Box
          mr={1.5}
          onClick={() => {
            selectedSlot(currtime);
           
     
            setValue(`Requests[${index}].time`, currtime.split('-')[0])
          }}
          {...register(`Requests[${index}].time`)}
          className={`slotshow ${selectedslot == currtime && "onEffect"}`}
        >
         {currtime}
        </Box>
      ))}
    </div>
  );
};

export default AfterNoonslot;
