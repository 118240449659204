import React from "react";
import UserMapping from "./UserMapping";

export default function PropertyName() {
  return (
    <>
      <div className="top-level-container">
        <UserMapping />
      </div>
    </>
  );
}
