import React from "react";
import Units from "./Units";

export default function PropertyName() {
  return (
    <>
      <div className="top-level-container">
        <Units />
      </div>
    </>
  );
}
