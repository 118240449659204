import React from "react";
import EditUnitDetailPage from "./EditUnitDetail";

const EditUnitDetail = () => {
  return (
    <>
      <div className="top-level-container">
        <EditUnitDetailPage />
      </div>
    </>
  );
};

export default EditUnitDetail;
