import Button from "@mui/material/Button";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ".././App.css";
import { buildingContext } from "../login/AuthProvider";
import {
  getStaffdetail,
  updateStaff,
  uploadFile,
} from "../services/allPropertiesAPI";
import CalenderSlot from "./CalenderSlot";
import StaffDetailUi from "./StaffDetailUi";

const StaffDetailPage = () => {
  const [handleLoader, setHandleLoader] = useState(false);
  const [user, setUser] = useState();
  const [editMode, setEditMode] = useState(false);
  const [nationality, setNationality] = useState();
  const [countryCode, setCountryCode] = useState();
  const [editedUserProfileFile, setEditedUserProfileFile] = useState();
  const [editedUserProfileLink, setEditedUserProfileLink] = useState();
  const [openDocument, setOpenDocument] = useState(false);
  const [userNewDocument, setUserNewDocument] = useState([]);
  const [isSubmit, SetIsSubmit] = useState(false);
  const [fieldError, setFieldError] = useState({});
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [appointment, setAppointment] = useState();
  const [propertyfields, setPropertyfields] = useState({
    userId: "",
    name: "",
    profilePicture: "",
    department: "",
    designation: "",
    appointment: "",
    nationality: "",
    emiratesId: "",
    email: "",
    passportNumber: "",
    offerLetterDocument: "",
    passportDocument: "",
    visaDocument: "",
    countryCode: "",
    mobileNumber: "",
    // document: [],
  });

  const [handleShift, setHandleShift] = useState(false);
  const _ref = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const userId = location.pathname.split("/")[2];
  const { selectedBuilding } = React.useContext(buildingContext);

  let buildingId = selectedBuilding;

  //fetch single User data --------------------------------------
  useEffect(() => {
    const fetchUser = async () => {
      setHandleLoader(true);
      const res = await getStaffdetail({ userId, buildingId });
      setPropertyfields({
        userid: res.data.data.flatsAssociated.staffId,
        profilePicture: res.data.data.profilePicture,
        name: res.data.data.name,
        department: res.data.data.department,
        designation: res.data.data.designation,
        appointment: res.data.data.appointment,
        nationality: res.data.data.nationality,
        emiratesId: res.data.data.emiratesId,
        passportNumber: res.data.data.passportNumber,
        offerLetterDocument: res.data.data.offerLetterDocument,
        passportDocument: res.data.data.passportDocument,
        visaDocument: res.data.data.visaDocument,
        // document: res.data.data.documents,
        mobileNumber: res.data.data.mobileNumber,
        email: res.data.data.email,
        countryCode:
          res.data.data.countryCode.split("").indexOf("+") === -1
            ? `+${res.data.data.countryCode}`
            : res.data.data.countryCode,
      });
      setHandleLoader(false);
    };

    fetchUser();
  }, [userId]);

  //updating user information ------------------------------------
  const updatePropertyfields = (event, key) => {
    setPropertyfields((preState) => {
      return {
        ...preState,
        [`${key}`]: event.target.value,
      };
    });
  };

  //fetching nationality and country code---------------------------------------------------------------------
  useEffect(() => {
    const fetchNationality = async () => {
      const res = await axios.get(
        "https://raw.githubusercontent.com/Dinuks/country-nationality-list/master/countries.json"
      );
      setNationality(res.data);
    };
    const fetchCountryCode = async () => {
      const res = await axios.get(
        "https://gist.githubusercontent.com/anubhavshrimal/75f6183458db8c453306f93521e93d37/raw/f77e7598a8503f1f70528ae1cbf9f66755698a16/CountryCodes.json"
      );
      setCountryCode(res.data);
    };

    fetchNationality();
    fetchCountryCode();
  }, []);

  //storing User profile image to local server
  const handleUpdateUserProfile = (e) => {
    setEditedUserProfileFile(e.target.files[0]);
    setEditedUserProfileLink(URL.createObjectURL(e.target.files[0]));
  };

  //when user click on save but handleSave function will run -----------------------------------------
  const handleSave = (e) => {
    e.preventDefault();
    SetIsSubmit(!isSubmit);
    setFieldError(validateForm(propertyfields));
  };

  // adding new User form valdation function----------------------------------------------------------------------------------------
  const validateForm = (value) => {
    const errors = {};
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    //error check for Firstname
    if (!value.name) {
      errors.name = "name is required";
    }

    //error check for email
    if (!value.email) {
      errors.email = "Email is required";
    }
    //error check for mobileNumber
    if (!value.mobileNumber) {
      errors.mobileNumber = "MobileNumber is required";
    } else if (
      value.mobileNumber.length <
      13 - `${propertyfields.countryCode.length}`
    ) {
      errors.mobileNumber = `Mobile number should be  ${
        13 - propertyfields.countryCode.length
      } digits`;
    }

    return errors;
  };

  //when user press save and every field is varified then this useEffect will run --------------------------------------------------
  useEffect(() => {
    //if no error in form then this will run -----------------------------------------------------------

    if (Object.keys(fieldError) == 0 && isSubmit) {
      const uploadUserInformation = async () => {
        let profileFormData = new FormData();
        let allDocumentFormData = new FormData();
        let userProfileLink = null;
        let documentLink = null;

        //for profile-------------------------------------------------------------------

        if (editedUserProfileFile) {
          //profile form data---------------------------------------
          profileFormData.append("file", editedUserProfileFile);

          //uploading file to aws s3
          const profileLink = await uploadFile(profileFormData);
          userProfileLink = profileLink.data.data[0].location;
        }

        //for document ---------------------------------------------------------------
        if (userNewDocument) {
          //document form data-------------------------
          userNewDocument.map((doc) => {
            allDocumentFormData.append("file", doc.document);
          });

          //uploading file to aws s3
          documentLink = await uploadFile(allDocumentFormData);
          userNewDocument.map((doc) => {
            documentLink.data.data.map((linkDoc) => {
              if (doc.document.name === linkDoc.originalName) {
                propertyfields.document.push({
                  name: doc.type,
                  url: linkDoc.location,
                  filename: linkDoc.originalName,
                });
              }
            });
          });
        }

        //getting links

        const {
          profilePicture,
          name,
          document,
          mobileNumber,
          countryCode,
          email,
          passportNumber,
        } = propertyfields;

        const newCountryCode = propertyfields.countryCode.replace("+", "");

        const userFullData = {
          mobileNumber: mobileNumber,
          countryCode: newCountryCode,
          email: email,
          passportNumber: passportNumber,
          documents: document,
          staffId: userId,
          name: name,
          profilePicture: userProfileLink
            ? userProfileLink
            : propertyfields.profilePicture,
        };

        try {
          const res = await updateStaff({ userFullData, buildingId });

          alert("user information saved successfully....");
          navigate("/staff");
        } catch (err) {
          alert(err);
        }
      };

      uploadUserInformation();
    }
  }, [isSubmit]);

  return (
    <>
      <div className="home">
        <Button
          variant={handleShift == false ? "contained" : "outlined"}
          sx={{
            marginRight: "1rem",
            borderRadius: "1rem",
            backgroundColor: handleShift === false ? "#0C344E" : "",
          }}
          onClick={() => setHandleShift(false)}
        >
          Staff Details
        </Button>
        <Button
          variant={handleShift === true ? "contained" : "outlined"}
          sx={{
            borderRadius: "1rem",
            backgroundColor: handleShift === true ? "#0C344E" : "",
          }}
          onClick={() => setHandleShift(true)}
          className=""
        >
          Staff Availability
        </Button>
        {handleShift === false ? (
          <>
            <StaffDetailUi
              propertyFields={propertyfields}
              setPropertyFields={setPropertyfields}
              setHandleLoader={setHandleLoader}
              handleLoader={handleLoader}
            />
          </>
        ) : (
          <>
            <br></br>
            <CalenderSlot />
          </>
        )}
      </div>
    </>
  );
};

export default StaffDetailPage;
