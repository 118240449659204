import { useState, useEffect } from "react";
import InputField from "../InputField";
import Form from "../Form";
import { TiDeleteOutline } from "react-icons/ti";
import { AiOutlinePlus } from "react-icons/ai";

const CatalogForm = ({ CategoryData }) => {
    //!Deleting Category Data
    const handleDeleteCategory = (id) => {
        // const updatedCategory = CategoryData.filter((item) => item.id !== id);
        // setCategoryData(updatedCategory);
    };

    const [editData, setEditData] = useState({
        category: "",
        serviceCost: "",
    });

    const { category, serviceCost } = editData;

    const handleChangeInput = (e) => {
        setEditData({ ...editData, [e.target.name]: e.target.value });
    };

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <div className="width:400px">
                    <h5 className="mb-0">Edit Charges Catalogue</h5>
                </div>
                <div className="d-flex align-items-center gap-3">
                    <button className="Abtn">
                        <AiOutlinePlus />
                        &nbsp; Add New Category
                    </button>
                    <button className="Cbtn">Cancel</button>
                    <button className="Sbtn">Save</button>
                </div>
            </div>
            {CategoryData?.map(({ id, category, chargeName, serviceCost }) => {
                return (
                    <Form key={id}>
                        <div
                            className="d-flex justify-content-end col-12"
                            onClick={() => handleDeleteCategory(id)}
                        >
                            <TiDeleteOutline style={{ fontSize: "25px" }} />
                        </div>
                        <InputField
                            placeholder="Category Name"
                            name="category"
                            value={category}
                            onChange={handleChangeInput}
                        />
                        <div className="d-flex  align-items-end">
                            <button className="addCharge">
                                Add New Charge Type
                            </button>
                        </div>
                        <InputField
                            placeholder="Charge Text"
                            name="chargeName"
                            value={chargeName}
                            onChange={handleChangeInput}
                        />
                        <InputField
                            placeholder="Service Cost (AED)"
                            name="serviceCost"
                            value={serviceCost}
                            onChange={handleChangeInput}
                        />
                    </Form>
                );
            })}
        </>
    );
};

export default CatalogForm;
