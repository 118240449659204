import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import { AiOutlinePlus } from "react-icons/ai";
import { TiDeleteOutline } from "react-icons/ti";
import { axiosClient } from "../../services/axiosClient";
import { toast } from "react-toastify";

const InputFiled = () => {
    const list = ["list1", "list2", "list3"];

    const [data, setData] = useState({
        title: "",
        description: "",
    });
    const { title, description } = data;

    const handleInputChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    //Add Document
    const [selectedImage, setSelectedImage] = useState();
    const [name, setName] = useState("");

    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(e.target.files[0]);
            setName(e.target.files[0].name);
        }
    };

  

    const removeSelectedImage = () => {
        setSelectedImage();
    };

    // !Send Create Announceent
    const createAnnouncement = () => {
        axiosClient
            .post(`notices/create`, {
                ...data,
                documents: [
                    {
                        name: name,
                        file: selectedImage,
                    },
                ],
            })
            .then((result) => {
                toast.success("Announcement Created SuccessFully");
                //   setData({
                //     chargeName: "",
                //     serviceCost: "",
                //   });
                //   navigate("/chargescatalouge");
            })
            .catch((err) => {
                toast.error(err.msg);
            });
    };

    return (
        <>
            <div>
                <div className="d-flex">
                    <div style={{ width: "700px" }}>
                        {/* <div className="mb-3">
                        <TextField
                            id="Area"
                            select
                            label="Select"
                            variant="standard"
                            placeholder="Select Property"
                            style={{
                                fontFamily: "Barlow",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "16px",
                                lineHeight: "19px",
                                width: "578px",
                            }}
                        >
                            {list?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div> */}
                        <div>
                            <TextField
                                id="standard-helperText"
                                variant="standard"
                                placeholder="Title"
                                style={{ marginTop: "24px", width: "578px" }}
                                onChange={handleInputChange}
                                name="title"
                                value={title}
                            />
                        </div>
                        <div className="mt-5">
                            <TextField
                                id="filled-multiline-static"
                                multiline
                                rows={4}
                                placeholder="Description"
                                variant="filled"
                                style={{ borderRadius: "12px", width: "578px" }}
                                onChange={handleInputChange}
                                name="description"
                                value={description}
                            />
                        </div>
                    </div>
                    <div>
                        <button
                            style={{
                                width: "295px",
                                height: "64px",
                                border: "1.5px dashed #DFE0E0",
                            }}
                        >
                            <AiOutlinePlus />
                            <label htmlFor="document">
                                &nbsp; &nbsp; Add Doucment
                            </label>
                            <input
                                type="file"
                                id="document"
                                onChange={imageChange}
                                style={{ display: "none" }}
                            />
                        </button>

                        {selectedImage && (
                            <div className="d-flex gap-2 mt-2">
                                <img
                                    src={URL.createObjectURL(selectedImage)}
                                    alt="Thumb"
                                    style={{
                                        width: "200px",
                                        height: "200px",
                                        objectFit: "contain",
                                    }}
                                />
                                <div onClick={removeSelectedImage}>
                                    <TiDeleteOutline
                                        style={{ fontSize: "25px" }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default InputFiled;
