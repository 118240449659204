import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({
  setValue,
  value = null,
  description,
  setDescription,
}) => {
  const [editorContent, setEditorContent] = useState("");
  const [editorHeight, setEditorHeight] = useState("150px");

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ color: [] }, { background: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "background",
  ];

  const editorOptions = {
    modules: modules,
    formats: formats,
    theme: "snow",
    placeholder: "Write something...",
    height: "500px",
  };
  const editorStyle = {
    height: editorHeight,
  };

  const handleChange = (value) => {
    setDescription(value);
  };
  return (
    <div>
      <ReactQuill
        theme="snow"
        value={description}
        onChange={handleChange}
        modules={editorOptions.modules}
        formats={editorOptions.formats}
        placeholder={editorOptions.placeholder}
        height={editorOptions.height}
        style={editorStyle}
      />
    </div>
  );
};

export default TextEditor;
