import React from "react";
import AddUnit from "./AddUnit";

export default function PropertyName() {
  return (
    <>
      <div className="top-level-container">
        <AddUnit />
      </div>
    </>
  );
}
