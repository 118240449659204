import React, { useContext } from "react";
import "./index.css";
import { buildingContext } from "../login/AuthProvider";
import ServiceRequestTabs from "./ServiceRequestTabs";

const Dashboard = () => {
  const { selectedBuilding } = useContext(buildingContext);

  return (
    <div className="home" style={{ borderTopLeftRadius: "50px" }}>
      <ServiceRequestTabs />
    </div>
  );
};

export default Dashboard;
