import { Card, CardMedia, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import React from "react";
import edit from "./../assets/edit.png";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useState } from "react";
// import EditStaffDetail from "./EditStaffDetail";
import CloseIcon from "@mui/icons-material/Close";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Carousol from "../components/Carousol";
import { buildingContext } from "../login/AuthProvider";
import OpenDoc from "../PropertyName/OpenDoc";
import { editFlatDetail } from "../services/allCommunityApi";
import { uploadFile } from "../services/allPropertiesAPI";
import pdfPhoto from "../StaffDetail/assets/pdfPhoto.png";
import EditUnitDetail from "../Units/EditUnitDetail";
import { showDash } from "../util";

const UnitDetailUi = ({
  propertyFields,
  setPropertyFields,
  setHandleLoader,
  handleEditMode,
  setHandleEditMode,
}) => {
  const urlParams = useParams();
  const staffId = urlParams.id;

  const [handleEditDocMode, setHandleEditDocMode] = useState(false);
  const [getImageDataDoc, setGetImageDataDoc] = useState();
  const [documentUrl, setDocumentUrl] = useState(() => {
    return [...propertyFields.document];
  });
  const [getLengthDoc, setGetLengthDoc] = useState();

  useEffect(() => {
    setDocumentUrl([...propertyFields.document]);
  }, [propertyFields?.document]);

  const { selectedBuilding } = React.useContext(buildingContext);
  let buildingId = selectedBuilding;
  const location = useLocation();
  const userId = location.pathname.split("/")[2];
  const handleEditDocButton = () => {
    setHandleEditDocMode(true);
  };

  const handleEditButton = (param) => {
    setHandleEditMode(true);
  };

  const handleRemoveDoc = (att, type) => {
    const filtered = documentUrl.filter((item) => item !== att);
    setDocumentUrl(filtered);
  };

  const handleUpdateData = async () => {
    setHandleLoader(true);
    const data = {
      documents: documentUrl,
      flatId: propertyFields?.flatId,
    };
    try {
      const res = await editFlatDetail(data);
      if (res?.data?.status === "success") {
        setHandleEditDocMode(false);
        toast("Saved Successfully");
      } else {
        toast("Check you Field");
      }
    } catch (err) {
      toast("Not get the data");
    }
    setHandleLoader(false);
  };

  const getDocUrl = async () => {
    setHandleLoader(true);
    try {
      const res = await uploadFile(getImageDataDoc);
      if (res.data.status === "success") {
        const getUrl = res.data.data.map((current) => current.location);
        setDocumentUrl([...documentUrl, ...getUrl]);
      } else {
        toast("Document is not uploaded. Please check your connection");
      }
    } catch {}
    setHandleLoader(false);
  };
  useEffect(() => {
    if (getLengthDoc) {
      getDocUrl();
    }
  }, [getLengthDoc]);

  return (
    <>
      <Grid container mt={2} gap={2}>
        <Grid xs={7}>
          <Box className="card" p={2}>
            <Box>
              <Box onClick={() => handleEditButton("")}>
                {!handleEditMode && (
                  <img alt="" src={edit} className="editImage" />
                )}
              </Box>
            </Box>
            {handleEditMode === true ? (
              <EditUnitDetail
                propertyFields={propertyFields}
                setPropertyFields={setPropertyFields}
                setHandleEditMode={setHandleEditMode}
                setHandleLoader={setHandleLoader}
              />
            ) : (
              <>
                <Carousol>
                  {propertyFields.Image.map((url, id) => {
                    return (
                      <a href={url} target="_blank">
                        <Card
                          key={id}
                          sx={{ maxWidth: 345, p: 1, boxShadow: "none" }}
                        >
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={url}
                          />
                        </Card>
                      </a>
                    );
                  })}
                </Carousol>

                <Box my={4}>
                  <Typography color="#111120" fontSize={20}>
                    Unit Details
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    my={2}
                    px={1.5}
                  >
                    <Typography color="#111120" lineHeight={2.5}>
                      Unit number
                    </Typography>
                    <Typography>{showDash(propertyFields?.name)}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    bgcolor="#FAFAFA"
                    alignItems="center"
                    my={2}
                    px={1.5}
                  >
                    <Typography color="#111120" lineHeight={2.5}>
                      Property name
                    </Typography>
                    <Typography>
                      {showDash(propertyFields?.buildingName)}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    my={2}
                    px={1.5}
                  >
                    <Typography color="#111120" lineHeight={2.5}>
                      Floor number
                    </Typography>
                    <Typography>{showDash(propertyFields?.floor)}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    bgcolor="#FAFAFA"
                    alignItems="center"
                    my={2}
                    px={1.5}
                  >
                    <Typography color="#111120" lineHeight={2.5}>
                      Owner Name
                    </Typography>
                    {propertyFields?.owner?.id ? (
                      <Link to={`/userdetail/${propertyFields?.owner?.id}`}>
                        <Typography>{propertyFields?.owner?.name}</Typography>
                      </Link>
                    ) : (
                      "-"
                    )}
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    my={2}
                    px={1.5}
                  >
                    <Typography color="#111120" lineHeight={2.5}>
                      Tenant Name
                    </Typography>
                    {propertyFields?.tenant?.id ? (
                      <Typography>
                        {propertyFields?.isExpired === false ? (
                          <Link
                            to={`/userdetail/${propertyFields?.tenant?.id}`}
                          >
                            {propertyFields?.tenant?.name}
                          </Link>
                        ) : (
                          <>-</>
                        )}
                      </Typography>
                    ) : (
                      "-"
                    )}
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    bgcolor="#FAFAFA"
                    alignItems="center"
                    my={2}
                    px={1.5}
                  >
                    <Typography color="#111120" lineHeight={2.5}>
                      Type of unit
                    </Typography>
                    <Typography>
                      {showDash(propertyFields?.flatType)}{" "}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={1.5}
                  >
                    <Typography color="#111120">Unit Size </Typography>
                    <Typography>
                      {propertyFields?.size}{" "}
                      {propertyFields?.size ? "sqft" : "-"}{" "}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    bgcolor="#FAFAFA"
                    alignItems="center"
                    my={2}
                    px={1.5}
                  >
                    <Typography color="#111120" lineHeight={2.5}>
                      No. of Bathroom
                    </Typography>
                    <Typography>
                      {showDash(propertyFields?.bathroom)}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={1.5}
                  >
                    <Typography color="#111120">No. of Bedroom</Typography>
                    <Typography>{showDash(propertyFields?.bedroom)}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    bgcolor="#FAFAFA"
                    alignItems="center"
                    my={2}
                    px={1.5}
                  >
                    <Typography color="#111120" lineHeight={2.5}>
                      Government Id
                    </Typography>
                    <Typography>{showDash(propertyFields?.unitId)}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={1.5}
                  >
                    <Typography color="#111120">POC Name</Typography>
                    <Typography>
                      {showDash(propertyFields?.contactName)}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    bgcolor="#FAFAFA"
                    alignItems="center"
                    my={2}
                    px={1.5}
                  >
                    <Typography color="#111120" lineHeight={2.5}>
                      POC Email
                    </Typography>
                    <Typography>
                      {showDash(propertyFields?.contactEmail)}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={1.5}
                  >
                    <Typography color="#111120">POC No.</Typography>
                    <Typography>
                      {propertyFields?.contactMobileNumber
                        ? `${propertyFields?.contactCountryCode}-${propertyFields?.contactMobileNumber}`
                        : "-"}
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Grid>

        <Grid xs={4}>
          <Box className="card" height="100vh">
            {handleEditDocMode === true ? (
              <>
                <Box display="flex" justifyContent="space-between" m={2}>
                  <Typography color="#111120" fontSize={20} lineHeight={1.5}>
                    Edit Documents
                  </Typography>
                  <Box
                    color=" #0C344E"
                    fontSize={16}
                    sx={{ cursor: "pointer", textDecoration: "underLine" }}
                    onClick={handleUpdateData}
                  >
                    Save Changes
                  </Box>
                </Box>
                <Grid>
                  <Typography textAlign="center" fontSize="20px">
                    Attach Document
                  </Typography>
                  <OpenDoc
                    setGetImageDataDoc={setGetImageDataDoc}
                    setGetLengthDoc={setGetLengthDoc}
                  />
                </Grid>
                <Grid>
                  {documentUrl.length > 0 &&
                    documentUrl?.map((current) => (
                      <Box
                        className="card"
                        display="flex"
                        justifyContent="space-between"
                        p={2}
                        m={2}
                        style={{
                          boxShadow: " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                          borderStyle: "none",
                        }}
                      >
                        <Box display="flex" justifyContent="space-between">
                          <Typography>
                            <img
                              src={pdfPhoto}
                              style={{ marginRight: "10px" }}
                              alt=""
                            />
                            {current &&
                              current
                                ?.split("/")[3]
                                ?.split(".")[0]
                                ?.slice(0, 10)}
                          </Typography>
                          <Typography>
                            <a
                              download
                              href={current}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <RemoveRedEyeIcon />
                            </a>
                            <CloseIcon
                              onClick={() => handleRemoveDoc(current, "local")}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                </Grid>
              </>
            ) : (
              <>
                <Box display="flex" justifyContent="space-between" m={2}>
                  <Typography color="#111120" fontSize={20} lineHeight={1.5}>
                    Attached Documents
                  </Typography>
                  <Box onClick={handleEditDocButton}>
                    <img src={edit} alt="" />
                  </Box>
                </Box>
                <Grid>
                  {documentUrl?.length > 0 ? (
                    documentUrl?.map((current) => (
                      <Box
                        className="card"
                        display="flex"
                        justifyContent="space-between"
                        p={2}
                        m={2}
                        style={{
                          boxShadow: " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                          borderStyle: "none",
                        }}
                      >
                        <Box display="flex" justifyContent="space-between">
                          <Typography>
                            <img
                              src={pdfPhoto}
                              style={{ marginRight: "10px" }}
                              alt=""
                            />
                            {current &&
                              current
                                ?.split("/")[3]
                                ?.split(".")[0]
                                ?.slice(0, 15)}
                          </Typography>
                          <Typography>
                            <a
                              href={current}
                              target="_blank"
                              download
                              rel="noreferrer"
                            >
                              <DownloadForOfflineIcon />
                            </a>
                          </Typography>
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Box textAlign="center">No Document</Box>
                  )}
                </Grid>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default UnitDetailUi;
