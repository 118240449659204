import Dialog from "@mui/material/Dialog";
import * as React from "react";
import "../App.css";

export default React.memo(function ConfirmBox({
  openConfirm,
  handleCloseConfirmBox,
  children,
}) {
  return (
    <Dialog open={openConfirm} onClose={handleCloseConfirmBox}>
      <div className="logoutContainer">{children}</div>
    </Dialog>
  );
});
