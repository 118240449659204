/* eslint-disable no-lone-blocks */
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import Loader from "../components/Loader";
import { editAssetSlice, fetchAssetData } from "../Redux/AssetSlice";
import { showDash } from "../util";
import EditAsset from "./EditAsset";
import edit from "../StaffDetail/assets/edit.png";
import CloseIcon from "@mui/icons-material/Close";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import pdfPhoto from "../StaffDetail/assets/pdfPhoto.png";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import OpenDoc from "../PropertyName/OpenDoc";
import { uploadFile } from "../services/allPropertiesAPI";
import { toast } from "react-toastify";

const AssetDetail = () => {
  const [handleEditMode, setHandleEditMode] = useState(false);
  const { pathname } = useLocation();
  const assetId = pathname.split("/")[2];
  const dispatch = useDispatch();
  const { assetData, isLoading, isSuccess } = useSelector(
    (state) => state.AssetData
  );
  const [handleEditDocMode, setHandleEditDocMode] = useState(false);
  const [getImageDataDoc, setGetImageDataDoc] = useState();
  const [documentUrl, setDocumentUrl] = useState([]);
  const [handleLoader, setHandleLoader] = useState(false);
  const [getLengthDoc, setGetLengthDoc] = useState();
  const [documentState, setDocumentState] = useState({
    documents: [],
  });
  const handleEditButton = () => {
    setHandleEditMode(true);
  };
  useEffect(() => {
    dispatch(fetchAssetData(assetId));
  }, [!handleEditMode, !handleEditDocMode]);

  useEffect(() => {
    setDocumentState({
      documents: assetData.documents,
    });
  }, [assetData?.documents]);

  const handleEditDocButton = () => {
    setHandleEditDocMode(true);
  };
  const handleRemoveDoc = (att, type) => {
    const filtered = documentState.documents.filter((item) => item !== att);
    setDocumentUrl(filtered);
    setDocumentState({
      documents: filtered,
    });
  };

  const handleUpdateData = async () => {
    const assetId = assetData.id;
    const data = {
      documents: [...documentState.documents],
    };
    dispatch(editAssetSlice({ data, assetId }));
    {
      isSuccess && setHandleEditDocMode(false);
    }
  };

  const getDocUrl = async () => {
    setHandleLoader(true);
    try {
      const res = await uploadFile(getImageDataDoc);
      if (res.data.status === "success") {
        const getUrl = res.data.data.map((current) => current.location);
        setDocumentUrl([...documentUrl, ...getUrl]);
        setDocumentState({
          documents: [...getUrl, ...documentState.documents],
        });
      } else {
        toast("Document is not uploaded. Please check your connection");
      }
    } catch {}
    setHandleLoader(false);
  };
  useEffect(() => {
    if (getLengthDoc) {
      getDocUrl();
    }
  }, [getLengthDoc]);
  return (
    <Grid className="top-level-container">
      <Grid className="home">
        {(isLoading || handleLoader) && <Loader />}
        <Grid container gap={2}>
          <Grid item xs={7.8}>
            <Box className="card" p={2}>
              {handleEditMode ? (
                <EditAsset
                  setHandleEditMode={setHandleEditMode}
                  assetData={assetData}
                />
              ) : (
                <>
                  <Box display="flex" justifyContent="space-between">
                    <Typography color="#111120" fontSize={20} lineHeight={1.5}>
                      Asset Details
                    </Typography>
                    <Box>
                      <img alt="..." src={edit} onClick={handleEditButton} />
                    </Box>
                  </Box>

                  <Box my={4}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      bgcolor="#fafafa"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Asset Id
                      </Typography>
                      <Typography>{showDash(assetData?.assetId)}</Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Name
                      </Typography>
                      <Typography>{showDash(assetData?.name)}</Typography>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                      bgcolor="#fafafa"
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Building Name
                      </Typography>
                      <Typography>
                        {showDash(
                          assetData?.isForFlat
                            ? assetData?.flat?.building?.name_en
                            : assetData?.building?.name_en
                        )}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Unit Name
                      </Typography>
                      <Typography>
                        {showDash(assetData?.flat?.name_en)}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                      bgcolor="#fafafa"
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Admin Name
                      </Typography>
                      <Typography>
                        {showDash(assetData?.admin?.name)}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Category
                      </Typography>
                      <Typography>{showDash(assetData?.category)}</Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                      bgcolor="#fafafa"
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Condition
                      </Typography>
                      <Typography>{showDash(assetData?.condition)}</Typography>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Floor
                      </Typography>
                      <Typography>{showDash(assetData?.floor)}</Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                      bgcolor="#fafafa"
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Location
                      </Typography>
                      <Typography>{showDash(assetData?.location)}</Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Model
                      </Typography>
                      <Typography>{showDash(assetData?.model)}</Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                      px={1.5}
                      bgcolor="#fafafa"
                    >
                      <Typography color="#111120" lineHeight={2.5}>
                        Brand
                      </Typography>
                      <Typography>
                        <span>{showDash(assetData?.brand)}</span>
                      </Typography>
                    </Box>
                    <Box my={2} px={1.5}>
                      <Typography color="#111120" lineHeight={2.5}>
                        Description :-
                      </Typography>
                      <Typography>
                        {showDash(assetData?.description)}
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="card" height="100vh">
              {handleEditDocMode === true ? (
                <>
                  <Box display="flex" justifyContent="space-between" m={2}>
                    <Typography color="#111120" fontSize={20} lineHeight={1.5}>
                      Edit Documents
                    </Typography>
                    <Box
                      color="#0C344E"
                      fontSize={16}
                      sx={{ cursor: "pointer", textDecoration: "underLine" }}
                      onClick={handleUpdateData}
                    >
                      Save Changes
                    </Box>
                  </Box>
                  <Grid>
                    <Typography textAlign="center" fontSize="20px">
                      Attach Document
                    </Typography>
                    <OpenDoc
                      setGetImageDataDoc={setGetImageDataDoc}
                      setGetLengthDoc={setGetLengthDoc}
                    />
                  </Grid>
                  <Grid>
                    {documentState?.documents?.length > 0 &&
                      documentState?.documents?.map((current) => (
                        <Box
                          className="card"
                          display="flex"
                          justifyContent="space-between"
                          p={2}
                          m={2}
                          style={{
                            boxShadow: " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                            borderStyle: "none",
                          }}
                        >
                          <Box display="flex" justifyContent="space-between">
                            <Typography>
                              <img
                                src={pdfPhoto}
                                style={{ marginRight: "10px" }}
                                alt=""
                              />
                              {current
                                ?.split("/")[3]
                                ?.split(".")[0]
                                ?.slice(0, 10)}
                            </Typography>
                            <Typography>
                              <a
                                download
                                href={current}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <RemoveRedEyeIcon />
                              </a>
                              <CloseIcon
                                onClick={() =>
                                  handleRemoveDoc(current, "local")
                                }
                              />
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                  </Grid>
                </>
              ) : (
                <>
                  <Box display="flex" justifyContent="space-between" m={2}>
                    <Typography color="#111120" fontSize={20} lineHeight={1.5}>
                      Attached Documents
                    </Typography>
                    <Box onClick={handleEditDocButton}>
                      <img src={edit} alt="" />
                    </Box>
                  </Box>
                  <Grid>
                    {documentState?.documents?.length > 0 ? (
                      documentState?.documents?.map((current) => (
                        <Box
                          className="card"
                          display="flex"
                          justifyContent="space-between"
                          p={2}
                          m={2}
                          style={{
                            boxShadow: " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                            borderStyle: "none",
                          }}
                        >
                          <Box display="flex" justifyContent="space-between">
                            <Typography>
                              <img
                                src={pdfPhoto}
                                style={{ marginRight: "10px" }}
                                alt=""
                              />
                              {current
                                ?.split("/")[3]
                                ?.split(".")[0]
                                ?.slice(0, 15)}
                            </Typography>
                            <Typography>
                              <a
                                href={current}
                                target="_blank"
                                download
                                rel="noreferrer"
                              >
                                <DownloadForOfflineIcon />
                              </a>
                            </Typography>
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Box textAlign="center">No Document</Box>
                    )}
                  </Grid>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
        {/* </Box> */}
      </Grid>
    </Grid>
  );
};

export default AssetDetail;
