import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import AddImageUi from "../../components/AddImageUi";
import {
  addStaff,
  getStaffAppointment,
  getStaffDepartment,
  getStaffDesignation,
  uploadFile,
} from "../../services/allPropertiesAPI";
// import UploadDocUI from "../../components/UploadDocUI";
import { useNavigate } from "react-router-dom";
import ErrorBox from "../../components/ErrorBox";
import UserDocument from "../../components/UserDocument";
import ViewImageUI from "../../components/ViewImageUI";

import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import { toast } from "react-toastify";
import UploadDocUIStaff from "../../components/UploadDocUIStaff";
import { buildingContext } from "../../login/AuthProvider";

export default function AddStaff() {
  const navigate = useNavigate();
  const _ref = useRef();
  const [openDocument, setOpenDocument] = useState(false);
  const [nationality, setNationality] = useState(null);
  const [userProfile, setUserProfile] = useState(undefined);

  // create other document state
  const [visaDocument, setVisaDocument] = useState(undefined);
  const [passportDocument, setPassportDocument] = useState(undefined);
  const [offerLetterDocument, setOfferLetterDocument] = useState(undefined);

  const [userDocument, setUserDocument] = useState([]);
  const [countryCode, setCountryCode] = useState(null);
  const [localUserImageUrl, setLocalUserImageUrl] = useState(undefined);
  const [fieldError, setFieldError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [propertyfields, setPropertyfields] = useState({
    name: "",
    department: "",
    designation: "",
    appointment: "",
    profilePicture: "",
    visaDocument: "",
    passportDocument: "",
    offerLetterDocument: "",
    nationality: "",
    emiratesId: "",
    passportNumber: "",
    email: "",
    mobileNumber: "",
    workingDays: [],
    workingHoursStart: "",
    workingHoursEnd: "",
    breakStartHours: "",
    breakEndHours: "",
  });

  const [profilePicRes, setProfilePicRes] = useState();

  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [appointment, setAppoinment] = useState([]);
  const [country, setCountry] = useState("");

  //  handle the days selected or unselected ---------------------------
  const { selectedBuilding } = React.useContext(buildingContext);
  let buildingId = selectedBuilding;
  const [chip, setChip] = useState({
    Sun: false,
    Mon: false,
    Tue: false,
    Wed: false,
    Thru: false,
    Fri: false,
    Sat: false,
  });

  const timeArr = [
    { time: "12:00 AM", value: 0 },
    { time: "1:00 AM", value: 1 },
    { time: "2:00 AM", value: 2 },
    { time: "3:00 AM", value: 3 },
    { time: "4:00 AM", value: 4 },
    { time: "5:00 AM", value: 5 },
    { time: "6:00 AM", value: 6 },
    { time: "7:00 AM", value: 7 },
    { time: "8:00 AM", value: 8 },
    { time: "9:00 AM", value: 9 },
    { time: "10:00 AM", value: 10 },
    { time: "11:00 AM", value: 11 },
    { time: "12:00 PM", value: 12 },
    { time: "1:00 PM", value: 13 },
    { time: "2:00 PM", value: 14 },
    { time: "3:00 PM", value: 15 },
    { time: "4:00 PM", value: 16 },
    { time: "5:00 PM", value: 17 },
    { time: "6:00 PM", value: 18 },
    { time: "7:00 PM", value: 19 },
    { time: "8:00 PM", value: 20 },
    { time: "9:00 PM", value: 21 },
    { time: "10:00 PM", value: 22 },
    { time: "11:00 PM", value: 23 },
  ];
  const [payload, setPayload] = useState([]);
  const handleChange = (key, index) => {
    setChip((prevState) => {
      return {
        ...prevState,
        [`${key}`]: !chip[key],
      };
    });

    if (payload.includes(index)) {
      const filteredArr = payload.filter((item) => item !== index);
      setPayload(filteredArr);
      setPropertyfields({
        ...propertyfields,
        workingDays: filteredArr,
      });
    } else {
      setPayload([...payload, index]);
      setPropertyfields({
        ...propertyfields,
        workingDays: [...payload, index],
      });
    }
  };
  //updating user input to a state called propertyField------------------------------------------------
  const updatePropertyfields = (event, key) => {
    setPropertyfields((preState) => {
      return {
        ...preState,
        [`${key}`]: event.target.value,
      };
    });
  };

  const validateForm = (value, doc) => {
    const errors = {};
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const regexMobile = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]/;

    //error check for Firstname
    if (!value.name) {
      errors.name = "Full Name is required";
    } else if (value.name.length < 4) {
      errors.name = "Should be more then 3 charecter";
    } else if (value.name.length > 20) {
      errors.name = "Should not exceed more than 20 characters";
    }

    //error check for email
    if (!value.email) {
      errors.email = "Email is required";
    } else if (!regex.test(value.email)) {
      errors.email = "Invalid email format";
    }
    if (!value.mobileNumber) {
      errors.mobileNumber = "Mobile number is required";
    } else if (!regexMobile.test(value.mobileNumber)) {
      errors.mobileNumber = "Invalid mobile number format";
    }

    if (!value.emiratesId) {
      errors.emiratesId = "Emirates Id is required";
    }

    if (!value.appointment) {
      errors.appointment = "Appointment is required";
    }
    if (!value.designation) {
      errors.designation = "Designation is required";
    }
    if (!value.department) {
      errors.department = "Department is required";
    }

    return errors;
  };

  const CountrySelect = ({ value, onChange, labels, ...rest }) => (
    <select
      {...rest}
      value={value}
      onChange={(event) => {
        onChange(event.target.value || undefined);
        setPropertyfields({
          ...propertyfields,
          countryCode: event.target.value,
        });
      }}
    >
      <option value="">{labels["ZZ"]}</option>
      {getCountries().map((country) => (
        <option key={country} value={getCountryCallingCode(country)}>
          {labels[country]} +{getCountryCallingCode(country)}
        </option>
      ))}
    </select>
  );

  CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired,
  };

  //when user press save and every field is varified then this useEffect will run --------------------------------------------------
  const handlepostdata = () => {
    const uploadUserInformation = async () => {
      let profileFormData;
      let profileLink;
      let userProfileLink;

      let visaDocumentLink;
      let visaDocumentFormData;
      let staffVisaDocumentLink;

      let passportDocumentLink;
      let passportDocumentFormData;
      let staffPassportDocumentLink;

      let offerLetterDocumentLink;
      let offerLetterDocumentFormData;
      let staffOfferLetterDocumentLink;

      if (userProfile) {
        profileFormData = new FormData();
        profileFormData.append("file", userProfile);
        profileLink = await uploadFile(profileFormData);
        userProfileLink = profileLink.data.data[0].location;
      }
      if (visaDocument) {
        visaDocumentFormData = new FormData();
        visaDocumentFormData.append("file", visaDocument.document);
        visaDocumentLink = await uploadFile(visaDocumentFormData);
        staffVisaDocumentLink = visaDocumentLink.data.data[0].location;
      }
      if (passportDocument) {
        passportDocumentFormData = new FormData();
        passportDocumentFormData.append("file", passportDocument.document);
        passportDocumentLink = await uploadFile(passportDocumentFormData);
        staffPassportDocumentLink = passportDocumentLink.data.data[0].location;
      }
      if (offerLetterDocument) {
        offerLetterDocumentFormData = new FormData();
        offerLetterDocumentFormData.append(
          "file",
          offerLetterDocument.document
        );
        offerLetterDocumentLink = await uploadFile(offerLetterDocumentFormData);
        staffOfferLetterDocumentLink =
          offerLetterDocumentLink.data.data[0].location;
      }

      const newCountryCode = propertyfields?.countryCode?.replace("+", "");
      const { name, ...other } = {
        ...propertyfields,
        countryCode: newCountryCode,
      };
      const userFullData = {
        ...other,
        name: name,
        profilePicture: userProfileLink ? userProfileLink : "",
        visaDocument: staffVisaDocumentLink ? staffVisaDocumentLink : "",
        passportDocument: staffPassportDocumentLink
          ? staffPassportDocumentLink
          : "",
        offerLetterDocument: staffOfferLetterDocumentLink
          ? staffOfferLetterDocumentLink
          : "",
        countryCode: newCountryCode ? newCountryCode : 971,
      };

      try {
        const res = await addStaff({ userFullData, buildingId });
        if (res?.data.status === "fail") {
          toast.error(res?.data?.msg);
        } else {
          toast("Saved Successfully");
          navigate("/staff");
        }
      } catch (err) {
        alert(err);
      }
    };

    uploadUserInformation();
  };

  //fetching nationality and country code---------------------------------------------------------------------
  useEffect(() => {
    const fetchDepartment = async () => {
      const res = await getStaffDepartment();
      setDepartment(res.data.data);
    };
    const fetchDesignation = async () => {
      const res = await getStaffDesignation();
      setDesignation(res.data.data);
    };
    const fetchAppoiment = async () => {
      const res = await getStaffAppointment();
      setAppoinment(res.data.data);
    };

    const fetchCountryCode = async () => {
      const res = await axios.get(
        "https://gist.githubusercontent.com/anubhavshrimal/75f6183458db8c453306f93521e93d37/raw/f77e7598a8503f1f70528ae1cbf9f66755698a16/CountryCodes.json"
      );
      setCountryCode(res.data);
      setNationality(res.data);
    };
    // fetchNationality();
    fetchCountryCode();
    fetchDepartment();
    fetchDesignation();
    fetchAppoiment();
  }, []);

  // creating local profile image url and save the selected file to state variable-----------------------------------------------------------------
  const handleUserProfile = (e) => {
    setLocalUserImageUrl(URL.createObjectURL(e.target.files[0]));
    setUserProfile(e.target.files[0]);
  };

  //this function wil run when user click on save Button ----------------------------------------------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    const resp = validateForm(propertyfields, userDocument);
    if (Object.keys(resp).length === 0) {
      handlepostdata();
    } else {
      setFieldError(resp);
    }
  };

  return (
    <>
      <div className="home">
        <div className="Property-top">
          <div className="details">Add New Staff</div>

          {/*two action button  1-cancel 2-save------------------------------------------ */}
        </div>

        {/* Add userImage UI ---------------------------------------*/}
        <input
          type="file"
          id="imgupload"
          style={{ display: "none" }}
          onChange={handleUserProfile}
          // onClick={(event) => {
          //     event.target.value = null;
          // }}
        />

        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label htmlFor="imgupload">
              {" "}
              <AddImageUi userProfile={userProfile} />{" "}
            </label>
            {fieldError.profilePicture && (
              <ErrorBox
                mes={fieldError.profilePicture}
                class={{ color: "black" }}
              />
            )}
          </div>
          {localUserImageUrl && (
            <ViewImageUI
              userProfile={userProfile}
              setUserProfile={setUserProfile}
              setLocalUserImageUrl={setLocalUserImageUrl}
              localUserImageUrl={localUserImageUrl}
            />
          )}
        </div>

        {/* admin user adding form ------------------------------------------------------------------------ */}

        <div
          style={{
            display: "flex",
          }}
        >
          {/* Left data adding user information------------------------------------------------------------- */}

          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "636px" },
            }}
            noValidate
            autoComplete="off"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {/* firstname and last name-------------------- */}

            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TextField
                  id="standard-multiline-flexible"
                  label="Name*"
                  inputProps={{ pattern: "^[a-z]+$" }}
                  multiline
                  maxRows={4}
                  value={propertyfields.name}
                  onChange={(event) => updatePropertyfields(event, "name")}
                  variant="standard"
                  style={{
                    fontFamily: "Barlow",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "19px",
                    width: "635px",
                    marginRight: "75px",
                  }}
                />
                <div>
                  {fieldError.name && <ErrorBox mes={fieldError.name} />}
                </div>
              </div>
            </div>
            {/* designation-------------------- */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                id="Desination"
                select
                label="Designation*"
                value={designation?.[propertyfields.designation]}
                onChange={(event) => updatePropertyfields(event, "designation")}
                variant="standard"
              >
                {designation?.map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>

              {fieldError.designation && (
                <ErrorBox mes={fieldError.designation} />
              )}
            </div>

            {/* Emailid ------------------------------------ */}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                id="standard-multiline-flexible"
                label="Email ID*"
                inputProps={{ maxLength: 50 }}
                multiline
                maxRows={4}
                value={propertyfields.email}
                onChange={(event) => updatePropertyfields(event, "email")}
                variant="standard"
                style={{
                  fontFamily: "Barlow",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "19px",
                }}
              />
              {fieldError.email && <ErrorBox mes={fieldError.email} />}
            </div>
            {/* Counrty code  and phone number----------------------------------- */}
            <div
              style={{
                display: "flex",
              }}
            >
              {/*--------CounrtyCode ----- */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "8px",
                }}
              >
                <FormControl fullWidth sx={{ marginTop: "23px" }}>
                  <Select
                    MenuProps={{ autoFocus: false }}
                    sx={{ width: "70px" }}
                    labelId="search-select-label"
                    id="search-select"
                    variant="standard"
                    name="countryCode"
                    defaultValue={"+971"}
                    className="customiseCountryCode"
                    value={propertyfields?.countryCode}
                    onChange={(event) =>
                      updatePropertyfields(event, "countryCode")
                    }
                    onClose={() => setSearchText("")}
                  >
                    <ListSubheader>
                      <TextField
                        size="small"
                        variant="standard"
                        placeholder="Type to search..."
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                    </ListSubheader>
                    {countryCode
                      ?.filter((option) =>
                        option.name
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                      )
                      .map((option) => (
                        <MenuItem
                          key={option.key}
                          value={option.dial_code}
                          sx={{ backgroundColor: "#fff" }}
                        >
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          >
                            <img
                              width="20"
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            {option.name} ({option.dial_code})
                          </Box>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              {/*--------PhoneNumber ----- */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TextField
                  id="standard-multiline-flexible"
                  label="Phone Number*"
                  inputProps={{
                    maxLength: 10 - `${country.length}`,
                  }}
                  multiline
                  type="number"
                  value={propertyfields.mobileNumber}
                  onChange={(event) =>
                    updatePropertyfields(event, "mobileNumber")
                  }
                  variant="standard"
                  style={{
                    width: "555px",
                    fontFamily: "Barlow",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                />
                {fieldError.mobileNumber && (
                  <ErrorBox mes={fieldError.mobileNumber} />
                )}
              </div>
            </div>

            {/* emirates Id----------------------------------- */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                id="standard-multiline-flexible"
                label="National ID Number"
                inputProps={{ maxLength: 50 }}
                multiline
                maxRows={4}
                value={propertyfields.emiratesId}
                onChange={(event) => updatePropertyfields(event, "emiratesId")}
                variant="standard"
                style={{
                  fontFamily: "Barlow",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "19px",
                }}
              />
              {fieldError.emiratesId && (
                <ErrorBox mes={fieldError.emiratesId} />
              )}
            </div>

            {/* nationality------------------------------------- */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "10px",
                marginTop: "5px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel
                  id="search-select-label"
                  sx={{ marginLeft: "-14px" }}
                >
                  Select Nationality
                </InputLabel>
                <Select
                  MenuProps={{ autoFocus: false }}
                  sx={{ width: "630px" }}
                  labelId="search-select-label"
                  id="search-select"
                  variant="standard"
                  name="nationality"
                  value={propertyfields.nationality}
                  onChange={(event) =>
                    updatePropertyfields(event, "nationality")
                  }
                  onClose={() => setSearchText("")}
                >
                  <ListSubheader>
                    <TextField
                      size="small"
                      variant="standard"
                      placeholder="Type to search..."
                      sx={{ width: "600px" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                          e.stopPropagation();
                        }
                      }}
                    />
                  </ListSubheader>
                  {nationality
                    ?.filter((option) =>
                      option.name
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    )
                    ?.map((option, i) => (
                      <MenuItem key={option?.id} value={option?.name}>
                        {option?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              {fieldError.nationality && (
                <ErrorBox mes={fieldError.nationality} />
              )}
            </div>
            {/* Department-------------------- */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                id="Department"
                select
                label="Department*"
                value={department?.[propertyfields.department]}
                onChange={(event) => updatePropertyfields(event, "department")}
                variant="standard"
              >
                {department?.map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>

              {fieldError.department && (
                <ErrorBox mes={fieldError.department} />
              )}
            </div>

            {/* passport number ------------------------------------------------------------- */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                id="standard-multiline-flexible"
                label="Passport Number"
                type="number"
                inputProps={{ minLength: 4, maxLength: 25 }}
                multiline
                maxRows={4}
                value={propertyfields.passportNumber}
                onChange={(event) =>
                  updatePropertyfields(event, "passportNumber")
                }
                variant="standard"
                style={{
                  fontFamily: "Barlow",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "19px",
                }}
              />
              {fieldError.passportNumber && (
                <ErrorBox mes={fieldError.passportNumber} />
              )}
            </div>

            {/* Appointment-------------------- */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                id="Appointment"
                select
                label="Appointment*"
                value={appointment?.[propertyfields.appointment]}
                onChange={(event) => updatePropertyfields(event, "appointment")}
                variant="standard"
              >
                {appointment?.map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>

              {fieldError.appointment && (
                <ErrorBox mes={fieldError.appointment} />
              )}
            </div>

            {/* working day ---------------------------------------------------------- */}
            <Grid my={2} mx={1}>
              <Typography>Working Days*</Typography>
              <Box display="flex">
                {Object.entries(chip).map(([key, data], index) => {
                  return (
                    <Box key={data.index} sx={{ padding: "4px" }}>
                      <div
                        onClick={(e) => handleChange(key, index)}
                        style={{
                          backgroundColor: data ? "#0C344E" : "#EFF0F6",
                          color: data ? "#fff" : "#0C344E",
                          borderRadius: "1.5rem",
                          padding: "8px",
                          fontSize: "16px",
                          fontWeight: "500",
                          cursor: "pointer",
                          width: "5rem",
                          textAlign: "center",
                        }}
                      >
                        {key}
                      </div>
                    </Box>
                  );
                })}
              </Box>
            </Grid>
            <Grid my={2} mx={1}>
              <label>Working Time*</label>
              <Grid display="flex">
                <TextField
                  id="Department"
                  select
                  label="In Time*"
                  style={{ width: "40%" }}
                  value={propertyfields.workingHoursStart}
                  onChange={(event) =>
                    updatePropertyfields(event, "workingHoursStart")
                  }
                  variant="standard"
                >
                  {timeArr?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.time}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="Department"
                  select
                  label="Out Time*"
                  style={{ width: "40%" }}
                  value={propertyfields.workingHoursEnd}
                  onChange={(event) =>
                    updatePropertyfields(event, "workingHoursEnd")
                  }
                  variant="standard"
                >
                  {timeArr?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.time}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid my={2} mx={1}>
              <label>Lunch Time*</label>
              <Grid display="flex">
                <TextField
                  id="Department"
                  select
                  label="Start Time*"
                  style={{ width: "40%" }}
                  value={propertyfields.breakStartHours}
                  onChange={(event) =>
                    updatePropertyfields(event, "breakStartHours")
                  }
                  variant="standard"
                >
                  {timeArr?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.time}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="Department"
                  select
                  label="End Time*"
                  style={{ width: "40%" }}
                  value={propertyfields.breakEndHours}
                  onChange={(event) =>
                    updatePropertyfields(event, "breakEndHours")
                  }
                  variant="standard"
                >
                  {timeArr?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.time}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Button
              type="submit"
              className="Button"
              variant="contained"
              color="success"
              onClick={handleSubmit}
              style={{
                border: "1px solid #0C344E",
                color: "white",
                background: "rgb(12, 52, 78)",
                width: "150px",
                height: "48px",
                borderRadius: "10px",
                textTransform: "capitalize",
                marginTop: "15px",
              }}
            >
              Save
            </Button>
          </Box>

          {/* Right data adding document----------------------------------------------------------------------- */}

          <div
            style={{
              position: "relative",
              width: "300px",
            }}
          >
            <div
              className="details"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Attach Document
              <Button
                variant="outline-success"
                type="submit"
                // disabled={!complete}
                className="Button"
                onClick={() => {
                  setOpenDocument(!openDocument);
                }}
                style={{
                  border: "1px dotted #0C344E",
                  color: "#0C344E",
                  borderRadius: "0.5rem",
                  background: "white",
                  width: "13rem",
                  height: "48px",
                  marginLeft: "0rem",
                  marginTop: "1rem",
                  //   display: !disabled ? "block" : "none",
                }}
              >
                + Add Document
              </Button>
            </div>

            {fieldError.document && <ErrorBox mes={fieldError.document} />}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {userDocument.map((doc) => (
                <UserDocument
                  setUserDocument={setUserDocument}
                  userDocument={userDocument}
                  doc={doc}
                />
              ))}
            </div>

            {openDocument && (
              <UploadDocUIStaff
                openDocument={openDocument}
                setOpenDocument={setOpenDocument}
                setVisaDocument={setVisaDocument}
                setPassportDocument={setPassportDocument}
                setOfferLetterDocument={setOfferLetterDocument}
                updatePropertyfields={updatePropertyfields}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
