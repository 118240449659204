import React from "react";

import LeftNav from "../components/leftNav";
import TopNav from "../components/topNav";
import AddUser from "./AddUser";

export default function PropertyName() {
  return (
    <>
      <div className="top-level-container">
        <AddUser />
      </div>
    </>
  );
}
