import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { CSVLink } from "react-csv";
import exportImage from "../assets/export.png";

const ExportCsvFile = (props) => {
 
  const excelRef = useRef();
  useEffect(() => {
    if (props.handleDefault.length > 0) {
      excelRef.current.link.click();
    }
  }, [props.handleDefault]);
  return (
    <>
      <div>
        <div
          style={{
            background: "white",
            borderRadius: "10px",
            padding: "12px",
          }}
          onClick={() => props.function()}
        >
          <img src={exportImage} alt="export" />
        </div>
        <CSVLink
          data={props.csvFile}
          className="mr-2"
          filename={props.fileName}
          target="_blank"
          ref={excelRef}
          style={{
            border: "2px solid black",
            padding: "10px",
            borderRadius: "5px",
            textDecoration: "none",
            color: "black",
            display: "none",
          }}
        ></CSVLink>
      </div>
    </>
  );
};

export default ExportCsvFile;
