import { Box, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import SearchBar from "../components/SearchBar";
import { buildingContext } from "../login/AuthProvider";
import { fetchAssetListing } from "../Redux/AssetSlice";
import AssetTable from "./AssetTable";

function Asset() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);
  const [showTotalData, setShowTotalData] = useState("");
  const [searchText, setSearchText] = useState("");
  const { selectedBuilding } = React.useContext(buildingContext);
  const { assetListingData, isLoading } = useSelector(
    (state) => state.AssetData
  );
  useEffect(() => {
    dispatch(
      fetchAssetListing({ selectedBuilding, page, postPerPage, searchText })
    );
  }, [selectedBuilding, page, searchText]);

  const onSearchChange = (value) => {
    setSearchText(value);
  };
  const totalCount = useMemo(() => {
    const newTotalCount = Math.ceil(assetListingData?.count / postPerPage);
    setShowTotalData(assetListingData?.count);
    return newTotalCount;
  }, [postPerPage, assetListingData?.count]);

  return (
    <div className="top-level-container">
      <div className="home">
        {isLoading && <Loader />}

        <div style={{ display: "flex", alignItems: "center" }}>
          <Box className="property">
            <Box display="flex" justifyContent="space-between" width="74vw">
              <Box>
                <h4>Asset</h4>
              </Box>
              <Box display="flex">
                <SearchBar
                  onChange={onSearchChange}
                  placeholder="Search"
                  maxLength="10"
                />
                <Link to="/add-asset" style={{ textDecoration: "none" }}>
                  <Button
                    className="Button"
                    variant="contained"
                    color="success"
                    style={{
                      border: "1px solid #0C344E",
                      color: "#0C344E",
                      background: "white",
                      width: "250px",
                      height: "48px",
                      fontFamily: "Barlow",
                      fontStyle: "normal",
                      fontweight: "500",
                      fontSize: "16px",
                      lineHeight: "19px",
                      borderRadius: "10px",
                      textTransform: "capitalize",
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 5V19"
                        stroke="#0C344E"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5 12H19"
                        stroke="#0C344E"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Add New Asset
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
        </div>
        <Box marginTop="50px">
          <AssetTable
            {...{
              assetListingData,
              totalCount,
              page,
              setPage,
              showTotalData,
              setShowTotalData,
              postPerPage,
              selectedBuilding,
            }}
          />
        </Box>
      </div>
    </div>
  );
}

export default Asset;
