import { yupResolver } from "@hookform/resolvers/yup";
import { Chip, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { guardSchema } from "../../components/AuthSchema";
import BuildingMultiSelectDropdown from "../../components/BuildingMultiSelectDropdown";
import CountryCodeDropDown from "../../components/CountryCodeDropDown";
import Loader from "../../components/Loader";
import Textfiled from "../../components/Textfiled";
import { getAllBuilding } from "../../services/allPropertiesAPI";
import { AddGuardData } from "../../services/allServisesApi";
import { encrypt } from "../../util/Encryption";

const AddGuard = () => {
  const [buildingData, setBuildingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      userName: "",
      password: "",
      mobileNumber: "",
      countryCode: "",
      buildings: [],
    },
    mode: "onChange",
    resolver: yupResolver(guardSchema),
  });
  const [countryCode, buildings] = watch(["countryCode", "buildings"]);

  const handleInputChangeBuilding = (event) => {
    const value = event.target.value;
    let elementExists = false;
    for (let i = 0; i < buildings.length; i++) {
      if (
        buildings[i].id === value.id ||
        buildings[i].buildingId === value.id
      ) {
        elementExists = true;
        break;
      }
    }
    if (!elementExists) {
      setValue("buildings", [...buildings, value]);
    }
  };

  const fetchBuildingData = async () => {
    let buildingFromStorage = sessionStorage.getItem("building");
    if (buildingFromStorage?.length > 0) {
      const resp = await getAllBuilding();
      let specificBuilding = resp?.data?.data?.rows?.filter(
        (building) => building.id === buildingFromStorage
      );

      setBuildingData(specificBuilding);
    } else {
      const resp = await getAllBuilding();

      setBuildingData(resp.data.data.rows);
    }
  };

  useEffect(() => {
    setValue("countryCode", "+971");
    fetchBuildingData();
  }, []);

  const handleDelete = (CheckedEle) => {
    const result = buildings?.filter((curElem) => {
      return curElem?.id !== CheckedEle;
    });
    setValue("buildings", result);
  };
  const onSubmit = async (data) => {
    setIsLoading(true);

    const payload = {
      ...data,
      countryCode: data.countryCode.replace("+", ""),
      mobileNumber: `${data.countryCode.replace("+", "")}${data.mobileNumber}`,
      buildings: data.buildings.map((building) => building.id),
      password: encrypt(data.password),
    };

    const resp = await AddGuardData(payload);

    if (resp.data.status == "success") {
      toast.success("Add Guard successfully");
      navigate("/guard");
    } else {
      toast.error(resp.data.msg);
    }
    setIsLoading(false);
  };
  return (
    <Box className="top-level-container">
      <Box className="home">
        {isLoading && <Loader />}
        <Box>
          <h4>Add Guard</h4>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Box marginTop="50px">
              <Box>
                <Textfiled
                  label={"Name*"}
                  type="text"
                  width="600px"
                  name="name"
                  maxLength="35"
                  setValue={setValue}
                  register={register}
                />
                <Typography
                  sx={{
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "red",
                  }}
                >
                  {errors?.name?.message}
                </Typography>
              </Box>
              <Box>
                <Textfiled
                  label={"User Name"}
                  type="text"
                  width="600px"
                  name="userName"
                  maxLength="35"
                  setValue={setValue}
                  register={register}
                />
                <Typography
                  sx={{
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "red",
                  }}
                >
                  {errors?.userName?.message}
                </Typography>
              </Box>
              <Box>
                <Textfiled
                  label="Password"
                  type="text"
                  width="600px"
                  name="password"
                  maxLength="35"
                  register={register}
                />
                <Typography
                  sx={{
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "red",
                  }}
                >
                  {errors?.password?.message}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "30px", display: "flex" }}>
                <Box marginRight="10px">
                  <CountryCodeDropDown
                    name="countryCode"
                    value={countryCode}
                    register={register}
                  />
                </Box>
                <Box marginTop="-29px">
                  <Textfiled
                    label="Mobile Number"
                    type="text"
                    width="520px"
                    name="mobileNumber"
                    maxLength="35"
                    register={register}
                    IntialvalueForCountryCode={countryCode}
                  />
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "red",
                    }}
                  >
                    {errors?.mobileNumber?.message && "This filed is required"}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <BuildingMultiSelectDropdown
                  label="Select Building"
                  name="buildings"
                  setValue={setValue}
                  width="600px"
                  innerWidth="600px"
                  buildings={buildings}
                />
              </Box>
              <Stack direction="row" spacing={2} marginTop="10px">
                {buildings.map((ele) => (
                  <Chip
                    sx={{
                      width: "150px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    label={ele?.name_en}
                    variant="outlined"
                    onDelete={() => {
                      handleDelete(ele?.id);
                    }}
                  />
                ))}
              </Stack>
            </Box>
            <Box>
              <button
                type="submit"
                id="savebtn"
                className="Button"
                variant="contained"
                color="success"
                style={{
                  border: "1px solid #0C344E",
                  color: "#0C344E",
                  background: "#0c344e",
                  width: "187px",
                  height: "48px",
                  fontFamily: "Barlow",
                  fontSize: "16px",

                  marginTop: "20px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                  color: "white",
                }}
                onClick={handleSubmit(onSubmit)}
              >
                Add Guard
              </button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AddGuard;
