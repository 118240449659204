import * as React from "react";
import "../App.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
//import logoutt from "../assets/logoutt.png";
import LOGOUT from "../assets/LOGOUT.png";
import SettingsPowerOutlinedIcon from "@mui/icons-material/SettingsPowerOutlined";

export default function LogoutDialog() {
  const Navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [logout, setLogout] = React.useState(true);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    localStorage.clear();
    sessionStorage.clear();
    Navigate("/", { replace: true });
    setLogout(false);
  };

  return (
    <div
      style={{
        marginLeft: "18px",
        display: "flex",
        flexDirection: "row",

        cursor: "pointer",
      }}
    >
      
      <SettingsPowerOutlinedIcon style={{ color: "#fff" }} />
      <div style={{ marginTop: "-0.5rem", marginRight: "4px" }}>
        <button
          onClick={handleClickOpen}
          className="logoutButton navDev"
          style={{
            fontFamily: "Barlow",
            fontSize: "1rem",
            textDecoration: "capitalise",
          }}
        >
          Logout
        </button>
        <div className="logoutModal">
          <Dialog open={open} onClose={handleClose}>
            <div className="logoutContainer">
              <h4 className="text-center logoutHeading">Logout ?</h4>
              <h5 className="text-center logoutText">
                Are You Sure , You want to Logout ?
              </h5>
              <div className="d-flex gap-5 align-items-center justify-content-center mt-4">
                <button onClick={handleClose} className="noBtn">
                  No
                </button>
                <button onClick={handleSubmit} className="yesBtn ">
                  <span className="text-center">Yes</span>
                </button>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
}
