import React from "react";

import AddStaff from "./AddStaff";

export default function AddStaffMain() {
  return (
    <>
      <div className="top-level-container">
        <AddStaff />
      </div>
    </>
  );
}
