import "./UserDocument.css";
import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

export default function UserDocument({
  doc,
  setUserDocument,
  userDocument,
  editMode,
}) {
  const handleDocDel = (e) => {
    const UpdatedDoc = userDocument.filter((item) => item.id !== doc.id);
    setUserDocument(UpdatedDoc);
  };
  return (
    <div className="docWrapper">
      <p className="documentType">{doc?.name ? doc.name : doc?.type}</p>
      <label className="documentName">
        <PictureAsPdfIcon className="pdf" />
        {doc?.filename ? doc.filename : doc?.document.name}{" "}
        <CloseIcon onClick={handleDocDel} className="delDoc" />
      </label>
    </div>
  );
}
