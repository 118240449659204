import { yupResolver } from "@hookform/resolvers/yup";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Grid,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../App.css";
import { buildingSchema } from "../components/AuthSchema";
import CountryCodeDropDown from "../components/CountryCodeDropDown";
import Loader from "../components/Loader";
import OpenDoc from "../PropertyName/OpenDoc";
import OpenGallery from "../PropertyName/OpenGallery";
import {
  getLocality,
  postAddNewBuilding,
  uploadFile,
} from "../services/allPropertiesAPI";
import pdfPhoto from "../StaffDetail/assets/pdfPhoto.png";
import { getMobileNumber } from "../util/index";
import { removeStringFunction } from "../util/removeStringFunction";
import "./AddNewProperty.css";
import { useDispatch } from "react-redux";
import { addProperty } from "../Redux/PropertySlice";

const buildings = [
  {
    value: "COMMERCIAL",
    label: "Commercial",
  },
  {
    value: "CO_LIVING",
    label: "Co-Living",
  },
  {
    value: "CO_WORKING",
    label: "Co-Working",
  },
  {
    value: "RESIDENTIAL",
    label: "Residential",
  },
  {
    value: "OTHERS",
    label: "Others",
  },
];

export default function PropertyName() {
  const navigate = useNavigate();
  const [getImageData, setGetImageData] = useState([]);
  const [getLength, setGetLength] = useState();
  const [getImageDataDoc, setGetImageDataDoc] = useState([]);
  const [getLengthDoc, setGetLengthDoc] = useState();
  const [imageUrl, setImageUrl] = useState([]);
  const [docUrl, setDocUrl] = useState([]);
  const [countryCode, setCountryCode] = useState(null);
  const [locality, setLocality] = useState([]);
  const [handleLoader, setHandleLoader] = useState(false);
  const [countryName, setCountryName] = useState("");
  const dispatch = useDispatch();

  const getUserIpAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      const ipAddress = response.data.ip;
      return ipAddress;
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };

  const getUserLocation = async (ipAddress) => {
    try {
      const response = await axios.get(`http://ip-api.com/json/${ipAddress}`);
      const location = response.data;
      return location;
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  const trackUserLocation = async () => {
    const ipAddress = await getUserIpAddress();
    const location = await getUserLocation(ipAddress);

    setCountryName(location.country);
  };
  useEffect(() => {}, []);

  const getLocalityData = async () => {
    trackUserLocation();
    const getData = await getLocality(countryName);
    if (getData.data.status === "success") {
      setLocality(getData.data.data);
    } else {
    }
  };

  const getImageUrl = async () => {
    setHandleLoader(true);
    try {
      const res = await uploadFile(getImageData);
      if (res.data.status === "success") {
        const getUrl = res.data.data.map((current) => current.location);
        setImageUrl([...imageUrl, ...getUrl]);
      } else {
        toast("Document is not uploaded.");
      }
    } catch {
      toast.error("Could not load image. Please select valid file");
    }
    setHandleLoader(false);
  };
  const getDocUrl = async () => {
    setHandleLoader(true);
    try {
      const res = await uploadFile(getImageDataDoc);
      if (res.data.status === "success") {
        const getUrl = res.data.data.map((current) => current.location);
        setDocUrl([...docUrl, ...getUrl]);
      } else {
        toast("Document is not uploaded.");
      }
    } catch {}
    setHandleLoader(false);
  };

  const {
    control,
    handleSubmit,
    watch,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      buildingId: "",
      name_en: "",
      name_ar: "",
      buildingType: "",
      longitude: "",
      latitude: "",
      address_en: "",
      governmentPropertyId: "",
      contactName: "",
      contactEmail: "",
      contactMobileNumber: "",
      url: "",
      description_en: "",
      images: "",
      documents: "",
      location: "",
      contactCountryCode: "",
    },
    resolver: yupResolver(buildingSchema),
  });

  const handleRemoveImg = (att) => {
    const filtered = imageUrl.filter((item) => item !== att);
    setImageUrl(filtered);
  };

  const handleRemoveDoc = (att) => {
    const filtered = docUrl.filter((item) => item !== att);
    setDocUrl(filtered);
  };

  useEffect(() => {
    getLocalityData();
    setValue("contactCountryCode", "+971");
  }, [countryName]);

  const [IntialvalueForCountryCode, longitude, latitude] = watch([
    "contactCountryCode",
    "longitude",
    "latitude",
  ]);

  useEffect(() => {
    if (getLength) {
      getImageUrl();
    }
  }, [getLength]);

  useEffect(() => {
    if (getLengthDoc) {
      getDocUrl();
    }
  }, [getLengthDoc]);

  const onSubmit = async (data) => {
    setHandleLoader(true);
    data["images"] = imageUrl;
    data["name_ar"] = data.name_en;
    data["address_ar"] = data.address_en;
    data["description_ar"] = data.description_en;
    data["documents"] = docUrl;
    data["location"] = [data.longitude, data.latitude];
    data["contactCountryCode"] = getMobileNumber(
      data.contactCountryCode,
      data.contactMobileNumber
    ).countryCode;
    data["contactMobileNumber"] = getMobileNumber(
      data.contactCountryCode,
      data.contactMobileNumber
    ).mobileNumber;
    const getData = removeStringFunction(data);
    dispatch(addProperty({ getData, navigate }));
  };

  const handleValue = (name, event) => {
    let newValue = event.target.value;
    if (newValue.length > 3) {
      newValue = newValue.slice(0, 15); // restrict to 3 digits
    }
    setValue(`${name}`, newValue);
  };

  return (
    <>
      <div className="top-level-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          {handleLoader && <Loader />}
          <Grid className="home">
            <Box display="flex" justifyContent="space-between" width="100%">
              <Typography fontSize={20}>Add New Property</Typography>
            </Box>
            <Box>
              <Box width="20%" display="flex">
                <OpenGallery
                  setGetImageData={setGetImageData}
                  setGetLength={setGetLength}
                />
                <Box m={2} display="flex" gap={2}>
                  <Box display="flex" gap={2}>
                    {imageUrl.map((curElem, id) => (
                      <Box position="relative" width="10rem" height="10rem">
                        <img
                          style={{ height: "100%", width: "100%" }}
                          alt="..."
                          src={curElem}
                          name="images"
                        />

                        <CancelIcon
                          style={{
                            position: "absolute",
                            right: "2px",
                            top: "5px",
                            color: "#000",
                          }}
                          onClick={() => handleRemoveImg(curElem)}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
              <Grid display="flex" width="100%">
                <Grid width="65%">
                  <Box width="100%" lineHeight="2rem">
                    <Controller
                      name="name_en"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          // error={errors}
                          id="standard-basic"
                          fullWidth
                          label="Property Name*"
                          variant="standard"
                          inputProps={{ maxLength: 50 }}
                        />
                      )}
                    />
                    <Typography
                      sx={{
                        fontStyle: "italic",
                        fontSize: "13px",
                        color: "red",
                      }}
                    >
                      {errors?.name_en?.message.slice(0, 26)}
                    </Typography>
                    <Controller
                      name="address_en"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="standard-basic"
                          label="Address Line"
                          variant="standard"
                          inputProps={{ maxLength: 100 }}
                          fullWidth
                          sx={{ marginTop: "1rem" }}
                        />
                      )}
                    />
                    <Box
                      className="inputColor"
                      width="100%"
                      display="flex"
                      justifyContent="space-between"
                      my={2}
                    >
                      <Box>
                        <Controller
                          name="longitude"
                          control={control}
                          rules={{ max: 3 }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="standard-basic"
                              label="Longitude"
                              type="text"
                              InputLabelProps={{
                                shrink: longitude ? true : false,
                              }}
                              onChange={(e) => {
                                handleValue("longitude", e);
                              }}
                              variant="standard"
                              sx={{ width: "300px" }}
                            />
                          )}
                        />
                        <Typography
                          sx={{
                            fontStyle: "italic",
                            fontSize: "13px",
                            color: "red",
                          }}
                        >
                          {errors?.longitude?.message.slice(0, 27)}
                        </Typography>
                      </Box>
                      <Box>
                        <Controller
                          name="latitude"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="Latitude"
                              label="Latitude"
                              InputLabelProps={{
                                shrink: latitude ? true : false,
                              }}
                              type="text"
                              onChange={(e) => {
                                handleValue("latitude", e);
                              }}
                              variant="standard"
                              sx={{ width: "300px" }}
                            />
                          )}
                        />
                        <Typography
                          sx={{
                            fontStyle: "italic",
                            fontSize: "13px",
                            color: "red",
                          }}
                        >
                          {errors?.latitude?.message.slice(0, 26)}
                        </Typography>
                      </Box>
                    </Box>
                    <Controller
                      name="buildingType"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          select
                          id="standard-basic"
                          label="Building Type"
                          variant="standard"
                          fullWidth
                        >
                          {buildings.map((option) => (
                            <MenuItem key={option.value} value={option.label}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    <Controller
                      name="localityId"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          // error={errors?.localityId}
                          select
                          id="standard-basic"
                          label="Locality*"
                          variant="standard"
                          fullWidth
                          sx={{ marginTop: "1rem" }}
                          // helperText={
                          //   errors?.localityId?.message &&
                          //   "Enter the locality name"
                          // }
                        >
                          {locality?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    <Typography
                      sx={{
                        fontStyle: "italic",
                        fontSize: "13px",
                        color: "red",
                      }}
                    >
                      {errors?.localityId?.message.slice(0, 26)}
                    </Typography>

                    <Controller
                      name="governmentPropertyId"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="standard-basic"
                          label=" Government Property Id"
                          variant="standard"
                          fullWidth
                          inputProps={{
                            maxLength: 35,
                          }}
                          sx={{ marginTop: "1rem" }}
                        />
                      )}
                    />
                    <Controller
                      name="contactName"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="standard-basic"
                          label=" POC Name"
                          variant="standard"
                          inputProps={{ maxLength: 50 }}
                          fullWidth
                          sx={{ marginTop: "1rem" }}
                        />
                      )}
                    />
                    <Controller
                      name="contactEmail"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="standard-basic"
                          label=" POC Email"
                          variant="standard"
                          fullWidth
                          sx={{ marginTop: "1rem" }}
                        />
                      )}
                    />
                    <Typography
                      sx={{
                        fontStyle: "italic",
                        fontSize: "13px",
                        color: "red",
                      }}
                    >
                      {errors?.contactEmail?.message && "Contact email invalid"}
                    </Typography>
                    <Grid width="100%" mt={4}>
                      <Box display="flex">
                        <CountryCodeDropDown
                          name="contactCountryCode"
                          value={IntialvalueForCountryCode}
                          register={register}
                        />

                        <Controller
                          name="contactMobileNumber"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              inputProps={{
                                maxLength:
                                  13 - IntialvalueForCountryCode.length,
                                minLength:
                                  13 - IntialvalueForCountryCode.length,
                              }}
                              id="mobileNumber"
                              label="POC Number"
                              variant="standard"
                              sx={{
                                width: "89%",
                                top: "-1rem",
                                marginLeft: "2%",
                              }}
                            />
                          )}
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontStyle: "italic",
                          fontSize: "13px",
                          color: "red",
                          marginTop: "-0.8rem",
                        }}
                      >
                        {errors?.contactMobileNumber?.message &&
                          "Enter valid number"}
                      </Typography>
                    </Grid>

                    <Controller
                      name="url"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="standard-basic"
                          label=" Url"
                          variant="standard"
                          fullWidth
                          sx={{ marginTop: "1rem" }}
                        />
                      )}
                    />
                    <Controller
                      name="description_en"
                      control={control}
                      render={({ field }) => (
                        <>
                          <label
                            style={{
                              margin: "20px 0 5px 0",
                            }}
                          >
                            Description
                          </label>
                          <TextareaAutosize
                            {...field}
                            aria-label="minimum height"
                            minRows={2}
                            placeholder="Add Description ..."
                            maxLength={300}
                            style={{
                              width: 650,
                              paddingLeft: "10px",
                            }}
                          />
                        </>
                      )}
                    />
                  </Box>

                  <Button
                    type="submit"
                    className="Button"
                    variant="contained"
                    color="success"
                    style={{
                      border: "1px solid #0C344E",
                      color: "white",
                      background: "rgb(12, 52, 78)",

                      width: "150px",
                      height: "48px",
                      borderRadius: "10px",
                      textTransform: "capitalize",
                      marginTop: "15px",
                    }}
                  >
                    Save
                  </Button>
                </Grid>

                <Grid ml={4}>
                  <Typography textAlign="center" fontSize="20px">
                    Attach Document
                  </Typography>
                  <OpenDoc
                    setGetImageDataDoc={setGetImageDataDoc}
                    setGetLengthDoc={setGetLengthDoc}
                  />
                  {docUrl.length > 0 &&
                    docUrl.map((curElem, id) => (
                      <Box
                        className="card"
                        display="flex"
                        justifyContent="space-between"
                        p={2}
                        m={2}
                        style={{
                          boxShadow: " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                          borderStyle: "none",
                        }}
                        width="18rem"
                      >
                        <Box display="flex" justifyContent="space-between">
                          <Typography>
                            <img
                              src={pdfPhoto}
                              style={{ marginRight: "10px" }}
                              alt=""
                            />
                            {curElem
                              ?.split("/")[3]
                              ?.split(".")[0]
                              ?.slice(0, 10)}
                          </Typography>
                          <Typography>
                            <a
                              download
                              href={curElem}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <RemoveRedEyeIcon />
                            </a>
                            <CloseIcon
                              onClick={() => handleRemoveDoc(curElem)}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </form>
      </div>
    </>
  );
}
