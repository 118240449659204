import React from "react";
import "./Dialog.css";
function DenyDialog({ message, onDialog, nameUser, areUSureDelete }) {
    return (
        <div
            style={{
                position: "fixed",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                backgroundColor: "rgba(0,0,0,0.5)",
            }}
            onClick={() => onDialog(false)}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    background: "white",
                    padding: "20px",
                    borderRadius: "10px",
                }}
            >
                <h3
                    stlye={{
                        color: "#111",
                        fontSize: "16px",
                        marginBottom: "30px",
                    }}
                >
                    {message}
                </h3>
                <h1
                    style={{
                        color: "blue",
                        fontSize: "20px",
                        color: "black",
                        marginBottom: "30px",
                    }}
                >
                    {nameUser}
                </h1>

                <div
                    style={{
                        width: "400px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                    }}
                >
                    <button onClick={areUSureDelete} className={"dialogRed"}>
                        Yes
                    </button>
                    <button
                        onClick={() => onDialog(false)}
                        className={"dialogGreen"}
                    >
                        No
                    </button>
                </div>
            </div>
        </div>
    );
}
export default DenyDialog;
