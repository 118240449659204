import InputField from "../InputField";
import "../index.css";
import { TiDeleteOutline } from "react-icons/ti";
import Form from "../Form";
import { useCallback, useState } from "react";
import NewCharges from "./NewCharges";

const CatalogForm2 = ({ id, removeDiv }) => {
    const clickHandler = useCallback(() => {
        removeDiv(id);
    }, [id, removeDiv]);

    //Adding Add New Charge Type Component
    const [newChargeList, setNewChargeList] = useState([]);
    const [newChargeListShow, setNewChargeListShow] = useState(false);

    const handleAddCharge = () => {
        setNewChargeListShow(true);
        setNewChargeList([...newChargeList, new Date().getTime()]);
    };

    return (
        <Form>
            <div
                className="d-flex justify-content-end col-12"
                onClick={clickHandler}
            >
                <TiDeleteOutline style={{ fontSize: "25px" }} />
            </div>
            <InputField placeholder="Catagory Name" />
            <div className="d-flex align-items-end">
                <button className="addCharge" onClick={handleAddCharge}>
                    Add New Charge Type
                </button>
            </div>
            <InputField placeholder="Charge Text" />
            <InputField placeholder="Service Cost (AED)" />
            {newChargeListShow &&
                newChargeList.map((id) => <NewCharges key={id} id={id} />)}
        </Form>
    );
};

export default CatalogForm2;
