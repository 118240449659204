import React, { useState, useEffect, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import { Link, useNavigate } from "react-router-dom";
import "./AllRequesttable.css";
import { getGuardData, getSoftDeleteGuard } from "../services/allServisesApi";
import Loader from "./Loader";
import { buildingContext } from "../login/AuthProvider";
import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import NoDataFound from "./NoDataFound";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ConfirmBox from "./ConfirmBox";
import { toast } from "react-toastify";
export default function GuardTable({
  searchText,
  postPerpage,
  currentPage,
  setcurrentPage,
  totalCount,
  settotalCount,
  guardDetail,
  setGuardDetail,
  showtotalData,
  setShowtotalData,
}) {
  const [loader, setLoader] = useState(false);
  const { selectedBuilding } = useContext(buildingContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [guardId, setGuardId] = useState(false);

  const fetchGuardDetail = async () => {
    setLoader(true);
    const resp = await getGuardData(
      currentPage,
      postPerpage,
      searchText,
      selectedBuilding
    );

    let totalData = resp.data.data.count;
    setShowtotalData(totalData);
    let pagesCount = Math.ceil(totalData / postPerpage);
    settotalCount(pagesCount);
    setGuardDetail(resp.data.data.rows);
    setLoader(false);
  };
  useEffect(() => {
    fetchGuardDetail();
  }, [currentPage, selectedBuilding]);
  const handleNavigate = (id) => {
    navigate(`/editguards/${id}`);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirmBox = () => {
    setOpenConfirm(false);
  };

  const deleteGuard = async () => {
    const resp = await getSoftDeleteGuard(guardId);
    if (resp.data.status == "fail") {
      toast.error(resp.data.msg);
    } else {
      toast.success("Guard delete successfully");
      fetchGuardDetail();
    }
  };
  return (
    <>
      {guardDetail?.length > 0 ? (
        <>
          {loader && <Loader />}
          <TableContainer component={Paper}>
            <Table sx={{}} aria-label="simple table">
              <TableHead className="thead">
                <TableRow>
                  <TableCell align="center" className="Sbold">
                    Name
                  </TableCell>
                  <TableCell align="center" className="Sbold">
                    User Name
                  </TableCell>
                  <TableCell align="center" className="Sbold">
                    Country Code
                  </TableCell>

                  <TableCell align="center" className="Sbold">
                    Mobile Number
                  </TableCell>
                  <TableCell align="center" className="Sbold">
                    Building Name
                  </TableCell>
                  <TableCell align="center" className="Sbold">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {guardDetail?.length > 0 &&
                  guardDetail?.map((row) => (
                    <TableRow
                      key={row.id}
                      className="rowTable"
                      onClick={() => {
                        setGuardId(row?.id);
                      }}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell
                        align="center"
                        sx={{ padding: "0px" }}
                        onClick={() => {
                          handleNavigate(row?.id);
                        }}
                      >
                        {row.name}
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{ padding: "0px" }}
                        onClick={() => {
                          handleNavigate(row?.id);
                        }}
                      >
                        {row.userName}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ padding: "0px" }}
                        onClick={() => {
                          handleNavigate(row?.id);
                        }}
                      >
                        +{row.countryCode}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ padding: "0px" }}
                        onClick={() => {
                          handleNavigate(row?.id);
                        }}
                      >
                        {row.mobileNumber.replace(row.countryCode, "")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ padding: "0px" }}
                        onClick={() => {
                          handleNavigate(row?.id);
                        }}
                      >
                        <Button sx={{ m: 1, color: "#471f20" }}>
                          {row?.guardBuildings?.length === 1
                            ? row?.guardBuildings[0]?.building?.name_en
                            : `${
                                row?.guardBuildings[0]?.building?.name_en
                              }${","} ${"+"}
                         ${row?.guardBuildings?.length - 1} ${"more"}`}
                        </Button>
                      </TableCell>
                      <ConfirmBox {...{ openConfirm, handleCloseConfirmBox }}>
                        <Typography variant="h6">
                          Are you sure, You want to delete ?
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            columnGap: "20px",
                            margin: "30px 0px 0px 15px",
                          }}
                        >
                          <Button
                            sx={{
                              textTransform: "capitalize",
                              border: "1px solid #0c344e",
                              color: "#0c344e",
                            }}
                            onClick={handleCloseConfirmBox}
                          >
                            No
                          </Button>
                          <Button
                            onClick={(e) => {
                              deleteGuard();
                              setOpenConfirm(false);
                              e.stopPropagation();
                            }}
                            variant="standard"
                            sx={{
                              textTransform: "capitalize",
                              border: "1px solid #0c344e",
                              color: "white",
                              background: "#0c344e",
                              "&:hover": {
                                backgroundColor: "white",
                                color: "#0c344e",
                              },
                            }}
                          >
                            Yes
                          </Button>
                        </Box>
                      </ConfirmBox>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          onClick={(e) => {
                            handleNavigate(guardId);
                            e.stopPropagation();
                          }}
                        >
                          Update
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            handleClose();
                            handleOpenConfirm();
                            e.stopPropagation();
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                      <TableCell align="center" padding="7px">
                        <MoreVertIcon onClick={handleClick} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex justify-content-between height-200px mt-2">
            <div>
              <p>
                Showing {postPerpage * (currentPage - 1) + 1}
                &nbsp;-
                {currentPage * postPerpage > showtotalData
                  ? showtotalData
                  : currentPage * postPerpage}{" "}
                out of {showtotalData} entries
              </p>
            </div>
            <div>
              <Pagination
                count={totalCount}
                onChange={(event, value) => {
                  setcurrentPage(value);
                }}
                shape="rounded"
                style={{
                  margin: "10px",
                  float: "right",
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <Grid className="nofound">
          <NoDataFound />
        </Grid>
      )}
    </>
  );
}
