import { yupResolver } from "@hookform/resolvers/yup";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { userSchema } from "../components/AuthSchema";
import DatePicker from "../components/DatePicker";
import OpenGallery from "../PropertyName/OpenGallery";
import UploadImage from "../PropertyName/UploadImage";
import { updateUser, uploadFile } from "../services/allPropertiesAPI";
import { getMobileNumber } from "../util";
import { removeStringFunction } from "../util/removeStringFunction";
import CountryCodeDropDown from "../components/CountryCodeDropDown";
import NationalityDropDown from "../components/NationalityDropDown";

const EditTenantDetail = ({
  propertyFields,
  setHandleEditMode,
  setHandleLoader,
  setPropertyFields,
}) => {
  const gender = ["Male", "Female"];
  const idType = ["Emirates Id", "Passport"];
  const [getImageData, setGetImageData] = useState([]);
  const [getLength, setGetLength] = useState();
  const [imageUrl, setImageUrl] = useState([]);
  const [profilePic, setProfilePic] = useState("");
  const subFinalArray = imageUrl.map((current) => current.location);
  const [localImgUrl, setLocalImgUrl] = useState(propertyFields?.userImage);

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      buildingId: propertyFields?.buildingId,
      name: propertyFields?.name,
      email: propertyFields?.email,
      mobileNumber: propertyFields?.mobileNumber,
      countryCode: propertyFields?.countryCode,
      documentId: propertyFields?.documentId,
      documentType: propertyFields?.documentType,
      alternateEmail: propertyFields?.alternateContactEmail
        ? propertyFields?.alternateContactEmail
        : "",
      alternateMobileNumber: propertyFields?.alternateContactMobileNumber,
      allocatedFlat:
        propertyFields?.allocatedFlatExpired === false
          ? propertyFields?.allocatedFlat
          : "",
      dateOfBirth: propertyFields?.dateOfBirth,
      gender: propertyFields?.gender,
      accountNumber: propertyFields?.accountNumber,
      bankName: propertyFields?.bankName,
      building:
        propertyFields?.allocatedFlatExpired === false
          ? propertyFields?.building
          : "",
      nationality: propertyFields?.nationality,
      profilePicture: propertyFields?.userImage,
      masterUserId: propertyFields?.userid,
      alternateCountryCode: propertyFields?.alternateCountryCode
        ? propertyFields?.alternateCountryCode
        : "",
      swiftCode: propertyFields?.swiftCode,
      iban: propertyFields.iban,
    },
    resolver: yupResolver(userSchema),
  });

  const [IntialvalueForCountryCode, nationality] = watch([
    "countryCode",
    "nationality",
  ]);
  const IntialvalueForAlternateCountryCode = watch("countryCode");

  useEffect(() => {
    setLocalImgUrl([...propertyFields?.userImage, ...subFinalArray]);
  }, [imageUrl]);

  const getImageUrl = async () => {
    setHandleLoader(true);
    try {
      const res = await uploadFile(getImageData);
      if (res.data.status === "success") {
        const getUrl = res.data.data.map((current) => current.location);
        setImageUrl([getUrl]);
        setProfilePic(getUrl[0]);
      } else {
        toast("Document is not uploaded. Please check your connection");
      }
    } catch {}
    setHandleLoader(false);
  };
  useEffect(() => {
    if (getLength) {
      getImageUrl();
    }
  }, [getLength]);

  const handleValue = (name, event) => {
    let newValue = event.target.value;
    if (newValue.length > 3) {
      newValue = newValue.slice(0, 20); // restrict to 3 digits
    }
    setValue(`${name}`, newValue);
  };

  const onSubmit = async (data) => {
    setHandleLoader(true);
    data["profilePicture"] = propertyFields.userImage
      ? propertyFields.userImage
      : profilePic;
    data["countryCode"] =
      data?.countryCode && data?.countryCode?.replace("+", "");
    data["alternateCountryCode"] =
      data?.alternateCountryCode &&
      data?.alternateCountryCode?.replace("+", "");
    data["mobileNumber"] =
      data?.mobileNumber &&
      getMobileNumber(data?.countryCode, data?.mobileNumber).mobileNumber;
    data["alternateMobileNumber"] =
      data?.alternateMobileNumber &&
      getMobileNumber(data?.alternateCountryCode, data?.alternateMobileNumber)
        .mobileNumber;
    data["dateOfBirth"] = data.dateOfBirth
      ? moment(data.dateOfBirth).format("DD/MM/YYYY")
      : null;
    const getData = removeStringFunction(data);
    const postData = await updateUser(getData);
    if (postData.data.status === "success") {
      toast("Saved Successful");
      setHandleEditMode(false);
    } else {
      toast(postData.data.msg);
      setHandleEditMode(false);
    }
    setHandleLoader(false);
  };
  const handleRemoveImg = (att, type) => {
    if (type) {
      setPropertyFields({
        userImage: "",
      });
    } else {
      setProfilePic("");
    }
  };
  // const handleText = (e, name) => {
  //   let newValue = e.target.value;
  //   newValue = newValue.replace(/[^A-Za-z]/gi, "");
  //   setValue(`${name}`, newValue);
  // };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography color="#111120" fontSize={18}>
              Edit User Details
            </Typography>
          </Box>
          <Box>
            {" "}
            <Button
              style={{ textDecoration: "underLine" }}
              onClick={() => {
                setHandleEditMode(false);
              }}
            >
              Cancel
            </Button>
            <Button type="submit" style={{ textDecoration: "underLine" }}>
              Save Changes
            </Button>
          </Box>
        </Box>
        <Box
          display="flex"
          style={{ overflowX: "scroll" }}
          className="hiddenScroll"
        >
          <OpenGallery
            setGetImageData={setGetImageData}
            setGetLength={setGetLength}
          />
          <Box m={2} display="flex" gap={2}>
            <Box display="flex" gap={2} style={{ position: "relative" }}>
              {propertyFields.userImage && (
                <>
                  <img
                    src={propertyFields.userImage}
                    alt="..."
                    style={{ height: "10rem", width: "10rem" }}
                  />
                  <CancelIcon
                    style={{
                      position: "absolute",
                      right: "2px",
                      top: "5px",
                      color: "#000",
                    }}
                    onClick={() =>
                      handleRemoveImg(propertyFields.userImage, "api")
                    }
                  />
                </>
              )}
              {profilePic && (
                <>
                  <img
                    src={profilePic}
                    alt="..."
                    style={{ height: "10rem", width: "10rem" }}
                  />
                  <CancelIcon
                    style={{
                      position: "absolute",
                      right: "2px",
                      top: "5px",
                      color: "#000",
                    }}
                    onClick={() => handleRemoveImg(profilePic, "")}
                  />
                </>
              )}
            </Box>
          </Box>
          <UploadImage
            getImageData={getImageData}
            setImageUrl={setImageUrl}
            getLength={getLength}
            setHandleLoader={setHandleLoader}
            imageUrl={imageUrl}
          />
        </Box>
        <Grid>
          <Box
            className="inputColor"
            width="100%"
            display="flex"
            justifyContent="space-between"
            my={5}
          >
            <Box>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="standard-basic"
                    label="Name"
                    inputProps={{ maxLength: 50 }}
                    // onChange={(e) => {
                    //   handleText(e, "name");
                    // }}
                    variant="standard"
                    sx={{ width: "300px" }}
                  />
                )}
              />
              <Typography
                sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
              >
                {errors?.name?.message}
              </Typography>
            </Box>
          </Box>
          <Box
            className="inputColor"
            width="100%"
            display="flex"
            justifyContent="space-between"
            my={2}
          >
            <Box>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="standard-basic"
                    label="Email"
                    variant="standard"
                    sx={{ width: "300px" }}
                  />
                )}
              />
              <Typography
                sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
              >
                {errors?.email?.message && "Email invalid"}
              </Typography>
            </Box>
            <Grid width="45%">
              <Box display="flex" marginTop="20px">
                <Box>
                  <CountryCodeDropDown name="countryCode" register={register} />
                </Box>

                <Box marginLeft="10px">
                  <Controller
                    name="mobileNumber"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        inputProps={{
                          maxLength: 13 - IntialvalueForCountryCode.length,
                          minLength: 13 - IntialvalueForCountryCode.length,
                        }}
                        id="mobileNumber"
                        variant="standard"
                        sx={{ width: "200px" }}
                      />
                    )}
                  />
                  <Typography
                    sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
                  >
                    {errors?.mobileNumber?.message && "Enter valid number"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>
          <Box
            className="inputColor"
            width="100%"
            display="flex"
            justifyContent="space-between"
            my={5}
          >
            <Box>
              <DatePicker
                label="D.O.B"
                Controller={Controller}
                control={control}
                name="dateOfBirth"
                register={register}
                width={"300px"}
              />
              <Typography
                sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
              >
                {errors?.dateOfBirth?.message}
              </Typography>
            </Box>
            <Box>
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    id="standard-basic"
                    label="Gender"
                    variant="standard"
                    sx={{ width: "300px" }}
                  >
                    {gender?.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Typography
                sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
              >
                {errors?.gender?.message}
              </Typography>
            </Box>
          </Box>
          <Box
            className="inputColor"
            width="100%"
            display="flex"
            justifyContent="space-between"
            my={5}
          >
            <Box>
              <Controller
                name="allocatedFlat"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="standard-basic"
                    label=" Allocated Flat"
                    disabled
                    variant="standard"
                    sx={{ width: "300px" }}
                  />
                )}
              />
              <Typography
                sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
              >
                {errors?.allocatedFlat?.message}
              </Typography>
            </Box>
            <Box>
              <Controller
                name="building"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="standard-basic"
                    label=" Building"
                    disabled
                    variant="standard"
                    sx={{ width: "300px" }}
                  />
                )}
              />
              <Typography
                sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
              >
                {errors?.building?.message}
              </Typography>
            </Box>
          </Box>
          <Box
            className="inputColor"
            width="100%"
            display="flex"
            justifyContent="space-between"
            my={5}
          >
            <Box>
              <Controller
                name="alternateEmail"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="standard-basic"
                    label="Alternate Email"
                    variant="standard"
                    sx={{ width: "300px" }}
                  />
                )}
              />
              <Typography
                sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
              >
                {errors?.alternateEmail?.message}
              </Typography>
            </Box>
            <Grid width="45%">
              <Box display="flex" marginTop="20px">
                <Box>
                  <CountryCodeDropDown
                    name="alternateCountryCode"
                    register={register}
                  />
                </Box>

                <Box marginLeft="10px">
                  <Controller
                    name="alternateMobileNumber"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Alternative MobileNumber"
                        inputProps={{
                          maxLength:
                            13 - IntialvalueForAlternateCountryCode.length,
                          minLength:
                            13 - IntialvalueForAlternateCountryCode.length,
                        }}
                        id="mobileNumber"
                        variant="standard"
                        sx={{ width: "200px", marginTop: "-14px" }}
                      />
                    )}
                  />
                </Box>
              </Box>
              <Typography
                sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
              >
                {errors?.alternateMobileNumber?.message && "Enter valid number"}
              </Typography>
            </Grid>
          </Box>
          <Box
            className="inputColor"
            width="100%"
            display="flex"
            justifyContent="space-between"
            my={5}
          >
            <Box>
              <Controller
                name="documentType"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    id="standard-basic"
                    label="Id Type"
                    variant="standard"
                    sx={{ width: "300px" }}
                  >
                    {idType?.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Typography
                sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
              >
                {errors?.documentType?.message}
              </Typography>
            </Box>
            <Box>
              <Controller
                name="documentId"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="standard-basic"
                    label="Id Number"
                    inputProps={{ maxLength: 30 }}
                    variant="standard"
                    sx={{ width: "300px" }}
                  />
                )}
              />
              <Typography
                sx={{ fontStyle: "italic", fontSize: "13px", color: "red" }}
              >
                {errors?.documentId?.message.slice(0, 30)}
              </Typography>
            </Box>
          </Box>
          <Box
            className="inputColor"
            width="100%"
            display="flex"
            justifyContent="space-between"
            my={5}
          >
            {/* <Controller
              name="nationality"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  id="standard-basic"
                  label="Nationality"
                  variant="standard"
                  sx={{ width: "45%" }}
                >
                  {nationality?.map((option) => (
                    <MenuItem key={option.key} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            /> */}
            <NationalityDropDown
              label="Select Nationality"
              name="nationality"
              register={register}
              value={nationality}
            />
            <Controller
              name="bankName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="standard-basic"
                  inputProps={{ maxLength: 50 }}
                  label="Bank Name"
                  variant="standard"
                  sx={{ width: "45%" }}
                />
              )}
            />
          </Box>
          <Box
            className="inputColor"
            width="100%"
            display="flex"
            justifyContent="space-between"
            my={5}
          >
            <Controller
              name="accountNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  id="standard-basic"
                  onChange={(e) => {
                    handleValue("accountNumber", e);
                  }}
                  label="Bank Account Number"
                  variant="standard"
                  sx={{ width: "45%" }}
                />
              )}
            />
            <Controller
              name="swiftCode"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="standard-basic"
                  label="SwiftCode"
                  variant="standard"
                  sx={{ width: "300px", marginTop: "1px" }}
                />
              )}
            />
          </Box>
          <Box
            className="inputColor"
            width="100%"
            display="flex"
            justifyContent="space-between"
            my={5}
          >
            <Controller
              name="iban"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  id="standard-basic"
                  label="Iban No"
                  onChange={(e) => {
                    handleValue("iban", e);
                  }}
                  variant="standard"
                  sx={{ width: "300px", marginTop: "1px" }}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditTenantDetail;
