import {
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import { showDash } from "../util";

const PreventativeTable = ({
  preventativeListingData,
  page,
  setPage,
  showTotalData,
  setShowTotalData,
  totalCount,
  postPerPage,
  selectedBuilding,
}) => {
  const heading = [
    "Preventative Id",
    "Title",
    "Priority",
    "Category",
    "Raised For",
    "Valid From",
    "Valid Till",
  ];

  return (
    <>
      {preventativeListingData?.rows?.length > 0 ? (
        <div>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead className="thead">
                <TableRow>
                  {heading.map((current, i) => (
                    <>
                      <TableCell align="center" className="bold">
                        {current}
                      </TableCell>
                    </>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {preventativeListingData?.rows?.map((row) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                      "&:nth-of-type(even)": {
                        backgroundColor: "#EFF0F6",
                      },
                    }}
                  >
                    <TableCell align="center">
                      <Link to={`/preventative/${row?.id}`}>{row?.pmId}</Link>
                    </TableCell>
                    <TableCell align="center">{showDash(row?.name)}</TableCell>
                    <TableCell align="center">
                      {showDash(row?.priority)}
                    </TableCell>
                    <TableCell align="center">
                      {showDash(row?.category)}
                    </TableCell>
                    <TableCell align="center">{showDash(row?.type)}</TableCell>
                    <TableCell align="center">
                      {showDash(row?.validFrom)}
                    </TableCell>
                    <TableCell align="center">
                      {showDash(row?.validTill)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* pagination  */}
          <Box display="flex" justifyContent="space-between" marginTop="10px">
            <Box>
              <p>
                Showing {postPerPage * (page - 1) + 1}
                &nbsp;-
                {page * postPerPage > showTotalData
                  ? showTotalData
                  : page * postPerPage}{" "}
                out of {showTotalData} entries
              </p>
            </Box>
            <Box>
              <Pagination
                count={totalCount}
                onChange={(event, value) => {
                  setPage(value);
                }}
                shape="rounded"
              />
            </Box>
          </Box>
        </div>
      ) : (
        <Grid className="nofound">No Data Found</Grid>
      )}
    </>
  );
};

export default PreventativeTable;
