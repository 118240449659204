import "./ViewImageUI.css"
import CloseIcon from '@mui/icons-material/Close';

export default function ViewImageUI({editedUserProfileLink,userProfile,localUserImageUrl,setLocalUserImageUrl,setUserProfile}){
   
    const handleDelete=()=>{
        setLocalUserImageUrl(null)
        setUserProfile(undefined)
    }
    return (
        <div className="imageContainer">
           <img className="mainImage" src={editedUserProfileLink? editedUserProfileLink:localUserImageUrl}/> 
           <CloseIcon className="imageDel" onClick={handleDelete}/>
        </div>
        
    )
}