import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "../index.css";
import { buildingContext } from "../login/AuthProvider";
import {
  getStaffAppointment,
  getStaffDepartment,
  getStaffDesignation,
  getStaffdetail,
  updateStaff,
  uploadFile,
} from "../services/allPropertiesAPI";
import Image from "./assets/gallery.png";
import { getMobileNumber } from "../util";

const EditStaffDetail = ({
  propertyFields,
  docType,
  setPropertyFields,
  setHandleEditMode,
  setHandleLoader,
}) => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const location = useLocation();
  const userId = location.pathname.split("/")[2];
  const { selectedBuilding } = React.useContext(buildingContext);
  let buildingId = selectedBuilding;
  // const pageUrl = location.pathname;
  const staffId = urlParams.id;
  const ref = useRef();
  const [image, setImage] = useState({
    imagePreviewUrl: "",
  });
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [appointment, setAppointment] = useState([]);
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [nationality, setNationality] = useState(null);
  const [removeProfile, setRemoveProfile] = useState("false");
  const [searchText, setSearchText] = useState("");
  const [handleData, setHandleData] = useState({
    staffId: staffId,
    name: propertyFields.name,
    department: propertyFields.department,
    designation: propertyFields.designation,
    appointment: propertyFields.designation,
    profilePicture: propertyFields.profilePicture,
    visaDocument: propertyFields.visaDocument,
    passportDocument: propertyFields.passportDocument,
    offerLetterDocument: propertyFields.offerLetterDocument,
    nationality: propertyFields.nationality,
    emiratesId: propertyFields.emiratesId,
    passportNumber: propertyFields.passportNumber,
    email: propertyFields.email,
    mobileNumber: propertyFields.mobileNumber,
    countryCode: propertyFields.countryCode,
  });
  const handleRemoveImg = (type) => {
    if (type === "api") {
      setHandleData({
        ...handleData,
        profilePicture: "",
      });
      setRemoveProfile(true);
    }
    if (type === "local") {
      setImage({
        imagePreviewUrl: "",
      });
    }
  };
  useEffect(() => {
    const fetchDepartment = async () => {
      setHandleLoader(true);
      const res = await getStaffDepartment();
      setDepartment(res.data.data);
      setHandleLoader(false);
    };
    const fetchDesignation = async () => {
      setHandleLoader(true);
      const res = await getStaffDesignation();
      setDesignation(res.data.data);
      setHandleLoader(false);
    };
    const fetchAppointment = async () => {
      setHandleLoader(true);
      const res = await getStaffAppointment();
      setAppointment(res.data.data);
      setHandleLoader(false);
    };

    const fetchCountryCode = async () => {
      setHandleLoader(true);
      const res = await axios.get(
        "https://gist.githubusercontent.com/anubhavshrimal/75f6183458db8c453306f93521e93d37/raw/f77e7598a8503f1f70528ae1cbf9f66755698a16/CountryCodes.json"
      );
      setCountryCode(res.data);
      setNationality(res.data);
      setHandleLoader(false);
    };

    fetchCountryCode();
    fetchDepartment();
    fetchDesignation();
    fetchAppointment();
  }, []);

  const handleFormData = (event, key) => {
    setHandleData((preState) => {
      return {
        ...preState,
        [`${key}`]: event.target.value,
      };
    });
  };

  const handleUpdateData = async () => {
    const request = {
      ...handleData,
      department: department?.find((i) => i.value === handleData.department)
        ?.key,
      appointment: appointment?.find((i) => i.value === handleData.appointment)
        ?.key,
      designation: designation?.find((i) => i.value === handleData.designation)
        ?.key,
      profilePicture: image.imagePreviewUrl,
      countryCode: handleData.countryCode.replace("+", ""),
    };
    setHandleLoader(true);
    try {
      const res = await updateStaff(request);
      toast(res?.data?.status);
      if (res?.data?.status) {
        const res = await getStaffdetail({ userId, buildingId });
        setPropertyFields({
          userId: res.data.data.flatsAssociated.staffId,
          profilePicture: res.data.data.profilePicture,
          name: res.data.data.name,
          department: res.data.data.department,
          designation: res.data.data.designation,
          appointment: res.data.data.appointment,
          nationality: res.data.data.nationality,
          emiratesId: res.data.data.emiratesId,
          passportNumber: res.data.data.passportNumber,
          offerLetterDocument: res.data.data.offerLetterDocument,
          passportDocument: res.data.data.passportDocument,
          visaDocument: res.data.data.visaDocument,
          // document: res.data.data.documents,
          mobileNumber: res.data.data.mobileNumber,
          email: res.data.data.email,
          countryCode:
            res.data.data.countryCode.split("").indexOf("+") === -1
              ? `+${res.data.data.countryCode}`
              : res.data.data.countryCode,
        });
        setHandleEditMode(false);
      }
    } catch (err) {
      alert(err);
    }
    setHandleLoader(false);
  };
  const handleImageData = async (event) => {
    setHandleLoader(true);
    const fileListArray = event.target.files;
    const formData = new FormData();
    formData.append("file", fileListArray[0]);
    try {
      const response = await uploadFile(formData);
      if (response.data.status === "success") {
        setImage({
          imagePreviewUrl: response.data.data[0].location,
        });
      } else {
        toast(response.data.status);
      }
    } catch {
      toast("err");
    }
    setHandleLoader(false);
  };
  return (
    <>
      <Grid>
        <Box display="flex" justifyContent="space-between">
          <Typography color="#111120" fontSize={18}>
            Edit Staff Details
          </Typography>
          <Typography
            color=" #0C344E"
            fontSize={16}
            sx={{ cursor: "pointer", textDecoration: "underLine" }}
            onClick={handleUpdateData}
          >
            Save Changes
          </Typography>
        </Box>
        {image.imagePreviewUrl === "" ? (
          <Box my={2} style={{ position: "relative" }}>
            <input
              type="file"
              ref={ref}
              style={{ display: "none" }}
              onChange={handleImageData}
            />
            <img
              alt=""
              src={
                propertyFields?.profilePicture === "" || removeProfile === true
                  ? Image
                  : propertyFields.profilePicture
              }
              style={{ height: "10rem", width: "14rem" }}
              onClick={() => ref.current.click()}
            />
            <CancelIcon
              style={{
                position: "absolute",
                left: "12.5rem",
                top: "2px",
                color: "#fff",
              }}
              onClick={() => handleRemoveImg("api")}
            />
          </Box>
        ) : (
          <Box my={2} style={{ position: "relative" }}>
            <input
              type="file"
              ref={ref}
              style={{ display: "none" }}
              onChange={handleImageData}
            />
            <img
              alt=""
              src={image.imagePreviewUrl}
              style={{ height: "10rem", width: "14rem" }}
              onClick={() => ref.current.click()}
            />
            <CancelIcon
              style={{
                position: "absolute",
                left: "12.5rem",
                top: "2px",
                color: "#fff",
              }}
              onClick={() => handleRemoveImg("local")}
            />
          </Box>
        )}
        <Grid>
          <Typography fontSize={20}>Other Details</Typography>
          <Box
            className="inputColor"
            width="100%"
            display="flex"
            justifyContent="space-between"
            my={5}
          >
            <TextField
              id="standard-basic"
              label="Name*"
              variant="standard"
              inputProps={{ maxLength: 50 }}
              sx={{ width: "45%" }}
              defaultValue={propertyFields?.name}
              onChange={(event) => handleFormData(event, "name")}
            />
            <Box display="flex">
              <Box marginTop="-3px">
                <FormControl fullWidth sx={{ marginTop: "23px" }}>
                  <Select
                    MenuProps={{ autoFocus: false }}
                    sx={{ width: "70px" }}
                    labelId="search-select-label"
                    id="search-select"
                    variant="standard"
                    name="countryCode"
                    className="customiseCountryCode"
                    defaultValue={propertyFields.countryCode}
                    onChange={(event) => handleFormData(event, "countryCode")}
                    onClose={() => setSearchText("")}
                  >
                    <ListSubheader>
                      <TextField
                        size="small"
                        variant="standard"
                        placeholder="Type to search..."
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                    </ListSubheader>
                    {countryCode
                      ?.filter((option) =>
                        option.name
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                      )
                      .map((option) => (
                        <MenuItem
                          key={option.key}
                          value={option.dial_code}
                          sx={{ backgroundColor: "#fff" }}
                        >
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          >
                            <img
                              width="20"
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            {option.name} ({option.dial_code})
                          </Box>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ marginTop: "20px" }}>
                <TextField
                  type="number"
                  id="standard-basic"
                  label=""
                  variant="standard"
                  sx={{ width: "100%" }}
                  defaultValue={propertyFields.mobileNumber}
                  onChange={(event) => handleFormData(event, "mobileNumber")}
                />
              </Box>
            </Box>
          </Box>
          <Box
            className="inputColor"
            width="100%"
            display="flex"
            justifyContent="space-between"
            my={2}
          >
            <TextField
              select
              id="standard-basic"
              label="Department"
              variant="standard"
              sx={{ width: "45%" }}
              defaultValue={propertyFields.department}
              onChange={(event) => handleFormData(event, "department")}
            >
              {department?.map((option) => (
                <MenuItem key={option.key} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              id="designation"
              label="Designation"
              variant="standard"
              sx={{ width: "45%" }}
              defaultValue={propertyFields.designation}
              onChange={(event) => handleFormData(event, "designation")}
            >
              {designation?.map((option, key) => (
                <MenuItem key={option.key} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box
            className="inputColor"
            width="100%"
            display="flex"
            justifyContent="space-between"
            my={5}
          >
            <FormControl fullWidth>
              <InputLabel id="search-select-label" sx={{ marginLeft: "-14px" }}>
                Select Nationality
              </InputLabel>
              <Select
                MenuProps={{ autoFocus: false }}
                sx={{ width: "255px" }}
                labelId="search-select-label"
                id="search-select"
                variant="standard"
                name="nationality"
                defaultValue={propertyFields.nationality}
                onChange={(event) => handleFormData(event, "nationality")}
                onClose={() => setSearchText("")}
              >
                <ListSubheader>
                  <TextField
                    size="small"
                    variant="standard"
                    placeholder="Type to search..."
                    sx={{ width: "300px" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>
                {nationality
                  ?.filter((option) =>
                    option.name.toLowerCase().includes(searchText.toLowerCase())
                  )
                  ?.map((option, i) => (
                    <MenuItem key={option?.id} value={option?.name}>
                      {option?.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              select
              id="standard-basic"
              label="Appointment"
              variant="standard"
              sx={{ width: "480px" }}
              defaultValue={propertyFields.appointment}
              onChange={(event) => {
                handleFormData(event, "appointment");
              }}
            >
              {appointment?.map((option, key) => (
                <MenuItem key={option.key} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box
            className="inputColor"
            width="100%"
            display="flex"
            justifyContent="space-between"
            my={5}
          >
            <TextField
              id="standard-basic"
              label="National ID Number"
              variant="standard"
              inputProps={{ maxLength: 20 }}
              sx={{ width: "45%" }}
              defaultValue={propertyFields.emiratesId}
              onChange={(event) => handleFormData(event, "emiratesId")}
            />
            <TextField
              id="standard-basic"
              label="Passport Number"
              variant="standard"
              inputProps={{ minLength: 4, maxLength: 25 }}
              sx={{ width: "45%" }}
              defaultValue={propertyFields.passportNumber}
              onChange={(event) => handleFormData(event, "passportNumber")}
            />
          </Box>
          <Box
            className="inputColor"
            width="100%"
            display="flex"
            justifyContent="space-between"
            my={5}
          >
            <TextField
              id="standard-basic"
              label="Email ID*"
              variant="standard"
              sx={{ width: "45%" }}
              defaultValue={propertyFields.email}
              onChange={(event) => handleFormData(event, "email")}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default EditStaffDetail;
