import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditNoticeDetails from "./EditNoticeDetails";
import edit from "../../StaffDetail/assets/edit.png";
import { useDispatch, useSelector } from "react-redux";
import { getNoticeDetails, patchNoticeDetails } from "../../Redux/NoticeSlice";
import { useParams } from "react-router-dom";
import { getDate } from "../../util";
import Loader from "../../components/Loader";
import CloseIcon from "@mui/icons-material/Close";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import OpenDoc from "../../PropertyName/OpenDoc";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import pdfPhoto from "../../StaffDetail/assets/pdfPhoto.png";
import { uploadFile } from "../../services/allPropertiesAPI";
import { toast } from "react-toastify";
import moment from "moment";

const NoticesDetail = () => {
  const { getNoticeDetailData, isLoading, isSuccess, isUpdated } = useSelector(
    (state) => state.NoticesData
  );
  const [handleEditMode, setHandleEditMode] = useState(false);
  const [handleEditDocMode, setHandleEditDocMode] = useState(false);
  const [getImageDataDoc, setGetImageDataDoc] = useState([]);
  const [getLengthDoc, setGetLengthDoc] = useState();
  const [docUrl, setDocUrl] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();

  const [propertyFields, setPropertyFields] = useState({
    documents: [],
  });

  useEffect(() => {
    setPropertyFields({
      documents: getNoticeDetailData?.documents,
    });
  }, [getNoticeDetailData.documents]);

  const handleEditButton = () => {
    setHandleEditMode(true);
  };
  const handleEditDocButton = () => {
    setHandleEditDocMode(true);
  };

  useEffect(() => {
    dispatch(getNoticeDetails(id));
  }, [isUpdated]);

  const getDocUrl = async () => {
    try {
      const res = await uploadFile(getImageDataDoc);
      if (res.data.status === "success") {
        const getUrl = res.data.data.map((current) => current.location);
        setDocUrl([...docUrl, ...getUrl]);

        setPropertyFields({
          documents: [...getUrl, ...propertyFields?.documents],
        });
      } else {
        toast("Document is not uploaded. Please check your connection");
      }
    } catch {}
  };
  useEffect(() => {
    if (getLengthDoc) {
      getDocUrl();
    }
  }, [getLengthDoc]);

  const handleUpdateData = () => {
    const noticeId = getNoticeDetailData.id;
    const newBuildings = getNoticeDetailData?.noticeBuildings?.map(
      (build) => build.buildingId
    );

    const payload = {
      actionDeadline: getNoticeDetailData?.actionDeadline
        ? moment(getNoticeDetailData?.actionDeadline).format("DD-MM-YYYY")
        : null,
      actionRequired: getNoticeDetailData?.actionRequired,
      category: getNoticeDetailData?.category,
      description: getNoticeDetailData.description,
      buildings: [...newBuildings],
      targetUser: getNoticeDetailData.targetUser,
      documents: propertyFields.documents,
    };
    setHandleEditDocMode(false);

    dispatch(patchNoticeDetails({ payload, noticeId }));
  };

  const handleRemoveDoc = (get) => {
    const result = propertyFields.documents.filter((url) => {
      return url !== get;
    });
    setPropertyFields((preState) => {
      return { ...preState, documents: result };
    });
  };

  return (
    <Grid className="top-level-container">
      <Grid className="home">
        {isLoading && <Loader />}
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Box className="card" p={2}>
                {handleEditMode === true ? (
                  <EditNoticeDetails
                    {...{
                      getNoticeDetailData,
                      setHandleEditMode,
                      id,
                      setHandleEditDocMode,
                    }}
                  />
                ) : (
                  <>
                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        color="#111120"
                        fontSize={20}
                        lineHeight={1.5}
                      >
                        Notice Details
                      </Typography>
                      <Box>
                        <img alt="..." src={edit} onClick={handleEditButton} />
                      </Box>
                    </Box>
                    <Box my={4}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        my={2}
                        px={1.5}
                      >
                        <Typography color="#111120" lineHeight={2.5}>
                          Title
                        </Typography>
                        <Typography>{getNoticeDetailData?.title} </Typography>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        my={2}
                        px={1.5}
                        bgcolor="#FAFAFA"
                      >
                        <Typography color="#111120" lineHeight={2.5}>
                          Category
                        </Typography>
                        <Typography>
                          {getNoticeDetailData?.category}{" "}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        my={2}
                        px={1.5}
                      >
                        <Typography color="#111120" lineHeight={2.5}>
                          Valid From
                        </Typography>
                        <Typography>
                          {getNoticeDetailData?.validFrom
                            ? getDate(getNoticeDetailData?.validFrom)
                            : "-"}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        my={2}
                        px={1.5}
                        bgcolor="#FAFAFA"
                      >
                        <Typography color="#111120" lineHeight={2.5}>
                          Valid To
                        </Typography>
                        <Typography>
                          {getNoticeDetailData?.validTill
                            ? getDate(getNoticeDetailData?.validTill)
                            : "-"}{" "}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        my={2}
                        px={1.5}
                      >
                        <Typography color="#111120" lineHeight={2.5}>
                          Target User
                        </Typography>
                        <Typography>
                          {getNoticeDetailData?.targetUser?.join(",")}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        my={2}
                        px={1.5}
                        bgcolor="#FAFAFA"
                      >
                        <Typography color="#111120" lineHeight={2.5}>
                          Building Name
                        </Typography>
                        <Typography>
                          {getNoticeDetailData?.noticeBuildings?.map(
                            (item, index) => {
                              return `${item?.building?.name_en} ${
                                getNoticeDetailData?.noticeBuildings.length -
                                  1 ===
                                index
                                  ? ""
                                  : ","
                              }`;
                            }
                          )}
                        </Typography>
                      </Box>
                      <Box alignItems="center" my={2} px={1.5}>
                        <Typography lineHeight={2.5}>Description:-</Typography>
                        <br />
                        <div
                          className="handleContent"
                          dangerouslySetInnerHTML={{
                            __html: getNoticeDetailData?.description,
                          }}
                        />
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box className="card" height="100vh">
                {handleEditDocMode === true ? (
                  <>
                    <Box display="flex" justifyContent="space-between" m={2}>
                      <Typography
                        color="#111120"
                        fontSize={20}
                        lineHeight={1.5}
                      >
                        Edit Documents
                      </Typography>
                      <Box
                        color=" #0C344E"
                        fontSize={16}
                        sx={{ cursor: "pointer", textDecoration: "underLine" }}
                        onClick={handleUpdateData}
                      >
                        Save Changes
                      </Box>
                    </Box>
                    <Grid>
                      <Typography textAlign="center" fontSize="20px">
                        Attach Document
                      </Typography>
                      <OpenDoc
                        setGetImageDataDoc={setGetImageDataDoc}
                        setGetLengthDoc={setGetLengthDoc}
                      />
                    </Grid>
                    <Grid>
                      {propertyFields?.documents?.length > 0 &&
                        propertyFields?.documents?.map((current) => (
                          <Box
                            className="card"
                            display="flex"
                            justifyContent="space-between"
                            p={2}
                            m={2}
                            style={{
                              boxShadow:
                                " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                              borderStyle: "none",
                            }}
                          >
                            <Box display="flex" justifyContent="space-between">
                              <Typography>
                                <img
                                  src={pdfPhoto}
                                  style={{ marginRight: "10px" }}
                                  alt=""
                                />
                                {current
                                  ?.split("/")[3]
                                  ?.split(".")[0]
                                  ?.slice(0, 10)}
                              </Typography>
                              <Typography>
                                <a
                                  href={current}
                                  download
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <RemoveRedEyeIcon />
                                </a>
                                <CloseIcon
                                  onClick={() => handleRemoveDoc(current)}
                                />
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Box display="flex" justifyContent="space-between" m={2}>
                      <Typography
                        color="#111120"
                        fontSize={20}
                        lineHeight={1.5}
                      >
                        Attached Documents
                      </Typography>
                      <Box onClick={handleEditDocButton}>
                        <img src={edit} alt="" />
                      </Box>
                    </Box>
                    <Grid>
                      {propertyFields?.documents?.length > 0 ? (
                        propertyFields?.documents.map((current) => (
                          <Box
                            className="card"
                            display="flex"
                            justifyContent="space-between"
                            p={2}
                            m={2}
                            style={{
                              boxShadow:
                                " 0px 3px 9px rgba(112, 112, 112, 0.22)",
                              borderStyle: "none",
                            }}
                          >
                            <Box display="flex" justifyContent="space-between">
                              <Typography>
                                <img
                                  src={pdfPhoto}
                                  style={{ marginRight: "10px" }}
                                  alt=""
                                />
                                {current
                                  ?.split("/")[3]
                                  ?.split(".")[0]
                                  ?.slice(0, 15)}
                              </Typography>
                              <Typography>
                                <a
                                  href={current}
                                  download
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <DownloadForOfflineIcon />
                                </a>
                              </Typography>
                            </Box>
                          </Box>
                        ))
                      ) : (
                        <Box textAlign="center">No Document</Box>
                      )}
                    </Grid>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default NoticesDetail;
