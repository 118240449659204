import { Button, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import "./UploadDocUI.css";
import CloseIcon from "@mui/icons-material/Close";
import ErrorBox from "../components/ErrorBox";


const Document = [
  {
    value: "Passport",
    lable: "Passport",
  },
  {
    value: "Visa Document",
    lable: "Visa Document",
  },
  {
    value: "Offer Letter",
    lable: "Offer Letter",
  },
];

export default function UploadDocUIStaff({
  updatePropertyfields,
  setUserDocument,
  setOpenDocument,
  openDocument,
  setVisaDocument,
  setPassportDocument,
  setOfferLetterDocument,
}) {
  const [userDoc, setUserDoc] = useState();
  const [docType, setDocType] = useState();

  const [fieldError, setFiledError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (Object.keys(fieldError) == 0 && isSubmit) {
      if (docType == "Visa Document") {
        setVisaDocument({
          id: userDoc?.name + new Date().getTime(),
          type: docType,
          document: userDoc,
        });
      }
      if (docType == "Passport") {
        setPassportDocument({
          id: userDoc?.name + new Date().getTime(),
          type: docType,
          document: userDoc,
        });
      }
      if (docType == "Offer Letter") {
        setOfferLetterDocument({
          id: userDoc?.name + new Date().getTime(),
          type: docType,
          document: userDoc,
        });
      }

      setOpenDocument(!openDocument);
    }
  }, [isSubmit]);

  const handleSave = (e) => {
    e.preventDefault();
    setIsSubmit(!isSubmit);
    // setFiledError(validateDoc(userDoc, docType));
  };

 

  const handleDocRemove = (e) => {
    setUserDoc();
  };

  return (
    <div className="wrapper">
      <div className="container mt-2">
        <input
          type="file"
          id="myDocument"
          style={{ display: "none" }}
          onClick={(event) => {
            event.target.value = null;
          }}
          onChange={(e) => setUserDoc(e.target.files[0])}
        />

        <div className="top mt-3">
          <label htmlFor="myDocument" className="addDocButton">
            {userDoc ? userDoc.name : "+Upload Document"}{" "}
          </label>
          {userDoc ? (
            <CloseIcon onClick={handleDocRemove} className="closeButton" />
          ) : null}
          {fieldError?.document && <ErrorBox mes={fieldError.document} />}
        </div>
        <div className="center mt-3">
          <TextField
            className="bottomOption"
            id="Document"
            select
            label="Document Type"
            // value={propertyfields.document}
            // value={Document[0].value}
            value={docType}
            onChange={(e) => setDocType(e.target.value)}
            variant="standard"
          >
            {Document.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.lable}
              </MenuItem>
            ))}
          </TextField>

          {fieldError?.documentType && (
            <ErrorBox mes={fieldError.documentType} />
          )}
        </div>
        <div className="bottom mt-3">
          <button className="buttonSave" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
