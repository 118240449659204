import React from "react";
import TextField from "@mui/material/TextField";
import "./index.css";
const InputField = ({
    placeholder,
    label,
    value,
    onChange,
    name,
    disabled,
}) => {
    return (
        <div className="mt-5">
            <TextField
                disabled={disabled}
                value={value}
                name={name}
                required
                onChange={onChange}
                id="standard-required"
                placeholder={placeholder}
                variant="standard"
                label={label}
                sx={{
                    width: "275px",
                    borderBottom: ".5px solid #DFE0E0",
                }}
            />
        </div>
    );
};

export default InputField;
