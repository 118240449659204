import { yupResolver } from "@hookform/resolvers/yup";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { patchAddNewBuilding, uploadFile } from "../services/allPropertiesAPI";
import axios from "axios";
import { buildingSchemaForEdit } from "../components/AuthSchema";
import { getMobileNumber } from "../util";
import { removeStringFunction } from "../util/removeStringFunction";
import OpenGallery from "./OpenGallery";
import CountryCodeDropDown from "../components/CountryCodeDropDown";

function EditBuildingDetail({
  propertyFields,
  setHandleEditMode,
  setHandleLoader,
}) {
  const [getImageData, setGetImageData] = useState([]);
  const [getLength, setGetLength] = useState();
  const [imageUrl, setImageUrl] = useState([...propertyFields.images]);
  const [countryCode, setCountryCode] = useState(null);
  const [localImgUrl, setLocalImgUrl] = useState(() => {
    return [...propertyFields?.images];
  });
  const getImageUrl = async () => {
    setHandleLoader(true);
    try {
      const res = await uploadFile(getImageData);
      if (res.data.status === "success") {
        const getUrl = res.data.data.map((current) => current.location);
        setImageUrl([...imageUrl, ...getUrl]);
      } else {
        toast("Document is not uploaded. Please check your connection");
      }
    } catch {}
    setHandleLoader(false);
  };

  useEffect(() => {
    if (getLength) {
      getImageUrl();
    }
  }, [getLength]);

  useEffect(() => {
    setLocalImgUrl([...propertyFields?.images]);
  }, [imageUrl]);

  const buildings = [
    {
      value: "RESIDENTIAL",
      label: "Residential",
    },
    {
      value: "COMMERCIAL",
      label: "Commercial",
    },
    {
      value: "CO_LIVING",
      label: "Co-Living",
    },
    {
      value: "CO_WORKING",
      label: "Co-Working",
    },
    {
      value: "OTHERS",
      label: "Others",
    },
  ];

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      buildingId: propertyFields?.buildingId,
      name_en: propertyFields?.name_en,
      buildingType: propertyFields?.buildingType,
      longitude: propertyFields?.location && propertyFields?.location[0],
      latitude: propertyFields?.location && propertyFields?.location[1],
      address_en: propertyFields?.address_en,
      governmentPropertyId: propertyFields?.governmentPropertyId,
      contactName: propertyFields?.contactName,
      contactEmail: propertyFields?.contactEmail
        ? propertyFields?.contactEmail
        : "",
      contactMobileNumber: propertyFields?.contactMobileNumber,
      url: propertyFields?.url,
      description_en: propertyFields?.description_en,
      images: propertyFields?.images,
      contactCountryCode: propertyFields?.countryCode,
    },
    resolver: yupResolver(buildingSchemaForEdit),
  });

  const [intialValueForCountryCode, contactMobileNumber] = watch([
    "contactCountryCode",
    "contactMobileNumber",
  ]);

  const onSubmit = async (data) => {
    setHandleLoader(true);
    data["images"] = imageUrl;
    data["contactCountryCode"] = getMobileNumber(
      data.contactCountryCode,
      data.contactMobileNumber
    ).countryCode;
    data["contactMobileNumber"] = getMobileNumber(
      data.contactCountryCode,
      data.contactMobileNumber
    ).mobileNumber;
    data["location"] = [data.longitude, data.latitude];
    const getData = removeStringFunction(data);
    const postData = await patchAddNewBuilding(getData);
    if (postData.data.status === "success") {
      setHandleEditMode(false);
      toast("Saved Successfully");
    } else {
      setHandleEditMode(false);
      toast(postData.data.msg);
    }
    setHandleLoader(false);
  };

  const handleRemoveImg = (att) => {
    const filtered = localImgUrl.filter((item) => item !== att);
    setImageUrl(filtered);
  };

  const fetchCountryCode = async () => {
    setHandleLoader(true);
    const res = await axios.get(
      "https://gist.githubusercontent.com/anubhavshrimal/75f6183458db8c453306f93521e93d37/raw/f77e7598a8503f1f70528ae1cbf9f66755698a16/CountryCodes.json"
    );
    setCountryCode(res.data);
    setHandleLoader(false);
  };
  useEffect(() => {
    fetchCountryCode();
  }, []);

  const handleValue = (name, event) => {
    let newValue = event.target.value;
    if (newValue.length > 3) {
      newValue = newValue.slice(0, 15); // restrict to 3 digits
    }
    setValue(`${name}`, newValue);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography color="#111120" fontSize={18}>
              Edit Property Details
            </Typography>
          </Box>
          <Box>
            <Button
              style={{ textDecoration: "underLine" }}
              onClick={() => {
                setHandleEditMode(false);
              }}
            >
              Cancel
            </Button>
            <Button type="submit" style={{ textDecoration: "underLine" }}>
              Save Changes
            </Button>
          </Box>
        </Box>
        <Box
          display="flex"
          style={{ overflowX: "scroll" }}
          className="hiddenScroll"
        >
          <OpenGallery
            setGetImageData={setGetImageData}
            setGetLength={setGetLength}
          />
          <Box m={2} display="flex" gap={2}>
            <Box display="flex" gap={2}>
              {imageUrl.map((curElem, id) => (
                <Box position="relative" width="10rem" height="10rem">
                  <img
                    style={{ height: "100%", width: "100%" }}
                    alt="..."
                    src={curElem}
                    name="images"
                  />
                  <CancelIcon
                    style={{
                      position: "absolute",
                      right: "2px",
                      top: "5px",
                      color: "#000",
                    }}
                    onClick={() => handleRemoveImg(curElem)}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Grid>
          <Box
            className="inputColor"
            width="100%"
            display="flex"
            justifyContent="space-between"
            my={5}
          >
            <Box>
              <Controller
                name="name_en"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    //   error={errors}
                    id="standard-basic"
                    label="Property Name*"
                    variant="standard"
                    inputProps={{
                      maxLength: 50,
                    }}
                    sx={{ width: "300px" }}
                  />
                )}
              />
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "red",
                }}
              >
                {errors?.name_en?.message}
              </Typography>
            </Box>
            <Box>
              <Controller
                name="buildingType"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    id="standard-basic"
                    label="Property Type"
                    variant="standard"
                    sx={{ width: "300px" }}
                  >
                    {buildings.map((option) => (
                      <MenuItem key={option.value} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Box>
          </Box>
          <Box
            className="inputColor"
            width="100%"
            display="flex"
            justifyContent="space-between"
            my={2}
          >
            <Box>
              <Controller
                name="longitude"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="standard-basic"
                    label="Longitude"
                    onChange={(e) => {
                      handleValue("longitude", e);
                    }}
                    type="number"
                    variant="standard"
                    sx={{ width: "300px" }}
                  />
                )}
              />
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "red",
                }}
              >
                {errors?.longitude?.message.slice(0, 28)}
              </Typography>
            </Box>
            <Box>
              <Controller
                name="latitude"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="Latitude"
                    label="Latitude"
                    type="number"
                    onChange={(e) => {
                      handleValue("latitude", e);
                    }}
                    variant="standard"
                    sx={{ width: "300px" }}
                  />
                )}
              />
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "red",
                }}
              >
                {errors?.latitude?.message.slice(0, 28)}
              </Typography>
            </Box>
          </Box>
          <Box
            className="inputColor"
            width="100%"
            display="flex"
            justifyContent="space-between"
            my={5}
          >
            <Controller
              name="address_en"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="standard-basic"
                  inputProps={{
                    maxLength: 100,
                  }}
                  label="Address"
                  variant="standard"
                  sx={{ width: "45%" }}
                />
              )}
            />
            <Controller
              name="governmentPropertyId"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="standard-basic"
                  label=" Government Property Id"
                  inputProps={{
                    maxLength: 35,
                  }}
                  variant="standard"
                  sx={{ width: "45%" }}
                />
              )}
            />
          </Box>
          <Box
            className="inputColor"
            width="100%"
            display="flex"
            justifyContent="space-between"
            my={5}
          >
            <Box>
              <Controller
                name="contactName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="standard-basic"
                    label=" POC Name"
                    inputProps={{
                      maxLength: 50,
                    }}
                    variant="standard"
                    sx={{ width: "300px" }}
                  />
                )}
              />
            </Box>
            <Box>
              <Controller
                name="contactEmail"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="standard-basic"
                    label="POC Email"
                    variant="standard"
                    sx={{ width: "300px" }}
                  />
                )}
              />
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "red",
                }}
              >
                {errors?.contactEmail?.message && "Contact email invalid"}
              </Typography>
            </Box>
          </Box>
          <Box
            className="inputColor"
            width="100%"
            display="flex"
            justifyContent="space-between"
            my={5}
          >
            <Grid width="45%">
              <Box display="flex" marginTop="17px">
                <Box>
                  <CountryCodeDropDown
                    name="contactCountryCode"
                    register={register}
                  />
                </Box>

                <Box marginLeft="10px" marginTop="-16px">
                  <Controller
                    name="contactMobileNumber"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="mobileNumber"
                        variant="standard"
                        label="POC Number"
                        inputProps={{
                          maxLength: 13 - intialValueForCountryCode.length,
                          minLength: 13 - intialValueForCountryCode.length,
                        }}
                        sx={{ width: "220px" }}
                      />
                    )}
                  />
                </Box>
              </Box>
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "red",
                }}
              >
                {errors?.contactMobileNumber?.message}
              </Typography>
            </Grid>
            <Controller
              name="url"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="standard-basic"
                  label=" Url"
                  variant="standard"
                  sx={{ width: "45%" }}
                />
              )}
            />
          </Box>
          <Box className="inputColor" width="100%">
            <Controller
              name="description_en"
              control={control}
              render={({ field }) => (
                <>
                  <label
                    style={{
                      margin: "10px 0 5px 0",
                    }}
                  >
                    Description
                  </label>
                  <TextareaAutosize
                    {...field}
                    aria-label="minimum height"
                    minRows={2}
                    placeholder="Description"
                    maxLength={300}
                    style={{
                      width: 650,
                      paddingLeft: "10px",
                    }}
                  />
                </>
              )}
            />
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

export default EditBuildingDetail;
