import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { patchNoticeDetails } from "../../Redux/NoticeSlice";
import { noticeSchema } from "../../components/AuthSchema";
import BuildingMultiSelectDropdown from "../../components/BuildingMultiSelectDropdown";
import CheckedBox from "../../components/CheckedBox";
import DatePicker from "../../components/DatePicker";
import TextEditor from "../../components/TextEditor";
import Textfiled from "../../components/Textfiled";
import { getAllBuilding } from "../../services/allPropertiesAPI";

const EditNoticeDetails = ({
  getNoticeDetailData,
  setHandleEditMode,
  id,
  handleUpdateData,
}) => {
  const [buildingData, setBuildingData] = useState([]);
  const [description, setDescription] = useState(
    getNoticeDetailData.description
  );

  const NOTICE_CATEGORIES = ["Event", "Announcement"];

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    control,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: {
      title: getNoticeDetailData.title,
      category: getNoticeDetailData.category,
      description: getNoticeDetailData.description,
      validFrom: getNoticeDetailData.validFrom,
      validTill: getNoticeDetailData.validTill,
      actionRequired: getNoticeDetailData.actionRequired,
      actionDeadline: getNoticeDetailData.actionDeadline,
      buildings: getNoticeDetailData.noticeBuildings,
      targetUser: getNoticeDetailData.targetUser,
    },
    mode: "onChange",
    resolver: yupResolver(noticeSchema),
  });

  const [actionRequired, targetUser, buildings] = watch([
    "actionRequired",
    "targetUser",
    "buildings",
  ]);
  const dispatch = useDispatch();

  const fetchBuildingData = async () => {
    const resp = await getAllBuilding();
    setBuildingData(resp?.data?.data?.rows);
  };
  useEffect(() => {
    fetchBuildingData();
  }, []);

  // const handleUpdateData = () => {
  // };
  const onSubmit = (data) => {
    const payload = {
      ...data,
      validFrom: data.validFrom
        ? moment(data.validFrom).format("DD-MM-YYYY")
        : null,
      validTill: data.validTill
        ? moment(data.validTill).format("DD-MM-YYYY")
        : null,
      actionDeadline: data.actionDeadline
        ? moment(data.actionDeadline).format("DD-MM-YYYY")
        : null,
      description: description,
    };
    if (!dirtyFields["validTill"]) {
      delete payload.validTill;
    }
    payload.buildings = data.buildings.map((ele) => {
      if (ele.building) {
        return ele.buildingId;
      } else {
        return ele.id;
      }
    });
    setHandleEditMode(false);
    const noticeId = getNoticeDetailData.id;
    dispatch(patchNoticeDetails({ payload, noticeId }));
  };

  const handleInputChange = (event) => {
    const arrData = targetUser;
    const value = event.target.value;
    if (targetUser.includes(value)) {
      const res = arrData.indexOf(value);
      arrData.splice(res, 1);
    } else {
      arrData.push(value);
    }
    setValue("targetUser", arrData);
  };

  const handleInputChangeBuilding = (event) => {
    const value = event.target.value;
    let elementExists = false;
    for (let i = 0; i < buildings.length; i++) {
      if (
        buildings[i].id === value.id ||
        buildings[i].buildingId === value.id
      ) {
        elementExists = true;
        break;
      }
    }
    if (!elementExists) {
      setValue("buildings", [...buildings, value]);
    }
  };

  const handleDelete = (CheckedEle, val) => {
    // const result = buildings?.filter((curElem) => {
    //   return curElem?.building?.name_en !== CheckedEle;
    // });
    // setValue("buildings", result);
    if (val === "old") {
      const result = buildings?.filter((curElem) => {
        return curElem?.building?.name_en !== CheckedEle;
      });
      setValue("buildings", result);
    } else {
      const result = buildings?.filter((curElem) => {
        return curElem?.name_en !== CheckedEle;
      });
      setValue("buildings", result);
    }
  };

  return (
    <Grid>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Box>
          <Typography color="#111120" fontSize={18}>
            Edit Notice Details
          </Typography>
        </Box>
        <Box>
          <Button
            style={{ textDecoration: "underLine" }}
            onClick={() => {
              setHandleEditMode(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            style={{ textDecoration: "underLine" }}
            onClick={handleSubmit(onSubmit)}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
      <Box className="d-flex justify-content-between">
        <Box sx={{ width: "45%" }}>
          <Textfiled
            label={"Title*"}
            type="text"
            width="100%"
            name="title"
            setValue={setValue}
            register={register}
          />
          <Typography
            sx={{
              fontStyle: "italic",
              fontSize: "13px",
              color: "red",
            }}
          >
            {errors?.title?.message}
          </Typography>
        </Box>
        <Box sx={{ width: "45%" }}>
          <Controller
            name="category"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                id="standard-basic"
                label="Category*"
                variant="standard"
                sx={{ width: "300px", marginTop: "12px" }}
              >
                {NOTICE_CATEGORIES.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Typography
            sx={{
              fontStyle: "italic",
              fontSize: "13px",
              color: "red",
            }}
          >
            {errors?.category?.message}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ margin: "40px 0px 70px 0px" }}>
        <TextEditor setDescription={setDescription} description={description} />
      </Box>
      <Box className="d-flex justify-content-between mt-3">
        <Box>
          <DatePicker
            label="Valid From"
            Controller={Controller}
            control={control}
            name="validFrom"
            register={register}
            width={"300px"}
          />
        </Box>
        <Box>
          <DatePicker
            label="Valid To"
            Controller={Controller}
            control={control}
            name="validTill"
            register={register}
            width={"300px"}
          />
        </Box>
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          columnGap: "30px",
          width: "100%",
          marginTop: "28px",
        }}
      >
        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            width: "300px",
            marginLeft: "-12px",
          }}
        >
          <CheckedBox
            Controller={Controller}
            control={control}
            defaultValues={actionRequired}
            className="handleOpcity"
            name="actionRequired"
            register={register}
          />
          <Typography sx={{ marginTop: "10px" }}>Action Required</Typography>
        </Box>
        <Box sx={{ marginLeft: "30px" }}>
          {actionRequired && (
            <DatePicker
              label="Deadline Date"
              Controller={Controller}
              control={control}
              name="actionDeadline"
              register={register}
              width={"300px"}
            />
          )}
        </Box>
      </Box> */}
      <Box sx={{ marginTop: "20px" }}>
        <FormGroup name="targetUser" onChange={handleInputChange}>
          <Box sx={{ display: "flex" }}>
            {" "}
            <FormControlLabel
              value="Owner"
              control={<Checkbox checked={targetUser.includes("Owner")} />}
              label="Owner"
            />
            <FormControlLabel
              value="Resident"
              control={<Checkbox checked={targetUser.includes("Resident")} />}
              label="Resident"
            />
          </Box>
        </FormGroup>
      </Box>
      <Box>
        <BuildingMultiSelectDropdown
          label="Select Building"
          name="buildings"
          setValue={setValue}
          width="395px"
          innerWidth="395px"
          buildings={buildings}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          columnGap: "10px",
          rowGap: "10px",
          marginTop: "10px",
        }}
      >
        {buildings.map((ele) => (
          <Chip
            sx={{
              width: "150px",
              display: "flex",
              justifyContent: "space-between",
            }}
            label={ele?.building ? ele?.building?.name_en : ele?.name_en}
            variant="outlined"
            onDelete={() => {
              handleDelete(
                ele?.building ? ele?.building?.name_en : ele?.name_en,
                ele?.building ? "old" : "new"
              );
            }}
          />
        ))}
      </Box>
    </Grid>
  );
};

export default EditNoticeDetails;
