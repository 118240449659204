import React from "react";
import StaffDetailPage from "./StaffDetail";

export default function StaffDetailMain() {
  return (
    <>
      <div className="top-level-container">
        <StaffDetailPage />
      </div>
    </>
  );
}
