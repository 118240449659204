import React from "react";
import { TextField } from "@mui/material";
const Textfiled = ({
  label,
  type,
  width,
  register,
  name,
  disabled,
  IntialvalueForCountryCode = null,
  maxLength,
  autofocus,
  defaultValue,
  value = null,
  setValue,
  setCheckChangeValue,
  checkOwnerCreateValue,
  shrink,
}) => {
  return (
    <TextField
      id="standard-helperText"
      variant="standard"
      {...register(name)}
      type={type}
      label={label}
      autofocus={autofocus}
      disabled={disabled}
      defaultValue={defaultValue}
      InputLabelProps={{
        shrink: shrink,
      }}
      onChange={() => {
        if (!checkOwnerCreateValue) {
          setCheckChangeValue(true);
        }
      }}
      inputProps={{
        maxLength: IntialvalueForCountryCode
          ? 13 - IntialvalueForCountryCode?.length
          : maxLength,
        minLength: 13 - IntialvalueForCountryCode?.length,
      }}
      style={{
        marginTop: "13px",
        width: width,
      }}
    />
  );
};

export default React.memo(Textfiled);
