import { Button, Chip, MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";
import SearchBar from "../components/SearchBar";
import LeaseTable from "../components/LeaseTable";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLeaseData } from "../Redux/LeaseSlice";
import { useEffect } from "react";
import { useMemo } from "react";
import LeaseFilterDailoc from "./LeaseFilterDailoc";
import { getflatDetailforFilter } from "../services/allCommunityApi";
import moment from "moment";
import { buildingContext } from "../login/AuthProvider";

const Lease = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [postPerpage, setpostPerpage] = useState(10);
  const [open, setOpen] = useState(false);
  const [selectedFilterType, setSelectedFilterType] = useState("");
  const [getFLatDetailForFilter, setGetFLatDetailForFilter] = useState([]);
  const [selectedPurpose, setSelectedPurpose] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const { selectedBuilding } = React.useContext(buildingContext);
  const buildingId = selectedBuilding;
  const [showTotalData, setShowTotalData] = useState("");
  const [searchText, setSearchText] = useState("");

  const FLAT_USAGE = ["Commercial", "Residential", "Industrial"];
  const REQUEST_STATUS = [
    {
      key: "Active",
      value: "Active",
    },
    {
      key: "In Active",
      value: "Expired",
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  let formatStartDate = startDate ? moment(startDate).format("YYYY-MM-DD") : "";
  let formatEndDate = endDate ? moment(endDate).format("YYYY-MM-DD") : "";

  useEffect(() => {
    dispatch(
      getLeaseData({
        page,
        postPerpage,
        selectedPurpose,
        formatStartDate,
        formatEndDate,
        selectedUnit,
        selectedStatus,
        buildingId,
        searchText,
      })
    );
  }, [page, searchText, buildingId]);
  const { leaseListData } = useSelector((state) => state.LeaseData);
  const totalCount = useMemo(() => {
    const newTotalCount = Math.ceil(leaseListData?.count / postPerpage);
    setShowTotalData(leaseListData?.count);
    return newTotalCount;
  }, [postPerpage, leaseListData?.count]);

  const flatDetailFilter = async () => {
    const resp = await getflatDetailforFilter(buildingId);
    setGetFLatDetailForFilter(resp.data.data.rows);
  };

  const filterded = useMemo(() => {
    return getFLatDetailForFilter.filter((item) => item.id === selectedUnit);
  }, [selectedUnit]);

  const handleDelete = (param) => {
    if (param === "selectedPurpose") {
      setSelectedPurpose("");
      AddFilter(
        "",
        formatStartDate,
        formatEndDate,
        selectedUnit,
        selectedStatus
      );
    } else if (param === "selectedUnit") {
      setSelectedUnit("");
      AddFilter(
        selectedPurpose,
        formatStartDate,
        formatEndDate,
        "",
        selectedStatus
      );
    } else if (param === "selectedStatus") {
      setSelectedStatus("");
      AddFilter(
        selectedPurpose,
        formatStartDate,
        formatEndDate,
        selectedUnit,
        ""
      );
    } else {
      setEndDate("");
      setStartDate("");
      AddFilter(selectedPurpose, "", "", selectedUnit, selectedStatus);
    }
  };

  const onSearchChange = (value) => {
    setSearchText(value);
  };
  const AddFilter = (
    selectedPurpose,
    formatStartDate,
    formatEndDate,
    selectedUnit,
    selectedStatus
  ) => {
    dispatch(
      getLeaseData({
        page,
        postPerpage,
        selectedPurpose: selectedPurpose,
        formatStartDate: formatStartDate,
        formatEndDate: formatEndDate,
        selectedUnit: selectedUnit,
        selectedStatus: selectedStatus,
        buildingId,
        searchText,
      })
    );
  };

  return (
    <div className="home">
      <div>
        <LeaseFilterDailoc
          {...{
            open,
            setOpen,
            handleClose,
            setSelectedFilterType,
            selectedFilterType,
            AddFilter,
            selectedPurpose,
            formatStartDate,
            formatEndDate,
            selectedUnit,
            selectedStatus,
          }}
        >
          {selectedFilterType === "Date" ? (
            <div className="d-flex justify-content-between">
              <input
                type="date"
                name="startDate"
                placeholder="Start Date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="startDate"
                style={{ marginRight: "10px", marginTop: "20px" }}
                // min={min}
              />
              <input
                name="endDate"
                type="date"
                placeholder="End Date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="startDate"
                style={{ marginTop: "20px" }}
                // min={startDate}
              />
            </div>
          ) : selectedFilterType === "Purpose" ? (
            <div className="d-flex">
              <TextField
                select
                label="Select Purpose"
                inputProps={{ maxLength: 100 }}
                multiline
                name="flatUsage"
                variant="standard"
                value={selectedPurpose}
                onChange={(e) => {
                  setSelectedPurpose(e.target.value);
                }}
                style={{
                  fontFamily: "Barlow",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  width: "300px",
                  lineHeight: "19px",
                }}
              >
                {FLAT_USAGE?.map((option) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
          ) : selectedFilterType === "Unit No" ? (
            <div className="d-flex">
              <TextField
                select
                label="Select Unit"
                value={selectedUnit}
                onChange={(e) => {
                  setSelectedUnit(e.target.value);
                }}
                variant="standard"
                style={{
                  fontFamily: "Barlow",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  width: "300px",
                  lineHeight: "19px",
                }}
              >
                {getFLatDetailForFilter?.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option?.id}>
                      {option?.name_en}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
          ) : selectedFilterType == "Lease status" ? (
            <div>
              <TextField
                select
                label="Select Lease Status"
                value={selectedStatus}
                onChange={(e) => {
                  setSelectedStatus(e.target.value);
                }}
                variant="standard"
                style={{
                  fontFamily: "Barlow",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  width: "300px",
                  lineHeight: "19px",
                }}
              >
                {REQUEST_STATUS?.map((option) => {
                  return (
                    <MenuItem key={option.key} value={option?.value}>
                      {option?.key}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
          ) : (
            ""
          )}
        </LeaseFilterDailoc>
        <div className="d-flex justify-content-between">
          <div>
            <h4>Lease</h4>
          </div>
          <div className="d-flex">
            <div>
              <SearchBar
                placeholder="search"
                onChange={onSearchChange}
                maxLength="20px"
              />
            </div>
            <div>
              <Button
                variant="contained"
                className="Button"
                style={{
                  border: "1px solid #0C344E",
                  color: "#0C344E",
                  background: "white",
                  width: "12rem",
                  height: "48px",
                  fontFamily: "Barlow",
                  fontStyle: "normal",
                  fontweight: "500",
                  fontSize: "14px",
                  borderRadius: "10px",
                  lineHeight: "19px",
                  margintLeft: "24px",
                  textTransform: "capitalize",
                  marginRight: "10px",
                }}
                onClick={() => {
                  flatDetailFilter();
                  handleClickOpen();
                  setSelectedFilterType("");
                }}
              >
                Add Filters
              </Button>
            </div>

            <div>
              <Link to="/addlease" style={{ textDecoration: "none" }}>
                <Button
                  className="Button"
                  variant="contained"
                  color="success"
                  style={{
                    border: "1px solid #0C344E",
                    color: "#0C344E",
                    background: "white",
                    width: "12rem",
                    height: "48px",
                    fontFamily: "Barlow",
                    fontStyle: "normal",

                    fontweight: "500",
                    fontSize: "14px",
                    borderRadius: "10px",
                    lineHeight: "19px",
                    margintLeft: "24px",
                    textTransform: "capitalize",
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: "0.5rem",
                      marginLeft: "-0.8rem",
                    }}
                  >
                    <path
                      d="M12 5V19"
                      stroke="#0C344E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5 12H19"
                      stroke="#0C344E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Add New Lease
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2">
        {selectedPurpose && (
          <Chip
            label={selectedPurpose}
            variant="outlined"
            onDelete={() => handleDelete("selectedPurpose")}
          />
        )}
        {endDate && (
          <Chip
            label={`${startDate} to ${endDate}`}
            variant="outlined"
            onDelete={() => handleDelete("endDate")}
          />
        )}
        {filterded[0]?.name_en && (
          <>
            <Chip
              label={`${filterded[0]?.name_en}`}
              variant="outlined"
              onDelete={() => handleDelete("selectedUnit")}
            />
          </>
        )}
        {selectedStatus && (
          <>
            <Chip
              label={selectedStatus === "Active" ? "Active" : "In Active"}
              variant="outlined"
              onDelete={() => handleDelete("selectedStatus")}
            />
          </>
        )}
      </div>
      <div className="my-5 ">
        <LeaseTable
          {...{
            totalCount,
            setPage,
            postPerpage,
            page,
            showTotalData,
          }}
        />
      </div>
    </div>
  );
};

export default Lease;
