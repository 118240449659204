import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import AfterNoonslot from "./AfterNoonslot";
import Eveningslot from "./Eveningslot";
import Morningslot from "./Morningslot";

const SlotSelect = ({register,index,setValue }) => {

    const [active, setActive] = useState("morning");
    const [selectedslot, setSelectedslot] = useState("");


  return (
    <div style={{ width: "600px", marginTop: "20px" }}>
      <div >
        <div className="showSlot">
          <Typography>Slot Preference Time</Typography>
          <div className=" d-flex mt-3  slotTitle">
            <p
              onClick={() => {
                setActive("morning");
              }}
              style={{
                cursor: "pointer",
                marginRight: "2rem",
              }}
              className={active === "morning" ? "activeS" : "inactiveS"}
            >
              Morning
            </p>
            <p
              className={active === "afternoon" ? "activeS" : "inactiveS"}
              style={{
                cursor: "pointer",
                marginRight: "2rem",
              }}
              onClick={() => {
                setActive("afternoon");
              }}
            >
              Afternoon
            </p>
            <p
              className={active === "evening" ? "activeS" : "inactiveS"}
              style={{
                cursor: "pointer",
                marginRight: "2rem",
              }}
              onClick={() => {
                setActive("evening");
              }}
            >
              Evening
            </p>
          </div>
          <Grid mt={-1}>
            {active === "morning" && (
              <Morningslot
                {...{
                  selectedslot,
                  setSelectedslot,
                  register,
                  index,
                  setValue,
                }}
              />
            )}
            {active === "afternoon" && (
              <AfterNoonslot
                {...{
                  selectedslot,
                  setSelectedslot,
                  register,
                  index,
                  setValue,
                }}
              />
            )}
            {active === "evening" && (
              <Eveningslot
                {...{
                  selectedslot,
                  setSelectedslot,
                  register,
                  index,
                  setValue,
                }}
              />
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default SlotSelect;
