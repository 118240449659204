import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import SearchBar from "../components/SearchBar";
import Button from "@mui/material/Button";
import StaffTable from "../components/StaffTable";
import {
  AllStaffsAPI,
  exportAllStaffsData,
} from "../services/allPropertiesAPI";
import { CSVLink } from "react-csv";
import { buildingContext } from "../login/AuthProvider";
import ExportCsvFile from "../components/ExportCsvFile";
import { toast } from "react-toastify";

export default function Home() {
  const [staff, setStaff] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalCount, settotalCount] = useState(0);
  const [postPerpage, setPostPerPage] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const [error, setError] = useState(false);
  const { selectedBuilding } = React.useContext(buildingContext);
  let buildingId = selectedBuilding;

  const onSearchChange = async (value) => {
    setSearchText(value);
    const resp = await AllStaffsAPI(
      currentPage,
      postPerpage,
      value,
      buildingId
    );
    let totalData = resp?.data?.data?.count;

    let pagesCount = Math.ceil(totalData / postPerpage);
    settotalCount(pagesCount);
    setStaff(resp?.data?.data?.rows);
  };
  const [data, setData] = useState([]);
  const [handleDefault, setHandleDefault] = useState([]);
  const UnitData = async () => {
    const resp = await exportAllStaffsData(buildingId);
    if (resp?.data?.status === "success") {
      setData(resp?.data?.data?.rows);
      setHandleDefault(resp?.data?.data?.rows);
      setError(false);
    } else {
      toast("Csv file is not downloaded please try again.");
    }
    if (resp?.data?.status === "fail") {
      setError(true);
    }
  };

 

  return (
    <>
      <div className="home">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="Property" style={{ marginRight: "40px" }}>
            Staffs
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "auto",
              order: 2,
              gap: "1.5rem",
            }}
          >
            <SearchBar
              style={{ marginRight: "40px" }}
              onChange={onSearchChange}
              placeholder="Search"
              maxLength="20"
            />
            <ExportCsvFile
              function={UnitData}
              csvFile={data}
              handleDefault={handleDefault}
              fileName="staff Data"
            />
            

            <div className="properties-button">
              <Link to="/addstaff" style={{ textDecoration: "none" }}>
                <Button
                  className="Button"
                  variant="contained"
                  color="success"
                  style={{
                    border: "1px solid #0C344E",
                    color: "#0C344E",
                    background: "white",
                    width: "12rem",
                    height: "48px",
                    fontFamily: "Barlow",
                    marginLeft: "15px",
                    fontStyle: "normal",
                    fontweight: "500",
                    fontSize: "14px",
                    lineHeight: "19px",
                    borderRadius: "10px",
                    textTransform: "capitalize",
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: "0.5rem",
                      marginLeft: "-0.8rem",
                    }}
                  >
                    <path
                      d="M12 5V19"
                      stroke="#0C344E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5 12H19"
                      stroke="#0C344E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Add New Staff
                </Button>
              </Link>
            </div>
          </div>
        </div>
        {error ? (
          <div className="d-flex justify-content-center mt-5">
            <p className="nofound">No Data Found</p>
          </div>
        ) : (
          <StaffTable
            searchText={searchText}
            postPerpage={postPerpage}
            currentPage={currentPage}
            totalCount={totalCount}
            setcurrentPage={setcurrentPage}
            settotalCount={settotalCount}
            staff={staff}
            setStaff={setStaff}
          />
        )}
      </div>
    </>
  );
}
