import { axiosClient, BASE_URL } from "./axiosClient";

export const getAllRequests = async (
  pageNo,
  dataLimit,
  search,
  buildingId,
  startDate,
  endDate,
  requestType,
  selectedTab,
  requestIsUrgent,
  selectedFlatForFilter,
  reload
) => {
  const resp = await axiosClient.get(
    `/maintenances/admin?page=${pageNo}&limit=${dataLimit}&search=${search}&buildingId=${buildingId}&startDate=${startDate}&endDate=${endDate}&categoryId=${requestType}&status=${selectedTab}&isUrgent=${requestIsUrgent}&flatId=${selectedFlatForFilter}`
  );
  return resp;
};
export const exportAllRequests = async (
  pageNo,
  dataLimit,
  buildingId,
  formatStartDate,
  formatEndDate,
  requestType,
  selectedTab,
  requestIsUrgent,
  selectedFlatForFilter
) => {
  const resp = await axiosClient.get(
    `/maintenances/admin?page=${pageNo}&limit=${dataLimit}&buildingId=${buildingId}&startDate=${formatStartDate}&endDate=${formatEndDate}&type=${requestType}&status=${selectedTab}&isUrgent=${requestIsUrgent}&flatId=${selectedFlatForFilter}`
  );
  return resp;
};
export const getGenerateInvoice = async (pageNo, dataLimit, buildingId) => {
  const resp = await axiosClient.get(
    `${BASE_URL}/maintenances/admin?page=${pageNo}&limit=${dataLimit}&buildingId=${buildingId}`
  );
  return resp;
};
export const getVisitorManagement = async (
  pageNo,
  dataLimit,
  startDate,
  endDate,
  buildingId
) => {
  const resp = await axiosClient.get(
    `${BASE_URL}/visitings/logs?page=${pageNo}&limit=${dataLimit}&startDate=${startDate}&endDate=${endDate}&buildingId=${buildingId}`
  );
  return resp;
};
export const getAllRequestProperty = async (urlParams) => {
  const resp = await axiosClient.get(
    `${BASE_URL}/maintenances/admin/${urlParams}`
  );
  return resp;
};
export const postStaffDeatail = async (data) => {
  const resp = await axiosClient.post(
    `${BASE_URL}/maintenances/assign-staff`,
    data
  );
  return resp;
};
export const getAssignStaffdetail = async () => {
  const resp = await axiosClient.get(`/staffs/admin`);
  return resp;
};
export const deleteStaffDeatail = async (data) => {
  const resp = await axiosClient.patch(
    `${BASE_URL}/maintenances/remove-staff`,
    data
  );
  return resp;
};
export const getGenerateCatalouge = async (urlParams) => {
  const resp = await axiosClient.get(
    `${BASE_URL}/maintenance-catalogues/request-payment/${urlParams}`
  );
  return resp;
};
export const getCatalougeType = async () => {
  const resp = await axiosClient.get(
    `${BASE_URL}/maintenance-catalogues/charges/types`
  );
  return resp;
};
export const getCatalougeTypyByCategory = async (key) => {
  const resp = await axiosClient.get(
    `${BASE_URL}/maintenance-catalogues/charges/category?category=${key}`
  );
  return resp;
};
export const changeStaffStatus = async (data) => {
  const resp = await axiosClient.patch("/maintenances/complete", data);
  return resp;
};
export const changeStatusOnDelete = async (data) => {
  const resp = await axiosClient.patch("/maintenances/complete", data);
  return resp;
};
export const changeGenarateStatus = async (data) => {
  const resp = await axiosClient.patch("/maintenances/complete", data);
  return resp;
};
export const getMentinanceStatus = async (maintenanceId) => {
  const resp = await axiosClient.get(`/maintenances/statuses/${maintenanceId}`);
  return resp;
};

export const getStatusForFilter = async () => {
  const resp = await axiosClient.get(`/maintenances/statuses`);
  return resp;
};
export const changePassword = async (data) => {
  const resp = await axiosClient.patch("/admins/change-password", data);
  return resp;
};
export const getPaymentRequest = async (requestID) => {
  const resp = await axiosClient.get(
    `/maintenance-catalogues/request-payment/${requestID}`
  );
  return resp;
};
export const AddGuardData = async (data) => {
  const resp = await axiosClient.post(`/guards/sign-up`, data);
  return resp;
};
export const getGuardById = (guardId) => {
  const resp = axiosClient.get(`/guards/admin/${guardId}`);
  return resp;
};
export const updateGuard = ({ guardId, payload }) => {
  const resp = axiosClient.patch(`/guards/admin/${guardId}`, payload);
  return resp;
};
export const getGuardData = async (
  currentPage,
  postPerpage,
  search,
  selectedBuilding
) => {
  if (search.length > 0) {
    const resp = axiosClient.get(
      `/guards/admin?page=${currentPage}&limit=${postPerpage}&search=${search}`
    );
    return resp;
  } else {
    const resp = axiosClient.get(
      `/guards/admin?page=${currentPage}&limit=${postPerpage}&buildingId=${
        selectedBuilding ? selectedBuilding : ""
      }`
    );
    return resp;
  }
};
export const VisitirDetailById = (visitorId) => {
  const resp = axiosClient.get(`/visitings/${visitorId}`);
  return resp;
};
export const AddRequest = async ({ userFullData }) => {
  let data = {
    type: userFullData?.type,
    isUrgent: userFullData?.isUrgent,
    isBuilding: userFullData?.isBuilding,
    description: userFullData?.description ? userFullData?.description : "",
    files: userFullData?.files,
    flatId: userFullData?.flatId,
    time: userFullData.time,
  };
  const resp = axiosClient.post(`/maintenances/admin`, data);
  return resp;
};
export const getMantinanceType = () => {
  const resp = axiosClient.get(`/maintenances/admin/categories/all`);
  return resp;
};

export const GetStaffAvailabilty = async ({ staffData, formatStartDate }) => {
  const resp = await axiosClient.get(
    `/staffs/availability/${staffData.id}?date=${formatStartDate}`
  );
  return resp;
};

export const updateServisesRequest = async (requestId, { data }) => {
  const resp = await axiosClient.patch(
    `/maintenances/admin/${requestId}`,
    data
  );
  return resp;
};
export const addMultipleRequest = async (data) => {
  return axiosClient.post(`/maintenances/admin/multiple`, data);
};

export const addImageApi = async (requestId, uplaodDomDocument) => {
  const resp = await axiosClient.post(
    `/maintenances/files/${requestId}`,
    uplaodDomDocument
  );
  return resp;
};
export const UpdateStaffTime = async (requestId, timeSlotId) => {
  const resp = await axiosClient.patch(
    `${BASE_URL}/maintenances//admin/timings/${requestId}`,
    timeSlotId
  );
  return resp;
};

export const TotalCountStatus = async (buildingId, data) => {
  const resp = await axiosClient.get(
    `/maintenances/admin/request-count?buildingId=${buildingId}&startDate=${data.formatStartDate}&endDate=${data.formatEndDate}&categoryId=${data.requestType}&isUrgent=${data.requestIsUrgent}&flatId=${data.selectedFlatForFilter}`
  );
  return resp;
};

export const getSoftDeleteGuard = (guardId) => {
  const resp = axiosClient.delete(`/guards/${guardId}`);
  return resp;
};
