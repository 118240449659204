import React from "react";
import LeftNav from "../../components/leftNav";
import TopNav from "../../components/topNav";
import Visitordetail from "./Visitordetail";

const UnitDetail = () => {
  return (
    <>
      {/* <TopNav /> */}
      <div className="top-level-container">
        {/* <LeftNav /> */}
        <Visitordetail />
      </div>
    </>
  );
};

export default UnitDetail;
