import React, { useEffect, useState } from "react";
import "./index.css";
import SearchBar from "../components/SearchBar";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import UnitTable from "../components/UnitTable";
import { Link } from "react-router-dom";
import { AllUnitAPI, exportFile } from "../services/allPropertiesAPI";
import { Grid, Snackbar } from "@mui/material";
import { CSVLink } from "react-csv";
import { getAllUnitDataExport } from "../services/allCommunityApi";
import { buildingContext } from "../login/AuthProvider";
import ExportCsvFile from "../components/ExportCsvFile";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 10,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function Units() {
  // const InitialPage = localStorage.getItem("unitPage");
  const [units, setUnit] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalCount, settotalCount] = useState(0);
  const [postPerpage, setPostPerPage] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const [cvnFile, setCvnFile] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [csvimport, setCsvimport] = useState(false);
  const [totalShowCount, settotalShowCount] = useState();
  const [snackbarInfo, setSnackbarInfo] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { selectedBuilding } = React.useContext(buildingContext);
  const { vertical, horizontal, open } = snackbarInfo;
  let buildingId = selectedBuilding;
  let count = 0;
  const onSearchChange = async (value) => {
    setSearchText(value);

    const resp = await AllUnitAPI(currentPage, postPerpage, value, buildingId);
    let totalData = resp.data.data.count;

    let pagesCount = Math.ceil(totalData / postPerpage);
    settotalCount(pagesCount);
    setUnit(resp.data.data.rows);
  };

  useEffect(() => {
    const sendDoc = async () => {
      let docFormData = new FormData();
      docFormData.append("file", cvnFile);
      docFormData.append("buildingId", units[0].buildingId);
      let params = {
        docFormData,
        buildingId,
      };

      try {
        const resp = await exportFile(docFormData, buildingId);
        if (resp.data.status === "fail") {
          toast("Select the Building");
        } else {
          toast(resp.data.msg);
          setCsvimport(true);
        }
      } catch {}
    };

    cvnFile && sendDoc();
  }, [cvnFile]);

  const handleClose = () => {
    setSnackbarInfo({
      open: false,
      vertical: "top",
      horizontal: "center",
    });
  };
  const [data, setData] = useState([]);
  const [handleDefault, setHandleDefault] = useState([]);

  const UnitData = async () => {
    const resp = await getAllUnitDataExport(buildingId);
    let csvFile = resp.data.data.rows.map((item) => {
      let obj = {};
      obj.BuildingName = item.building.name_en;
      obj.Floor = item.floor;
      obj.FlatNumber = item.name_en;
      obj.Size = item.size + "sqft";
      obj.Bathroom = item.flatInfo.bathroom;
      obj.Bedroom = item.flatInfo.bedroom;
      obj.ContractEndDate =
        item.contractDetails &&
        item?.contractDetails[0]?.contractEndDate?.slice(0, 10);
      obj.ContractStartDate =
        item.contractDetails &&
        item?.contractDetails[0]?.contractStartDate?.slice(0, 10);
      obj.Status = `${
        item.contractDetails?.isExpired === true ? "vacant" : "occupied"
      }`;

      return obj;
    });
    setData(csvFile);
    setHandleDefault(csvFile);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };
  return (
    <>
      <div className="home">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="Property" style={{ marginRight: "40px" }}>
            Units
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "auto",
              order: 2,
            }}
          >
            <SearchBar
              style={{ marginRight: "40px" }}
              onChange={onSearchChange}
              placeholder="Search"
            />

            <div className="properties-button">
              <Link to="/addunit" style={{ textDecoration: "none" }}>
                <Button
                  className="Button"
                  variant="contained"
                  color="success"
                  style={{
                    border: "1px solid #0C344E",
                    color: "#0C344E",
                    background: "white",
                    width: "187px",
                    height: "48px",
                    fontFamily: "Barlow",
                    fontStyle: "normal",
                    fontweight: "500",
                    fontSize: "16px",
                    lineHeight: "19px",
                    borderRadius: "10px",
                    textTransform: "capitalize",
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 5V19"
                      stroke="#0C344E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5 12H19"
                      stroke="#0C344E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Add New Unit
                </Button>
              </Link>
            </div>
            <input
              type="file"
              id="exportinput"
              accept=".csv"
              style={{ display: "none" }}
              onChange={(e) => {
                setCvnFile(e.target.files[0]);
              }}
              onClick={(e) => {
                e.target.value = null;
              }}
            />

            <label htmlFor="exportinput">
              <div
                className="Button"
                variant="contained"
                color="success"
                style={{
                  border: "1px solid #0C344E",
                  color: "#0C344E",
                  background: "white",
                  width: "150px",
                  height: "48px",
                  fontFamily: "Barlow",
                  fontStyle: "normal",
                  fontweight: "500",
                  fontSize: "16px",
                  lineHeight: "19px",
                  textTransform: "capitalize",
                  textAlign: "center",
                  margin: "0px 12px",
                  paddingTop: "13px",
                  borderRadius: "10px",
                  boxShadow:
                    " 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                }}
              >
                Import Data
              </div>
            </label>

            <ExportCsvFile
              function={UnitData}
              csvFile={data}
              handleDefault={handleDefault}
              fileName="All Request Data"
            />
          </div>
        </div>
        <Grid>
          <UnitTable
            searchText={searchText}
            postPerpage={postPerpage}
            currentPage={currentPage}
            totalCount={totalCount}
            setcurrentPage={setcurrentPage}
            settotalCount={settotalCount}
            setUnit={setUnit}
            units={units}
            csvimport={csvimport}
            setCsvimport={setCsvimport}
            // totalData={totalData}
          />
        </Grid>
      </div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={snackbarInfo.open}
        // variant="success"
        autoHideDuration={3000}
        onClose={handleClose}
        message="File Uploaded Successfully"
        key={vertical + horizontal}
      />
    </>
  );
}
