import { useEffect, useState } from "react";
import "../index.css";
import CatalogForm from "./CatalogForm";
import CatalogForm2 from "./CatalogForm2";
import SearchBar from "../../../components/SearchBar";
import RoundIcons from "../../../components/RoundIcons";
import { useNavigate } from "react-router-dom";
import { axiosClient } from "../../../services/axiosClient";
import CircularProgress from "@mui/material/CircularProgress";

const ChargesCatalouge = () => {
    const navigate = useNavigate();

    const handleEditNavigate = () => {
        navigate("/editchargescatalouge");
    };

    const handleCreateNavigate = () => {
        navigate("/createchargescatalouge");
    };

    //Get Charges Catalogues
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [data3, setData3] = useState(null);
    // we are getting api response in three array of object we need to concatinate them into one array
    // when we get resonse in one array of object then we will replace it one state
    const getCatalogues = async () => {
        try {
            setLoading(true);
            let resp = await axiosClient.get("maintenance-catalogues/charges");
            let result = resp.data.data;
            setData(result.Engineering);
            setData2(result.Housekeeping);
            setData3(result.Other);
            setLoading(false);
        } catch (error) {
           
            setLoading(false);
        }
    };
  // 
    useEffect(() => {
        getCatalogues();
    }, []);

    // Charges By CATALOG Concat
    const CategoryData = data?.concat(data2)?.concat(data3);

    // const CategoryData2 = data?.concat(data2)?.concat(data3);

    // const [newdata, setNewData] = useState(null);

    // useEffect(() => {
    //   if (CategoryData2) {
    //     setNewData([...CategoryData2]);
    //   }
    // }, [CategoryData2]);


    if (loading) {
        return (
            <div
                className="d-flex justify-content-center"
                style={{ margin: "0 auto", alignItems: "center" }}
            >
                <CircularProgress color="secondary" />
            </div>
        );
    }

    return (
        <div className="home bg-white" style={{ borderTopLeftRadius: "50px" }}>
            <div className="p-4">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="width:400px">
                        <h5 className="h5_margin">Charges Catalogue</h5>
                    </div>
                    <div className=" d-flex align-items-center gap-3">
                        <div>
                            <SearchBar />
                        </div>
                        <RoundIcons />
                        <button className="Cbtn" onClick={handleCreateNavigate}>
                            Create
                        </button>
                        <button className="Sbtn" onClick={handleEditNavigate}>
                            Edit
                        </button>
                    </div>
                </div>
                <div>
                    {CategoryData?.map((item) => {
                        return (
                            <CatalogForm CategoryData={item} key={item.id} />
                        );
                    })}
                </div>
                {/* <div className="Cform2">
          <CatalogForm2 />
        </div> */}
            </div>
        </div>
    );
};

export default ChargesCatalouge;
